import React from "react";
import '../globalComponents/globalComponents.scss'
import routes from "../../constants/routes";
import {Link} from "react-router-dom";
import {Rate} from 'antd';
import {Assets} from "../../assets";

export default (props) => {
    const item = props.item;
    const maxTimeLimit = 150;
    let downTime = 0;

    return <div key={props.key} className={props.filter ? "col-lg-4 col-md-4 col-sm-6 comXS" : ""}
                onMouseDown={() => downTime = new Date().getTime()}
                onTouchStart={() => downTime = new Date().getTime()}
                onClick={() => {
                    // const time = new Date().getTime() - downTime;
                    // if (time < maxTimeLimit)
                        props.history.push(routes.viewSupplier + `/${item.companyUrl}`,{  currentState: props.currentState})
                }}>
        <div className="single-feature supplierHolder">
            <div className="thumb" style={{background: `#f5f5f5`, backgroundSize: `cover`, backgroundPosition: `center`}}>
                {/*<img className={"product objectFit"}*/}

                {/*<img className={"product objectFit"}*/}
                {/*     src={item.profilePicture !== null ? item.profilePicture : Assets.placeholderCompany} alt="img"/>*/}

                <img className={item.profilePicture !== null ? "image-setup":"product objectFit"}
                     src={item.profilePicture !== null ? item.profilePicture : Assets.placeholderCompany} alt="img"/>
                {
                    item.verifiedSeller && <img className="verified ml-2" src={Assets.badge} alt='img'/>
                }

            </div>
            <div className="details supplierDetail">
                <div className={'card-content'}>
              
                    <h6 className="title readeal-top" style={{width: '60%'}}>{item.companyName}</h6>


                    <div className="display-flex align-items-center">
                        <Rate
                            disabled
                            value={item.ratingValue}
                            allowHalf={true}
                        />

                        <p className="mb-0">&nbsp;({item.ratingCount})</p>
                    </div>

                    <ul className="info-list mt-2">
                        {/*<li><i className="fa fa-map-marker "/>{item.location !== null ? item.location : 'N/A'}</li>*/}

                        <p className="categories">
                            {/*{*/}
                            {/*    item.categories.map((cat, index) => (*/}
                            {/*        index < 2 &&*/}
                            {/*        <p key={index} className="c3">{index === 0 ? cat.name : ", " + cat.name}</p>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <p className="c3">{item.categories.length > 0 && item.categories[0].name}</p>
                        </p>
                    </ul>
                </div>
                {/*<div className="separator mt-3 mb-2"/>*/}
                {/*<ul className="contact-list">*/}
                {/*    <li className="readeal-top"><Link className="btn btn-yellow">View Details</Link></li>*/}
                {/*</ul>*/}

                <div className="btn-view-details">
                    <div className={'new-rule'}></div>
                    <span>VIEW MORE</span>
                </div>
            </div>
        </div>
    </div>
}
