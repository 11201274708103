import ApiService from './apiHandler';

export async function addNewPurchaseRequest(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `request`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updatePurchaseRequest(data) {
    const apiObject = {};
    apiObject.method = 'PUT';
    apiObject.endpoint = `request`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getRequest(index,size,data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `request/get-all?index=${index}&size=${size}`;
    apiObject.body = data
    apiObject.authentication = true
    apiObject.offpreloader = true
    return await ApiService.callApi(apiObject);
}

export async function favRequest(id) {
    const apiObject = {};
    apiObject.method = 'PUT';
    apiObject.endpoint = `request/${id}/favourite`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getSubmittedQuotationsForRequest(index,size,data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `request/quotation/submitted?index=${index}&size=${size}`;
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function quoteForRequest(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `quotation`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updateQuoteStatus(rID,qId,status) {
    const apiObject = {};
    apiObject.method = 'PUT';
    apiObject.endpoint = `request/${rID}/quotation/${qId}/update/${status}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updateRequestStatus(rID,status) {
    const apiObject = {};
    apiObject.method = 'Patch';
    apiObject.endpoint = `request/${rID}/${status}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getRequestDetails(url) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `request/${url}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}
