import React, { useState } from "react";
import strings from "../../locals/string.json";
import constants, { DISTRICTS } from "../../constants/constants";
import Files from "react-files";
import apiConfig from "../../service/apiConfig";
import SecondaryEmail from "./secondaryEmail";
import { Assets } from "../../assets";
// import { Select } from "antd";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Select from "react-select";


const registration = strings.registration;
const pr = strings.purchaseRequest;
const toast = strings.toast;
const { Option } = Select
const Name_REGEX = /^[A-z ]+$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const WEB_SITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const PASSWORD_REGEX = /^.{7,}$/;

export default (props) => {
    const [view, setView] = useState(false);
    let companyTypes = [];
    let districts = [];
    props.state.companyTypeData.map((type, index) => {
        companyTypes.push({
            value: type,
            label: type.companyType,
            name: "companyType"
        })
    })

    DISTRICTS.map((type, index) => {
        districts.push({
            value: type,
            label: type,
            name: "location"
        })
    })

    const validPw = (props.state.confirmPassword !== '' && (props.state.confirmPassword !== props.state.password));
    return <form className="contact-form-wrap contact-form-bg" onSubmit={event => event.preventDefault()}>
        <h6>{registration.personalInformation}</h6>
        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.firstName}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="firstName" required="required" onChange={props.onTextChange}
                        value={props.state.firstName}
                        placeholder="First name" />
                    {
                        (props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.lastName}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="lastName" required="required" onChange={props.onTextChange}
                        value={props.state.lastName} placeholder="Last name" />
                    {
                        (props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidLName}</span>
                            : null
                    }
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.designation} {registration.optional}</p>
                <div className="rld-single-input">
                    <input type="text" name="designation" placeholder={registration.designation}
                        onChange={props.onTextChange}
                        value={props.state.designation}
                        className={(props.state.designation.trim() !== '' && !Name_REGEX.test(props.state.designation)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.designation.trim() !== '' && !Name_REGEX.test(props.state.designation)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidDName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.email}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="email" required="required" onChange={props.onTextChange}
                        value={props.state.email} placeholder="Email address" />
                    {
                        (props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidEmail}</span>
                            : null
                    }
                </div>
            </div>
        </div>


        {/*{*/}
        {/*    props.state.userType === constants.USER_SELLER &&*/}
        {/*        <>*/}
        {/*            <p className="inputLabel">{registration.secondaryEmail}</p>*/}
        {/*            <SecondaryEmail onChangeSecondaryEmail={props.onChangeSecondaryEmail}*/}
        {/*                            emailCount={props.state.packages[props.state.subscription_plan].emailCount ?? 2}/>*/}
        {/*        </>*/}
        {/*}*/}

        <p className="inputLabel mb-2">{registration.mobile}<span>*</span></p>
        <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="LK"
            placeholder="Mobile number"
            value={props.state.mobile}
            className={(props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ? 'input-error' : ''}
            onChange={value => props.onTextChange({ target: { name: 'mobile', value: value ?? '' } })}
        />
        {
            (props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ?
                <span
                    className={'fields-error-text'}>{toast.invalidMobile}</span>
                : null
        }

        <h6 className="mt-5">{registration.password} <i
            onClick={() => setView(!view)}
            className={"fa pointer " + (view ? 'fa-eye' : 'fa-eye-slash')} /></h6>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.password}<span>*</span></p>
                <div className="rld-single-input">
                    <input type={view ? 'text' : "password"} name="password" required="required"
                        onChange={props.onTextChange}
                        autoComplete={false}
                        value={props.state.password} placeholder={registration.password}
                        className={(props.state.password.trim() === '' || !PASSWORD_REGEX.test(props.state.password)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.password.trim() === '' || !PASSWORD_REGEX.test(props.state.password)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.emptyPassword}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.confirmPassword}<span>*</span></p>
                <div className="rld-single-input">
                    <input type={view ? 'text' : "password"}
                        name="confirmPassword" required="required" onChange={props.onTextChange}
                        value={props.state.confirmPassword}
                        style={{ border: validPw && '1px solid red' }} placeholder="Confirm password" />
                </div>
                {
                    validPw && <span className="text-danger">*Password not matched!</span>
                }
            </div>
        </div>

        <h6 className="mt-5">{registration.companyInformation}</h6>

        {/*<div className="row">*/}
        {/*<div className="col-md-5 col-12">*/}
        {/*    <p className="inputLabel">{registration.companyImage}*/}
        {/*        {props.state.userType === constants.USER_SELLER && <span>*</span>}</p>*/}
        {/*    <div className="display-flex flex-row align-items-center mt-3">*/}
        {/*        <img className="companyImage mr-3" src={props.state.image !== null ? props.state.image : Assets.companyLogo}*/}
        {/*             alt='image'/>*/}
        {/*        <Files*/}
        {/*            className='files-dropzone-file'*/}
        {/*            onChange={props.onFilesChange1}*/}
        {/*            accepts={["image/png", "image/jpg", "image/jpeg"]}*/}
        {/*            multiple={false}*/}
        {/*            maxFileSize={10485760}*/}
        {/*            minFileSize={0}*/}
        {/*            onError={error => console.log(error)}*/}
        {/*            clickable*/}
        {/*        >*/}
        {/*            <p className="choose2 m-0">{pr.chooseCI}</p>*/}
        {/*        </Files>*/}
        {/*        {*/}
        {/*            props.state.image !== null &&*/}
        {/*            <p className="remove m-0" onClick={()=>props.removeImage('PROFILE')}>Remove</p>*/}
        {/*        }*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*{*/}
        {/*    props.state.userType === constants.USER_SELLER &&*/}
        {/*    <div className="col-md-7 col-12">*/}
        {/*        <p className="inputLabel">{registration.coverImage}<span>*</span></p>*/}
        {/*        <div className="display-flex flex-row align-items-center mt-3">*/}
        {/*            <img className="companyCoverImage mr-3" src={props.state.coverImage !== null ? props.state.coverImage : Assets.companyLogo}*/}
        {/*                 alt='image'/>*/}
        {/*            <Files*/}
        {/*                className='files-dropzone-file'*/}
        {/*                onChange={props.onFilesChange2}*/}
        {/*                accepts={["image/png", "image/jpg", "image/jpeg"]}*/}
        {/*                multiple={false}*/}
        {/*                maxFileSize={10485760}*/}
        {/*                minFileSize={0}*/}
        {/*                onError={error => console.log(error)}*/}
        {/*                clickable*/}
        {/*            >*/}
        {/*                <p className="choose2 m-0">{pr.chooseCI}</p>*/}
        {/*            </Files>*/}
        {/*            {*/}
        {/*                props.state.coverImage !== null &&*/}
        {/*                <p className="remove m-0" onClick={()=>props.removeImage('COVER')}>Remove</p>*/}
        {/*            }*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*}*/}

        {/*</div>*/}


        <p className="inputLabel">{registration.companyName}{props.state.userType === constants.USER_SELLER ?
            <span>*</span> : registration.optional}</p>
        <div className="rld-single-input">
            <input type="text" name="companyName" required="required" onChange={props.onTextChange}
                onBlur={props.checkCompanyUrlAvailability}
                className={props.state.userType === constants.USER_SELLER && (props.state.companyName.trim() === '') && props.state.isValidation ? 'input-error' : ''}
                value={props.state.companyName} placeholder="Company name" />
            {
                props.state.userType === constants.USER_SELLER && (props.state.companyName.trim() === '') && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.invalidCName}</span>
                    : null
            }
        </div>

        {
            props.state.userType === constants.USER_SELLER &&
            <>
                <p className="inputLabel">{registration.companyUrl}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="companyUrl" placeholder={registration.companyUrl}
                        onChange={props.onTextChange}
                        onBlur={props.checkCompanyUrlAvailability}
                        className={props.state.companyUrl.trim() === apiConfig.frontEnd && props.state.isValidation ? 'input-error' : ''}
                        value={props.state.companyUrl} />
                    {
                        props.state.companyUrl.trim() === apiConfig.frontEnd && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidCUrl}</span>
                            : null
                    }
                </div>
            </>
        }


        {/* <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel mb-2">{registration.hotline}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Hot line"
                    value={props.state.hotline}
                    onChange={value => props.onTextChange({ target: { name: 'hotline', value: value ?? '' } })}
                    className={(props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline)) && props.state.isValidation ? 'input-error' : ''}
                />
                {
                    (props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline)) && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel mb-2">{registration.land}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Land line"
                    value={props.state.land}
                    onChange={value => props.onTextChange({ target: { name: 'land', value: value ?? '' } })}
                    className={props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land) && props.state.isValidation ? 'input-error' : ''}
                />
                {
                    (props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land)) && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
        </div> */}

        {
            props.state.userType === constants.USER_SELLER &&
            <div className="row">
                <div className="col-md-6 col-sm-12 ">
                    <p className="inputLabel">{registration.address}<span>*</span></p>
                    <div className="rld-single-input">
                        <input
                            className={props.state.address.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                            type="text" name="address" placeholder="Address" onChange={props.onTextChange}
                            value={props.state.address} />
                        {
                            props.state.address.trim() === '' && props.state.isValidation ?
                                <span
                                    className={'fields-error-text'}>{toast.address}</span>
                                : null
                        }
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 ">
                    <p className="inputLabel" style={{ marginBottom: 5 }}>{registration.district}<span>*</span></p>
                    <Select
                        showSearch
                        value={[
                            {
                                label: props.state.location === null ? registration.district : props.state.location,
                                value: props.state.location === null ? registration.district : props.state.location
                            }
                        ]}
                        placeholder="District"
                        optionFilterProp="children"
                        className={props.state.location === null && props.state.isValidation ? 'input-error' : ''}
                        options={districts}
                        // filterOption={(input, option) =>
                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                        onChange={value => props.onTextChange({ target: { name: 'location', value: value.value } })}
                    >
                        {/* {
                            DISTRICTS.map(item => <Option value={item}>{item}</Option>)
                        } */}
                    </Select>

                    {
                        props.state.location === null && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.district}</span>
                            : null
                    }
                </div>
            </div>
        }

        {/* company Type */}
        {
            props.state.userType === constants.USER_SELLER &&
            <div className="row">
                <div className="col-md-6 col-sm-12 ">
                    <p className="inputLabel" style={{ marginBottom: 5 }}>{registration.companyType}<span>*</span></p>
                    <Select
                        showSearch
                        value={[
                            {
                                label: props.state.companyType === null ? "Company Type" : props.state.companyType.companyType,
                                value: props.state.companyType === null ? "Company Type" : props.state.companyType.companyType
                            }
                        ]}
                        placeholder="Company Type"
                        optionFilterProp="children"
                        options={companyTypes}
                        className={props.state.companyType === null && props.state.isValidation ? 'input-error' : ''}
                        // filterOption={(input, option) =>
                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                        onChange={value => props.onTextChange({ target: { name: 'companyType', value: value.value } })}
                    >
                        {
                            companyTypes.map((item, index) => <Option value={index}>{item.label}</Option>)
                        }
                    </Select>

                    {
                        props.state.companyType === null && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.companyType}</span>
                            : null
                    }
                </div>
            </div>
        }

        <p className="inputLabel">{registration.website} {registration.optional}</p>
        <div className="rld-single-input">
            <input type="text" name="website" placeholder="Web site" onChange={props.onTextChange}
                value={props.state.website}
                className={(props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website)) && props.state.isValidation ? 'input-error' : ''}
            />
            {
                (props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website)) && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.invalidWeb}</span>
                    : null
            }
        </div>

        {
            props.state.userType === constants.USER_SELLER &&
            <>
                <h6 className="mt-4">{registration.billingProf} {registration.optional}</h6>

                <Files
                    className='files-dropzone-file'
                    onChange={props.onFileChange}
                    accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf"]}
                    multiple={false}
                    maxFileSize={52428800}
                    minFileSize={0}
                    onError={error => console.log(error)}
                    clickable
                >
                    <div className="file-wrapper">
                        <p className="choose">{registration.chooseFile}</p>
                        {
                            props.state.billingProof &&
                            <p className="view-file" onClick={props.onViewFile}>{registration.viewFile}</p>
                        }
                    </div>
                </Files>
                <div className="mb-2 mt-1 ml-4" style={{ flexDirection: 'row', display: 'flex' }}>
                    <p>{props.state.filename}&nbsp;&nbsp;</p>
                    <p >{pr.fileSize}</p>
                </div>

            </>
        }
    </form>
}
