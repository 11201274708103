import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import routes from "../../constants/routes";
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Intro extends Component {


    render() {

        const inlineStyle = {
            backgroundImage: `url(${Assets.ad})`
        }

        return !this.props.loggedInStatus && <div className="city-intro-area pd-bottom-70">
            <div className="intro-area pd-top-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md col-sm-6-6">
                            <a href="/pricing" className={"single-intro-media media single-intro-media-active"}>
                                <div className="media-left">
                                    <img src={ Assets.sell } alt={ 'imagealt' } />
                                </div>
                                <div className="media-body">
                                    <h4>Looking for the buy products or service?</h4>
                                    <p>Start your free trail with B2B.LK to better experience in online market. View Price Guides.</p>
                                </div>
                            </a>
                        </div>

                        <div className="col-md col-sm-6-6">
                            <a href="/pricing" className={"single-intro-media media "}>
                                <div className="media-left">
                                    <img src={ Assets.rent } alt={ 'imagealt' } />
                                </div>
                                <div className="media-body">
                                    <h4>Want to sell your products or service?</h4>
                                    <p>Start your free trail with B2B.LK to better experience in online market. View Price Guides.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(Intro));
