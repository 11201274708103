import React from 'react'
import {Modal} from "react-bootstrap";
import '../purchaseRequest/requests.scss'
import strings from "../../locals/string.json";
import Files from "react-files";
import * as commonFunc from "../../constants/commonFunc";
import {validateQuoteDetails} from "../../constants/validation";
import {createQuoteDetails} from "../../constants/commonFunc";
import {quoteForRequest} from "../../service/request";
import {ToastUtil} from "../../constants/toastUtil";
import {Select} from "antd";
import {CURRENCY} from "../../constants/constants";

const purchaseRequest = strings.purchaseRequest;
const {Option} = Select;

class AddQuoteModal extends React.Component {
    state = {
        quotePrice: '',
        description: '',
        currency: 'LKR',
        attachment: undefined,
        attachmentFile: undefined,
        attachmentType: undefined,
    }

    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                attachmentType: file.type,
                attachmentFile: file,
                attachment: imageDataUrl
            });
        }
    };

    onButtonClick = () => {
        if (validateQuoteDetails(this.state)) {
            quoteForRequest(createQuoteDetails({...this.state, id: this.props.item.id}))
                .then(response => {
                    if (response.success) {
                        this.props.reload();
                        this.clearAll()
                        ToastUtil.showSuccessToast(response.msg)
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    clearAll = () => {
        this.setState({
            quotePrice: '',
            description: '',
            currency: 'LKR',
            attachment: undefined,
            attachmentFile: undefined,
            attachmentType: undefined,
        })

        this.props.close()
    }

    render() {

        return <Modal
            className="updateRequest"
            show={this.props.open}
            onHide={this.clearAll}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>Add Quotation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="contact-form-wrap contact-form-bg">
                    <h6 className="mt-4" style={{marginBottom: 5}}>{purchaseRequest.quotationDetails}<span className={'red-star'}>*</span></h6>
                    <div className="row mb-3">
                        <div className="col-md-2 col-sm-12 mb-2">
                            <Select
                                value={this.state.currency}
                                showSearch
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={value => this.setState({currency: value})}
                            >
                                {
                                    CURRENCY.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="col-md-10 col-sm-12 ">
                            <div className="form-group rld-single-input mt-0">
                                <input type={'number'} name="quotePrice" required="required"
                                       onChange={this.onTextChange}
                                       value={this.state.quotePrice} placeholder={purchaseRequest.quotePrice}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-group rld-single-input">
                        <textarea rows={5} name="description" required="required" onChange={this.onTextChange}
                                  value={this.state.description} maxLength={1000}
                                  placeholder={purchaseRequest.quoteDescription}/>
                        <span
                            className={`description-text-limit ${this.state.description.length >= 1000 ? 'description-text-limit-error' : null}`}>{this.state.description.length}/1000</span>
                    </div>

                    <h6 className="mt-4 mb-0">{purchaseRequest.attachment}<span className={'red-star'}>*</span></h6>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3">
                            <Files
                                className='files-dropzone-file'
                                onChange={this.onFilesChange}
                                accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf"]}
                                multiple={false}
                                maxFileSize={10485760}
                                minFileSize={0}
                                onError={error => console.log(error)}
                                clickable
                            >
                                <div className="file-wrapper">
                                    <p className="choose">{purchaseRequest.chooseFile}</p>
                                    {
                                        this.state.attachment &&
                                        <p className="view-file"
                                           onClick={() => {
                                               commonFunc.getB64ToBlob(this.state.attachment, this.state.attachmentType)
                                           }}>{purchaseRequest.viewFile}</p>
                                    }
                                </div>
                            </Files>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-white" onClick={this.clearAll}>{purchaseRequest.cancel}</button>
                <button className="btn btn-yellow" onClick={this.onButtonClick}>{purchaseRequest.addQuote}</button>
            </Modal.Footer>
        </Modal>
    }
}

export default AddQuoteModal;
