import React, { useEffect } from "react";
import strings from "../../locals/string.json";
import Files from "react-files";
import 'antd/dist/antd.css';
import { Checkbox, DatePicker, Select } from "antd";
import moment from "moment";
import constants, { CURRENCY, DISTRICTS } from "../../constants/constants";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import {
    EMAIL_REGEX
} from "../../constants/validation";
import Cookies from "js-cookie";
import filter from "../filter/filter";


const toast = strings.toast;
const { Option } = Select
const purchaseRequest = strings.purchaseRequest;
const registration = strings.registration;
const Name_REGEX = /^[A-z ]+$/;

export default (props) => {
    useEffect(() => {
    }, [props]);


    function disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    return <form className="contact-form-wrap contact-form-bg">
        <h6>{purchaseRequest.reqInfo}</h6>

        <p className="inputLabel">{purchaseRequest.headline}<span>*</span></p>
        <div className="rld-single-input">
            <textarea className={props.state.title.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                rows={2} name="title" required="required" onChange={props.onTextChange}
                value={props.state.title} placeholder={purchaseRequest.headline} />
            {
                props.state.title.trim() === '' && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.purchaseRequestTitle}</span>
                    : null
            }

        </div>

        <p className="inputLabel">{purchaseRequest.description}<span>*</span></p>
        <div className="rld-single-input">
            <textarea maxLength={1000}
                className={props.state.description.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                rows={5} name="description" required="required" onChange={props.onTextChange}
                value={props.state.description} placeholder={purchaseRequest.description} />

            {
                props.state.description.trim() === '' && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.descriptionError}</span>
                    : null
            }
            <span
                className={`description-text-limit ${props.state.description.length >= 1000 ? 'description-text-limit-error' : null}`}>{props.state.description.length}/1000</span>
        </div>

        <div className="row">

            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{purchaseRequest.qty}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={props.state.quantity.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="quantity" required="required" onChange={props.onTextChange}
                        value={props.state.quantity} placeholder={purchaseRequest.qty} />
                    {
                        props.state.quantity.trim() === '' && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.qtyError}</span>
                            : null
                    }
                </div>
            </div>

            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{purchaseRequest.estimatedValue + purchaseRequest.optional}</p>
                <div className="row">
                    <div className="col-md-4 col-4 pr-0">
                        <div className="rld-single-input">
                            <Select
                                value={props.state.currency}
                                showSearch
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={value => props.onTextChange({ target: { name: 'currency', value: value } })}
                            >
                                {
                                    CURRENCY.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="col-md-8 col-8  pl-0">
                        <div className="rld-single-input">
                            <input type="number" name="estimatedValue" required="required" onChange={props.onTextChange}
                                // onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                placeholder={purchaseRequest.estimatedValue}
                                className={props.state.estimatedValue.trim() !== '' && Name_REGEX.test(props.state.estimatedValue) ? 'input-error' : ''}
                                value={props.state.estimatedValue} />
                            {
                                props.state.estimatedValue.trim() === '' && Name_REGEX.test(props.state.estimatedValue) ?
                                    <span
                                        className={'fields-error-text'}>{toast.estimatedValue}</span>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div className="row">
            <div className="col-md-6 col-sm-12">
                <Checkbox className="mt-4" checked={props.state.urgent}
                    onChange={props.onCheck}>{purchaseRequest.urgent}</Checkbox>
            </div>
        </div>


        <div className="row">
            <div className="col-md-6 col-sm-12">
                <p className="inputLabel mb-2">{purchaseRequest.attachment + purchaseRequest.optional}</p>
                <Files
                    className='files-dropzone-file'
                    onChange={props.onFileChange}
                    accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf", 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                    multiple={false}
                    maxFileSize={52428800}
                    minFileSize={0}
                    onError={error => console.log(error)}
                    clickable
                >
                    <div className="file-wrapper">
                        <p className="choose">{purchaseRequest.chooseFile}</p>
                        {
                            props.state.attachment &&
                            <p className="view-file" onClick={props.onViewFile}>{purchaseRequest.viewFile}</p>
                        }
                    </div>

                </Files>
                <div className="mb-2 mt-1 ml-4" style={{ flexDirection: 'row', display: 'flex' }}>
                    <p>{props.state.filename}&nbsp;&nbsp;</p>
                    <p >{purchaseRequest.fileSize}</p>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <p className="inputLabel mb-2">{purchaseRequest.closingDate}<span>*</span></p>
                <DatePicker
                    value={moment(props.state.closingDate)}
                    placeholder={purchaseRequest.closingDate}
                    allowClear={false}
                    className={props.state.closingDate === '' && props.state.isValidation ? 'input-error' : ''}
                    disabledDate={disabledDate}
                    onChange={(date, string) => props.onTextChange({ target: { name: 'closingDate', value: string } })}
                    format={constants.DATE_FORMAT}
                />
                {
                    props.state.closingDate === '' && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.closingDateError}</span>
                        : null
                }
            </div>
        </div>


        <div className="row">
            <div className="col-md-6 col-sm-12">
                {/*<p className="inputLabel mb-2">{purchaseRequest.preferredContactMethod + purchaseRequest.optional}</p>*/}
                <p className="inputLabel mb-2">{purchaseRequest.preferredContactMethod}<span>*</span></p>
                <Select
                    defaultValue={props.state.preferredContactMethod}
                    showSearch
                    placeholder="Preferred Contact Method"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={value => props.onTextChange({ target: { name: 'preferredContactMethod', value: value } })}
                >
                    <Option value={constants.TELEPHONE}>Call</Option>
                    <Option value={constants.WHATSAPP}>Whatsapp</Option>
                    <Option value={constants.EMAIL}>Email</Option>
                    <Option value={constants.ALL}>All</Option>
                </Select>
            </div>
            {
                Cookies.get(constants.ACCESS_TOKEN) === undefined ?
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            {
                                props.state.preferredContactMethod === constants.EMAIL || props.state.preferredContactMethod === constants.ALL ?
                                    <div className="col-md-12 col-sm-12 ">
                                        <p className="inputLabel">{registration.email}<span>*</span></p>
                                        <div className="rld-single-input">
                                            <input
                                                className={(props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ? 'input-error' : ''}
                                                type="text" name="email" required="required"
                                                onChange={props.onTextChange}
                                                value={props.state.email} placeholder={registration.email} />
                                            {
                                                (props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ?
                                                    <span
                                                        className={'fields-error-text'}>{toast.invalidEmail}</span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                props.state.preferredContactMethod === constants.TELEPHONE || props.state.preferredContactMethod === constants.WHATSAPP || props.state.preferredContactMethod === constants.ALL ?
                                    <div className="col-md-12 col-sm-12">
                                        <p className="inputLabel mb-2">{registration.mobile}<span>*</span></p>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="LK"
                                            placeholder="Mobile number"
                                            value={props.state.mobile}
                                            className={(props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ? 'input-error' : ''}
                                            onChange={value => props.onTextChange({
                                                target: {
                                                    name: 'mobile',
                                                    value: value ?? ''
                                                }
                                            })}
                                        />
                                        {
                                            (props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ?
                                                <span
                                                    className={'fields-error-text'}>{toast.invalidMobile}</span>
                                                : null
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    : null
            }
        </div>

        <div className="row">
            <div className="col-md-6 col-sm-12">
                <p className="inputLabel mb-2">{purchaseRequest.district + purchaseRequest.optional}</p>
                <Select
                    showSearch
                    placeholder={purchaseRequest.district}
                    optionFilterProp="children"
                    defaultValue={props.state.district}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={value => props.onTextChange({ target: { name: 'district', value: value } })}
                >
                    {
                        DISTRICTS.map(item => <Option value={item}>{item}</Option>)
                    }
                </Select>
            </div>
        </div>
    </form>
}
