export const __DEV__ = window.location.hostname === 'localhost' || window.location.hostname === 'cdev.b2b.lk';

const constants = {
    USER_SELLER: 'B2B_SELLER',
    B2B_EXPIRED_SELLER: 'B2B_EXPIRED_SELLER',
    USER_BUYER: 'B2B_BUYER',
    USER_GUEST: 'B2B_GUEST',
    E_TENDER_SELLER: 'E_TENDER_SELLER',

    AWARDED: 'WON',
    PENDING: 'PENDING',
    INTERESTED: 'INTERESTED',
    NOT_INTERESTED: 'NOT_INTERESTED',
    REJECTED: "REJECTED",

    TELEPHONE: 'CALL',
    EMAIL: 'EMAIL',
    WHATSAPP: 'WHATSAPP',
    ALL: 'ALL',

    DATE_FORMAT: 'YYYY/MM/DD',
    DATE_FORMAT_EXTEND: 'YYYY MMM DD',

    ACCESS_TOKEN: 'FpZDWwZxnE',
    USER_OBJ: 'FpZDWwZxnEXakWkL',
    REFRESH_TOKEN: 'RtEw67ZUI6bg',
    BASIC_AUTH: 'QjJCX1VTRVI6',
    BASIC_AUTH_E_TENDERS: 'QjJCX0VfVEVOREVSX1VTRVI6',
    VERIFY_USER_NOTE: "VERIFY_USER_NOTE",
    EXISTING_B2B_BUYER: 600,
    EXISTING_B2B_SELLER: 601,
    EXISTING_E_TENDER_SELLER: 602,
    user: 'https://muktiindiauk.com/images/team/administrato.png',
    // gatewayUrl: 'https://cbcmpgs.gateway.mastercard.com/form/version/52/merchant/WEBXPATOKLKR/session.js',
    // gatewayUrl: 'https://test-gateway.mastercard.com/form/version/52/merchant/WEBXPATOKLKR/session.js',
    // gatewayUrl: 'https://test-gateway.mastercard.com/form/version/55/merchant/WEBXPATOKLKR/session.js',
    gatewayUrl: 'https://test-gateway.mastercard.com/form/version/55/merchant/0020008/session.js',
    // reCaptureSiteKey: '6Lfm2zYcAAAAAPkApsUgt9b3lB8AMtqtedOxh6cQ',
    reCaptureSiteKey: '6Lf1CqYlAAAAAHSMOLbkwTt92HYz5NhK-KbZoSgb',
    wordLimit: 1000,
    inquiryWordLimit: 100,
    default: 'DEFAULT',
    VIEW_TENDER: '5052536000b74de7ab67b005c858ef69',
    REDIRECT_TO_HOME: "REDIRECT_TO_HOME",
}

export const categoryActions = {
    GET_ALL: 'GET_ALL',
    AVAILABLE: 'AVAILABLE',
    POPULAR: 'POPULAR'
}

export const CURRENCY = ['LKR', 'USD']

export default constants;


export const QUOTATIONS_STATUS = [
    {
        key: "Awarded",
        value: "WON"
    },
    {
        key: "Interested",
        value: "INTERESTED"
    },
    {
        key: "Rejected",
        value: "NOT_INTERESTED"
    },
    {
        key: "Pending",
        value: "PENDING"
    }
]

export const QUOTATIONS_STATUS_SELLER = [
    {
        key: "PENDING",
        value: "PENDING"
    },
    {
        key: "AWARDED",
        value: "WON"
    },
    {
        key: "REJECTED",
        value: "NOT_INTERESTED"
    }
]

export const REQUEST_STATUS = [
    {
        key: "On Going",
        value: "ONGOING"
    },
    {
        key: "Completed",
        value: "AWARDED"
    },
    // {
    //     key: "Rejected",
    //     value: "REJECTED"
    // },

]

export const DISTRICTS = [
    "Colombo", "Gampaha", "Kalutara", "Puttalam", "Kurunegala", "Jaffna", "Kilinochchi", "Mannar",
    "Mullaitivu", "Vavuniya", "Anuradhapura", "Polonnaruwa", "Matale", "Kandy",
    "Nuwara Eliya", "Kegalle", "Ratnapura", "Trincomalee", "Batticaloa", "Ampara", "Badulla", "Monaragala", "Hambantota", "Matara", "Galle"
]

export const categories = [
    {
        id: 0,
        name: "Computer & IT",
        subCategory: [
            {
                id: 0,
                name: "Computer Software",
                checked: false
            },
            {
                id: 1,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 2,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 3,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 1,
        name: "Agriculture",
        subCategory: [
            {
                id: 4,
                name: "Software Computer",
                checked: false
            },
            {
                id: 5,
                name: "consumables & Computer Hardware",
                checked: false
            },
            {
                id: 6,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 7,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 2,
        name: "Furniture",
        subCategory: [
            {
                id: 8,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 9,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 10,
                name: "Computer Software",
                checked: false
            },
            {
                id: 11,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 3,
        name: "Constructions",
        subCategory: [
            {
                id: 12,
                name: "Computer Software",
                checked: false
            },
            {
                id: 13,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 14,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 15,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 4,
        name: "Kids",
        subCategory: [
            {
                id: 16,
                name: "Computer Software",
                checked: false
            },
            {
                id: 17,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 18,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 19,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    }
]

export const packages = [
    {
        id: 0,
        subscriptionName: "Trial",
        validityDuration: "1 Month",
        amountOfCategory: 3,
        amountOfUsers: 3,
        price: 0
    },
    {
        id: 1,
        packageName: "Bronze",
        validityDuration: "1 Month",
        categoryCount: 3,
        userCount: 3,
        price: 28000
    },
    {
        id: 2,
        subscriptionName: "Silver",
        validityDuration: "2 Month",
        amountOfCategory: 3,
        amountOfUsers: 2,
        price: 48000
    },
    {
        id: 3,
        subscriptionName: "Gold",
        validityDuration: "2 Month",
        amountOfCategory: 3,
        amountOfUsers: 2,
        price: 65000
    }
]

export const popularSuppliers = [
    {
        id: 0,
        companyName: 'Vegas Landville Apartment',
        firstName: "Test",
        lastName: "Test",
        hotLine: '0382222222',
        designation: 'Software Engineer',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        location: 'Kaluthara',
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Lacinia dui, amet, ut netus tempor, eget ut vitae massa. Eu tristique nisi, enim in risus blandit sed. Velit condimentum feugiat velit amet integer. Tristique nunc pharetra aenean etiam massa sem. Morbi lacus interdum velit quam risus. Sem vitae morbi purus nisl massa adipiscing sit diam habitant. At amet gravida cursus cursus congue nisl vitae amet. Tristique eu facilisis massa convallis. Cursus sodales aliquam ut fringilla blandit arcu. Metus pharetra magna nunc, donec vitae massa sit risus. At diam, in nunc iaculis tristique amet dictum massa. \n' +
            '\n' +
            'Lacinia dui, amet, ut netus tempor, eget ut vitae massa. Eu tristique nisi, enim in risus blandit sed. Velit condimentum feugiat velit amet integer. Tristique nunc pharetra aenean etiam massa sem. Morbi lacus interdum velit quam risus. ',
        website: ".com",
        coverImage: "https://pngimage.net/wp-content/uploads/2018/06/white-design-png-4.png",
        ratingValue: 3.75,
        ratingCount: 12,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test",
                        checked: true
                    }
                ]
            },
            {
                id: 1,
                name: "Agriculture",
                image: ".png",
                subCategory: [
                    {
                        id: 4,
                        name: "Test",
                        checked: false
                    }
                ]
            },
            // {
            //     id: 2,
            //     name: "Constructions",
            //     image: ".png",
            //     subCategory: [
            //         {
            //             id: 8,
            //             name: "Test",
            //             checked: true
            //         }
            //     ]
            // }
        ]
    },
    {
        id: 1,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        profilePicture: null,
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 3,
        ratingCount: 2,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 2.5,
        ratingCount: 10,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 3,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 0,
        ratingCount: 0,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 4,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 0,
        ratingCount: 0,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
]

export const testimonials = {
    "title1": "What Our Customers ",
    "title2": "Are Saying ",
    "items": [
        {
            "name": "Varun Vachhar",
            "designation": "Chief Technology Officer",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "Tayla Elias",
            "designation": "CEO",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "Luca Inwood",
            "designation": "Land Owner",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "George Kavel",
            "designation": "Land Owner",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        }

    ]
}

export const ratings = [
    {
        id: 0,
        comment: 'Amet, sit nunc, enim vulputate eget proin etiam id. Orci commodo nunc aliquam ullamcorper morbi at quis vel molestie. Ullamcorper sollicitudin augue felis amet morbi sed a egestas sem. Nisl tincidunt eu velit parturient. Gravida varius justo.',
        rate: 4.5,
        from: {
            firstName: "Jane",
            lastName: "Smith",
            companyName: "Barclays International",
            profilePicture: "https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg"
        }
    }, {
        id: 0,
        comment: 'Amet, sit nunc, enim vulputate eget proin etiam id. Orci commodo nunc aliquam ullamcorper morbi at quis vel molestie. Ullamcorper sollicitudin augue felis amet morbi sed a egestas sem. Nisl tincidunt eu velit parturient. Gravida varius justo.',
        rate: 4.5,
        from: {
            firstName: "Jane",
            lastName: "Smith",
            companyName: "Barclays International",
            profilePicture: "https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg"
        }
    }
]

export const breakPoint = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 3,
        },
    },
]

export const breakPoint2 = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2,
        },
    },
]

export const breakPoint3 = [
    {
        breakpoint: 420,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 550,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 3,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 4,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 6,
        },
    },
]

export const breakPoint4 = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 3,
        },
    },
]

export const paymentResponse = 'https://b2b.lk/my-profile'
