import React from 'react'
import { Modal } from "react-bootstrap";
import './requests.scss'
import strings from "../../locals/string.json";
import Files from "react-files";
import { Checkbox, DatePicker, Select } from "antd";
import moment from "moment";
import * as commonFunc from "../../constants/commonFunc";
import { createPurchaseRequestDetails } from "../../constants/commonFunc";
import constants, { categoryActions, CURRENCY, DISTRICTS } from "../../constants/constants";
import { validatePurchaseRequestDetails, validatePurchaseRequestSubCategory } from "../../constants/validation";
import { ToastUtil } from "../../constants/toastUtil";
import MultiSelect from "../multiSelect/multiSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updatePurchaseRequest } from "../../service/request";
import Container from "../myProfile/newCategoryModel";
import { getCategories } from "../../service/category";

const { Option } = Select;
const purchaseRequest = strings.purchaseRequest;
const toast = strings.toast;
const Name_REGEX = /^[A-z ]+$/;

class UpdateRequestModal extends React.Component {
    state = {
        id: 0,
        step: 1,
        title: '',
        estimatedValue: '',
        quantity: '',
        description: '',
        urgent: false,
        attachment: undefined,
        attachmentFile: undefined,
        attachmentType: undefined,
        preferredContactMethod: '',
        closingDate: '',
        district: '',
        currency: 'LKR',
        categories: [],
        subCategory: [],
        selectedCategory: undefined,
        selectedSub: undefined,
        selectedNodes: [],
        filename: ''
    }

    setValues = () => {
        const item = this.props.item;
        this.setState({
            id: item.id,
            title: item.topic,
            refNo: item.refNo,
            estimatedValue: item.estimatedValue === 0 ? '' : item.estimatedValue.toString(),
            quantity: item.qty.toString(),
            description: item.description === null ? '' : item.description,
            urgent: item.urgent,
            attachment: item.attachment,
            preferredContactMethod: item.prefferedContactMethod,
            closingDate: item.closingDate,
            district: item.location,
            currency: item.currency,
            subCategory: item.subCategory,
            categories: this.props.categories
        })

        this.getCategories()
    }

    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                attachmentType: file.type,
                attachmentFile: file,
                attachment: imageDataUrl,
                filename: file.name
            });
        }
    };

    onButtonClick = () => {
        if (validatePurchaseRequestDetails(this.state)) {
            this.updatePurchaseRequest();
        }
    }

    onRemoveSubCategory = (item) => {
        let data = this.state.subCategory;
        data.splice(data.findIndex(e => e.id === item.id), 1);
        this.setState({ subCategory: data })
    }

    onChangeSubCategory = (item) => {
        let data = this.state.subCategory;
        if (data.length === 3) {
            ToastUtil.showErrorToast(toast.maximumCategory);
            return;
        }
        if (!(data.filter(e => e.id === item.id).length > 0)) {
            data.push(item);
        }
        this.setState({ subCategory: data, selectedSub: item.name })
    }

    onChangeCategory = (item) => {
        this.setState({
            selectedSub: null,
            selectedCategory: this.state.categories[item]
        })
    }

    updatePurchaseRequest = () => {
        updatePurchaseRequest(createPurchaseRequestDetails(this.state))
            .then(response => {
                if (response.success) {
                    this.props.close();
                    this.props.onupdate();
                    ToastUtil.showSuccessToast(response.msg)
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    onChange = (currentNode, selectedNodes) => {
        let catArray = []
        selectedNodes.map((cat, index) => {
            let mainCatID = cat.value.split("L")[1].replace('M', '')
            catArray.push(mainCatID)
        })
        this.clickChild()

        let limit = this.state.packages[this.state.subscription_plan].categoryCount
        if ([...new Set(catArray)].length <= limit) {
            this.setState({ selectedNodes })
        } else {
            ToastUtil.showErrorToast('Category count exeeded')
        }
    }

    getCategories = () => {
        getCategories(categoryActions.GET_ALL)
            .then(async response => {
                if (response.success) {
                    let mainCategoryArray = []
                    let subCategoryArray = []
                    let subCategoryArraywithboolean = []
                    response.body.forEach(element => {
                        mainCategoryArray = mainCategoryArray.concat(element.category);
                    });

                    mainCategoryArray.forEach(ele => {
                        subCategoryArray = subCategoryArray.concat(ele);
                    });

                    subCategoryArray.map((val, index) => {
                        val.subCategory.map((ele, ind) => {
                            if (ele.checked) {
                                subCategoryArraywithboolean.push({ value: `${ele.id}L` + `${val.id}M` })
                            }
                        })
                    })

                    const cat = response.body.map(item => {
                        return {
                            value: `${item.id}S'`,
                            label: item.name,
                            disabled: true,
                            children: item.category.map(main => {
                                return {
                                    value: `${main.id}M`,
                                    label: main.name,
                                    disabled: true,
                                    children: main.subCategory.map(sub => {
                                        return {
                                            value: `${sub.id}L` + `${main.id}M`,
                                            label: sub.name,
                                            disabled: false,
                                            checked: sub.checked
                                        }
                                    })
                                }
                            }),
                        }
                    });
                    await this.setState({
                        categories: cat,
                        selectedNodes: subCategoryArraywithboolean
                    })
                    // this.clickChild()
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    render() {

        function disabledDate(current) {
            return current && current < moment().endOf('day');
        }

        return <Modal
            className="updateRequest"
            show={this.props.open}
            onShow={this.setValues}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>Ref: {this.state.refNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="contact-form-wrap contact-form-bg">
                    <h6 className="mt-4 mb-0">{purchaseRequest.requestInformation}</h6>

                    <p className="inputLabel">{purchaseRequest.headline}<span>*</span></p>
                    <div className="rld-single-input">
                        <textarea rows={2} name="title" required="required" onChange={this.onTextChange}
                            value={this.state.title} placeholder={purchaseRequest.headline}
                            className={this.state.title.trim() === '' ? 'input-error' : ''}
                        />
                        {
                            this.state.title.trim() === '' ?
                                <span
                                    className={'fields-error-text'}>{toast.purchaseRequestTitle}</span>
                                : null
                        }
                    </div>

                    <p className="inputLabel">{purchaseRequest.description}<span>*</span></p>
                    <div className="form-group rld-single-input">
                        <textarea rows={5} name="description" required="required" onChange={this.onTextChange}
                            value={this.state.description}
                            maxLength={1000}
                            className={this.state.description.trim() === '' ? 'input-error' : ''}
                            placeholder={purchaseRequest.description}
                        />
                        {
                            this.state.description.trim() === '' ?
                                <span
                                    className={'fields-error-text'}>{toast.descriptionError}</span>
                                : null
                        }
                        <span
                            className={`description-text-limit ${this.state.description.length >= 1000 ? 'description-text-limit-error' : null}`}>{this.state.description.length}/1000</span>
                        {/* <label>{purchaseRequest.description}</label> */}
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12 ">
                            <p className="inputLabel">{purchaseRequest.qty}<span>*</span></p>
                            <div className="rld-single-input">
                                <input type="text" name="quantity" required="required" onChange={this.onTextChange}
                                    value={this.state.quantity} placeholder={purchaseRequest.qty}
                                    className={this.state.quantity.trim() === '' ? 'input-error' : ''}
                                />
                                {
                                    this.state.quantity.trim() === '' ?
                                        <span
                                            className={'fields-error-text'}>{toast.qtyError}</span>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 ">
                            <p className="inputLabel">{purchaseRequest.estimatedValue}</p>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <div className="rld-single-input">
                                        <Select
                                            value={this.state.currency}
                                            showSearch
                                            placeholder="Select Category"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={value => this.onTextChange({
                                                target: {
                                                    name: 'currency',
                                                    value: value
                                                }
                                            })}
                                        >
                                            {
                                                CURRENCY.map((item, i) =>
                                                    <Option key={i} value={item}>{item}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-12">
                                    <div className="rld-single-input">
                                        <input type="number" name="estimatedValue" required="required"
                                            onChange={this.onTextChange}
                                            // onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                            placeholder={purchaseRequest.estimatedValue}
                                            value={this.state.estimatedValue}
                                            className={this.state.estimatedValue.trim() !== '' && Name_REGEX.test(this.state.estimatedValue) ? 'input-error' : ''}
                                        />
                                        {
                                            this.state.estimatedValue.trim() === '' && Name_REGEX.test(this.state.estimatedValue) ?
                                                <span
                                                    className={'fields-error-text'}>{toast.estimatedValue}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-12">
                            <Checkbox className="mt-4" checked={this.state.urgent}
                                onChange={() => this.setState({ urgent: !this.state.urgent })}>{purchaseRequest.urgent}</Checkbox>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="inputLabel mb-2">{purchaseRequest.attachment}</p>
                            <Files
                                className='files-dropzone-file'
                                onChange={this.onFilesChange}
                                accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf", 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                                multiple={false}
                                maxFileSize={52428800}
                                minFileSize={0}
                                onError={error => console.log(error)}
                                clickable
                            >
                                <div className="file-wrapper">
                                    <p className="choose">{purchaseRequest.chooseFile}</p>
                                    {
                                        this.state.attachment &&
                                        <p className="view-file"
                                            onClick={() => {
                                                !this.state.attachmentType ?
                                                    window.open(this.state.attachment, '_blank') :
                                                    commonFunc.getB64ToBlob(this.state.attachment, this.state.attachmentType)
                                            }}>{purchaseRequest.viewFile}</p>
                                    }
                                </div>
                            </Files>
                            <div className="mb-2 mt-1 ml-4" style={{ flexDirection: 'row', display: 'flex' }}>
                                <p>{this.state.filename}&nbsp;&nbsp;</p>
                                <p >{purchaseRequest.fileSize}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mt-3">
                            <p className="inputLabel mb-2">{purchaseRequest.closingDate}<span>*</span></p>
                            <DatePicker
                                value={moment(this.state.closingDate)}
                                allowClear={false}
                                placeholder="Closing Date"
                                disabledDate={disabledDate}
                                onChange={(date, string) => this.onTextChange({
                                    target: {
                                        name: 'closingDate',
                                        value: string
                                    }
                                })}
                                format={constants.DATE_FORMAT}
                                className={this.state.closingDate.trim() === '' ? 'input-error' : ''}
                            />
                            {
                                this.state.closingDate.trim() === '' ?
                                    <span
                                        className={'fields-error-text'}>{toast.closingDateError}</span>
                                    : null
                            }
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <p className="inputLabel mb-2">{purchaseRequest.preferredContactMethod}</p>
                            <Select
                                value={this.state.preferredContactMethod}
                                showSearch
                                placeholder="Preferred Contact Method"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={value => this.onTextChange({
                                    target: {
                                        name: 'preferredContactMethod',
                                        value: value
                                    }
                                })}
                            >
                                <Option value={constants.TELEPHONE}>Call</Option>
                                <Option value={constants.WHATSAPP}>Whats app</Option>
                                <Option value={constants.EMAIL}>Email</Option>
                                <Option value={constants.ALL}>All</Option>
                            </Select>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <p className="inputLabel mb-2">{purchaseRequest.district}</p>
                            <Select
                                showSearch
                                value={this.state.district}
                                placeholder="District"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={value => this.onTextChange({ target: { name: 'district', value: value } })}
                            >
                                {
                                    DISTRICTS.map(item => <Option value={item}>{item}</Option>)
                                }
                            </Select>
                        </div>
                    </div>

                    {/*<p className="inputLabel mb-2">{purchaseRequest.category}<span>*</span></p>*/}

                    {/*<div className="ml-1">*/}
                    {/*    <Container*/}
                    {/*        data={this.state.categories}*/}
                    {/*        onChange={this.onChange}*/}
                    {/*        setClick={click => this.clickChild = click}*/}
                    {/*    />*/}
                    {/*</div>*/}

                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-white" onClick={this.props.close}>{purchaseRequest.cancel}</button>
                <button className="btn btn-yellow" onClick={this.onButtonClick}>{purchaseRequest.update}</button>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(UpdateRequestModal));
