import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import Register from "../components/oauth/register";
const Login = (props) => {

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    return <div>
        <Navbar />
        <Banner headertitle="Register"/>
        <Register history={props.history} location={props.location}/>
        <Footer />
    </div>
}

export default Login

