import React, {Component} from 'react';
import {Assets} from "../../assets";

class Banner extends Component {

    render() {
        const inlineStyle = {
            backgroundImage: this.props.product?.seller?.coverImage !== null ? `url(${this.props.product?.seller?.coverImage})` :
                `url(${Assets.productBack})`
        }
        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner ">
                                <h1 className="page-title product-page-title">{this.props.product?.productName !== null ? this.props.product?.productName : "Not Found"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Banner
