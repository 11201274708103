import React, { Component } from 'react';
import './globalComponents.scss'
import strings from "../../locals/string.json";
import { breakPoint3, categoryActions } from "../../constants/constants";
import Slider from "react-slick";
import { Assets } from "../../assets";
import { getCategories } from "../../service/category";
import YouTube from "react-youtube";
import VideoModal from "./videoModal";

const landing = strings.landing;

class PopularCategories extends Component {

    state = {
        categories: []
    }

    componentWillMount() {
        getCategories(categoryActions.POPULAR)
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.body
                    })
                }
            })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 6,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint3,
            rows: 1,
        };



        let count = 1

        return <>
            {this.state.categories.length > 6 ? <div className="properties-area pd-bottom-60 popularSupplier">
                <div className={'category-area pd-top-60 pd-bottom-60'}>
                    <div className={'container'}>
                        {
                            this.state.categories.length >= 6 && <>
                                <div className="section-title">
                                    <span className="txt-sub-title">POPULAR</span>
                                    <h2 className="title">{landing.popularCategories}</h2>
                                </div>
                                <Slider {...settings}>
                                    {this.state.categories.map((item, i) => {
                                        let elem = (<div className="p-2" key={i}>
                                            {/*<div className="catHolder" style={{backgroundImage: `url(../../assets/img/category_${count}.png)`, backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>*/}
                                            <div className="catHolder">
                                                {/*<img className="cat"*/}
                                                {/*     src={item.image !== null ? item.image : Assets.categoryPlaceholder}*/}
                                                {/*     alt="img"/>*/}
                                                <div className="custom-category-card">
                                                    <h6 className="custom-category-title readeal-top">{item.name}</h6>
                                                    <h6 className="custom-category-sub-title readeal-top">{item.requestCount ?? 0} Requests</h6>
                                                </div>
                                                <img className={'category-bg-image'}
                                                    src={`../../assets/img/category_${count}.png`} />
                                            </div>
                                        </div>)

                                        count = count + 1
                                        if (count === 6) {
                                            count = 1
                                        }

                                        return elem
                                    }
                                    )}
                                </Slider>
                            </>
                        }
                    </div>
                </div>

                <div className="container">
                    <div className="section-title mt-5" align={'center'}>
                        <span className="txt-sub-title">WATCH</span>
                        <h2 className="title inner-title text-center" style={{ width: '100%' }}>Our Story</h2>
                    </div>
                    <div className="display-flex justify-content-center section-title pointer"
                        onClick={() => this.setState({ modalOpen: true })}
                        style={{ width: '100%' }}>
                        <img className="shadow-img" width={'50%'} src={Assets.aboutUsVideo2} alt="image" />
                    </div>

                    <VideoModal
                        open={this.state.modalOpen}
                        close={() => this.setState({ modalOpen: false })}
                        videoId='RLZVONxJUFI'
                    />
                </div>
            </div> : null}
        </>
    }
}

export default PopularCategories
