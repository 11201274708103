import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import ViewAllQuotations from "../components/quotation/viewAllQuotations";

const ReceivedQuotations = (props) => {


    useEffect(()=> {
        document.querySelector('body').scrollTo(0,0)
    },[])

    return <div>
        <Navbar/>
        <Banner headertitle="My Quotations"/>
        <ViewAllQuotations stateData={props?.location?.state?.quotationsState} qtype={"R"} history={props.history}/>
        <Footer/>
    </div>
}

export default ReceivedQuotations
