import React from "react";
import './requests.scss'
import strings from "../../locals/string.json";
import {Assets} from "../../assets";
import 'antd/dist/antd.css';
import RequestItem from "./requestItem";
import ReactPaginate from 'react-paginate';
import routes from "../../constants/routes";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PurchaseRequestModal from "./purchaseRequestModal";
import Filter from "../filter/filter";
import {isBrowser} from "react-device-detect";
import {ToastUtil} from "../../constants/toastUtil";
import {favRequest, getRequest} from "../../service/request";
import {toTop} from "../../constants/commonFunc";
import Cookies from "js-cookie";
import constants from '../../constants/constants'
import * as common from '../../constants/commonFunc'

const pr = strings.purchaseRequest;

class AllRequests extends React.Component {

    state = {
        advance: false,
        requests: [],
        action: "LIVE",
        modelOpen: false,
        myCategory: Cookies.get(constants.ACCESS_TOKEN) === undefined ? false : this.props.userType === constants.USER_SELLER,
        index: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        search: '',
        selectedSubCategory: null,
        location: null,
        publishedDate: null,
        closingDate: null,
        categoryList: [],
        isNoData: false,
        filter: {
            publishStart: null,
            publishEnd: null,
            expireStart: null,
            expireEnd: null
        },
        preLoaderValue: false
    }

    componentDidMount() {
        this.checkDataHandler()
    }

    checkDataHandler = async () => {
        const stateData = this.props?.location?.state
        if (stateData?.allReqState) {
            await this.setState({...stateData.allReqState})
        }
        this.getRequest()
    }

    getRequest = (search) => {
        this.setState({preLoaderValue: true});
        getRequest(search ? 0 : this.state.index, this.state.size, {
            action: this.state.action,
            location: this.state.location,
            name: this.state.search,
            category: null,
            availability: this.state.myCategory,
            subCategory: this.state.selectedSubCategory,
            publishedDateStart: this.state.filter.publishStart,
            publishedDateEnd: this.state.filter.publishEnd,
            closingDateStart: this.state.filter.expireStart,
            closingDateEnd: this.state.filter.expireEnd
        })
            .then(response => {
                this.setState({preLoaderValue: false});
                if (response.success) {
                    this.setState({
                        requests: response.body.content,
                        totalPages: response.body.totalPages,
                        totalElements: response.body.totalElements,
                        isNoData: response.body.content.length === 0 ? true : false
                    });

                    // document.querySelector('body').scrollTo(0, 0)
                } else {
                    this.setState({
                        isNoData: true
                    })
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onPageSelect = async data => {
        await this.setState({index: data.selected});
        this.getRequest();
    }

    onFilterBtn = async action => {
        await this.setState({action});
        this.getRequest();
    }

    onFilterItemChange = async (name, value, value1, allow) => {
        if (allow) {
            let dates = await common.getConvertedUTCDateTIme(value, value1)
            if (name === 'publishedDate') {
                await this.setState({
                    ...this.state,
                    filter: {
                        ...this.state.filter,
                        publishStart: dates.dateStart,
                        publishEnd: dates.dateEnd,
                    }
                });
            } else if (name === 'closingDate') {
                await this.setState({
                    ...this.state,
                    filter: {
                        ...this.state.filter,
                        expireStart: dates.dateStart,
                        expireEnd: dates.dateEnd
                    }
                });
            } else {
                await this.setState({
                    ...this.state,
                    [name]: value
                });
            }
        }
        this.getRequest();
    }

    onMyCategory = async (name, value) => {
        await this.setState({[name]: value});
    }

    onClear = async () => {
        await this.setState({
            selectedSubCategory: null,
            location: null,
            publishedDate: null,
            closingDate: null,
            search: '',
            filter: {
                publishStart: null,
                publishEnd: null,
                expireStart: null,
                expireEnd: null
            }
        });
        this.getRequest();
    }

    onFav = (id) => {
        favRequest(id)
            .then(response => {
                if (response.success) {
                    this.getRequest();
                } else {
                    ToastUtil.showSuccessToast(response.msg)
                }
            })
    }

    render() {

        return <div>
            <div className="main-search-area requests">
                <div className="container">
                    <div className="banner-search-wrap">
                        <ul className="banner-button nav nav-tabs rld-banner-tab">
                            <li className="nav-item">
                                <a
                                    onClick={() => this.setState({modelOpen: true})}
                                    className="free-quotes-btn active" data-toggle="tab">{pr.getFreeQuotes}</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tabs_1">
                                <div className="product-search-container">
                                    <div className="row">
                                        <div className="titleHolder col-md-3 mb-2">
                                            <h8 className="searchTitle-new">{pr.findRequest}</h8>
                                        </div>

                                        <div className="col-xl-7 col-lg-7 col-md-6">
                                            <div className="rld-single-input left-icon">
                                                <input
                                                    onKeyDown={e => e.key === 'Enter' && this.getRequest()}
                                                    onChange={event => this.setState({search: event.target.value})}
                                                    type="text" placeholder={pr.searchPH} value={this.state.search}/>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12" align={'center'}>
                                            <button type={"button"}
                                                    onClick={() => this.getRequest(true)}
                                                    className="btn searchBtn">{pr.findNow}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mg-top-40 ">

                        <div className="display-flex justify-content-end" style={{width: '95%'}}>
                            {
                                !isBrowser ? this.state.advance ?
                                    <h6 className="advanceFilter"
                                        onClick={() => this.setState({advance: !this.state.advance})}>{pr.hide}</h6> :
                                    <img className="advanceFilter" src={Assets.advFilter}
                                         alt="advance filter"
                                         onClick={() => this.setState({advance: !this.state.advance})}/> : null
                            }
                        </div>

                        <Filter
                            categoryFilter={true}
                            category={true}
                            isLocation={true}
                            publishedDate={true}
                            closingDate={true}
                            advance={this.state.advance}
                            onClear={this.onClear}
                            onFilterItemChange={this.onFilterItemChange}
                            onMyCategory={this.onMyCategory}
                        />
                        <div className="col-xl-8 col-lg-8">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="row custom-gutter mt-3">
                                        <div className="col-xl-9 col-lg-10 col-md-9 col-sm-9 col-xs-12">
                                            <div className="property-filter-menu-wrap">
                                                <div className="property-filter-menu mb-0 portfolio-filter ">
                                                    <button
                                                        className={this.state.action === "LIVE" ? "active" : ''}
                                                        onClick={() => this.onFilterBtn('LIVE')}>LIVE
                                                    </button>
                                                    <button
                                                        className={this.state.action === "LATEST" ? "active" : ''}
                                                        onClick={() => this.onFilterBtn('LATEST')}>LATEST
                                                    </button>
                                                    <button
                                                        onClick={() => this.onFilterBtn('ALL')}
                                                        className={this.state.action === "ALL" ? "active" : ''}>ALL
                                                    </button>

                                                    {
                                                        this.props.loggedInStatus &&
                                                        <>
                                                            <button
                                                                className={this.state.action === "FAV" ? "active" : ''}
                                                                onClick={() => this.onFilterBtn('FAV')}>FAVOURITES
                                                            </button>
                                                            {/*<button*/}
                                                            {/*    className={this.state.action === "MY" ? "active" : ''}*/}
                                                            {/*    onClick={() => this.props.history.push(routes.myRequests)}>My*/}
                                                            {/*    Requests*/}
                                                            {/*</button>*/}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={'col-xl-3 col-lg-2 col-md-3 col-sm-3 col-xs-12 text-right mt-3'}>
                                            <h6 className="filter-title mb-lg-0">{this.state.totalElements !== 0 && this.state.totalElements + " REQUESTS"}&nbsp;</h6>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !this.state.preLoaderValue?
                                    this.state.requests.map((item, index) => (
                                        !this.state.myCategory ? <div className="col-md-12 col-sm-12">
                                            <RequestItem
                                                key={index}
                                                allReqState={this.state}
                                                onFav={() => this.onFav(item.id)}
                                                history={this.props.history}
                                                item={{...item, owner: this.props.myRequest}}/>
                                        </div> : (
                                            item.availability &&
                                            <div className="col-md-12 col-sm-12">
                                                <RequestItem
                                                    key={index}
                                                    allReqState={this.state}
                                                    onFav={() => this.onFav(item.id)}
                                                    history={this.props.history}
                                                    item={{...item, owner: this.props.myRequest}}/>
                                            </div>
                                        )
                                    ))
                                        :
                                        <div className="spinner">
                                            <div className="dot1"></div>
                                            <div className="dot2"></div>
                                        </div>
                                }

                                {
                                    !this.state.preLoaderValue?
                                    this.state.requests.length === 0 && this.state.isNoData &&
                                    <div className="display-flex flex-column  align-items-center"
                                         style={{width: '100%'}}>
                                        <img src={Assets.requestEmpty} alt="empty"/>
                                    </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </div>

                    <div className="display-flex justify-content-center" style={{width: '100%'}}>
                        {
                            this.state.requests.length !== 0 &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                forcePage={this.state.index}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.onPageSelect}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        }
                    </div>
                </div>
            </div>

            <PurchaseRequestModal
                title={''}
                open={this.state.modelOpen}
                close={() => this.setState({modelOpen: !this.state.modelOpen})}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(AllRequests));
