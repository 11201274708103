import React from 'react'
import { Modal } from "react-bootstrap";
import './requests.scss'
import strings from "../../locals/string.json";
import constants from "../../constants/constants";
import { Rate } from 'antd'
import { updateQuoteStatus } from "../../service/request";
import { ToastUtil } from "../../constants/toastUtil";
import { numberWithCommas } from "../../constants/commonFunc";

const purchaseRequest = strings.purchaseRequest;

class UpdateRequestModal extends React.Component {

    state = {
        item: undefined,
        quotation: undefined
    }

    setValues = () => {
        this.setState(this.props.item)
    }

    onButtonClick = (status) => {
        updateQuoteStatus(this.state.item.id, this.state.quotation.id, status)
            .then(response => {
                if (response.success) {
                    this.props.close();
                    this.props.reload();
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    render() {
        const item = this.state.item;
        const quotation = this.state.quotation;
        return <Modal
            className="updateRequest"
            show={this.props.open}
            onShow={this.setValues}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            {
                item && <>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Ref: {item.refNo}</Modal.Title>
                        {
                            quotation.buyerFollowupStatus === constants.INTERESTED ?
                                item.owner &&
                                <Rate
                                    value={quotation.buyerFollowupStatus === constants.INTERESTED ? 1 : 0}
                                    allowHalf={false}
                                    onChange={() => this.onButtonClick('PENDING')}
                                    count={1} />
                                :
                                item.owner &&
                                <Rate
                                    value={quotation.buyerFollowupStatus === constants.INTERESTED ? 1 : 0}
                                    allowHalf={false}
                                    onChange={() => this.onButtonClick('INTERESTED')}
                                    count={1} />
                        }


                    </Modal.Header>
                    <Modal.Body className="view-request">
                        <h6 className="mt-4 mb-0 title">{purchaseRequest.requestInformation}</h6>
                        <div className="p-3">
                            <p className="sub-title">{item.topic}</p>
                            <p className="headline">Estimated Purchase Value:
                                <span className="c1"> {item.estimatedValue === 0 ? 'N/A' :
                                    item.currency + " " + numberWithCommas(item.estimatedValue.toFixed(2))}</span></p>
                            <p className="headline">Quantity:
                                <span className="c2"> {item.qty}</span></p>
                            <p className="headline">Urgent Requirement:
                                <span className="c2"> {item.urgent ? 'Yes' : 'No'}</span></p>
                        </div>

                        <div className="separator mt-3 mb-3" />

                        <h6 className="mb-0 title">{purchaseRequest.quotationDetails}</h6>
                        <div className="p-3">
                            <p className="headline">Company:
                                <span className="c2"> {quotation.seller.companyName}</span></p>
                            <p className="headline">Quote Amount:
                                <span className="c2"> {quotation.currency + " " + quotation.quotePrice}</span></p>
                            <p className="headline">Quote Description:
                                {/*<span className="c2"> {quotation.quoteDescription}</span>*/}
                                    <div className="form-group rld-single-input c2">
                                    <textarea rows={6} name={'description'}
                                              value={quotation.quoteDescription}
                                              maxLength={1000}
                                              className={quotation.quoteDescription.trim() === '' ? 'input-error' : ''}
                                              placeholder={quotation.quoteDescription}
                                    />
                                    </div>

                            </p>
                            <p className="headline">Quotation:
                                <span
                                    className="quotation-view"
                                    onClick={() => window.open(quotation.attachment, '_blank')}> View file</span></p>
                        </div>

                        <div className="separator mt-3 mb-3" />
                        <h6 className="mb-0 title">{purchaseRequest.contactDetails}</h6>
                        <div className="p-3">
                            <p className="headline">{purchaseRequest.sellerName}:
                                <span
                                    className="c2"> {quotation.seller.firstName + " " + quotation.seller.lastName}</span>
                            </p>

                            <p className="headline">{purchaseRequest.email}:
                                <span className="c2"> {quotation.seller.email}</span></p>

                            {
                                quotation.seller?.secondaryEmails !== null && quotation.seller.secondaryEmails.split(',').map((email, index) => {
                                    return <>
                                        <p className="headline">{purchaseRequest.email}:
                                            <span className="c2"> {email}</span></p>
                                    </>
                                })
                            }

                            <p className="headline">{purchaseRequest.hotLine}:
                                <span className="c2"> {quotation.seller.hotLine === null ? "N/A" : quotation.seller.hotLine}</span></p>

                            <p className="headline">{purchaseRequest.telephone}:
                                <span className="c2"> {quotation.seller.landNumber === null ? "N/A" : quotation.seller.landNumber}</span></p>

                            <p className="headline">{purchaseRequest.mobile}:
                                <span className="c2"> {quotation.seller.mobileNumber === null ? "N/A" : quotation.seller.mobileNumber}</span></p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {
                            item.owner &&
                            <>
                                {
                                    quotation.buyerFollowupStatus === "NOT_INTERESTED" ?
                                        <button className="btn btn-yellow"
                                            onClick={() => this.onButtonClick('PENDING')}><i
                                                className="fa fa-trophy mr-2" />{purchaseRequest.ignored}</button> :

                                        <button className="btn btn-white"
                                            onClick={() => this.onButtonClick('NOT_INTERESTED')}><i
                                                className="fa fa-trophy mr-2" />{purchaseRequest.ignore}</button>
                                }
                                {/* <button className="btn btn-red"
                                    onClick={() => this.onButtonClick('NOT_INTERESTED')}><i
                                        className="fa fa-ban mr-2" />{quotation.buyerFollowupStatus === "NOT_INTERESTED" ? purchaseRequest.ignored : purchaseRequest.ignore}</button> */}
                                {
                                    quotation.buyerFollowupStatus === constants.AWARDED ?
                                        <button className="btn btn-yellow"
                                            onClick={() => this.onButtonClick('PENDING')}><i
                                                className="fa fa-trophy mr-2" />{purchaseRequest.awarded}</button> :

                                        <button className="btn btn-white"
                                            onClick={() => this.onButtonClick('WON')}><i
                                                className="fa fa-trophy mr-2" />{purchaseRequest.award}</button>
                                }
                            </>
                        }

                    </Modal.Footer>
                </>
            }
        </Modal>
    }
}

export default UpdateRequestModal;
