import React, {Component} from 'react';
import '../globalComponents/globalComponents.scss'
import {Link} from 'react-router-dom';
import strings from "../../locals/string.json";
import {breakPoint} from "../../constants/constants";
import routes from "../../constants/routes";
import Products from './products';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Slider from "react-slick";
import {ToastUtil} from "../../constants/toastUtil";
import {getAllProducts} from "../../service/product";

const landing = strings.landing;

class PopularProducts extends Component {

    state = {
        products: []
    }

    componentWillMount() {
        this.getAllProducts()
    }

    getAllProducts = async () => {
        await getAllProducts(0, 10, {
            action: "LATEST",
            location: null,
            name: null,
            category: null,
            subCategory: null,
        }).then(response => {
            if (response.success) {
                this.setState({
                    products: response.body.content,
                })
            } else {
                ToastUtil.showErrorToast(response.msg);
            }
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint
        };
        return this.state.products.length >= 4 && <div className="properties-area pd-top-60 popularSupplier">
            <div className="container">
                <div className="section-title">
                    <h2 className="title">{landing.latestProducts}</h2>
                    <Link className="btn-view-all" to={routes.products}>View All</Link>
                </div>

                <Slider {...settings}>
                    {this.state.products.map((item, i) =>
                        <div className="p-2" key={i}>
                            <Products
                                key={i}
                                history={this.props.history}
                                loggedInStatus={this.props.loggedInStatus}
                                item={item}
                            />
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(PopularProducts));
