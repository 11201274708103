import React from "react";
import './products.scss'
import { Assets } from "../../assets";
import strings from "../../locals/string.json";
import { checkLoginStatus, numberWithCommas, openWebSite } from "../../constants/commonFunc";
import ProductViewModal from "./productViewModal";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { trackProduct } from "../../service/product";
import routes from "../../constants/routes";

const pr = strings.purchaseRequest;

class ViewProduct extends React.Component {

    state = {
        modelOpen: false,
        isMobileVisible: true
    }

    showMobileHandler = async () => {
        await this.setState({
            isMobileVisible: false
        })

        const obj = {
            sellerId: this.props.product.seller.id
        }
        trackProduct(obj).then(response => {
        })
    }

    render() {
        const item = this.props.product;

        return <div className="register-page-area pd-bottom-100 viewProductPage">
            <div className="container">
                <img className="pointer mt-3" src={Assets.back} alt="back"
                    onClick={() => {
                        if (this.props.location.state !== undefined) {
                            this.props.history.push(routes.products, { currentStatus: this.props.location.state.currentStatus })
                        } else {
                            this.props.history.push(routes.products)
                        }
                    }
                } />
                <div className="row mt-4">
                    <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">

                        <div className="property-news-single-card product-view-img mt-4 mb-0">
                            <div className="thumb">
                                <img className="productExpandImage" src={item.image} alt={'image'} />
                            </div>
                        </div>

                        <p className="title mt-3">{pr.productInformation}</p>

                        <div className="p-3">
                            <p className="headline">Unit Amount:
                                <span
                                    className="c1"> {item.currency + " " + numberWithCommas(item.unitPrice.toFixed(2))}</span>
                            </p>

                            <p className="headline">Availability:
                                <span className="c1"> {item.availability ? 'In-Stock' : 'Sold Out'}</span></p>

                            <p className="headline">Category:
                                <span className="c3"> {item.category.name}</span></p>
                            <p className="headline">Sub Category:
                                <span className="c3"> {item.subCategory.name}</span></p>

                        </div>

                        <div className="separator mt-3 mb-3" />

                        <h6 className="title">{pr.rDescription}</h6>
                        <p className="c2 mt-3 whiteSpacePre">{item.description}</p>

                        <div className="separator mt-3 mb-3" />

                        {
                            item.productAttributes !== null && item.productAttributes !== "" && <h6 className="title mt-4 mb-3">{pr.productAttributes}</h6>
                        }

                        <ul>
                            {
                                item.productAttributes !== null && item.productAttributes !== "" && item.productAttributes.split(',').map((item, index) => {
                                    const att = item.split(':');
                                    return <div className="productAttribute">
                                        <li key={index} style={{ flex: 3 }}><b>{att[0]}</b></li>
                                        <p style={{ flex: 9 }}>: {att[1]}</p>
                                    </div>
                                })
                            }
                        </ul>

                        <button className="btn btn-yellow" onClick={() => {
                            this.setState({ modalOpen: !this.state.modalOpen })
                        }}>{pr.inquireNow}</button>

                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <div className="profileDetailsHolder">
                            <div className="companyDetails" onClick={() => this.props.history.push(routes.viewSupplier + `/${item.seller.companyUrl.split(' ').join('-')}`)}>
                                <img className="logo"
                                    src={item.seller.profilePicture !== null ? item.seller.profilePicture : Assets.companyLogo}
                                    alt="img" />

                                <p className="title">{item.seller.companyName !== null ? item.seller.companyName : "N/A"}
                                    {
                                        item.seller?.verifiedSeller &&
                                        <img className="verified verifiedWithProductView ml-2" src={Assets.badge}
                                            alt='img' />
                                    }
                                </p>
                            </div>
                            <div className="separator mt-3" />

                            <div className="p-3">
                                <p className="title">{pr.contactDetails}</p>

                                <p className="headline mt-3"><i className="fa fa-user" /> &nbsp;&nbsp;{pr.sellerName}
                                </p>
                                <p className="contactDetails">{item.seller.firstName !== null && item.seller.lastName !== null ?
                                    item.seller.firstName + " " + item.seller.lastName : "N/A"}</p>

                                <p className="headline mt-3"><i
                                    className="fa fa-map-marker" /> &nbsp;&nbsp;{pr.address}</p>
                                <p className="contactDetails">{item.seller?.address !== null ? item.seller.address : "N/A"}</p>

                                <p className="headline mt-3"><i className="fa fa-mobile" /> &nbsp;&nbsp;{pr.mobile}
                                </p>
                                {this.state.isMobileVisible ?
                                    <p className="contactDetails">{item.seller?.hotLine !== null ? item.seller.hotLine.substring(0, 4) + "XXXXXX" : "N/A"}</p> :
                                    <p className="contactDetails">{item.seller?.hotLine !== null ? item.seller.hotLine : "N/A"}</p>}
                                {this.state.isMobileVisible && item.seller?.hotLine !== null ?
                                    <p className="contactDetailsMobileNumber"><a onClick={this.showMobileHandler}>Click
                                        to show phone number </a></p> : null}

                                <p className="headline mt-3"><i className="fa fa-fax" /> &nbsp;&nbsp;{pr.telephone}
                                </p>
                                <p className="contactDetails">{item.seller?.landNumber !== null ? item.seller.landNumber : "N/A"}</p>

                                <p className="headline mt-3"><i className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}
                                </p>
                                <p className="contactDetails hideOverflow">{item.seller?.email !== null ? item.seller.email : "N/A"}</p>

                                {
                                    item.seller?.secondaryEmails !== null && item.seller.secondaryEmails.split(',').map((email, index) => {
                                        return <>
                                            <p key={index} className="headline mt-3"><i
                                                className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}</p>
                                            <p className="contactDetails hideOverflow">{email}</p>
                                        </>
                                    })
                                }

                                <p className="headline mt-3"><i className="fa fa-globe" /> &nbsp;&nbsp;{pr.website}
                                </p>
                                <p aria-rowcount={2} className="contactDetails pointer text-ali"
                                    onClick={() => openWebSite(item.seller?.website)}>{item.seller?.website !== null ? item.seller.website : "N/A"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ProductViewModal
                item={item}
                loggedInStatus={this.props.loggedInStatus}
                user={this.props.user}
                open={this.state.modalOpen}
                inquire={true}
                close={() => this.setState({ modalOpen: !this.state.modalOpen })}
            />

        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(ViewProduct));
