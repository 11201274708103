import React from "react";
import strings from "../../locals/string.json";
import {Assets} from "../../assets";
import 'antd/dist/antd.css';
import ReactPaginate from 'react-paginate';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PurchaseRequestModal from "../purchaseRequest/purchaseRequestModal";
import Supplier from "./supplier";
import {isBrowser} from "react-device-detect";
import Filter from "../filter/filter";
import {getAllSuppliers} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";

const pr = strings.purchaseRequest;

class AllSuppliers extends React.Component {

    state = {
        advance: false,
        suppliers: [],
        purchaseRequestModal: false,
        index: 0,
        size: 12,
        totalPages: 0,
        totalElements: 0,
        search: '',
        selectedSubCategory: null,
        location: null,
        isNoData: false,
        preLoaderValue: false
    }

    componentDidMount() {
        this.checkDataHandler()
    }

    checkDataHandler = async () => {
        const stateData = this.props?.location?.state
        if (stateData?.currentState) {
            await this.setState({...stateData.currentState})
        }
        this.getAllSellers()
    }

    getAllSellers = () => {
        this.setState({preLoaderValue: true});
        getAllSuppliers(this.state.index, this.state.size, {
            location: this.state.location,
            name: this.state.search,
            action: 'ALL',
            category: null,
            subCategory: this.state.selectedSubCategory,
        }).then(response => {
            this.setState({preLoaderValue: false});
            if (response.success) {
                this.setState({
                    suppliers: response.body.content,
                    totalPages: response.body.totalPages,
                    totalElements: response.body.totalElements,
                    isNoData: response.body.content.length === 0 ? true : false
                })

                // document.querySelector('body').scrollTo(0, 0)
            } else {
                this.setState({
                    isNoData: true
                })
                ToastUtil.showErrorToast(response.msg);
            }
        })
    }

    onFilterItemChange = async (name, value) => {
        await this.setState({[name]: value});
        this.getAllSellers();
    }

    onPageSelect = async data => {
        await this.setState({index: data.selected});
        this.getAllSellers();
    }

    onClear = async () => {
        await this.setState({
            selectedSubCategory: null,
            location: null,
            search: ''
        });
        this.getAllSellers();
    }

    render() {

        return <div>
            <div className="main-search-area requests">
                <div className="container">
                    <div className="banner-search-wrap">
                        <ul className="banner-button nav nav-tabs rld-banner-tab">
                            <li className="nav-item">
                                <a
                                    onClick={() => this.setState({purchaseRequestModal: !this.state.purchaseRequestModal})}
                                    className="free-quotes-btn nav-link active" data-toggle="tab">{pr.getFreeQuotes}</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tabs_1">
                                <div className="product-search-container">
                                    <div className="row">
                                        <div className="titleHolder col-md-3 mb-2">
                                            <h8 className="searchTitle-new">{pr.findSuppliers}</h8>
                                        </div>

                                        <div className="col-xl-7 col-lg-7 col-md-6">
                                            <div className="rld-single-input left-icon">
                                                <input
                                                    onKeyDown={e => e.key === 'Enter' && this.getAllSellers()}
                                                    onChange={event => this.setState({search: event.target.value})}
                                                    type="text" placeholder={pr.searchPH4} value={this.state.search}/>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12" align={'center'}>
                                            <button type={"button"}
                                                    onClick={this.getAllSellers}
                                                    className="btn btn-yellow searchBtn">{pr.findNow}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mg-top-40 ">
                        <div className="display-flex justify-content-end" style={{width: '95%'}}>
                            {
                                !isBrowser ? this.state.advance ?
                                    <h6 className="advanceFilter"
                                        onClick={() => this.setState({advance: !this.state.advance})}>{pr.hide}</h6> :
                                    <img className="advanceFilter" src={Assets.advFilter}
                                         alt="advance filter"
                                         onClick={() => this.setState({advance: !this.state.advance})}/> : null
                            }
                        </div>

                        <Filter
                            category={true}
                            isLocation={true}
                            quoteStatus={false}
                            publishedDate={false}
                            closingDate={false}
                            advance={this.state.advance}
                            onClear={this.onClear}
                            onFilterItemChange={this.onFilterItemChange}
                        />

                        <div className={"col-xl-8 col-lg-8"}>
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <h6 className="text-right filter-title mb-lg-0">{this.state.totalElements !== 0 && this.state.totalElements + " Suppliers"}&nbsp;</h6>
                                </div>
                                <div className="row popularSupplier mt-2">
                                    {
                                        !this.state.preLoaderValue?
                                            this.state.suppliers.map((item, i) =>
                                                <Supplier
                                                    key={i}
                                                    item={item}
                                                    filter={true}
                                                    currentState={this.state}
                                                    history={this.props.history}
                                                />
                                            )
                                            :
                                            <div className="spinner">
                                                <div className="dot1"></div>
                                                <div className="dot2"></div>
                                            </div>
                                    }
                                </div>

                                {
                                    !this.state.preLoaderValue?
                                    this.state.suppliers.length === 0 && this.state.isNoData &&
                                    <div className="display-flex flex-column align-items-center"
                                         style={{width: '100%'}}>
                                        <img src={Assets.supplierEmpty} alt="empty"/>
                                    </div>
                                        :
                                        null
                                }
                            </div>
                        </div>

                    </div>
                    <div className="display-flex justify-content-center" style={{width: '100%'}}>
                        {
                            this.state.suppliers.length !== 0 &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                forcePage={this.state.index}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.onPageSelect}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        }
                    </div>
                </div>
            </div>

            <PurchaseRequestModal
                title={''}
                open={this.state.purchaseRequestModal}
                close={() => this.setState({purchaseRequestModal: !this.state.purchaseRequestModal})}
            />
        </div>
    }
}

export default connect(null, null)(withRouter(AllSuppliers));
