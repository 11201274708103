import React from "react";
import { Modal } from "react-bootstrap";
import './oauth.scss'
import 'antd/dist/antd.css';
import { Steps } from "antd";
import strings from "../../locals/string.json";
import constants, { __DEV__, categoryActions } from "../../constants/constants";
import { Assets } from "../../assets";
import StepOne from './registrationStep01';
import StepTwo from './registrationStep2';
import StepThree from './registrationStep03';
import Payment from './payment';
import { isBrowser } from "react-device-detect";
import * as commonFunc from "../../constants/commonFunc";
import { createUser, createUserRegDetails } from "../../constants/commonFunc";
import {
    SEARCH_SPACE_REGEXP,
    SEARCH_VALUE_REGEXP,
    validateBuyerRegDetails,
    validateCategory, validatePromoCode
} from "../../constants/validation";
import {
    checkCompanyUrlAvailability,
    checkEmailAvailability, checkETUserDiscount,
    checkPromoCode,
    getSubscriptions, makePayment,
    register,
    trackUser,
    get_company_types,
    get_packages_from_company_type
} from "../../service/auth";
import { ToastUtil } from "../../constants/toastUtil";
import routes from "../../constants/routes";
import { getCategories } from "../../service/category";
import { handleErrors, WebXPayTokenizeInit } from "../../constants/payment";
import getCroppedImg, { dataURLtoFile } from "../../constants/cropImage";
import Cropper from "react-easy-crop";
import $ from 'jquery'
import apiConfig from "../../service/apiConfig";
import Cookies from "js-cookie";
import * as userActions from "../../store/domain/user/action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import swal from "sweetalert";
import Container from '../../components/myProfile/newCategoryModel'
import CategorySelector from "../categorySelector";
import * as Constants from "../../constants/constants";

const registration = strings.registration;
const toast = strings.toast;
const modals = strings.modals;
const { Step } = Steps;

class RegistrationModal extends React.Component {

    state = {
        step: 1,
        firstName: __DEV__ ? 'sahan' : '',
        lastName: __DEV__ ? 'dinuka' : '',
        email: __DEV__ ? 'sahan485645@sdc.com' : '',
        password: __DEV__ ? '12345678' : '',
        confirmPassword: __DEV__ ? '12345678' : '',
        companyName: __DEV__ ? 'Ceyentra' : '',
        companyUrl: __DEV__ ? apiConfig.frontEnd + 'ssdf156' : '',
        designation: __DEV__ ? 'Software Engineer' : '',
        website: __DEV__ ? 'ceyentra.com' : '',
        mobile: __DEV__ ? '+94746448359' : '',
        hotline: '',
        land: '',
        address: __DEV__ ? 'Panadura' : '',
        billingProof: undefined,
        billingProofType: undefined,
        billingProofFile: undefined,
        category: [],
        subscription_plan: 0,
        packages: [],
        cat1: undefined,
        cat2: undefined,
        cat3: undefined,
        location: null,
        nameOnTHeCard: '',
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cvv: '',
        promo: '',
        applyPromo: false,
        viewBreakDown: true,
        secondaryEmail: '',
        secondaryEmails: [],

        catIdArray: [],
        categories: [],
        subCategory: [],

        imageType: undefined,
        imageFile: undefined,
        image: null,
        coverImageFile: undefined,
        coverImageType: undefined,
        coverImage: null,
        crop: { x: 100, y: 100 },
        zoom: 1,
        open: false,
        isProfile: true,
        percentage: 0,
        maximumAmount: 0,
        etUserDiscount: 0,
        isValidation: false,
        companyType: null,
        companyTypeData: [],
        selectedSubscriptionID: 1,
        promoCodeId: '',
        userType: this.props.userType,
        selectedNodes: [],
        nodeLimit: 0,
        filename: ""
    }

    getCategories = () => {
        if (this.props.userType === constants.USER_SELLER) {
            this.trackUserRegistration();
        }
        this.setState({ step: 2 })
        // this.props.userType === constants.USER_SELLER &&
        // getCategories(categoryActions.GET_ALL)
        //     .then(async response => {
        //         if (response.success) {
        //             const cat = response.body.map(item => {
        //                 return {
        //                     ...item,
        //                     subCategory: item.subCategory.map(sub => {
        //                         return {
        //                             ...sub,
        //                             checked: false
        //                         }
        //                     })
        //                 }
        //             });
        //             const catLength = this.state.packages[this.state.subscription_plan].categoryCount;
        //             const catIdArray = []
        //             for (let i = 0; i < catLength; i++) {
        //                 await this.setState({
        //                     ['cat' + (i + 1)]: undefined
        //                 })
        //                 cat[i] && catIdArray.push(-1)
        //             }
        //             await this.setState({
        //                 categories: cat,
        //                 catIdArray: catIdArray,
        //                 step: 2
        //             })
        //         }
        //     })
    }

    onTextChange = async (event) => {
        if (event.target.name === 'companyName') {
            await this.setState({
                [event.target.name]: event.target.value,
                companyUrl: apiConfig.frontEnd + event.target.value.toLowerCase().trim()
                    .replace(SEARCH_VALUE_REGEXP, '  ')
                    .replace(SEARCH_SPACE_REGEXP, '-').trim()
            });
        } else if (event.target.name === 'companyUrl') {
            if (event.target.value.toLowerCase().trim().length > 15) {
                const val = event.target.value.split(apiConfig.frontEnd);
                await this.setState({
                    companyUrl: apiConfig.frontEnd + val[1].toLowerCase().trim()
                        .replace(SEARCH_VALUE_REGEXP, '  ')
                        .replace(SEARCH_SPACE_REGEXP, '-').trim()
                });
            }

        } else if (event.target.name === 'companyType') {
            this.getSubscriptionPackages(event.target.value.id)
            await this.setState({
                [event.target.name]: event.target.value
            })
        } else if (event.target.name === 'promo') {
            if (event.target.value === '') {
                await this.setState({
                    [event.target.name]: event.target.value,
                    percentage: 0,
                    maximumAmount: 0,
                })
            } else {
                await this.setState({
                    [event.target.name]: event.target.value,
                })
            }
        } else {
            await this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onCardDetailsChange = async (name, value) => {
        await this.setState({
            [name]: value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                billingProofType: file.type,
                billingProofFile: file,
                billingProof: imageDataUrl,
                filename: file.name
            });
        }
    };

    onSubCategoryChange = (category, item) => {
        const result = commonFunc.onSubCategoryChange(category, item, this.state.subCategory, this.state.category)
        this.setState({ subCategory: result.data, category: result.cat })
    }

    onSelectAllSubCategory = (category, status) => {
        const data = this.state.subCategory;
        const cat = this.state.category;
        category.subCategory.map((item, index) => {
            if ((data.filter(e => e.id === item.id).length > 0)) {
                data.splice(data.findIndex(e => e.id === item.id), 1);
            }
            if (status) {
                data.push(item);
            }
        })
        if (!(cat.filter(e => e.id === category.id).length > 0)) {
            cat.push(category);
        } else {
            if (!status) {
                cat.splice(cat.findIndex(e => e.id === category.id), 1);
            }
        }
        this.setState({ subCategory: data, category: cat })
    }

    setCategory = async (index, value) => {
        // const category = { ...this.state.categories[value] }
        // await this.setState({
        //     ["cat" + index]: category
        // })
        await this.setState({
            ["cat" + index]: value
        })
    }

    onCategoryChange = (index, value) => {
        // const category = { ...this.state.categories[value] }
        const category = value
        const result = commonFunc.onCategoryChange(category, this.state.subCategory, this.state.subCategory);
        const catIdArray = [...this.state.catIdArray];
        catIdArray[index] = category.value;

        this.setState({
            subCategory: result.data, category: result.cat,
            ["cat" + (index + 1)]: category,
            catIdArray: catIdArray
        })
    }

    handleSubCategoryChange = (index, status, id) => {
        let data = { ...this.state['cat' + index] };
        const result = commonFunc.handleSubCategoryChange(index, status, id, data)
        this.setState({ ['cat' + index]: result })
    }

    onAdd = () => {
        let data = this.state.secondaryEmail;
        data.push({
            id: data.length,
            value: ''
        })
        this.setState({ secondaryEmail: data })
    }

    onRemove = (index) => {
        let data = this.state.secondaryEmail;
        data.splice(index, 1);
        this.setState({ secondaryEmail: data })
    }

    onFilesChange1 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                imageType: file.type,
                imageFile: file,
                image: imageDataUrl,
                open: true,
                isProfile: true
            });
        }
    };

    onFilesChange2 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                coverImageType: file.type,
                coverImageFile: file,
                coverImage: imageDataUrl,
                isProfile: false,
                open: true
            });
        }
    };

    onButtonClick = () => {

        if (this.state.open) {
            this.setState({
                open: false,
                [this.state.isProfile ? 'image' : 'coverImage']: this.state.imageCrop
            });
            return;
        }

        if (this.props.userType === constants.USER_BUYER) {
            this.setState({ isValidation: true })
            if (validateBuyerRegDetails(this.state, undefined, false)) {
                this.checkEmailAvailability();
            }
        } else {
            if (this.state.step === 2) {
                if (typeof (this.state.selectedSubscriptionID) === "number" && this.state.selectedSubscriptionID !== 1) {
                    ToastUtil.showErrorToast('Please select a package');
                } else {
                    this.setState({ step: 3 })
                }
                return;
            }
            if (this.state.step === 1) {

                this.setState({ isValidation: true })
                if (validateBuyerRegDetails(this.state, undefined, true, false)) {
                    this.checkEmailAvailability();
                    return;
                }
                return;
            }
            if (this.state.step === 3 && validateCategory(this.state)) {
                this.saveUser();
            }
        }
    }

    checkEmailAvailability = () => {
        this.setState({ isValidation: false })
        const userType = this.props.userType;
        checkEmailAvailability(this.state.email, this.props.becomeSeller ?? false)
            .then(response => {
                if (response.success) {
                    if (userType === constants.USER_SELLER) {
                        this.checkCompanyUrlAvailability(true);
                    } else {
                        this.saveUser();
                    }
                } else {
                    if (response.status && response.status === constants.EXISTING_E_TENDER_SELLER) {
                        if (userType === constants.USER_SELLER) {
                            this.checkCompanyUrlAvailability(true);
                        } else {
                            this.saveUser();
                        }
                    } else {
                        ToastUtil.showErrorToast(response.msg);
                        if (response.status) {
                            this.props.history.push(routes.login, { error: response.status });
                        }
                    }
                }
            })
    }

    checkCompanyUrlAvailability = (btnClick) => {
        const companyUrl = this.state.companyUrl;
        companyUrl !== apiConfig.frontEnd ?
            checkCompanyUrlAvailability(companyUrl.split(apiConfig.frontEnd)[1])
                .then(response => {
                    if (response.success) {
                        btnClick && this.getAllCategory();
                    } else {
                        btnClick && ToastUtil.showErrorToast(response.msg);
                        this.setState({ companyUrl: companyUrl + Math.floor(Math.random() * 100) })
                    }
                }) : btnClick && this.getAllCategory();
    }

    trackUserRegistration = () => {
        const obj = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobileNumber: this.state.mobile,
            address: this.state.address,
            companyName: this.state.companyName === '' ? null : this.state.companyName
        }
        trackUser(obj)
            .then(response => {
            })
    }

    onShowConfirmtion = (name, email) => {
        swal({
            title: modals.hi + name + ",",
            text: this.props.userType === constants.USER_SELLER ? toast.trialAccountCreateSeller : toast.AccountCreateBuyer,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Ok", value: "action", className: "swalSuccess" },
            }
        })
    }

    saveUser = () => {
        // if (this.state.selectedNodes.length === 0) {
        //     ToastUtil.showErrorToast(toast.emptyCategory);
        //     return false;
        // } else {
        //     let limit = this.state.packages[this.state.subscription_plan].categoryCount
        //     console.log(this.state.nodeLimit, limit)
        //     if (limit < this.state.nodeLimit) {
        //         ToastUtil.showErrorToast('Category count exeeded')
        //         return false;
        //     }
        // }

        let cat_validation = false;
        this.state.catIdArray.map((cat, index) => {
            if (cat !== -1) {
                cat_validation = true;
            }
        });

        if (!cat_validation && this.state.userType === "B2B_SELLER") {
            ToastUtil.showErrorToast(toast.emptyCategory);
            return false;
        }

        this.setState({ isValidation: false })
        const userType = this.props.userType;
        register(createUserRegDetails({ ...this.state, userType }))
            .then(response => {
                if (response.success) {
                    Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(constants.ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.user)));
                    this.props.setAuth(createUser(response.user));
                    this.props.setUserType(response.user.userType);

                    if (userType === constants.USER_SELLER) {
                        if (this.state.subscription_plan !== 0) {
                            this.paymentHandler(this.state);
                            this.setState({ step: 4 });
                            this.checkETUserDiscount();
                        } else {
                            this.onShowConfirmtion(response.user.firstName, response.user.email)
                            this.props.history.push(routes.home);
                        }
                    } else {
                        this.onShowConfirmtion(response.user.firstName, response.user.email)
                        this.props.history.push(routes.home);
                    }
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    getCompanyType = () => {
        this.props.userType === constants.USER_SELLER ?
            get_company_types()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            companyTypeData: response.body,
                        });
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                }) : this.setState({ step: 1 })
    }


    getAllCategory = () => {
        if (this.props.userType === constants.USER_SELLER) {
            this.trackUserRegistration();
        }

        this.props.userType === constants.USER_SELLER && getCategories(categoryActions.GET_ALL)
            .then(async response => {
                if (response.success) {
                    let filtered = response.body.filter(e => e.id !== 1)

                    const cat = filtered.map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                            disabled: true,
                            // readOnly: true,
                            children: item.category.map(main => {
                                return {
                                    value: main.id,
                                    subCategory: main.subCategory.map(setSubCategory => {
                                        return {
                                            value: setSubCategory.id,
                                            label: setSubCategory.name,
                                            checked: false
                                        }
                                    }),
                                    label: main.name,
                                    disabled: false,
                                    checked: false,
                                    children: main.subCategory.map(sub => {
                                        return {
                                            value: sub.id,
                                            label: sub.name,
                                            disabled: false,
                                            checked: sub.checked,
                                            subCategory: [
                                                {
                                                    value: sub.id,
                                                    label: sub.name,
                                                    checked: false
                                                }
                                            ]
                                        }
                                    })
                                }
                            }),
                        }
                    });
                    const catLength = 3;
                    const catIdArray = []
                    for (let i = 0; i < catLength; i++) {
                        await this.setState({
                            ['cat' + (i + 1)]: undefined
                        })
                        cat[i] && catIdArray.push(-1)
                    }
                    await this.setState({
                        categories: cat,
                        catIdArray: catIdArray,
                        nodesFiltered: cat,
                        step: 2
                    })
                }
            })
    }

    getSubscriptionPackages = (id) => {
        this.props.userType === constants.USER_SELLER ?
            get_packages_from_company_type(id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            packages: response.body,
                        });
                        if (this.props.becomeSeller) {
                            this.setState({
                                subscription_plan: this.props.subscription_plan,
                                step: 1,
                            });
                            if (this.props.loggedInStatus) {
                                this.setState({
                                    firstName: this.props.user.firstName,
                                    lastName: this.props.user.lastName,
                                    email: this.props.user.email,
                                })
                            }

                        }
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                }) : this.setState({ step: 1 })
    }

    onClear = () => {
        this.setState({
            step: 1,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            companyName: '',
            companyUrl: '',
            designation: '',
            website: '',
            address: '',
            mobile: '',
            hotline: '',
            land: '',
            billingProof: undefined,
            billingProofType: undefined,
            billingProofFile: undefined,
            imageType: undefined,
            imageFile: undefined,
            image: null,
            coverImageFile: undefined,
            coverImageType: undefined,
            coverImage: null,
            category: [],
            subCategory: [],
            subscription_plan: 1,
            selectedSubscriptionID: 1,
            categories: [],
            packages: [],
            cat1: undefined,
            cat2: undefined,
            cat3: undefined,
            nameOnTHeCard: '',
            cardNumber: '',
            expDate: '',
            cvv: '',
            promo: '',
            applyPromo: false,
            viewBreakDown: true,
            secondaryEmail: '',
            catIdArray: [],
            percentage: 0,
            maximumAmount: 0,
            etUserDiscount: 0,
            isValidation: false,
            location: null,
            companyType: null
        });
        this.props.close();
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const result = await getCroppedImg(this.state.isProfile ? this.state.image : this.state.coverImage, croppedAreaPixels, 0);
        const file = dataURLtoFile(result, 'product-image.jpg')
        file.preview = {
            type: "image",
            url: result
        }
        file.extension = "jpg"
        file.id = "files-1"
        await this.setState({
            imageCrop: result,
            [this.state.isProfile ? 'imageFile' : 'coverImageFile']: file
        })
    }

    onClose = () => {
        this.setState({ isValidation: false })
        if (this.state.open) {
            this.setState({
                [this.state.isProfile ? 'image' : 'coverImage']: null,
                open: false
            })
        } else {
            this.onClear()
        }
    }

    removeImage = type => {
        if (type === 'PROFILE') {
            this.setState({
                imageType: undefined,
                imageFile: undefined,
                image: null,
            })
        } else {
            this.setState({
                coverImageFile: undefined,
                coverImageType: undefined,
                coverImage: null,
            })
        }
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    checkPromoCode = () => {
        if (validatePromoCode(this.state)) {
            checkPromoCode(this.state.promo)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            percentage: response.body.discountPercentage,
                            maximumAmount: response.body.maxDiscount,
                            promoCodeId: response.body.id
                        })
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    checkETUserDiscount = () => {
        checkETUserDiscount(this.state.email)
            .then(response => {
                // if (response.success) {
                //     this.setState({
                //         etUserDiscount: response.body.amount,
                //         // promoCodeId: response.body.id
                //     })
                // }

                if (response.success) {
                    let price = this.state.packages[this.state.subscription_plan].price
                    let discount = (price * response.body.discount) / 100

                    this.setState({
                        ...this.state,
                        etUserDiscount: response.body.suitable ? discount : 0,
                        suitableForDiscount: response.body.suitable
                    })
                }
            })
    }

    paymentHandler = () => {
        let script = document.createElement('script');
        script.setAttribute('src', constants.gatewayUrl);
        document.head.appendChild(script);
        const local = this;
        script.onload = function () {
            WebXPayTokenizeInit(
                {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                    nameOnCard: "#cardholder-name",
                },
                local.afterInit,
            );
        };
        script.onerror = function (e) {
            console.log(e)
        }
    }

    afterInit = (GenerateSession) => {
        const local = this
        $('#save-card-button').click(function () {
            GenerateSession(
                function (session) {
                    local.makePayment(session)
                },
                function (error) {
                    handleErrors(error);
                }
            );
        });
    }

        makePayment = (session) => {
        const plan = this.state.packages[this.state.subscription_plan]
        let discount = Math.round((plan.price * this.state.percentage) / 100).toFixed(2)
        if (discount > this.state.maximumAmount)
            discount = this.state.maximumAmount;
        let totalDiscount = (Number(discount) + this.state.etUserDiscount).toFixed(2)

        const data = {
            email: this.state.email,
            amount: plan.price - totalDiscount,
            sessionId: session,
            secure3dResponseURL: Constants.paymentResponse,
            promoCodeId: this.state.promoCodeId === '' ? 0 : this.state.promoCodeId
        }
        makePayment(data)
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    this.onClear();
                    this.props.history.push(`${routes.home}?=registration`)
                } else {
                    if(response.data.type === '3ds') {
                        this.props.history.push({
                            pathname: '/secure-3ds-redirection',
                            state: {html3ds: response.data.html3ds},
                        });
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                }
            })
    }

    changeStep = () => {
        this.setState({
            step: 2
        })
    }

    onChange = async (currentNode, selectedNodes) => {
        // this.setState({ selectedNodes })
        // console.log('onChange::', currentNode, selectedNodes)
        // this.clickChild()
        let catArray = []
        selectedNodes.map((cat, index) => {
            cat.subCategory.map((selectCat) => {
                let mainCatID = selectCat.value.split("L")[1].replace('M', '')
                catArray.push(mainCatID)
            })
        })
        this.clickChild()
        await this.setState({ nodeLimit: [...new Set(catArray)].length })

        let limit = this.state.packages[this.state.subscription_plan].categoryCount
        if ([...new Set(catArray)].length <= limit) {
            this.setState({ selectedNodes })
        } else {
            ToastUtil.showErrorToast('Category count exeeded')
        }
    }

    test = () => { }



    render() {
        const userType = this.props.userType;
        return <Modal
            className="register"
            show={this.props.open}
            onShow={this.getCompanyType}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={false}>
                {
                    userType === constants.USER_SELLER && (this.state.step !== 1 && this.state.step !== 4) &&
                    <img className="pointer" src={Assets.back} alt="back"
                        onClick={() => this.setState({ step: this.state.step - 1 })} />
                }
                <Modal.Title>{this.state.open ? 'Crop Image' : this.state.step === 4 ? registration.payment : userType === constants.USER_SELLER ? registration.registrationSeller : registration.registrationBuyer}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.open ?
                        <Cropper
                            image={this.state.isProfile ? this.state.image : this.state.coverImage}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.isProfile ? 4 / 4 : 16 / 5}
                            onCropChange={value => this.setState({ crop: value })}
                            onZoomChange={value => this.setState({ zoom: value })}
                            onCropComplete={this.onCropComplete}
                        /> :
                        <>
                            {
                                (userType === constants.USER_SELLER && this.state.step !== 4) &&
                                <Steps current={this.state.step - 1}>
                                    <Step title={isBrowser ? "Personal Information" : ""} />
                                    <Step title={isBrowser ? "Subscription Plan" : ""} />
                                    <Step title={isBrowser ? "Categories" : ""} />
                                </Steps>
                            }
                            {
                                this.state.step === 2 && this.state.packages.length !== 0 &&
                                <StepThree
                                    state={{ ...this.state, userType }}
                                    subscription_plan={this.state.subscription_plan}
                                    onSelectSubscription={(id, selectedID) =>
                                        this.setState({ subscription_plan: id, selectedSubscriptionID: selectedID })
                                    } />
                            }
                            {
                                this.state.step === 1 &&
                                <StepOne
                                    state={{ ...this.state, userType }}
                                    onTextChange={this.onTextChange}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    checkCompanyUrlAvailability={() => this.checkCompanyUrlAvailability(false)}
                                    onFilesChange1={this.onFilesChange1}
                                    onFilesChange2={this.onFilesChange2}
                                    removeImage={this.removeImage}
                                    onChangeSecondaryEmail={value => this.setState({ secondaryEmails: value })}
                                    onViewFile={() => commonFunc.getB64ToBlob(this.state.billingProof, this.state.billingProofType)}
                                    onFileChange={this.onFilesChange} />

                            }
                            {
                                this.state.step === 3 &&
                                <div className={'ml-3 mt-3'}>
                                    {/*<StepTwo*/}
                                    {/*    state={{ ...this.state, userType }}*/}
                                    {/*    categoryCount={this.state.packages[this.state.subscription_plan].categoryCount}*/}
                                    {/*    onCategoryChange={this.onCategoryChange}*/}
                                    {/*    onSelectAllSubCategory={this.onSelectAllSubCategory}*/}
                                    {/*    setCategory={this.setCategory}*/}
                                    {/*    clearSelection={this.clearSelection}*/}
                                    {/*    active={1}*/}
                                    {/*    handleSubCategoryChange={this.handleSubCategoryChange}*/}
                                    {/*    onSubCategoryChange={this.onSubCategoryChange} />*/}

                                    {/*<Container*/}
                                    {/*    data={this.state.categories}*/}
                                    {/*    onChange={this.onChange}*/}
                                    {/*    setClick={click => this.clickChild = click}*/}
                                    {/*/>*/}

                                    <div>
                                        {/*<button onClick={this.getAllCategory}>Get All Categories</button>*/}
                                        <CategorySelector
                                            data={this.state.categories}
                                            state={{ ...this.state, userType }}
                                            categoryCount={this.state.packages[this.state.subscription_plan].categoryCount}
                                            // categoryCount={3} // only For Testing
                                            onCategoryChange={this.onCategoryChange}
                                            onSelectAllSubCategory={this.onSelectAllSubCategory}
                                            setCategory={this.setCategory}
                                            clearSelection={this.clearSelection}
                                            active={1}
                                            handleSubCategoryChange={this.handleSubCategoryChange}
                                            onSubCategoryChange={this.onSubCategoryChange}
                                        />
                                    </div>

                                </div>
                            }
                            {
                                this.state.step === 4 && this.state.packages.length !== 0 &&
                                <Payment
                                    state={{ ...this.state }}
                                    onTextChange={this.onTextChange}
                                    applyPromo={this.checkPromoCode}
                                    viewBreakDown={() => this.setState({ viewBreakDown: !this.state.viewBreakDown })}
                                    onSelectSubscription={(id) => this.setState({ subscription_plan: id })} />
                            }
                        </>
                }

            </Modal.Body>
            {
                this.state.step === 3 && this.state.subscription_plan !== 0 &&
                <p className="warningBeforReg"><span>*</span>note - As you have selected a corporate
                    package
                    in sign up process, please settle the payment within today to activate the account.
                </p>
            }

            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClose}>{registration.cancel}</button>
                {
                    this.state.step !== 4 &&
                    <button className="btn btn-yellow"
                        onClick={this.onButtonClick}>{this.state.open ? "Crop" : userType === constants.USER_BUYER ? registration.register :
                            this.state.step !== 3 ? registration.next : "Register"}</button>
                }
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationModal));
