import React, { Component } from 'react';
import strings from "../../locals/string.json";
import { breakPoint4 } from "../../constants/constants";
import { Assets } from "../../assets";
import routes from "../../constants/routes";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Slider from "react-slick";
import { getSubscriptions } from "../../service/auth";
import { numberWithCommas } from "../../constants/commonFunc";

const landing = strings.landing;

class Testimonials extends Component {

    state = {
        packages: []
    }

    componentWillMount() {
        getSubscriptions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        packages: response.body
                    })
                }
            })
    }

    render() {
        const maxTimeLimit = 150;
        let downTime = 0;

        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint4,
        };
        return <div className={"client-area  pd-top-60  pd-bottom-60 "}>
            <div className="container">
                <div className="section-title text-center pricing">
                    <h2 className="title text-dark">{landing.pricingGuide}</h2>
                </div>

                <div className="row package">

                </div>
                <Slider {...settings}>
                    {
                        this.state.packages.map((item, index) => (
                            <div className="p-2 package" key={index}>
                                <div className="padding15"
                                    onMouseDown={() => downTime = new Date().getTime()}
                                    onTouchStart={() => downTime = new Date().getTime()}
                                    onClick={() => {
                                        // const time = new Date().getTime() - downTime;
                                        // if (time < maxTimeLimit)
                                            this.props.history.push(routes.register, {
                                                subscription_plan: 0, //change index to 0
                                                becomeSeller: true
                                            })
                                    }}>
                                    <div className="packageHolder">
                                        <img className="cardBg" src={Assets.cardBg2} alt={'check'} />

                                        <h6 className="title">{item.packageName}</h6>

                                        <h6 className="priceTag">{item.price !== 0 && "Rs"}
                                            <h6 className="price">{item.price === 0 ? 'Free' : numberWithCommas(item.price)}</h6>
                                        </h6>

                                        <h6 className="priceTag">{item.validityDuration}</h6>
                                        <h6 className="userTag mt-4">{item.secondaryEmailCount} Email{item.secondaryEmailCount === 1 ? '' : 's'}</h6>
                                        <h6 className="userTag">{item.categoryCount === 69 ? "Unlimited" : item.categoryCount} {item.categoryCount === 1 ? 'Category' : 'Categories'}</h6>

                                        <button
                                            className="btn btn-yellow mb-4 mt-4">{index === 0 ? landing.getStart : landing.selectPlan}</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </Slider>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(Testimonials));
