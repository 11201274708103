import React, {Component} from 'react';
import constants, {testimonials} from "../../constants/constants";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {Assets} from "../../assets";
import './globalComponents.scss'

class Testimonials extends Component {

    render() {
        const inlineStyle = {
            // backgroundImage: `url(${Assets.test})`
            backgroundColor:'#ededed',
            marginTop:0
        }

        return <div className={"client-area pd-bottom-60 testimonials"} style={inlineStyle}>
            <div className="container">
                <div className="section-title text-center">
                    <span className="txt-sub-title">TESTIMONIALS</span>
                    <h2 className="title">What Our Clients Say</h2>
                </div>

                <div className="client-review-img">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="client-slider-2 text-center">
                                <Carousel
                                    autoPlay
                                    infiniteLoop
                                    showStatus={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    {testimonials.items.map((item, i) =>
                                        <div key={i} className="item p-5">
                                            <div className="single-client-review">
                                                <div className="review-details">
                                                    <p className="comment">{item.content}</p>
                                                    <p className="author">{item.name}</p>
                                                    <p className="designation">{item.designation} - {item.company}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Carousel>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Testimonials
