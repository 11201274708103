import moment from "moment";
import constants from "./constants";
import Cookies from "js-cookie";
import swal from "sweetalert";
import strings from "../locals/string.json";
import routes from "./routes";
import apiConfig from "../service/apiConfig";

const modals = strings.modals;

export function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

export const getB64ToBlob = (b64Data, contentType = 'image/png') => {
    const byteCharacters = atob(b64Data.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    window.open(URL.createObjectURL(blob), '_blank');
};

export const onSubCategoryChange = (category, item, data, cat) => {
    if (!(data.filter(e => e !== undefined && e.value === item.value).length > 0)) {
        data.push(item);
    } else {
        data.splice(data.findIndex(e => e.value === item.value), 1);
    }

    if (!(cat.filter(e => e !== undefined && e.value === category.value).length > 0)) {
        cat.push(category);
    }

    return {data, cat}
}

export const onSubCategoryChangeForProducts = (category, item, data, cat) => {
    // if (!(data.filter(e => e !== undefined && e.value === item.value).length > 0)) {
    //     data.push(item);
    // } else {
    //     data.splice(data.findIndex(e => e.value === item.value), 1);
    // }

    let new_arr = [];
    new_arr.push(item);
    data = new_arr;

    if (!(cat.filter(e => e !== undefined && e.value === category.value).length > 0)) {
        cat.push(category);
    }

    return {data, cat}
}

export const onCategoryChange = (category, data, cat) => {
    category.subCategory.map(item => {
        if ((data.filter(e => e.value === item.value).length > 0)) {
            data.splice(data.findIndex(e => e.value === item.value), 1);
        }
        return true;
    })
    if ((cat.filter(e => e.value === category.value).length > 0)) {
        cat.splice(cat.findIndex(e => e.value === category.value), 1);
    }
    return {data, cat}
}

export const handleSubCategoryChange = (index, status, id, data) => {
    const arr = [];
    data.subCategory.map(sub => {
        if (id !== undefined) {
            if (sub.value === id) {
                arr.push({
                    ...sub,
                    checked: status
                })
            } else {
                arr.push(sub)
            }
        } else {
            arr.push({
                ...sub,
                checked: status
            })
        }
    })
    return {
        ...data,
        subCategory: arr
    }
}

export const handleSubCategoryChangeForProducts = (index, status, id, data) => {
    const arr = [];
    data.subCategory.map(sub => {
        if (id !== undefined) {
            if (sub.value === id) {
                arr.push({
                    ...sub,
                    checked: status
                })
            } else {
                arr.push({
                    ...sub,
                    checked: false
                })
            }
        } else {
            arr.push({
                ...sub,
                checked: status
            })
        }
    })
    return {
        ...data,
        subCategory: arr
    }
}

export const dateDiff = (item) => {
    let count = moment(item.closingDate).diff(moment(), 'days');
    count = count === 0 ? count : count + 1
    return count
}

export const removeCookiesValues = async () => {
    await Cookies.remove(constants.ACCESS_TOKEN);
    await Cookies.remove(constants.REFRESH_TOKEN);
};

export const createUser = (data) => {
    if (data !== undefined) {
        return {
            id: data.account.id ?? null,
            firstName: data.firstName ?? null,
            lastName: data.lastName ?? null,
            email: data.email ?? null,
            userType: data.userType ?? null,
            mobileNumber: data.mobileNumber ?? null,
            companyName: data.account.companyName ?? null,
            categoryCount: data.account.categoryCount ?? null,
            designation: data.account.designation ?? null,
            landNumber: data.account.landNumber ?? null,
            hotLine: data.account.hotLine ?? null,
            website: data.account.website ?? null,
            profilePicture: data.account.profilePicture ?? null,
            companyUrl: data.account.companyUrl ?? null,
            address: data.account.address ?? null,
            coverImage: data.account.coverImage ?? null,
            ratingValue: data.account.ratingValue ?? null,
            ratingCount: data.account.ratingCount ?? null,
            location: data.account.location ?? null,
            secondaryEmails: data.account.secondaryEmails ?? null,
            description: data.account.description ?? null,
            categories: data.account.categories ?? null,
            accountManager: data.account.accountManager ?? null,
            expiryDate: data.account.expiryDate ?? null,
            emailCount: data.account.emailCount ?? null,
            verifiedSeller: data.account.verifiedSeller ?? null,
            billingProof: data.account.billingProof ?? null,
            companyType: data.account.companyType ?? null
        }
    }
}

export const createUserRegDetails = state => {
    let formData = new FormData();
    formData.append("firstName", state.firstName);
    formData.append("lastName", state.lastName);
    formData.append("email", state.email);
    formData.append("password", state.password);
    formData.append("mobileNumber", state.mobile);
    state.location && formData.append("location", state.location);

    state.companyName !== '' && formData.append("companyName", state.companyName);
    state.companyType !== null && formData.append("companyTypeId", state.companyType.id);
    state.companyUrl !== '' && formData.append("companyUrl", state.companyUrl.split(apiConfig.frontEnd)[1]);
    state.designation !== '' && formData.append("designation", state.designation);
    state.website !== '' && formData.append("website", state.website);
    state.hotline !== '' && formData.append("hotLine", state.hotline);
    state.land !== '' && formData.append("landNumber", state.land);
    state.address !== '' && formData.append("address", state.address);
    state.coverImageFile && formData.append("coverImage", state.coverImageFile);
    state.imageFile && formData.append("profilePicture", state.imageFile);
    if (state.userType === constants.USER_SELLER) {
        formData.append("packageId", state.selectedSubscriptionID !== 1 ? state.selectedSubscriptionID.id : state.selectedSubscriptionID)
    }

    let secondaryEmails = '';
    state.secondaryEmails.map((item, index) => {
        secondaryEmails += (index !== 0 ? "," : "") + item
    })

    secondaryEmails !== '' && formData.append("secondaryEmail", secondaryEmails);
    let new_Sub_Category_Array = []
    // state.selectedNodes.map((val, index) => {
    //     val.subCategory.map((SelectedCat) => {
    //         let subCatID = SelectedCat.value.split("L")[0]
    //         new_Sub_Category_Array.push({ "id": parseInt(subCatID), "name": SelectedCat.label, "checked": true })
    //     })
    // })

    if (state.packages.length !== 0) {
        let categoryCount = state.packages[state.subscription_plan].categoryCount;

        [...Array(categoryCount)].map((val, index) => {
            let data = {...state['cat' + (index + 1)]}
            if (data !== undefined & data !== null & data !== "") {
                if (data.subCategory !== undefined) {
                    data.subCategory.map(val => {
                        new_Sub_Category_Array.push({"id": parseInt(val.value), "name": val.label, "checked": true})
                    })
                }

            }
        });
    }
    new_Sub_Category_Array.length !== 0 && formData.append("userSubCategoriesString", JSON.stringify(new_Sub_Category_Array));
    state.billingProofFile && formData.append("billingProof", state.billingProofFile);
    formData.append("type", state.userType);
    return formData;
}

export const createETUserRegDetails = state => {
    let formData = new FormData();
    formData.append("firstName", state.firstName);
    formData.append("lastName", state.lastName);
    formData.append("email", state.email);
    formData.append("password", state.password);
    formData.append("mobileNumber", state.mobile);

    state.companyName !== null && formData.append("companyName", state.companyName);
    state.companyUrl !== null && formData.append("companyUrl", state.companyUrl);
    state.designation !== null && formData.append("designation", state.designation);
    state.website !== null && formData.append("website", state.website);
    state.address !== null && formData.append("address", state.address);
    state.location !== null && formData.append("location", state.location);
    state.landNumber !== null && formData.append("landNumber", state.landNumber);
    state.hotLine !== null && formData.append("hotLine", state.hotLine);

    state.secondaryEmail !== null && formData.append("secondaryEmail", state.secondaryEmail);
    formData.append("type", constants.USER_SELLER);
    return formData;
}

export const createPurchaseRequestDetails = state => {
    let formData = new FormData();
    state.id && formData.append("id", state.id);
    formData.append("topic", state.title);
    formData.append("description", state.description);
    formData.append("qty", state.quantity);
    formData.append("closingDate", moment(state.closingDate).format(constants.DATE_FORMAT));
    formData.append("prefferedContactMethod", state.preferredContactMethod);
    formData.append("urgent", state.urgent);
    formData.append("currency", state.currency);
    formData.append("subCategoryString", "[]");
    formData.append("unit", '0');
    formData.append("recaptchaToken", state.recaptchaToken);
    state.user && formData.append("userString", JSON.stringify(state.user));

    formData.append("estimatedValue", state.estimatedValue === '' ? 0 : Number.parseFloat(state.estimatedValue).toFixed(2));
    (state.district && state.district !== '') && formData.append("location", state.district);
    state.attachmentFile && formData.append("attachment", state.attachmentFile);
    return formData;
}

export const createQuoteDetails = state => {
    let formData = new FormData();
    formData.append("requestId", state.id);
    formData.append("quotePrice", state.quotePrice);
    formData.append("quoteDescription", state.description);
    formData.append("currency", state.currency);
    state.attachmentFile && formData.append("quotePdf", state.attachmentFile);
    return formData;
}

export const createUpdateQuoteDetails = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("quotePrice", state.quotePrice);
    formData.append("quoteDescription", state.description);
    state.attachmentFile && formData.append("quotePdf", state.attachmentFile);
    return formData;
}

export const createProduct = (state, update) => {
    // let catDummy = state.selectedNodes[0].value
    // let cat = catDummy.split('L')[1].split('M')[0]
    // let subCat = catDummy.split('L')[0]

    let formData = new FormData();
    state.id && formData.append("id", state.id);
    formData.append("productName", state.productName);
    formData.append("description", state.description);
    formData.append("unitPrice", state.unitPrice === "" ? "0" + state.unitPrice : state.unitPrice);

    formData.append("currency", state.currency);
    state.availability !== undefined && formData.append("availability", state.availability);

    let att = '';
    state.productAttributes.map((item, index) => {
        att += (index !== 0 ? "," : "") + item.key + ":" + item.value
    })

    formData.append("productAttributes", att);
    state.imageFile && formData.append("image", state.imageFile);

    let new_Sub_Category_Array = []
    let mainCat = 0
    // state.selectedNodes.map((val, index) => {
    //     let subCatID = val.value.split("L")
    //     mainCat = subCatID[1].split('M')[0]
    //     // new_Sub_Category_Array.push(subCatID)
    //     new_Sub_Category_Array.push({ "id": parseInt(subCatID[0]), "name": val.label, "checked": true })
    // })

    let categoryCount = state.category.length;
    [...Array(categoryCount)].map((val, index) => {
        let data = {...state['cat' + (index + 1)]}
        if (data !== undefined & data !== null & data !== "") {
            if (data.subCategory !== undefined) {
                data.subCategory.map(val => {
                    if (val.checked) {
                        new_Sub_Category_Array.push({"id": parseInt(val.value), "name": val.label, "checked": true})
                    }
                })
            }

        }
    });

    if (update) {
        formData.append("category", mainCat.toString());
    } else {
        formData.append("category", mainCat.toString());
    }

    formData.append("subCategory", new_Sub_Category_Array.length > 0 ? new_Sub_Category_Array[0].id : 0);

    let keywordList = [];
    if (state.value.length > 0) {
        keywordList = state.value.map((val) => {
            return val.value
        })
    }
    formData.append("keyword", keywordList.length > 0 ? '[' + keywordList + ']' : "[]");
    return formData;
}

export const checkLoginStatus = () => {
    if (localStorage.getItem(constants.ACCESS_TOKEN) === null) {
        swal({
            title: modals.sorry,
            text: modals.loginWarning,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Ok", value: "action", className: "swalSuccess"},
                dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        window.location = routes.login
                        break;
                    default:
                        break;
                }
            });
        return false;
    } else return true
}

export const checkAvailability = (permission) => {
    swal({
        title: modals.sorry,
        text: permission ? modals.categorySubscribe : modals.becomeASeller,
        closeOnClickOutside: false,
        buttons: {
            success: {text: "Ok", value: "action", className: "swalSuccess"},
            dangerMood: !permission ? {text: "Cancel", value: "cancel", className: "swalCancel"} : null
        }
    })
        .then((value) => {
            switch (value) {
                case "action":
                    if (!permission) window.location = routes.login
                    break;
                default:
                    break;
            }
        });
}

export const createUpdateUser = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    state.firstName.trim() !== '' && formData.append("firstName", state.firstName);
    state.lastName.trim() !== '' && formData.append("lastName", state.lastName);
    state.hotLine.trim() !== '' && formData.append("hotLine", state.hotLine);
    state.landNumber.trim() !== '' && formData.append("landNumber", state.landNumber);
    state.mobile.trim() !== '' && formData.append("mobileNumber", state.mobile);
    state.address.trim() !== '' && formData.append("address", state.address);
    state.location !== null && formData.append("location", state.location);
    state.companyName.trim() !== '' && formData.append("companyName", state.companyName);
    state.designation.trim() !== '' && formData.append("designation", state.designation);
    state.website.trim() !== '' && formData.append("website", state.website);
    state.companyUrl.trim() !== '' && formData.append("companyUrl", state.companyUrl.split(apiConfig.frontEnd)[1]);
    state.coverImageFile && formData.append("coverImage", state.coverImageFile);
    state.billingProofFile && formData.append("billingProof", state.billingProofFile);
    state.imageFile && formData.append("profilePicture", state.imageFile);
    // state.description.trim() !== '' && formData.append("description", state.description);

    let secondaryEmails = '';
    state.secondaryEmails.map((item, index) => {
        secondaryEmails += (index !== 0 ? "," : "") + item
    })

    formData.append("secondaryEmails", state.secondaryEmails.length > 0 ? secondaryEmails : null);
    return formData;
}

export const createUpdateUserCategory = async state => {
    let new_Sub_Category_Array = []
    // state.selectedNodes.map((val, index) => {
    //     console.log("KA--------------: ", val);
    //     val.subCategory.map((SelectedCat) => {
    //
    //         console.log("KA--------------: ", SelectedCat.value);
    //
    //         let subCatID = SelectedCat.value.split("L")[0]
    //         new_Sub_Category_Array.push({ "id": parseInt(subCatID), "name": SelectedCat.label, "checked": true })
    //     })
    // })
    // let categoryCount = state.maxCategoryCount;

    const categoryCount = await Object.keys(state).filter((key) => {
        const match = key.match(/^cat(\d+)$/);
        return match && match[1];
    }).length;

    [...Array(categoryCount)].map((val, index) => {
        let data = {...state['cat' + (index + 1)]}
        if (data !== undefined & data !== null & data !== "") {
            if (data.subCategory !== undefined) {
                data.subCategory.map(val => {
                    if (val.checked) {
                        new_Sub_Category_Array.push({"id": parseInt(val.value), "name": val.label, "checked": true})
                    }
                })
            }

        }
    });

    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("subCategoriesString", JSON.stringify(new_Sub_Category_Array));
    return formData;
}

export const createUpdateUserDescription = state => {
    let formData = new FormData();
    formData.append("id", state.id);
    formData.append("description", state.editorHtml.toString());
    return formData;
}

export const getBase64 = (file) => {
    if (file.endsWith('.pdf')) {
        fetch(file).then(resp => resp.arrayBuffer()).then(resp => {
            const file = new Blob([resp], {type: 'application/pdf'});
            document.getElementById('downloadbtn').href = URL.createObjectURL(file);
        });
    } else {
        let image = new Image();
        image.crossOrigin = "anonymous";
        image.src = file;
        image.onload = function () {
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            canvas.getContext('2d').drawImage(this, 0, 0);
            let blob;
            if (image.src.indexOf(".jpg") > -1) {
                blob = canvas.toDataURL("image/jpeg");
            } else if (image.src.indexOf(".png") > -1) {
                blob = canvas.toDataURL("image/png");
            } else if (image.src.indexOf(".gif") > -1) {
                blob = canvas.toDataURL("image/gif");
            } else {
                blob = canvas.toDataURL("image/png");
            }
            document.getElementById('downloadbtn').href = blob;
        };
    }
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const openWebSite = (web) => {
    if (!web.startsWith('https://')) web = 'https://' + web;
    window.open(web, '_blank')
}

export const toTop = () => {
    document.querySelector('body').scrollTo(0, 0)
}

export async function getConvertedUTCDateTIme(dateStart, dateEnd) {
    if (dateStart !== null && dateEnd !== null) {
        let pub = (new Date((dateStart).format().toString().split('T')[0] + " 00:00:00").toISOString()).split('.')[0] + "Z";
        let ex = (new Date((dateEnd).format().toString().split('T')[0] + " 23:59:59").toISOString()).split('.')[0] + "Z";
        return {
            dateStart: pub,
            dateEnd: ex
        }
    }
    return null
}
