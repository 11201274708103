import React from "react";
import './requests.scss'
import { checkAvailability, checkLoginStatus, dateDiff, numberWithCommas } from "../../constants/commonFunc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import routes from "../../constants/routes";
import UpdateRequestModal from "./updateRequestModal";
import moment from "moment";
import constants from "../../constants/constants";
import swal from "sweetalert";
import { updateRequestStatus } from "../../service/request";
import { ToastUtil } from "../../constants/toastUtil";
import strings from "../../locals/string.json";
import { isBrowser } from "react-device-detect";


const modals = strings.modals;

class RequestItem extends React.Component {
    state = {
        updateRequestModal: false,
    }

    onView = (item) => {
        if (this.props.loggedInStatus) {
            if (item.owner || item.availability || item.myRequest) {
                if (this.props.myReqState) {
                    this.props.history.push(routes.viewRequest + `/${item.userRequestUrl}`, {...item,myReqState:this.props.myReqState})
                } else if (this.props.allReqState) {
                    this.props.history.push(routes.viewRequest + `/${item.userRequestUrl}`, {...item,allReqState:this.props.allReqState})
                } else {
                    this.props.history.push(routes.viewRequest + `/${item.userRequestUrl}`, item)
                }

            } else {
                checkAvailability(true)
            }
        } else {
            checkAvailability()
        }
    }

    onDelete = (id) => {
        swal({
            title: modals.areUSure,
            text: modals.delete,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Delete", value: "action", className: "swalSuccess" },
                dangerMood: { text: "Cancel", value: "cancel", className: "swalCancel" }
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        updateRequestStatus(id, 'DELETED')
                            .then(response => {
                                if (response.success) {
                                    this.props.onUpdate();
                                } else {
                                    ToastUtil.showErrorToast(response.msg)
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    render() {
        const item = this.props.item;
        return <div className="single-feature style-two">
            <div className="details pointer">
                <div className="details-wrap">

                    {/*<div className="actionHolder3">*/}
                    {/*    <p className="btn btn-white"*/}
                    {/*       onClick={() => item.owner ? this.onView(item) : checkLoginStatus()}><i*/}
                    {/*        className="fa fa-eye"/> View More</p>*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    !item.owner &&*/}
                    {/*    <>*/}
                    {/*        <p className={"remaining2 mt-2 " + (this.props.loggedInStatus && "alignWithFav")}*/}
                    {/*           style={{*/}
                    {/*               color: dateDiff(item) <= 0 ? "var(--main-color-two)" :*/}
                    {/*                   dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)"*/}
                    {/*           }}>*/}
                    {/*            {*/}
                    {/*                dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 1 ? '1 Day Remaining' : dateDiff(item) + " Days Remaining"*/}
                    {/*            }*/}
                    {/*        </p>*/}
                    {/*    </>*/}
                    {/*}*/}
                    {
                        !item.owner && this.props.loggedInStatus &&
                        <i className={item.fav ? "fa fa-heart" : "fa fa-heart-o"} onClick={this.props.onFav} />
                    }

                    {
                        item.owner &&
                        <div className="actionHolder">
                            <p className="actionBtn deleteLabel" onClick={() => this.onDelete(item.id)}><i
                                className="fa fa-trash" /> Delete</p>
                            <p className="actionBtn"
                                onClick={() => this.setState({ updateRequestModal: !this.state.updateRequestModal })}>
                                <i
                                    className="fa fa-edit" /> Edit
                            </p>
                        </div>
                    }
                    <div onClick={() => this.onView(item)}>
                        <h6 className="request-title-new tender-title mb-2 pr-4">{item.topic}</h6>

                        {/* // change category to sub category // */}
                        <span className={'request-category'}>{
                            item.subCategory.length > 0 ? item.subCategory.map((item, index) => index === 0 ? item.name : ", " + item.name) : 'N/A'
                        }</span>
                        <br />
                        <span className={'request-ref-no'}>Ref. No: {item.refNo}</span>



                        <div className={'row mt-1 mb-2'}>
                            {
                                item.location !== null ?
                                    <div className={'col-md-3'}>
                                        <span className={'txt-card-head'}>Location: </span>
                                        <span className={'txt-card-data'}>{item.location !== null ? item.location : 'N/A'}</span>
                                    </div>
                                    : null
                            }


                            <div className={`col-md-3`}>
                                <span className={'txt-card-head'}>Quantity: </span><span
                                    className={'txt-card-data'}>{item.qty}</span>
                            </div>
                            <div className={`${item.location === null ? 'col-md-7' : 'col-md-4'}`}>
                                <span className={'txt-card-head'}>Estimated Value: </span><span
                                    className={'txt-card-data'}>{item.estimatedValue === 0 ? 'N/A' :
                                        item.currency + " " + numberWithCommas(item.estimatedValue.toFixed(2))}</span>
                            </div>
                            <div className={'col-md-2'}>
                                <span className={'txt-card-normal'}>Quotations: </span><span
                                    className={'txt-card-data'}>{item.quotationCount}</span>
                            </div>
                        </div>
                        <div className="separator" />

                        <div className={'row'}>
                            <div className={'req-card-dates-pub col-md-4'}>
                                <b>Published On</b> : {moment(item.publishedDate).format(constants.DATE_FORMAT_EXTEND)}
                            </div>
                            <div className={'req-card-dates-cls col-md-4'}>
                                <b>Closing On</b> : {moment(item.closingDate).format(constants.DATE_FORMAT_EXTEND)}
                            </div>
                            <div className={'col-md-4'} align={'right'}>
                                <div className={`remaining-time-card`} style={{
                                    backgroundColor:  (item.owner && item.status === "PENDING") ? "var(--main-color-four)" :
                                        dateDiff(item) <= 0 ? "var(--main-color-two)" :
                                        dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)"
                                }}>
                                    {
                                        (item.owner && item.status === "PENDING") ?
                                            <span className={'remaining-time-card-text'}>Pending Approval</span> :
                                            <span className={'remaining-time-card-text'}>{
                                                dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 0 ? 'Less than a day' : dateDiff(item) + " Days Remaining"
                                            }</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        item.owner &&
                        <UpdateRequestModal
                            item={item}
                            categories={this.props.categories}
                            open={this.state.updateRequestModal}
                            close={() => this.setState({ updateRequestModal: !this.state.updateRequestModal })}
                            onupdate={this.props.onUpdate}
                        />
                    }

                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(RequestItem));
