import React, {Component} from 'react';
import {Assets} from "../../assets";

class Banner extends Component {

    render() {
        const inlineStyle = {
            backgroundImage: this.props.supplier?.coverImage !== null ?
                `url(${this.props.supplier?.coverImage})` : `url(${Assets.subBanner2})`
        }
        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h1 className="page-title ">
                                    {this.props.supplier?.companyName !== null ? this.props.supplier?.companyName : "Not Found"}
                                    {
                                        this.props.supplier?.verifiedSeller && <img className="verifiedSupplier verifiedWithViewSupplier ml-2" src={Assets.badge} alt='img'/>
                                    }
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Banner
