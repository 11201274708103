import React from 'react';
import './multiSelect.scss'
import Select from "react-select";

class MultiSelect extends React.Component {

    state = {
        open: false,
        text: '',
        selectedCategory: -1,
        selectedSubCategory: -1,
        viewSub: false
    }

    render() {
        const propState = this.props.state;
        const options = [];
        let mainCat = []
        {
            propState.categories.map((cate, index) => {
                cate.category.map((mainCat) => {
                    mainCat.subCategory.map((subCat) => {
                        options.push({
                            sub: subCat,
                            value: 'sub' + subCat.id + '_' + mainCat.id,
                            label: mainCat.name + " - " + subCat.name
                        })
                    })
                })
                // cate.subCategory.map((subCate,subCateIndex)=>{
                //     options.push({sub:subCate, value: 'sub'+subCate.id+'_'+cate.id, label: cate.name+" - "+subCate.name})
                // })
            })
        }

        return <div>
            <p>You can select upto 3 categories</p>
            {
                propState.subCategory.length !== 0 &&
                <div className='multiSelectContainer mb-3'>
                    <div className="searchWrapper" onClick={() => this.setState({open: !this.state.open})}>
                        {
                            propState.subCategory.map((item, index) => (
                                <span key={index} className="chip  false false"
                                      onClick={() => this.props.onRemoveSubCategory(item)}>{item.name}
                                    <i className="fa fa-close ml-2"/></span>
                            ))
                        }
                    </div>
                </div>
            }

            <Select
                className="basic-single mb-3"
                classNamePrefix="select"
                value={{value: null, label: 'Search and select category'}}
                isSearchable={true}
                options={options}
                placeholder={'Select Category'}
                onChange={({sub}) => this.props.onChangeSubCategory(sub)}
            />

            <div className="row">


                {
                    propState.categories.map((itemSuper, i) => {
                        itemSuper.category.map((item, i) =>
                            <div key={i} className="col-md-6 col-12">
                                <div
                                    className="categoryDisplay"
                                    onClick={() => {
                                        this.setState({
                                            viewSub: i !== this.state.selectedCategory,
                                            selectedCategory: i !== this.state.selectedCategory ? i : -1
                                        })
                                    }}
                                >
                                    <li className="groupHeading">{item.name}</li>
                                    <i className="fa fa-chevron-down arrowIcon"/>
                                </div>


                                {
                                    item.subCategory.map((sub, index) => {
                                        if ((this.state.viewSub && this.state.selectedCategory === i))
                                            if (!(propState.subCategory.filter(e => e.id === sub.id).length > 0)) {
                                                return <p key={sub.id}
                                                          onClick={() => this.props.onChangeSubCategory(sub)}
                                                          className="groupChildEle false false option">{sub.name}</p>
                                            }
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>


        </div>
    }
}

export default MultiSelect
