import React from "react";
import * as userActions from "../../store/domain/user/action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastUtil } from "../../constants/toastUtil";
import { updateUser } from "../../service/suppliers";
import { createUpdateUserCategory, createUpdateUserDescription, createUser } from "../../constants/commonFunc";
import strings from "../../locals/string.json";
import constants from "../../constants/constants";

const toast = strings.toast;
const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
}
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

class UserDescription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            editorHtml: ''
        }
    }

    componentDidMount() {
        if (this.props.user?.description !== null && this.props.user?.description.trim() !== '' && this.props.user?.description !== "null") {
            const xmlhttp = new XMLHttpRequest();
            const self = this;

            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    self.setState({ editorHtml: xmlhttp.responseText })
                }
            }
            xmlhttp.open("GET", this.props.user.description, false);
            xmlhttp.send();
        }

        this.setState({
            id: this.props.user.id
        })
    }

    handleChange = (html) => {
        this.setState({ editorHtml: html });
    }

    onSave = () => {
        updateUser(createUpdateUserDescription(this.state))
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.body)));
                    this.props.setAuth(createUser(response.body));
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    render() {
        return <div>
            <div className="display-flex justify-content-end mb-3">
                <button className="btn btn-yellow mt-3 mr-2"
                    onClick={this.onSave}>
                    <i className="fa fa-save mr-2" />Update Description
                </button>
            </div>
            <ReactQuill
                theme='snow'
                onChange={this.handleChange}
                value={this.state.editorHtml}
                modules={modules}
                formats={formats}
                bounds={'.app'}
                placeholder={'Enter your description'}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDescription));
