import React from "react";
import './oauth.scss';
import strings from "../../locals/string.json";

const registration = strings.registration;

export default (props) => {
    const plan = props.state.packages[props.state.subscription_plan]

    let discount = Math.round((plan.price * props.state.percentage) / 100).toFixed(2)
    if (discount > props.state.maximumAmount)
        discount = props.state.maximumAmount.toFixed(2);

    let totalDiscount = (Number(discount) + props.state.etUserDiscount).toFixed(2)
    let total = plan.price - totalDiscount;

    return <form className="contact-form-wrap contact-form-bg payment">
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <div className="row">
                    <div className="col-12 pb-3">
                        <div className="paymentHolder padding15">
                            <h6 className="title">{registration.paymentDetails}</h6>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h6 className="inputTitle">{registration.nameOnTHeCard}<span>*</span></h6>
                                    <div className="rld-single-input">
                                        <input type="text"
                                            id="cardholder-name"
                                            readOnly
                                            placeholder={registration.ownerName}
                                            value={props.state.nameOnTHeCard} />
                                    </div>
                                    <span className="text-danger card-holder-error err" />
                                </div>
                                <div className="col-12">
                                    <h6 className="inputTitle">{registration.cardNumber}<span>*</span></h6>
                                    <div className="rld-single-input">
                                        <input type="number"
                                            id="card-number"
                                            readOnly
                                            onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                            placeholder={registration.cardNumberPH}
                                            value={props.state.cardNumber} />
                                    </div>
                                    <span className="text-danger card-number-error err" />
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <h6 className="inputTitle">{registration.expDate}<span>*</span></h6>
                                    <div className='row'>
                                        <div className="col-6">
                                            <div className="rld-single-input">
                                                <input type="number"
                                                    id="expiry-month"
                                                    readOnly
                                                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                    placeholder="MM"
                                                    value={props.state.expMonth} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="rld-single-input">
                                                <input type="number"
                                                    id="expiry-year"
                                                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                    placeholder="YY"
                                                    readOnly
                                                    value={props.state.expYear} />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="text-danger exp-month-error err" />
                                    <br />
                                    <span className="text-danger exp-year-error err" />
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <h6 className="inputTitle">{registration.cvv}<span>*</span></h6>
                                    <div className="rld-single-input">
                                        <input type="number"
                                            id="security-code"
                                            readOnly
                                            onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                            placeholder={registration.cvPH}
                                            value={props.state.cvv} />
                                    </div>
                                    <span className="text-danger cvv-error err" />
                                </div>
                                <div className="col-12">
                                    <div className="general-error text-danger">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-3 ">
                        <div className="paymentHolder padding15">
                            <h6 className="title">{registration.checkoutReview}</h6>
                            <h6 className="checkoutDescription">{plan.packageName} membership
                                with {plan.emailCount !== undefined ? plan.emailCount : plan.secondaryEmailCount} email{(plan.emailCount !== undefined ? plan.emailCount : plan.secondaryEmailCount) > 1 ? 's' : ''} and {plan.categoryCount} {plan.categoryCount > 1 ? 'categories' : 'category'}.</h6>
                            <h6 className="validity"><b>Validity: </b>{plan.validityDuration}</h6>
                            <h6 className="validity"><b>Rs. {plan.price.toFixed(2)}</b></h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="paymentHolder padding15">

                    <h6 className="inputTitle">{registration.applyPromoCode}</h6>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 ">
                            <div className="rld-single-input">
                                <input type="text"
                                    name="promo"
                                    placeholder={registration.cvPH}
                                    onChange={props.onTextChange}
                                    value={props.state.promo} />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 ">
                            <div className="rld-single-input">
                                <button className="btn btn-yellow" type={"button"}
                                    onClick={props.applyPromo}>{registration.apply}</button>
                            </div>
                        </div>
                    </div>

                    <h6 className="mt-5">{registration.checkoutSummary}</h6>

                    <div className="paymentDetailsHolder mt-2">
                        <h6 className="paymentItem">{registration.subtotal}</h6>
                        <h6 className="paymentItem">RS. {plan.price.toFixed(2)}</h6>
                    </div>

                    <div className="paymentDetailsHolder" onClick={props.viewBreakDown}>
                        <h6 className="paymentItem">{registration.PromotionalDiscount} &nbsp;
                            <i className={props.state.viewBreakDown ? "fa fa-angle-up" : "fa fa-angle-down"}
                                aria-hidden="true" /></h6>
                        <h6 className="paymentItem">(RS. {totalDiscount})</h6>
                    </div>
                    {
                        props.state.viewBreakDown &&
                        <>
                            <div className="paymentDetailsHolder pl-3" onClick={props.viewBreakDown}>
                                <h6 className="paymentItem2">Promo Code</h6>
                                <h6 className="paymentItem2">RS. {discount}</h6>
                            </div>
                            <div className="paymentDetailsHolder pl-3" onClick={props.viewBreakDown}>
                                <h6 className="paymentItem2">ETender Member Discount</h6>
                                <h6 className="paymentItem2">RS. {props.state.etUserDiscount.toFixed(2)}</h6>
                            </div>
                        </>
                    }

                    <div className="paymentDetailsHolder mt-2">
                        <h6 className="paymentItem">{registration.total}</h6>
                        <h6 className="paymentItem">RS. {total.toFixed(2)}</h6>
                    </div>

                    <button id="save-card-button"
                        className="btn btn-yellow " type={"button"}>{registration.payNow}</button>
                </div>
            </div>
        </div>
    </form>
}
