import {__DEV__} from "../constants/constants";

const dev = 'https://b2b-et.ceyentra.lk';
const testing = 'https://b2b-et-test.ceyentra.lk';
const prod = 'https://api.b2b.lk';

const dev_basePath = `b2b-api`;
const test_basePath = `b2b-api`;
const prod_basePath = `api`;

const conf = {
    // serverUrl: __DEV__ ? dev : testing,
    serverUrl: prod,
    basePath: prod_basePath,
    version: `v1/`,
    frontEnd: 'b2b.lk/supplier/'
};

export default conf;
