import ApiService from './apiHandler';

export async function getAllProducts(index, size, data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `product/view-all?index=${index}&size=${size}`;
    apiObject.body = data
    apiObject.authentication = true
    apiObject.offpreloader = true
    return await ApiService.callApi(apiObject);
}

export async function addProduct(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `product`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updateProduct(data) {
    const apiObject = {};
    apiObject.method = 'PUT';
    apiObject.endpoint = `product`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function deleteProduct(id) {
    const apiObject = {};
    apiObject.method = 'DELETE';
    apiObject.endpoint = `product/${id}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getProduct(name) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `product/${name}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}
export async function productInqury(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `product/inqure`;
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function trackProduct(obj) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `seller/log`;
    apiObject.body = obj
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getAllKeywords() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `admin/keyword/get-global`;
    apiObject.body = null
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getKeywordsFromTitle(obj) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `product/keywords`;
    apiObject.body = obj
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}
