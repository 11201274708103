import React from "react";
import { Rate } from "antd";
import Rating from "./rating";
import { Assets } from "../../assets";
import { ToastUtil } from "../../constants/toastUtil";
import { getSupplierReview } from "../../service/suppliers";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class SupplierReview extends React.Component {

    state = {
        ratings: [],
        index: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        isNoData: false,
        totalRatingValue: 0
    }

    componentWillMount() {
        this.getAllReviews();
    }

    getAllReviews = async () => {
        getSupplierReview(this.props.item.id, this.state.index, this.state.size)
            .then(async response => {
                if (response.success) {
                    await this.setState({
                        ratings: response.body.ratings.content,
                        totalPages: response.body.ratings.totalPages,
                        totalElements: response.body.ratings.totalElements,
                        isNoData: response.body.ratings.content.length === 0,
                        totalRatingValue: response.body.totalRatingValue
                    })
                } else {
                    this.setState({ isNoData: true })
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onPageSelect = async data => {
        await this.setState({ index: data.selected });
        this.getAllReviews();
    }

    render() {
        const item = this.props.item;
        return <div className="mt-4 review">
            <div className="display-flex justify-content-between">
                <div className="display-flex align-items-center">
                    <p className="mb-0 ratingValue">{this.state.totalRatingValue}&nbsp;&nbsp;</p>
                    <Rate
                        disabled
                        allowHalf={true}
                        value={this.state.totalRatingValue}
                    />
                </div>
                {
                    !this.props.user && this.props.loggedInStatus &&
                    <button className="btn btn-yellow"
                        onClick={this.props.modalOpen}>Write
                        Review</button>
                }
            </div>

            <div className="separator mt-3 mb-3" />

            {
                this.state.ratings.map((item, index) => (
                    <Rating key={index} item={item} />
                ))
            }

            {
                this.state.isNoData && this.state.ratings.length === 0 &&
                <div className="display-flex flex-column  align-items-center"
                    style={{ width: '100%' }}>
                    <img src={Assets.reviewEmpty} alt="empty" />
                </div>
            }

            <div className="display-flex justify-content-center mt-3" style={{ width: '100%' }}>
                {
                    this.state.ratings.length !== 0 &&
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.totalPages}
                        forcePage={this.state.index}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={this.onPageSelect}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                }

            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(SupplierReview));
