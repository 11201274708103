import React from "react";
import {Assets} from "../../assets";
import {Rate} from "antd";
import strings from "../../locals/string.json";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import constants from "../../constants/constants";
import MyCategories from './categories';
import MyProfileUpdateModal from "./myProfileUpdateMdal";
import './myProfile.scss'
import SupplierReview from "../suppliers/supplierReview";
import UserDescription from "./userDescription";
import AccountManager from "./accountManager";
import MySubscription from "./mySubscription";
import {getSupplierReview} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";
import routes from "../../constants/routes";
import {checkCompanyUrlAvailability} from '../../service/auth'
import apiConfig from "../../service/apiConfig";
import * as services from "../../service/auth";
import {createUser} from "../../constants/commonFunc";
import Cookies from "js-cookie";

const pr = strings.purchaseRequest;
const profile = strings.profile;

class MyProfile extends React.Component {

    state = {
        action: 'Category',
        modalOpen: false,
        totalRatingValue: 0,
        totalElements: 0,
        renewalFee: 0
    }

    componentWillMount() {
        this.props.userType === constants.USER_SELLER && getSupplierReview(this.props.user.id, 0, 10)
            .then(response => {
                if (response.success) {
                    this.setState({
                        totalRatingValue: response.body.totalRatingValue,
                        totalElements: response.body.ratings.totalElements,
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    componentDidMount() {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            this.updateUser()
        }
    }

    updateUser = async () => {
        await services.getExpireData().then(async (res) => {
            await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)));
            this.setState({
                renewalFee: res.body.account.renewalFee
            })
        })
    }

    checkCompanyUrlAvailability = (btnClick, e) => {
        const companyUrl = e.companyUrl;
        companyUrl !== apiConfig.frontEnd ?
            checkCompanyUrlAvailability(companyUrl.split(apiConfig.frontEnd)[1])
                .then(response => {
                    if (response.success) {
                        btnClick && this.getAllCategory();
                    } else {
                        btnClick && ToastUtil.showErrorToast(response.msg);
                        this.setState({companyUrl: companyUrl + Math.floor(Math.random() * 100)})
                    }
                }) : btnClick && this.getAllCategory();
    }

    render() {
        const user = this.props.user;
        return <div>
            <div className="register-page-area pd-bottom-100 view-supplier">
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <div className="profileDetailsHolder">
                                <div className="companyDetails">
                                    <img className="logo"
                                         src={user.profilePicture !== null ? user.profilePicture : Assets.companyLogo}
                                         onClick={() => user.profilePicture !== null && window.open(user.profilePicture, '_blank')}
                                         alt="img"/>
                                    <p className="title">{user.companyName !== null ? user.companyName : "N/A"}</p>

                                    <div className="display-flex align-items-center">
                                        <Rate
                                            disabled
                                            value={1}
                                            count={1}
                                        />

                                        <p className="mb-0 ratingValue display-flex align-items-center">&nbsp;{this.state.totalRatingValue}
                                            <p className="mb-0 ratingCount">&nbsp;&nbsp;{this.state.totalElements} Reviews</p>
                                        </p>
                                    </div>

                                    {
                                        this.props.loggedInStatus &&
                                        <button className="btn btn-white review"
                                                onClick={() => this.setState({modalOpen: !this.state.modalOpen})}>
                                            <i className="fa fa-edit mr-2"/>Edit</button>
                                    }

                                </div>

                                <div className="separator mt-3"/>

                                <div className="p-3">
                                    <p className="title">{pr.contactDetails}</p>

                                    <p className="headline mt-3"><i
                                        className="fa fa-map-marker"/> &nbsp;&nbsp;{pr.address}</p>
                                    <p className="contactDetails">{user.address !== null ? user.address : "N/A"}</p>

                                    <p className="headline mt-3"><i className="fa fa-mobile"/> &nbsp;&nbsp;{pr.mobile}
                                    </p>
                                    <p className="contactDetails">{user.mobileNumber !== null ? user.mobileNumber : "N/A"}</p>

                                    <p className="headline mt-3"><i
                                        className="fa fa-phone"/> &nbsp;&nbsp;{profile.hotline}
                                    </p>
                                    <p className="contactDetails">{user.hotLine !== null ? user.hotLine : "N/A"}</p>

                                    <p className="headline mt-3"><i className="fa fa-fax"/> &nbsp;&nbsp;{pr.telephone}
                                    </p>
                                    <p className="contactDetails">{user.landNumber !== null ? user.landNumber : "N/A"}</p>

                                    <p className="headline mt-3"><i className="fa fa-envelope"/> &nbsp;&nbsp;{pr.email}
                                    </p>
                                    <p className="contactDetails">{user.email !== null ? user.email : "N/A"}</p>

                                    {
                                        user.secondaryEmails !== null && user.secondaryEmails !== "" && user.secondaryEmails.split(',').map((email, index) => {
                                            return <>
                                                <p key={index} className="headline mt-3"><i
                                                    className="fa fa-envelope"/> &nbsp;&nbsp;{pr.email}</p>
                                                <p className="contactDetails">{email}</p>
                                            </>
                                        })
                                    }

                                    <p className="headline mt-3"><i className="fa fa-globe"/> &nbsp;&nbsp;{pr.website}
                                    </p>
                                    <p  className="contactDetails">{user.website !== null ? user.website : "N/A"}</p>

                                </div>

                            </div>
                        </div>
                        {
                            (this.props.userType === constants.USER_SELLER || this.props.userType === constants.B2B_EXPIRED_SELLER) &&
                            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                <div className="property-filter-menu-wrap mt-3">
                                    <div className="property-filter-menu mb-0 portfolio-filter ">
                                        <button
                                            className={this.state.action === "Category" ? "active" : ''}
                                            onClick={() => this.setState({action: 'Category'})}>Categories
                                        </button>
                                        {/* <button
                                            className={this.state.action === "My Product" ? "active" : ''}
                                            onClick={()=> this.props.history.push(routes.myProducts)}>My Products
                                        </button> */}
                                        <button
                                            className={this.state.action === "Review" ? "active" : ''}
                                            onClick={() => this.setState({action: 'Review'})}>Reviews
                                        </button>
                                        <button
                                            className={this.state.action === "About" ? "active" : ''}
                                            onClick={() => this.setState({action: 'About'})}>About
                                        </button>
                                        {
                                            this.props.user.accountManager !== null &&
                                            <button
                                                className={this.state.action === "Account Manager" ? "active" : ''}
                                                onClick={() => this.setState({action: 'Account Manager'})}>Support
                                            </button>
                                        }
                                        <button
                                            className={this.state.action === "My Subscription" ? "active" : ''}
                                            onClick={() => this.setState({action: 'My Subscription'})}>My Subscription
                                        </button>
                                    </div>

                                    {
                                        this.state.action === 'Category' &&
                                        <MyCategories
                                            user={user}
                                        />
                                    }

                                    {
                                        this.state.action === 'About' &&
                                        <UserDescription/>
                                    }

                                    {
                                        this.state.action === 'Review' &&
                                        <SupplierReview
                                            user={true}
                                            item={user}/>
                                    }

                                    {
                                        this.state.action === 'Account Manager' &&
                                        <AccountManager user={user}/>
                                    }

                                    {
                                        this.state.action === 'My Subscription' &&
                                        <MySubscription state={this.state} user={user}/>
                                    }
                                </div>
                            </div>
                        }

                        {
                            this.props.userType === constants.USER_BUYER &&
                            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 placeholderImage
                            display-flex flex-column align-items-center justify-content-center">
                                <img className="regImg" src={Assets.regSeller} alt="seller"/>
                                <button className="btn btn-yellow loginBtn"
                                        onClick={() => this.props.history.push(routes.pricing)}>Become a Seller
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <MyProfileUpdateModal
                user={user}
                open={this.state.modalOpen}
                close={() => this.setState({modalOpen: !this.state.modalOpen})}
                checkCompanyUrlAvailability={(e) => this.checkCompanyUrlAvailability(false, e)}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(MyProfile));
