import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import routes from "../../constants/routes";
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import './globalComponents.scss'

class Ads extends Component {


    render() {

        const inlineStyle = {
            backgroundImage: `url(${Assets.newAdBanner})`,
            backgroundPosition:'center'
        }

        return !this.props.loggedInStatus && <div className="call-to-action-area pt-5">
            <div className="custom-register-banner text-center" style={inlineStyle}>
                <div className="custom-register-content">
                    <h3 className="custom-ad-title title">Grow your business with B2B.lk</h3>
                    <span className={'custom-ad-sub-title'}>Start your free trial today</span>
                    {/*<div align={'center'}>*/}
                    {/*    <p className={'custom-ad-paragraph mt-3 mb-5'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                    {/*        At culpa natus odio officia officiis pariatur praesentium soluta.*/}
                    {/*        At aut dolorem doloribus impedit nisi nobis obcaecati quaerat, quam quasi. Delectus, expedita!</p>*/}
                    {/*</div>*/}
                    <Link className="custom-register-btn btn btn-white" to={routes.register}>REGISTER NOW</Link>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(Ads));
