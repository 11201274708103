import React, {useEffect, useState} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBannerProduct';
import ViewProductPage from '../components/products/viewProduct';
import {getProduct} from "../service/product";
import {ToastUtil} from "../constants/toastUtil";

const ViewProduct = (props) => {

    const [product, setProduct] = useState(undefined);

    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0);
        getSupplierDetails();
    }, [])

    const getSupplierDetails = () => {
        let product = props.match.params.product;
        getProduct(product)
            .then(response => {
                if (response.success) {
                    setProduct(response.body);
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    return <div>
        <Navbar/>
        <Banner product={product}/>
        {
            product && <ViewProductPage product={product} history={props.history}/>
        }
        <Footer/>
    </div>
}

export default ViewProduct
