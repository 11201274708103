/*global PaymentSession*/
import {ToastUtil} from "./toastUtil";
import $ from 'jquery'

export const WebXPayTokenizeInit = (card, readyCallBack) => {
    if (PaymentSession) {
        const hostedSession = new HostedSession();
        hostedSession.configure(card, readyCallBack);
    } else {
        ToastUtil.showErrorToast("Bank's payment session not initialized!");
    }
}

function HostedSession() {
    let successCallBack = function () {
    };
    let errorCallBack = function () {
    };

    function configure(card, readyCallBack) {
        PaymentSession.configure({
            fields: {card: card},
            frameEmbeddingMitigation: ["javascript"],
            interaction: {
                displayControl: {
                    formatCard: "EMBOSSED",
                    invalidFieldCharacters: "REJECT"
                }
            },
            callbacks: {
                initialized: function (response) {
                    readyCallBack(GenerateSession);
                },
                formSessionUpdate: function (response) {
                    if (response.status) {
                        if ("ok" === response.status) {
                            // check if the security code was provided by the user
                            if (response.sourceOfFunds.provided.card.securityCode) {
                                successCallBack(response.session.id);
                            } else {
                                errorCallBack({
                                    type: "system_error",
                                    details: "cvv missing"
                                });
                            }
                        } else if ("fields_in_error" === response.status) {
                            errorCallBack({
                                type: "fields_in_error",
                                details: response.errors
                            });
                        } else if ("request_timeout" === response.status) {
                            errorCallBack({
                                type: "request_timeout",
                                details: response.errors.message
                            });
                        } else if ("system_error" === response.status) {
                            errorCallBack({
                                type: "system_error",
                                details: response.errors.message
                            });
                        }
                    } else {
                        errorCallBack({
                            type: "fields_in_error",
                            details: response
                        });
                    }
                }
            }
        })
    }

    function GenerateSession(callback, error) {
        successCallBack = callback;
        errorCallBack = error;
        PaymentSession.updateSessionFromForm("card");
    }

    return {
        configure: configure,
    };
}

export function handleErrors(error) {
    switch (error.type) {
        case 'fields_in_error': {
            if (error.details.cardNumber) {
                if (error.details.cardNumber === 'missing') {
                    $('.card-number-error').html('Enter valid card number');
                }
                if (error.details.cardNumber === 'invalid') {
                    $('.card-number-error').html('Invalid card number');
                }
            }
            if (error.details.expiryMonth) {
                if (error.details.expiryMonth === 'missing') {
                    $('.exp-month-error').html('Enter expiration month');
                }
                if (error.details.expiryMonth === 'invalid') {
                    $('.exp-month-error').html('Invalid expiration month');
                }
            }
            if (error.details.expiryYear) {
                if (error.details.expiryYear === 'missing') {
                    $('.exp-year-error').html('Enter expiration year');
                }
                if (error.details.expiryYear === 'invalid') {
                    $('.exp-year-error').html('Invalid expiration year');
                }
            }
            if (error.details.securityCode) {
                if (error.details.securityCode === 'missing') {
                    $('.cvv-error').html('Enter CVV');
                }
                if (error.details.securityCode === 'invalid') {
                    $('.cvv-error').html('Invalid CVV');
                }
            }
            break;
        }
        case 'request_timeout': {
            $('.general-error').html('<span class="text-decoration-uppercase">' + error.details + '</span>')
            break;
        }
        case 'system_error': {
            if (error.details === 'cvv missing') {
                $('.general-error').html('Enter CVV details');
            } else {
                $('.general-error').html(error.details);
            }
            break;
        }
    }
}
