import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Banner from '../components/banner/subBanner';
import Footer from "../components/globalComponents/footer";
import IslandWild from "../components/globalComponents/islandWide";
import OurStory from "../components/globalComponents/ourStory";
import Mission from "../components/globalComponents/mission-two";

const AboutUs = (props) => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0)
    }, [])

    return <div>
        <Navbar/>
        <Banner headertitle="About Us"/>
        <IslandWild/>
        <OurStory/>
        <Mission/>
        <Footer/>
    </div>
}

export default AboutUs

