import React from 'react'
import {Modal} from "react-bootstrap";
import '../purchaseRequest/requests.scss'
import strings from "../../locals/string.json";
import Files from "react-files";
import * as commonFunc from "../../constants/commonFunc";
import {validateQuoteDetails} from "../../constants/validation";
import './quote.scss'
import {updateQuote} from "../../service/quotation";
import {createUpdateQuoteDetails} from "../../constants/commonFunc";
import {ToastUtil} from "../../constants/toastUtil";
import {Select} from "antd";
import {CURRENCY} from "../../constants/constants";
import routes from "../../constants/routes";

const purchaseRequest = strings.purchaseRequest;
const {Option} = Select;

class UpdateQuoteModal extends React.Component {
    state = {
        quotePrice: '',
        description: '',
        topic: '',
        currency: 'LKR',
        qty: '',
        id: '',
        attachment: undefined,
        attachmentFile: undefined,
        attachmentType: undefined,
    }

    setValues = () => {
        const item = this.props.item;
        this.setState({
            id: item.id,
            quotePrice: item.quotePrice.toString(),
            description: item.quoteDescription,
            attachment: item.attachment,
            currency: item.currency,
            topic: item.requestDetails.topic,
            qty: item.requestDetails.qty

        })
    }

    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                attachmentType: file.type,
                attachmentFile: file,
                attachment: imageDataUrl
            });
        }
    };

    onButtonClick = () => {
        if (validateQuoteDetails(this.state, true)) {
            updateQuote(createUpdateQuoteDetails(this.state))
                .then(response => {
                    if (response.success) {
                        this.props.close();
                        this.props.reload();
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    onView = () => {
        const item = this.props.item
        const data = {
            ...item,
            ...item.requestDetails,
            buyer: {
                ...item.requestDetails.buyer,
                ...item.requestDetails.buyer.account,
            }
        }
        this.props.history.push(routes.viewRequest + `/${data.userRequestUrl}`, data)
    }

    render() {

        return <Modal
            className="updateRequest"
            show={this.props.open}
            onShow={this.setValues}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{this.props.qtype ? "View" : "Edit"} Quotation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="contact-form-wrap contact-form-bg quote">
                    <h6 className="mt-4 mb-0 title ">{purchaseRequest.requestInformation}</h6>

                    <div className="p-3">
                        <p className="sub-title pointer hyperLink" onClick={this.onView}>{this.state.topic}</p>
                        <p className="headline">Quantity:
                            <span className="c2"> {this.state.qty}</span></p>
                    </div>

                    <h6 className="mt-4 title" style={{marginBottom: 5}}>{purchaseRequest.quotationDetails}</h6>

                    <div className="row mb-3">
                        <div className="col-md-2 col-4 ">
                            <Select
                                value={this.state.currency}
                                disabled
                                showSearch
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={value => this.setState({currency: value})}
                            >
                                {
                                    CURRENCY.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="col-md-10 col-8 ">
                            <div className="form-group rld-single-input mt-0">
                                <input name="quotePrice" required="required" onChange={this.onTextChange} disabled
                                       value={this.state.quotePrice}/>
                                {/*<label>{purchaseRequest.quotePrice}</label>*/}
                            </div>
                        </div>
                    </div>

                    <div className="form-group rld-single-input">
                        <textarea rows={5} name="description" required="required" onChange={this.onTextChange} disabled
                                  value={this.state.description} maxLength={1000}/>
                        <span
                            className={`description-text-limit ${this.state.description.length >= 1000 ? 'description-text-limit-error' : null}`}>{this.state.description.length}/1000</span>
                        {/*<label>{purchaseRequest.quoteDescription}</label>*/}
                    </div>

                    <h6 className="mt-4 mb-0 title display-flex">{purchaseRequest.attachment}

                        <p className="view-file ml-4"
                           onClick={() => {
                               !this.state.attachmentType ?
                                   window.open(this.state.attachment, '_blank') :
                                   commonFunc.getB64ToBlob(this.state.attachment, this.state.attachmentType)
                           }}>{purchaseRequest.viewFile}</p>
                    </h6>
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-6 col-sm-12 mt-3">*/}
                    {/*        <Files*/}
                    {/*            className='files-dropzone-file'*/}
                    {/*            onChange={this.onFilesChange}*/}
                    {/*            accepts={["image/png", "image/jpg", "image/jpeg"]}*/}
                    {/*            multiple={false}*/}
                    {/*            maxFileSize={10485760}*/}
                    {/*            minFileSize={0}*/}
                    {/*            onError={error => console.log(error)}*/}
                    {/*            clickable*/}
                    {/*        >*/}
                    {/*            <div className="file-wrapper">*/}
                    {/*                <p className="choose">{purchaseRequest.chooseFile}</p>*/}
                    {/*                {*/}
                    {/*                    this.state.attachment &&*/}
                    {/*                    <p className="view-file"*/}
                    {/*                       onClick={() => {*/}
                    {/*                           !this.state.attachmentType ?*/}
                    {/*                               window.open(this.state.attachment, '_blank') :*/}
                    {/*                               commonFunc.getB64ToBlob(this.state.attachment, this.state.attachmentType)*/}
                    {/*                       }}>{purchaseRequest.viewFile}</p>*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </Files>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-white" onClick={this.props.close}>{purchaseRequest.cancel}</button>
                {/*<button className="btn btn-yellow" onClick={this.onButtonClick}>{purchaseRequest.updateQuote}</button>*/}
            </Modal.Footer>
        </Modal>
    }
}

export default UpdateQuoteModal;
