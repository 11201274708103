import React,{useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import AllSuppliers from "../components/suppliers/allSuppliers";

const Suppliers = (props) => {

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    return <div>
        <Navbar/>
        <Banner type={'supplier'} headertitle={"Suppliers"}/>
        <AllSuppliers history={props.history}/>
        <Footer/>
    </div>
}

export default Suppliers
