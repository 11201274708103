import React, {Component} from 'react';
import '../globalComponents/globalComponents.scss'
import {Link} from 'react-router-dom';
import strings from "../../locals/string.json";
import {breakPoint} from "../../constants/constants";
import routes from "../../constants/routes";
import Supplier from './supplier';
import Slider from "react-slick";
import {getAllSuppliers} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";

const landing = strings.landing;

class PopularSuppliers extends Component {

    state = {
        suppliers: []
    }

    componentWillMount() {
        getAllSuppliers(0, 10, {
            location: null,
            name: null,
            action: 'POPULAR',
            category: null,
            subCategory: null,
        }).then(response => {
            if (response.success) {
                this.setState({
                    suppliers: response.body.content,
                })
            } else {
                ToastUtil.showErrorToast(response.msg);
            }
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint,
        };

        return this.state.suppliers.length >= 4 && <div className="properties-area pd-top-60 pd-bottom-60 popularSupplier">
            <div className="container">
                <div className={'card-container'}>
                    <div className="section-title">
                        <span className="txt-sub-title">POPULAR</span>
                        <span className="title">{landing.popularSuppliers}</span>
                        <Link className="btn-view-all" to={routes.suppliers}>VIEW ALL</Link>
                    </div>

                    <Slider {...settings}>
                        {this.state.suppliers.map((item, i) =>
                            <div className="p-2 mb-3" key={i}>
                                <Supplier
                                    item={item}
                                    history={this.props.history}
                                />
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    }
}

export default PopularSuppliers
