import React,{useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import AllProducts from "../components/products/allProducts";
import routes from "../constants/routes";

const Products = (props) => {

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    const own = props.location.pathname === routes.myProducts
    return <div>
        <Navbar/>
        <Banner headertitle={own ? "My Products" : "Products"}/>
        <AllProducts history={props.history} own={own}/>
        <Footer/>
    </div>
}

export default Products
