import React from "react";
import {Modal} from "react-bootstrap";
import './oauth.scss'
import 'antd/dist/antd.css';
import strings from "../../locals/string.json";
import {Assets} from "../../assets";
import OtpInput from 'react-otp-input';
import {validateEmail, validateOtp, validatePassword} from "../../constants/validation";
import {requestPin, updateForgotPassword} from "../../service/auth";
import {ToastUtil} from "../../constants/toastUtil";

const registration = strings.registration;

class ForgotPasswordModal extends React.Component {

    state = {
        step: 1,
        email: '',
        otp: '',
        password: '',
        confirmPassword: '',
    }

    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }


    onButtonClick = () => {
        if (this.state.step === 1 && validateEmail(this.state)) {
            this.getPin();
            return;
        }
        if (this.state.step === 2 && validateOtp(this.state) && validatePassword(this.state)) {
            updateForgotPassword({
                otp: this.state.otp,
                newPassword: this.state.password
            }).then(response => {
                if (response.success) {
                    this.setState({step: 1, email: '', otp: '', password: '', confirmPassword: ''});
                    ToastUtil.showSuccessToast(response.msg);
                    this.props.close();
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
        }
    }

    getPin = () => {
        requestPin(this.state.email)
            .then(response => {
                if (response.success) {
                    this.setState({step: 2});
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    render() {
        return <Modal
            className="register forgot"
            show={this.props.open}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="md"
        >
            <Modal.Header closeButton={true}>
                {
                    this.state.step === 2 &&
                    <img className="pointer" src={Assets.back} alt="back"
                         onClick={() => this.setState({step: this.state.step - 1})}/>
                }
                <Modal.Title>{this.state.step === 1 ? registration.forgotPw : this.state.step === 2 ? registration.verifyEmail : registration.enterNewPW}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.step === 1 &&
                        <>
                            <p className="sub-title mt-4">Follow these simple steps to reset your password:</p>
                            <p className="steps">1. Enter your email address.</p>
                            <p className="steps">2. Wait for the unique OTP code to be sent.</p>
                            <p className="steps">3. Enter the OTP code in next screen and reset your password.</p>

                            <div className="form-group rld-single-input mt-3 mb-3">
                                <input type="text" name="email" required="required" onChange={this.onTextChange}
                                       value={this.state.email}/>
                                <label>{registration.email}</label>
                            </div>
                        </>
                }

                {
                    this.state.step === 2 &&
                    <>
                        <p className="sub-title mt-4">We have sent an 5-digit OTP code to your email address:&nbsp;
                            <b>{this.state.email}</b>.</p>
                        <p className="steps">Please check your inbox and enter the OTP code below</p>

                        <div className="justify-content-center mt-4 mb-4">
                            <OtpInput
                                value={this.state.otp}
                                onChange={(otp) => this.setState({ otp })}
                                numInputs={5}
                                inputStyle={{
                                    width: 50,
                                    height: 50,
                                    border: '1px solid #D4D4D4',
                                    borderRadius: 4,
                                    fontSize: 20
                                }}
                                separator={<span>&nbsp;</span>}
                            />
                        </div>

                        <p className="sub-title mt-4">Enter your new password here to recover your account</p>

                        <div className="form-group rld-single-input mb-4">
                            <input type="password" name="password" required="required" onChange={this.onTextChange}
                                   value={this.state.password}/>
                            <label>{registration.newPassword}</label>
                        </div>
                        <div className="form-group rld-single-input mb-4">
                            <input type="password" name="confirmPassword" required="required" onChange={this.onTextChange}
                                   value={this.state.confirmPassword}/>
                            <label>{registration.confirmPassword}</label>
                        </div>

                    </>
                }


                <button className="btn btn-yellow"
                        onClick={this.onButtonClick}>
                    {this.state.step === 1 ? registration.sendOTP : this.state.step === 2 ? registration.verifyOTP : registration.resetPW}
                </button>

                {
                    this.state.step !== 2 ?
                        <p className="dont">{registration.remember}
                            <p className="register" onClick={this.props.close}>&nbsp;{registration.login}</p>
                        </p> :
                        <p className="dont">{registration.notReceived}
                            <p className="register" onClick={this.getPin}>&nbsp;{registration.resend}</p>
                        </p>
                }

            </Modal.Body>
        </Modal>
    }
}

export default ForgotPasswordModal;
