import React from "react";
import './supplier.scss'
import {Assets} from "../../assets";
import {Rate} from "antd";

export default (props) => {
    const item = props.item;

    return <div className="display-flex flex-row rateItem mt-3" key={props.key}>
        <img className="ratingImage"
             src={item.from.profilePicture !== null ? item.from.profilePicture : Assets.avatarPlaceholder} alt="img"/>
        <div className="ml-3">
            <p className="ratingName">{item.from.firstName+" "+item.from.lastName}</p>
            <p className="companyName">{item.from.companyName !== null ?
                item.from.companyName : 'N/A'}</p>
            <Rate
                disabled
                allowHalf={true}
                value={item.rate}
            />
            <p className="companyName mt-2">{item.comment}</p>
        </div>
    </div>
}
