import React, {Component} from 'react';
import {Assets} from "../../assets";

class Banner extends Component {

    render() {
        const inlineStyle = {
            backgroundImage: `url(${this.props.type === 'purchase' ? Assets.purchaseBanner : this.props.type === 'supplier' ? Assets.supplierBanner : Assets.subBanner})`
        }
        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h1 className="page-title">{this.props.headertitle}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Banner
