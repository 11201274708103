import React from 'react';
import Navbar from '../components/globalComponents/navbar';
import Banner from "../components/banner/subBanner";
import Footer from "../components/globalComponents/footer";
import PricingPage from '../components/globalComponents/pricing';

const Pricing = (props) => {
    return <div>
        <Navbar />
        <Banner headertitle="Pricing Guide"/>
        <PricingPage history={props.history}/>
        <Footer/>
    </div>
}

export default Pricing

