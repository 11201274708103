import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/storeConfig/store";
import routes from "./constants/routes";
import Landing from './views/landing';
import Login from './views/login';
import Register from './views/register';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Requests from "./views/requests";
import MyRequests from "./views/myRequests";
import ViewRequests from "./views/viewRequests";
import Quotations from "./views/quotations";
import ReceivedQuotations from "./views/receivedQuotations";
import Products from "./views/products";
import ViewProduct from "./views/viewProduct";
import MyProducts from "./views/myProducts";
import Suppliers from "./views/suppliers";
import ViewSupplier from "./views/viewSupplier";
import MyProfile from "./views/myProfile";
import AboutUs from "./views/aboutUs";
import Contact from "./views/contact";
import Error from "./components/globalComponents/expire";
import Pricing from "./views/pricing";
import Secure3dsRedirection from "./views/Secure3dsRedirection";
import $ from "jquery";

$(document).on("wheel", "input[type=number]", function (e) {
    $(this).blur();
});

class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <Suspense>
                    <Router>
                        <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <Switch>
                            {/* oauth & landing */}
                            <Route exact path={routes.home} component={Landing}/>
                            <Route exact path={routes.login} component={Login}/>
                            <Route exact path={routes.register} component={Register}/>

                            {/* requests */}
                            <Route exact path={routes.requests} component={Requests}/>
                            <Route exact path={routes.myRequests} component={MyRequests}/>
                            <Route exact path={routes.viewRequest + "/:url"} component={ViewRequests}/>
                            <Route exact path={routes.viewRequestQuotations + "/:url"} component={ViewRequests}/>

                            {/* quotations */}
                            <Route exact path={routes.myQuotes} component={Quotations}/>
                            <Route exact path={routes.receivedQuotes} component={ReceivedQuotations}/>

                            {/* products */}
                            <Route exact path={routes.products} component={Products}/>
                            <Route exact path={routes.products + "/:product"} component={ViewProduct}/>
                            <Route exact path={routes.myProducts} component={MyProducts}/>

                            {/* suppliers */}
                            <Route exact path={routes.suppliers} component={Suppliers}/>
                            <Route exact path={routes.viewSupplier + "/:company_name"} component={ViewSupplier}/>

                            {/* my-profile */}
                            <Route exact path={routes.myProfile} component={MyProfile}/>

                            {/* b2b */}
                            <Route exact path={routes.about} component={AboutUs}/>
                            <Route exact path={routes.pricing} component={Pricing}/>
                            <Route exact path={routes.contact} component={Contact}/>

                            {/*3ds payment layer*/}
                            <Route exact path={routes.secure3dsRedirection} component={Secure3dsRedirection}/>

                        </Switch>
                        <Error/>
                    </Router>
                </Suspense>
            </Provider>
        )
    }
}

export default Root;

ReactDOM.render(<Root/>, document.getElementById('root'));
