import React from 'react';
import { useLocation } from 'react-router-dom';

const Secure3dsRedirection = () => {

    const location = useLocation();
    const html3ds = location.state?.html3ds;

    return(
        <div>
            { html3ds }
        </div>
    );

};

export default Secure3dsRedirection;
