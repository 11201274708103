import React from "react";
import {Modal} from "react-bootstrap";
import '../globalComponents/globalComponents.scss'
import strings from "../../locals/string.json";
import Files from "react-files";
import * as commonFunc from "../../constants/commonFunc";
import {createProduct} from "../../constants/commonFunc";
import {Select, Switch} from "antd";
import {categoryActions, CURRENCY} from "../../constants/constants";
import {validateProductDetails} from "../../constants/validation";
import {addProduct, getAllKeywords, getKeywordsFromTitle} from "../../service/product";
import {ToastUtil} from "../../constants/toastUtil";
import {getCategories} from "../../service/category";
import Cropper from 'react-easy-crop'
import getCroppedImg, {dataURLtoFile} from "../../constants/cropImage";
import CreatableSelect from "react-select/creatable";
import Container from "../myProfile/newCategoryModel";
import CategorySelector from "../categorySelector";
import {tr} from "react-date-range/dist/locale";

const pr = strings.purchaseRequest;
const {Option} = Select;
const toast = strings.toast;

const components = {
    DropdownIndicator: null,
};


const createOption = (label) => ({
    label,
    value: label,
});

class NewProductModal extends React.Component {

    state = {
        imageType: undefined,
        imageFile: undefined,
        productName: "",
        image: "",
        description: "",
        productAttributes: [],
        unitPrice: '0',
        currency: 'LKR',
        availability: true,
        categories: [],
        category: [],
        selectedCategory: undefined,
        subCategory: [],
        selectedSub: undefined,
        open: false,
        crop: {x: 100, y: 100},
        zoom: 1,
        isValidation: false,
        value: [],
        inputValue: '',
        keywordList: [],
        catIdArray: [],
        nodesFiltered: [],
        selectedNodes: [],
        keyword: '',
        newKeyword: '',

        openCrop: false,
    }

    getCategories = () => {
        getCategories(categoryActions.AVAILABLE, true)
            .then(async response => {
                if (response.success) {
                    const cat = response.body.map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                            disabled: true,
                            children: item.category.map(main => {
                                return {
                                    value: main.id,
                                    label: main.name,
                                    disabled: true,
                                    children: main.subCategory.map(sub => {
                                        return {
                                            value: sub.id,
                                            label: sub.name,
                                            disabled: false,
                                            checked: false
                                        }
                                    }),
                                    subCategory: main.subCategory.map(sub => {
                                        return {
                                            value: sub.id,
                                            label: sub.name,
                                            disabled: false,
                                            checked: false
                                        }
                                    })
                                }
                            }),
                        }
                    });
                    const catLength = 3;
                    const catIdArray = []
                    for (let i = 0; i < catLength; i++) {
                        await this.setState({
                            ['cat' + (i + 1)]: undefined
                        })
                        cat[i] && catIdArray.push(-1)
                    }
                    await this.setState({
                        categories: cat,
                        catIdArray: catIdArray,
                        nodesFiltered: cat,
                    })
                }
            })

        this.allKeywords()
    }

    allKeywords = () => {
        getAllKeywords().then((res) => {
            if (res.success) {
                let keywords = []
                res.body.map((keyword) => {
                    if (keyword.keyword !== null) {
                        keywords.push(keyword.keyword)
                    }
                })

                this.setState({keywordList: keywords})
            }
        })
    }

    onTextChange = (event) => {
        if (event.target.name === 'productName') {
            if (event.target.value.trim() === '') {
                this.setState({
                    [event.target.name]: event.target.value,
                    value: []
                })
            } else {
                this.setState({
                    [event.target.name]: event.target.value
                })
            }
        } else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            await this.setState({
                imageType: file.type,
                imageFile: file,
                image: imageDataUrl,
                open: false
            });
        }
    };

    onCropOpenChange = async value => {
        await this.setState({
            open: value
        });
    };

    onChangeSubCategory = (item) => {
        this.setState({
            subCategory: item.id,
            selectedSub: item.name
        })
    }

    onChangeCategory = (item) => {
        this.setState({
            selectedSub: null,
            subCategory: null,
            selectedCategory: this.state.categories[item]
        })
    }

    onAttributeChange = (value, type, index) => {
        const data = [];
        this.state.productAttributes.map((item, i) => {
            if (i !== index) {
                data.push(item);
            } else {
                const obj = {
                    key: type === 'key' ? value : item.key,
                    value: type === 'value' ? value : item.value
                }
                data.push(obj)
            }
        })
        this.setState({productAttributes: data})
    }

    onRemove = (index) => {
        let data = this.state.productAttributes;
        data.splice(index, 1);
        this.setState({productAttributes: data})
    }

    onAdd = () => {
        let data = this.state.productAttributes;
        data.push({
            id: data.length,
            key: '',
            value: ''
        })
        this.setState({productAttributes: data})
    }

    onButtonClick = () => {
        if (this.state.open) {
            this.setState({open: false, image: this.state.imageCrop});
            return;
        }

        if (validateProductDetails(this.state)) {
            addProduct(createProduct(this.state, false))
                .then(response => {
                    if (response.success) {
                        this.setState({
                            imageType: undefined,
                            imageFile: undefined,
                            productName: "",
                            image: "",
                            currency: "LKR",
                            description: "",
                            productAttributes: [],
                            unitPrice: '',
                            availability: true,
                            categories: [],
                            selectedCategory: undefined,
                            subCategory: "",
                            selectedSub: undefined,
                            value: [],
                            isValidation: false
                        })
                        this.props.close();
                        this.props.reload();
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        } else {
            this.setState({isValidation: true})
        }
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const result = await getCroppedImg(this.state.image, croppedAreaPixels, 0);
        const file = dataURLtoFile(result, 'product-image.jpg')
        file.preview = {
            type: "image",
            url: result
        }
        file.extension = "jpg"
        file.id = "files-1"
        await this.setState({
            imageCrop: result,
            imageFile: file
        })
    }

    onClose = () => {
        if (this.state.open) {
            this.setState({
                image: '',
                open: false
            })
        } else {
            this.setState({
                imageType: undefined,
                imageFile: undefined,
                productName: "",
                image: "",
                currency: "LKR",
                description: "",
                productAttributes: [],
                unitPrice: '',
                availability: true,
                categories: [],
                selectedCategory: undefined,
                subCategory: "",
                selectedSub: undefined,
                value: [],
                isValidation: false,
            })
            this.props.close()
        }
    }

    handleChange = async (value, actionMeta) => {
        await this.setState({
            value: value,
            isExpandKeyword: true
        });
    };

    handleInputChange = (inputValue) => {
        this.setState({
            newKeywordDummy: inputValue,
            isExpandKeyword: true
        });
    };

    handleKeyDown = (event) => {
        const {inputValue, value} = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (!this.findAlreadyAddedKeyword(inputValue)) {
                    this.setState({
                        inputValue: '',
                        value: [...value, createOption(inputValue)],
                    });
                } else {
                    ToastUtil.showErrorToast('Keyword already added')
                }
        }
    }

    findAlreadyAddedKeyword = (input) => {
        const {value} = this.state
        let dummyArray = []

        if (value.length > 0) {
            value.map((val) => {
                dummyArray.push(val.value)
            })
        }

        return dummyArray.includes(input)
    }

    /**
     * get keywords from title
     **/
    onTitleLeave = async () => {
        if (this.state.productName !== '') {
            const obj = {
                "title": this.state.productName
            }
            getKeywordsFromTitle(obj).then((res) => {
                if (res.success) {
                    let foundList = []
                    res.body.map((keyword) => {
                        foundList.push({
                            label: keyword.replace(/%20/g, ' '),
                            value: keyword
                        })
                    })
                    let arr1 = this.state.value;
                    let newAddedValues = arr1.filter((obj1) => obj1.__isNew__ === true);
                    this.setState({
                        value: foundList.concat(newAddedValues),
                        newKeywordDummy: '',
                        isExpandKeyword: false
                    })
                }
            })
        } else {
            this.setState({
                ...this.state,
                value: []
            })
        }

        // await this.setState({
        //     value: []
        // })

        // const {value, productName, keywordList} = this.state;
        // let foundList = []

        // if (productName !== '') {

        //     let wordsList = productName.trim().split(' ')
        //     wordsList = [...wordsList, ...productName.trim().split(',')]
        //     wordsList = [...wordsList, ...productName.trim().split('-')]
        //     wordsList = [...wordsList, ...productName.trim().split('.')]

        //     const words = wordsList.filter((val, id, array) => {
        //         return array.indexOf(val) == id;
        //     });

        //     words.map((word) => {
        //         keywordList.map((keyword) => {
        //             if (keyword === word.toLowerCase()) {
        //                 foundList.push({
        //                     label: keyword,
        //                     value: keyword
        //                 })
        //             }
        //         })
        //     })

        //     this.setState({
        //         value: [...value, ...foundList]
        //     })
        // }
    }

    onChange = (currentNode, selectedNodes) => {
        if (selectedNodes.length === 1) {
            this.setState({selectedNodes})
        } else {
            ToastUtil.showErrorToast('Category count exceeded')
        }
        // this.setState({selectedNodes})
        // console.log('onChange::', currentNode, selectedNodes)
        // this.clickChild()
        // let catArray = []
        // selectedNodes.map((cat, index) => {
        //     let mainCatID = cat.value.split("L")[0]
        //     catArray.push(mainCatID)
        // })
        // this.clickChild()
        //
        // // let limit = this.state.packages[this.state.subscription_plan].categoryCount
        // if (this.state.selectedNodes.length <= 1) {
        //     this.setState({selectedNodes})
        //     console.log('onChange::', currentNode, selectedNodes)
        // } else {
        //     ToastUtil.showErrorToast('Category count exeeded')
        // }
    }

    onCategoryChange = async (index, value) => {
        // const category = { ...this.state.categories[value]
        const category = value;
        const result = commonFunc.onCategoryChange(category, this.state.subCategory ? this.state.subCategory : [], this.state.category)
        const catIdArray = [...this.state.catIdArray];
        catIdArray[index] = category.value;
        await this.setState({
            subCategory: result.data,
            category: result.cat,
            ["cat" + (index + 1)]: category,
            catIdArray: catIdArray
        })
    }

    onSelectAllSubCategory = (category, status) => {
        const data = this.state.subCategory;
        const cat = this.state.category;
        category.subCategory.map((item, index) => {
            if ((data.filter(e => e.id === item.id).length > 0)) {
                data.splice(data.findIndex(e => e.id === item.id), 1);
            }
            if (status) {
                data.push(item);
            }
        })

        if (!(cat.filter(e => e.id === category.id).length > 0)) {
            cat.push(category);
        } else {
            if (!status) {
                cat.splice(cat.findIndex(e => e.id === category.id), 1);
            }
        }
        this.setState({subCategory: data, category: cat})
    }

    setCategory = async (index, value) => {
        // const category = { ...this.state.categories[value] }
        await this.setState({
            ["cat" + index]: value
        })
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    handleSubCategoryChange = async (index, status, id) => {
        let data = {...this.state['cat' + index]};
        const result = commonFunc.handleSubCategoryChangeForProducts(index, status, id, data)
        this.setState({['cat' + index]: result})
    }

    onSubCategoryChange = (category, item) => {
        const result = commonFunc.onSubCategoryChangeForProducts(category, item, this.state.subCategory, this.state.category)
        this.setState({subCategory: result.data, category: result.cat})
    }

    render() {
        const item = this.state;
        const {inputValue, value} = this.state;

        const userType = "";

        let keywords = []
        this.state.keywordList.map((keyword) => {
            keywords.push({value: keyword, label: keyword})
        })

        return <Modal
            className="productViewModal"
            show={this.props.open}
            onHide={this.onClose}
            onShow={this.getCategories}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{item.open ? 'Crop Image' : 'Add New Product'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="myProduct">
                {
                    this.state.open ?
                        <Cropper
                            image={item.image}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={4 / 3}
                            onCropChange={value => this.setState({crop: value})}
                            onZoomChange={value => this.setState({zoom: value})}
                            onCropComplete={this.onCropComplete}
                        />
                        :
                        <>
                            <h6 className="mt-4 title">{pr.productInformation}</h6>
                            <div className="row">
                                {
                                    !item.open && item.image !== '' &&
                                    <div className="col-md-3 col-sm-12 mt-3" style={{position: 'relative'}}>
                                        <img className="productImage" src={item.image} alt='image'/>
                                        <button className="btn" onClick={() => this.onCropOpenChange(true)}>
                                            <i className="fa fa-crop" aria-hidden="true"></i> Crop the image
                                        </button>
                                    </div>
                                }

                                <div className="col-md-9 col-sm-12 mt-3">
                                    <div>
                                        <Files
                                            className='files-dropzone-file'
                                            onChange={this.onFilesChange}
                                            accepts={["image/png", "image/jpg", "image/jpeg"]}
                                            multiple={false}
                                            maxFileSize={10485760}
                                            minFileSize={0}
                                            onError={error => console.log(error)}
                                            clickable
                                        >
                                            <p className="choose m-0">{pr.choosePI}</p>
                                        </Files>
                                    </div>
                                </div>
                            </div>

                            <div className="separator mt-3 mb-3"/>

                            <form className="contact-form-bg">
                                <div className="form-group rld-single-input">
                                    <textarea rows={2} name="productName" required="required"
                                              onChange={this.onTextChange}
                                              value={item.productName}
                                              maxLength={250}
                                              className={item.productName.trim() === '' && this.state.isValidation ? 'input-error' : ''}
                                        /* onBlur={this.onTitleLeave} // removed keyword auto searching from title option under the client request on 2023-06-23 */
                                              placeholder={pr.pName}
                                    />
                                    <span
                                        className={`description-text-limit ${item.productName.length >= 250 ? 'description-text-limit-error' : null}`}>{item.productName.length}/250</span>
                                    {/*<label>{pr.pName}</label>*/}
                                    {
                                        item.productName.trim() === '' && this.state.isValidation ?
                                            <span
                                                className={'fields-error-text'}>{toast.pName}</span>
                                            : null
                                    }
                                </div>

                                <div className="form-group rld-single-input mt-2">
                                    <textarea rows={4} name="description" required="required"
                                              onChange={this.onTextChange}
                                              value={item.description}
                                              maxLength={4000}
                                              className={item.description.trim() === '' && this.state.isValidation ? 'input-error' : ''}
                                              placeholder={pr.pDescription}
                                    />
                                    <span
                                        className={`description-text-limit ${item.description.length >= 4000 ? 'description-text-limit-error' : null}`}>{item.description.length}/4000</span>
                                    {/*<label>{pr.pDescription}</label>*/}
                                    {
                                        item.description.trim() === '' && this.state.isValidation ?
                                            <span
                                                className={'fields-error-text'}>{toast.pDescription}</span>
                                            : null
                                    }
                                </div>

                                <h6 className="mt-4 title mr-3">{pr.productAttributes}</h6>
                                <h6 className="mt-3 add" onClick={this.onAdd}>+ Add Attribute</h6>

                                {
                                    item.productAttributes.map((item, index) => {
                                        return <>

                                            <h6 className="mt-3 remove" onClick={() => this.onRemove(index)}>Remove
                                                Attribute</h6>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group rld-single-input">
                                                        <input required="required" value={item.key}
                                                               onChange={event => this.onAttributeChange(event.target.value, 'key', index)}/>
                                                        <label>Attribute Name</label>
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="form-group rld-single-input">
                                                        <input required="required" value={item.value}
                                                               onChange={event => this.onAttributeChange(event.target.value, 'value', index)}/>
                                                        <label>Attribute Value</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }

                                <p className="inputLabel" style={{marginBottom: 5}}>{pr.unitPrice}</p>
                                <div className="row">
                                    <div className="col-md-2 col-4 ">
                                        <Select
                                            value={this.state.currency}
                                            showSearch
                                            placeholder="Select Category"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={value => this.setState({currency: value})}
                                        >
                                            {
                                                CURRENCY.map((item, i) =>
                                                    <Option key={i} value={item}>{item}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-10 col-8 ">
                                        <div className="rld-single-input ">
                                            <input name="unitPrice" required="required"
                                                   onChange={this.onTextChange}
                                                   value={item.unitPrice ? item.unitPrice : 0}
                                                   placeholder={pr.unitPrice}
                                                   className={item.unitPrice.trim() === '' && this.state.isValidation ? 'input-error' : ''}
                                            />
                                        </div>
                                        {
                                            item.unitPrice.trim() === '' && this.state.isValidation ?
                                                <span
                                                    className={'fields-error-text'}>{toast.unitPrice}</span>
                                                : null
                                        }
                                    </div>
                                </div>

                                <div className="row updateRequest mt-4">
                                    <h6 className="mt-4 title ml-3 mb-2">{'Category'}</h6>
                                    <div className={'col-md-12'}>
                                        {/*<Container*/}
                                        {/*    data={this.state.categories}*/}
                                        {/*    onChange={this.onChange}*/}
                                        {/*    setClick={click => this.clickChild = click}*/}
                                        {/*/>*/}


                                        {/*Kavindu Akash*/}

                                        <CategorySelector
                                            data={this.state.categories}
                                            catlimit={true}
                                            state={{...this.state, userType}}
                                            // categoryCount={categoryCount}
                                            categoryCount={1} // only For Testing
                                            onCategoryChange={this.onCategoryChange}
                                            onSelectAllSubCategory={this.onSelectAllSubCategory}
                                            setCategory={this.setCategory}
                                            clearSelection={this.clearSelection}
                                            active={1}
                                            handleSubCategoryChange={this.handleSubCategoryChange}
                                            onSubCategoryChange={this.onSubCategoryChange}
                                        />


                                    </div>
                                    {/*<div className="col-md-6 col-sm-12 mt-3">*/}
                                    {/*    <Select*/}
                                    {/*        allowClear*/}
                                    {/*        showSearch*/}
                                    {/*        placeholder="Select category"*/}
                                    {/*        optionFilterProp="children"*/}
                                    {/*        filterOption={(input, option) =>*/}
                                    {/*            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                                    {/*        }*/}
                                    {/*        filterSort={(optionA, optionB) =>*/}
                                    {/*            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())*/}
                                    {/*        }*/}
                                    {/*        onChange={value => this.onChangeCategory(value)}*/}
                                    {/*    >*/}
                                    {/*        {*/}
                                    {/*            this.state.categories.map((item, i) =>*/}
                                    {/*                <Option key={i} value={i}>{item.name}</Option>*/}
                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*    </Select>*/}
                                    {/*</div>*/}
                                    {/*{*/}
                                    {/*    item.selectedCategory &&*/}
                                    {/*    <div className="col-md-6 col-sm-12 mt-3">*/}
                                    {/*        <Select*/}
                                    {/*            showSearch*/}
                                    {/*            value={this.state.selectedSub}*/}
                                    {/*            placeholder="Select sub category"*/}
                                    {/*            optionFilterProp="children"*/}
                                    {/*            filterOption={(input, option) =>*/}
                                    {/*                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                                    {/*            }*/}
                                    {/*            filterSort={(optionA, optionB) =>*/}
                                    {/*                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())*/}
                                    {/*            }*/}
                                    {/*            onChange={value => this.onChangeSubCategory(this.state.selectedCategory.subCategory[value])}*/}
                                    {/*        >*/}
                                    {/*            {*/}
                                    {/*                this.state.selectedCategory.subCategory.map((item, i) =>*/}
                                    {/*                    <Option key={i} value={i}>{item.name}</Option>*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*        </Select>*/}

                                    {/*    </div>*/}
                                    {/*}*/}

                                </div>

                                <h6 className="mt-4">{pr.productAvailability}</h6>

                                <Switch checked={this.state.availability}
                                        onChange={(value) => this.setState({availability: value})}/>

                                <div>
                                    <h6 className="mt-4 title mr-3 mb-2">{pr.keywords}</h6>
                                    <CreatableSelect
                                        components={components}
                                        isClearable
                                        isMulti
                                        options={keywords}
                                        isSearchable={true}
                                        // menuIsOpen={this.state.isExpandKeyword}
                                        defaultValue={this.state.isUpdatedKeyword ? {
                                            label: this.state.updatedKeyword.name,
                                            value: this.state.updatedKeyword.id
                                        } : this.state.keyword}
                                        onChange={this.handleChange}
                                        onInputChange={this.handleInputChange}
                                        onKeyDown={this.handleKeyDown}
                                        value={this.state.value}
                                        onFocus={() => this.setState({
                                            // newKeywordDummy: this.state.newKeyword,
                                            newKeyword: '',
                                            isExpandSource: true
                                        })}
                                        inputValue={this.state.newKeywordDummy}
                                        blurInputOnSelect
                                        onMenuClose={() => this.setState({
                                            newKeyword: this.state.newKeywordDummy,
                                            isExpandKeyword: false
                                        })}
                                        placeholder={this.state.keyword || 'Type keyword and press enter...'}
                                    />
                                </div>
                            </form>
                        </>
                }


            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClose}>{pr.cancel}</button>
                <button className="btn btn-yellow"
                        onClick={this.onButtonClick}>{item.open ? pr.crop : pr.submit2}</button>
            </Modal.Footer>
        </Modal>
    }
}

export default NewProductModal;
