import ApiService from './apiHandler';

/**
 *
 * @param action one of from GET_ALL, AVAILABLE, POPULAR
 * @returns {Promise<*>}
 */
export async function getCategories(action, auth) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `category/${action}`;
    apiObject.authentication = auth;
    return await ApiService.callApi(apiObject);
}

export async function getCategoriesByProductId(id) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `category/product/${id}`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}
