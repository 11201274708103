const routes = {
    home: '/',
    login: '/login',
    register: '/register',
    requests: '/requests',
    myQuotes: '/my-quotations',
    receivedQuotes: '/received-quotations',
    myRequests: '/my-requests',
    products: '/products',
    myProducts: '/my-products',
    suppliers: '/suppliers',
    contact: '/contact',
    pricing: '/pricing',
    about: '/about-us',
    forgotPW: '/forgot-password',
    myProfile: '/my-profile',
    viewSupplier: '/supplier',
    viewProduct: '/view-product',
    viewRequest: '/view-request',
    viewRequestQuotations: '/view-request-quotation',
    secure3dsRedirection: '/secure-3ds-redirection'
}

export default routes;
