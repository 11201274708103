import React from "react";
import { Modal } from "react-bootstrap";
import strings from "../../locals/string.json";
import { Rate } from "antd";
import './supplier.scss'
import { validateRating } from "../../constants/validation";
import { addReview } from "../../service/suppliers";
import { ToastUtil } from "../../constants/toastUtil";

const supplier = strings.supplier;

class RateModal extends React.Component {

    state = {
        description: '',
        rate: 0
    }

    onButtonClick = () => {
        if (validateRating(this.state)) {
            addReview({
                comment: this.state.description,
                rate: this.state.rate,
                sellerId: this.props.item.id,
            })
                .then(response => {
                    if (response.success) {
                        this.setState({
                            description: '',
                            rate: 0
                        })
                        this.props.close();
                        this.props.reload();
                        ToastUtil.showSuccessToast(response.msg)
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    onClose = () => {
        this.setState({ description: '', rate: 0 });
        this.props.close();
    }

    render() {
        return <Modal
            className="rateModal"
            show={this.props.open}
            onHide={this.onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{supplier.rateTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-3">
                <Rate value={this.state.rate} allowHalf onChange={value => this.setState({ rate: value })} />

                <div className="form-group rld-single-input mt-4">
                    <textarea rows={5} name="description" required="required"
                        onChange={e => this.setState({ description: e.target.value })}
                        value={this.state.description}
                        maxLength={700}
                        placeholder={supplier.rateDescription}
                    />
                    <span
                        className={`description-text-limit ${this.state.description.length >= 700 ? 'description-text-limit-error' : null}`}>{this.state.description.length}/700</span>
                    {/* <label>{supplier.rateDescription}</label> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClose}>{supplier.cancel}</button>
                <button className="btn btn-yellow" onClick={this.onButtonClick}>{supplier.submit}</button>
            </Modal.Footer>
        </Modal>
    }
}

export default RateModal;
