import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    startOfTomorrow,
    endOfTomorrow,
} from "date-fns";
import moment from "moment";

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfNextWeek: startOfWeek(addDays(new Date(), +7)),
    endOfNextWeek: endOfWeek(addDays(new Date(), +7)),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfTomorrow: startOfTomorrow(new Date()),
    endOfTomorrow: endOfTomorrow(new Date()),

};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    }
};

export function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
    {
        label: "Today",
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: null,
            main: "today"
        })
    },
    // {
    //     label: "Tomorrow",
    //     range: () => ({
    //         startDate: defineds.startOfTomorrow,
    //         endDate: null,
    //         main: "tomorrow"
    //     })
    // },
    {
        label: "Yesterday",
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: null,
            main: "today"
        })
    },

    // {
    //     label: "This Week",
    //     range: () => ({
    //         startDate: defineds.startOfWeek,
    //         endDate: defineds.endOfWeek,
    //         main: "week"
    //     })
    // },
    {
        label: "Last Week",
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek
        })
    },
    // {
    //     label: "Next Week",
    //     range: () => ({
    //         startDate: defineds.startOfNextWeek,
    //         endDate: defineds.endOfNextWeek
    //     })
    // },
    // {
    //     label: "This Month",
    //     range: () => ({
    //         startDate: defineds.startOfMonth,
    //         endDate: defineds.endOfMonth,
    //         main: "month"
    //     })
    // },
    {
        label: "Last Month",
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth
        })
    },

    // {
    //     label: "This Year",
    //     range: () => ({
    //         startDate: moment().startOf("year").toDate(),
    //         endDate: moment().endOf("day").toDate()
    //     })
    // },
    // {
    //     label: "Last Year",
    //     range: () => ({
    //         startDate: moment().subtract(1, "years").startOf("year").toDate(),
    //         endDate: moment().subtract(1, "years").endOf("year").toDate()
    //     })
    // }
]);


export const defaultStaticRangesClosing = createStaticRanges([
    {
        label: "Today",
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: null,
            main: "today"
        })
    },
    {
        label: "Tomorrow",
        range: () => ({
            startDate: defineds.startOfTomorrow,
            endDate: null,
            main: "tomorrow"
        })
    },
    // {
    //     label: "Yesterday",
    //     range: () => ({
    //         startDate: defineds.startOfYesterday,
    //         endDate: null,
    //         main: "today"
    //     })
    // },

    {
        label: "This Week",
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
            main: "week"
        })
    },
    // {
    //     label: "Last Week",
    //     range: () => ({
    //         startDate: defineds.startOfLastWeek,
    //         endDate: defineds.endOfLastWeek
    //     })
    // },
    {
        label: "Next Week",
        range: () => ({
            startDate: defineds.startOfNextWeek,
            endDate: defineds.endOfNextWeek
        })
    },
    {
        label: "This Month",
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
            main: "month"
        })
    },
    // {
    //     label: "Last Month",
    //     range: () => ({
    //         startDate: defineds.startOfLastMonth,
    //         endDate: defineds.endOfLastMonth
    //     })
    // },

    // {
    //     label: "This Year",
    //     range: () => ({
    //         startDate: moment().startOf("year").toDate(),
    //         endDate: moment().endOf("day").toDate()
    //     })
    // },
    // {
    //     label: "Last Year",
    //     range: () => ({
    //         startDate: moment().subtract(1, "years").startOf("year").toDate(),
    //         endDate: moment().subtract(1, "years").endOf("year").toDate()
    //     })
    // }
]);

export const defaultInputRanges = [
    {
        label: "days up to today",
        range(value) {
            return {
                startDate: addDays(
                    defineds.startOfToday,
                    (Math.max(Number(value), 1) - 1) * -1
                ),
                endDate: defineds.endOfToday
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
            if (!range.startDate) return "∞";
            return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
        }
    },
    {
        label: "days starting today",
        range(value) {
            const today = new Date();
            return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 1) - 1)
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
            if (!range.endDate) return "∞";
            return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
        }
    }
];
