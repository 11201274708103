import React, {Component} from 'react';
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Banner extends Component {

    render() {
        const inlineStyle = {
            backgroundImage: this.props.user?.coverImage !== null ?
                `url(${this.props.user?.coverImage})` : `url(${Assets.subBanner2})`
        }
        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h1 className="page-title ">
                                    {this.props.user?.companyName !== null ? this.props.user?.companyName : "Not Found"}
                                    {
                                        this.props.user?.verifiedSeller && <img className="verifiedSupplier verifiedWithViewSupplier ml-2" src={Assets.badge} alt='img'/>
                                    }
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(Banner));
