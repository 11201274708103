import React from "react";
import {Modal} from "react-bootstrap";
import '../globalComponents/globalComponents.scss'
import strings from "../../locals/string.json";
import Files from "react-files";
import * as commonFunc from "../../constants/commonFunc";
import {createUpdateUser, createUser} from "../../constants/commonFunc";
import {
    SEARCH_SPACE_REGEXP,
    SEARCH_VALUE_REGEXP,
    validateUpdateBuyerDetails,
    validateUpdateSellerDetails
} from "../../constants/validation";
import {Assets} from "../../assets";
import constants, {DISTRICTS} from "../../constants/constants";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import './myProfile.scss'
import apiConfig from "../../service/apiConfig";
import {Select} from "antd";
import {removeImage, updateUser} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";
import * as userActions from "../../store/domain/user/action";
import SecondaryEmail from "../oauth/secondaryEmail";
import getCroppedImg, {dataURLtoFile} from "../../constants/cropImage";
import Cropper from "react-easy-crop";
import PhoneInput from "react-phone-number-input";
import {isPossiblePhoneNumber} from 'react-phone-number-input';

const pr = strings.purchaseRequest;
const registration = strings.registration;
const profile = strings.profile
const {Option} = Select
const toast = strings.toast;
const Name_REGEX = /^[A-z ]+$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const WEB_SITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

class MyProfileUpdateModal extends React.Component {

    state = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        companyUrl: '',
        designation: '',
        website: '',
        landNumber: '',
        address: '',
        mobile: '',
        hotLine: '',
        location: null,
        imageType: undefined,
        imageFile: undefined,
        image: null,
        coverImageFile: undefined,
        coverImageType: undefined,
        coverImage: null,
        description: "",
        secondaryEmails: [],
        crop: {x: 100, y: 100},
        zoom: 1,
        open: false,
        isProfile: true,
        billingProofType: undefined,
        billingProofFile: undefined,
        billingProof: null,
    }

    onTextChange = async (event) => {
        if (event.target.name === 'companyName') {
            await this.setState({
                [event.target.name]: event.target.value,
                companyUrl: event.target.value !== '' ? apiConfig.frontEnd + event.target.value.toLowerCase().trim()
                    .replace(SEARCH_VALUE_REGEXP, '  ')
                    .replace(SEARCH_SPACE_REGEXP, '-').trim() : ''
            });
        } else if (event.target.name === 'companyUrl') {
            if (event.target.value.toLowerCase().trim().length > 15) {
                const val = event.target.value.split(apiConfig.frontEnd);
                await this.setState({
                    companyUrl: apiConfig.frontEnd + val[1].toLowerCase().trim()
                        .replace(SEARCH_VALUE_REGEXP, '  ')
                        .replace(SEARCH_SPACE_REGEXP, '-').trim()
                });
            }
        } else {
            await this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onBillingProofChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                billingProofType: file.type,
                billingProofFile: file,
                billingProof: imageDataUrl
            });
        }
    };

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                imageType: file.type,
                imageFile: file,
                image: imageDataUrl,
                open: true,
                isProfile: true
            });
        }
    };

    onFilesChange2 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                coverImageType: file.type,
                coverImageFile: file,
                coverImage: imageDataUrl,
                isProfile: false,
                open: true
            });
        }
    };

    setValue = async () => {
        const item = this.props.user;
        const secondaryEmails = [];
        item.secondaryEmails !== null && item.secondaryEmails.split(',').map((item, index) => {
            secondaryEmails.push(item)
        })
        await this.setState({
            id: item.id,
            companyName: item.companyName === null ? '' : item.companyName,
            image: item.profilePicture,
            coverImage: item.coverImage,
            secondaryEmails: secondaryEmails,
            companyUrl: item.companyUrl === null ? '' : apiConfig.frontEnd + item.companyUrl,

            firstName: item.firstName === null ? '' : item.firstName,
            lastName: item.lastName === null ? '' : item.lastName,
            email: item.email === null ? '' : item.email,
            designation: item.designation === null ? '' : item.designation,
            website: item.website === null ? '' : item.website,
            landNumber: item.landNumber === null ? '' : this.setMobile(item.landNumber),
            address: item.address === null ? '' : item.address,
            hotLine: item.hotLine === null ? '' : this.setMobile(item.hotLine),
            mobile: item.mobileNumber === null ? '' : this.setMobile(item.mobileNumber),
            location: item.location,
            billingProof: item.billingProof,
        })
    }

    setMobile = mobile => {
        if (!mobile.toString().startsWith('+')) {
            mobile = '+' + mobile
        }
        return mobile.toString();
    }

    onButtonClick = () => {
        if (this.state.open) {
            this.setState({
                open: false,
                [this.state.isProfile ? 'image' : 'coverImage']: this.state.imageCrop
            });
            return;
        }

        if (this.props.userType === constants.USER_BUYER) {
            if (validateUpdateBuyerDetails(this.state)) {
                this.updateUser()
            }
        } else {
            if (validateUpdateSellerDetails(this.state)) {
                this.updateUser()
            }
        }
    }

    updateUser = () => {
        updateUser(createUpdateUser(this.state))
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.body)));
                    this.props.setAuth(createUser(response.body));
                    this.props.close();
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    removeImage = type => {
        removeImage(type)
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.body)));
                    this.props.setAuth(createUser(response.body));
                    this.setValue();
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const result = await getCroppedImg(this.state.isProfile ? this.state.image : this.state.coverImage, croppedAreaPixels, 0);
        const file = dataURLtoFile(result, 'product-image.jpg')
        file.preview = {
            type: "image",
            url: result
        }
        file.extension = "jpg"
        file.id = "files-1"
        await this.setState({
            imageCrop: result,
            [this.state.isProfile ? 'imageFile' : 'coverImageFile']: file
        })
    }

    onClose = () => {
        if (this.state.open) {
            this.setState({
                [this.state.isProfile ? 'image' : 'coverImage']: null,
                open: false
            })
        } else {
            this.props.close()
        }
    }

    render() {
        const item = this.state;

        return <Modal
            className="productViewModal myProfile"
            show={this.props.open}
            onShow={this.setValue}
            onHide={this.onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{item.open ? 'Crop Image' : 'Edit Profile'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="myProduct">
                {
                    this.state.open ?
                        <Cropper
                            image={item.isProfile ? item.image : item.coverImage}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.isProfile ? 4 / 4 : 16 / 5}
                            onCropChange={value => this.setState({crop: value})}
                            onZoomChange={value => this.setState({zoom: value})}
                            onCropComplete={this.onCropComplete}
                        />
                        :
                        <form className="contact-form-bg mt-3 ">

                            <div className="row">
                                <div className="col-md-5 col-12">
                                    <h6 className="mt-3">{registration.companyImage} {registration.optional}</h6>
                                    <div className="display-flex flex-row align-items-center">
                                        <img className="companyImage "
                                             src={item.image !== null ? item.image : Assets.companyLogo}
                                             alt='image'/>
                                        <Files
                                            className='files-dropzone-file'
                                            onChange={this.onFilesChange}
                                            accepts={["image/png", "image/jpg", "image/jpeg"]}
                                            multiple={false}
                                            maxFileSize={10485760}
                                            minFileSize={0}
                                            onError={error => console.log(error)}
                                            clickable
                                        >
                                            <p className="choose m-0">{pr.chooseCI}</p>
                                        </Files>
                                        {
                                            this.props.user.profilePicture !== null &&
                                            <p className="remove m-0"
                                               onClick={() => this.removeImage('PROFILE')}>Remove</p>
                                        }
                                    </div>
                                </div>

                                {
                                    this.props.userType === constants.USER_SELLER &&
                                    <div className="col-md-7 col-12">
                                        <h6 className="mt-3">{registration.coverImage} {registration.optional}</h6>
                                        <div className="display-flex flex-row align-items-center">
                                            <img className="companyCoverImage "
                                                 src={item.coverImage !== null ? item.coverImage : Assets.companyLogo}
                                                 alt='image'/>
                                            <Files
                                                className='files-dropzone-file'
                                                onChange={this.onFilesChange2}
                                                accepts={["image/png", "image/jpg", "image/jpeg"]}
                                                multiple={false}
                                                maxFileSize={10485760}
                                                minFileSize={0}
                                                onError={error => console.log(error)}
                                                clickable
                                            >
                                                <p className="choose m-0">{pr.chooseCI}</p>
                                            </Files>
                                            {
                                                this.props.user.coverImage !== null &&
                                                <p className="remove m-0"
                                                   onClick={() => this.removeImage('COVER')}>Remove</p>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="separator mt-3 mb-3"/>

                            <h6>{registration.personalInformation}</h6>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 ">
                                    <p className="inputLabel mb-1 mt-0">{registration.firstName}<span>*</span></p>
                                    <div className=" rld-single-input">
                                        <input type="text" name="firstName" required="required"
                                               onChange={this.onTextChange}
                                               value={this.state.firstName}
                                               placeholder={registration.firstName}
                                               className={this.state.firstName.trim() === '' || !Name_REGEX.test(this.state.firstName) ? 'input-error' : ''}
                                        />
                                        {
                                            this.state.firstName.trim() === '' || !Name_REGEX.test(this.state.firstName) ?
                                                <span
                                                    className={'fields-error-text'}>{toast.invalidName}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <p className="inputLabel mb-1 mt-0">{registration.lastName}<span>*</span></p>
                                    <div className=" rld-single-input">
                                        <input type="text" name="lastName" required="required"
                                               onChange={this.onTextChange}
                                               value={this.state.lastName}
                                               placeholder={registration.lastName}
                                               className={this.state.lastName.trim() === '' || !Name_REGEX.test(this.state.lastName) ? 'input-error' : ''}
                                        />
                                        {
                                            this.state.lastName.trim() === '' || !Name_REGEX.test(this.state.lastName) ?
                                                <span
                                                    className={'fields-error-text'}>{toast.invalidLName}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*<div className="form-group rld-single-input">*/}
                            {/*    <input type="text" name="email" required="required" onChange={this.onTextChange}*/}
                            {/*           value={this.state.email}/>*/}
                            {/*    <label>{registration.email}</label>*/}
                            {/*</div>*/}

                            <div className="row">
                                <div className="col-md-6 col-sm-12 mb-sm-3 mb-md-3">
                                    <p className="inputLabel mb-1 mt-0">{registration.mobile}<span>*</span></p>
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="LK"
                                        placeholder={registration.mobile}
                                        value={this.state.mobile}
                                        onChange={value => this.onTextChange({
                                            target: {
                                                name: 'mobile',
                                                value: value ?? ''
                                            }
                                        })}
                                        className={this.state.mobile.trim() === '' || !isPossiblePhoneNumber(this.state.mobile) ? 'input-error' : ''}
                                    />
                                    {
                                        this.state.mobile.trim() === '' || !isPossiblePhoneNumber(this.state.mobile) ?
                                            <span
                                                className={'fields-error-text'}>{toast.invalidMobile}</span>
                                            : null
                                    }
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <p className="inputLabel mb-1 mt-0">{registration.designation} {registration.optional}</p>
                                    <div className="rld-single-input">
                                        <input type="text" name="designation" placeholder={registration.designation}
                                               onChange={this.onTextChange}
                                               value={this.state.designation}
                                               className={this.state.designation.trim() !== '' && !Name_REGEX.test(this.state.designation) ? 'input-error' : ''}
                                        />
                                        {
                                            this.state.designation.trim() !== '' && !Name_REGEX.test(this.state.designation) ?
                                                <span
                                                    className={'fields-error-text'}>{toast.invalidDName}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>


                            {
                                this.props.userType === constants.USER_SELLER && item.secondaryEmails.length > 0 &&
                                <>
                                    <p className="inputLabel mb-1 mt-0">{registration.secondaryEmail}</p>
                                    <SecondaryEmail
                                        user={this.props.user}
                                        items={this.state.secondaryEmails}
                                        onChangeSecondaryEmail={value => this.setState({secondaryEmails: value})}
                                        emailCount={this.props.user.emailCount ?? 2}/>
                                </>
                            }

                            <h6 className="mt-4">{registration.companyInformation}</h6>


                            <p className="inputLabel mb-1 mt-0">{registration.companyName}<span>*</span></p>
                            <div className="rld-single-input">
                                <input type="text" name="companyName" placeholder={registration.companyName}
                                       onChange={this.onTextChange}
                                       value={this.state.companyName}
                                       onBlur={() => this.props.checkCompanyUrlAvailability(this.state)}
                                       className={this.state.companyName.trim() === '' ? 'input-error' : ''}
                                />
                                {
                                    this.state.companyName.trim() === '' ?
                                        <span
                                            className={'fields-error-text'}>{toast.invalidCName}</span>
                                        : null
                                }
                            </div>

                            {
                                this.props.userType === constants.USER_SELLER &&
                                <>
                                    <p className="inputLabel mb-1 mt-0">{registration.companyUrl}<span>*</span></p>
                                    <div className="rld-single-input">
                                        <input type="text" name="companyUrl" placeholder={registration.companyUrl}
                                               onChange={this.onTextChange}
                                               value={this.state.companyUrl}
                                               onBlur={() => this.props.checkCompanyUrlAvailability(this.state)}
                                               className={this.state.companyUrl.trim() === apiConfig.frontEnd && this.props.state.isValidation ? 'input-error' : ''}
                                        />
                                        {
                                            this.state.companyUrl.trim() === apiConfig.frontEnd && this.props.state.isValidation ?
                                                <span
                                                    className={'fields-error-text'}>{toast.invalidCUrl}</span>
                                                : null
                                        }
                                    </div>
                                </>
                            }

                            <div className="row">
                                <div className="col-md-6 col-sm-12 ">
                                    <p className="inputLabel mb-1 mt-0">{registration.address} {registration.optional}</p>
                                    <div className="rld-single-input">
                                        <input type="text" name="address"
                                               placeholder={registration.address}
                                               required="required" onChange={this.onTextChange}
                                               value={this.state.address}
                                               className={this.props.userType === constants.USER_SELLER && this.state.address.trim() === '' ? 'input-error' : ''}
                                        />
                                        {
                                            this.props.userType === constants.USER_SELLER && this.state.address.trim() === '' ?
                                                <span
                                                    className={'fields-error-text'}>{toast.address}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 mb-3">
                                    <p className="inputLabel mb-1 mt-0">{registration.district}<span>*</span></p>
                                    <Select
                                        showSearch
                                        value={this.state.location}
                                        className={this.state.location === null ? 'input-error' : ''}
                                        placeholder="District"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={value => this.onTextChange({
                                            target: {
                                                name: 'location',
                                                value: value
                                            }
                                        })}
                                    >
                                        {
                                            DISTRICTS.map(item => <Option value={item}>{item}</Option>)
                                        }
                                    </Select>
                                    {
                                        this.state.location === null ?
                                            <span
                                                className={'fields-error-text'}>{toast.district}</span>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className="rld-single-input">
                                <p className="inputLabel mb-1 mt-0">{registration.website} {registration.optional}</p>
                                <input type="text" name="website" placeholder={registration.website}
                                       onChange={this.onTextChange}
                                       value={this.state.website}
                                       className={this.state.website.trim() !== '' && !WEB_SITE_REGEX.test(this.state.website) ? 'input-error' : ''}
                                />
                                {
                                    this.state.website.trim() !== '' && !WEB_SITE_REGEX.test(this.state.website) ?
                                        <span
                                            className={'fields-error-text'}>{toast.invalidWeb}</span>
                                        : null
                                }
                            </div>


                            <div className="row">
                                <div className="col-md-6 col-sm-12 mb-sm-3 mb-md-3">
                                    <p className="inputLabel mb-1 mt-0">{profile.hotline} {registration.optional}</p>
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="LK"
                                        placeholder={registration.hotline}
                                        value={this.state.hotLine}
                                        onChange={value => this.onTextChange({
                                            target: {
                                                name: 'hotLine',
                                                value: value ?? ''
                                            }
                                        })}
                                        className={this.state.hotLine.trim() !== '' && !isPossiblePhoneNumber(this.state.hotLine) ? 'input-error' : ''}
                                    />
                                    {
                                        this.state.hotLine.trim() !== '' && !isPossiblePhoneNumber(this.state.hotLine) ?
                                            <span
                                                className={'fields-error-text'}>{toast.invalidMobile}</span>
                                            : null
                                    }
                                </div>
                                <div className="col-md-6 col-sm-12 mb-sm-3 mb-md-3">
                                    <p className="inputLabel mb-1 mt-0">{registration.land} {registration.optional}</p>
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="LK"
                                        placeholder={registration.land}
                                        value={this.state.landNumber}
                                        className={this.state.landNumber.trim() !== '' && !isPossiblePhoneNumber(this.state.landNumber) ? 'input-error' : ''}
                                        onChange={value => this.onTextChange({
                                            target: {
                                                name: 'landNumber',
                                                value: value ?? ''
                                            }
                                        })}
                                    />
                                    {
                                        this.state.landNumber.trim() !== '' && !isPossiblePhoneNumber(this.state.landNumber) ?
                                            <span
                                                className={'fields-error-text'}>{toast.invalidMobile}</span>
                                            : null
                                    }
                                </div>
                            </div>

                            {
                                this.props.userType === constants.USER_SELLER &&
                                <>
                                    <p className="inputLabel mb-1 mt-0">{registration.billingProf} {registration.optional}</p>

                                    <Files
                                        className='files-dropzone-file'
                                        onChange={this.onBillingProofChange}
                                        accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf"]}
                                        multiple={false}
                                        maxFileSize={10485760}
                                        minFileSize={0}
                                        onError={error => console.log(error)}
                                        clickable
                                    >
                                        <div className="file-wrapper">
                                            <p className="choose2">{registration.chooseFile}</p>
                                            {
                                                this.state.billingProof &&
                                                <p className="view-file"
                                                   onClick={() => {
                                                       !this.state.billingProofType ?
                                                           window.open(this.state.billingProof, '_blank') :
                                                           commonFunc.getB64ToBlob(this.state.billingProof,
                                                               this.state.billingProofType)
                                                   }}>{registration.viewFile}</p>
                                            }
                                        </div>
                                    </Files>

                                </>
                            }

                        </form>
                }

            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClose}>{pr.cancel}</button>
                <button className="btn btn-yellow"
                        onClick={this.onButtonClick}>{item.open ? pr.crop : pr.update2}</button>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyProfileUpdateModal));
