import React, { Component } from 'react';
import '../globalComponents/globalComponents.scss'
import { Link } from 'react-router-dom';
import strings from "../../locals/string.json";
import constants, { breakPoint2 } from "../../constants/constants";
import routes from "../../constants/routes";
import { Assets } from "../../assets";
import { checkAvailability, dateDiff, numberWithCommas } from "../../constants/commonFunc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Slider from "react-slick";
import moment from "moment";
import { ToastUtil } from "../../constants/toastUtil";
import { getRequest } from "../../service/request";

const landing = strings.landing;

class LatestRequests extends Component {

    state = {
        requests: [],
    }

    componentWillMount() {
        getRequest(0, 10, {
            action: "LATEST",
            location: null,
            name: null,
            category: null,
            subCategory: null,
            publishedDate: null,
            closingDate: null
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        requests: response.body.content,
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onView = (item) => {
        if (this.props.loggedInStatus && this.props.userType === constants.USER_SELLER) {
            if (item.owner || item.availability) {
                this.props.history.push(routes.viewRequest + `/${item.userRequestUrl}`, item)
            } else {
                checkAvailability(true)
            }
        } else {
            checkAvailability()
        }
    }

    render() {
        const maxTimeLimit = 150;
        let downTime = 0;
        const inlineStyle = {
            // backgroundImage: `url(${Assets.cardBg3})`
        }
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint2
        };

        return this.state.requests.length >= 4 &&
            <div className="properties-area pd-top-60 pd-bottom-60 popularSupplier">
                <div className="container">
                    <div className="section-title">
                        <span className="txt-sub-title">LATEST</span>
                        <h2 className="title">{landing.latestRequests}</h2>
                        <Link className="btn-view-all" to={routes.requests}>VIEW ALL</Link>
                    </div>
                    <Slider {...settings}>
                        {this.state.requests.map((item, i) =>
                            <div className="p-2" key={i}
                                onMouseDown={() => downTime = new Date().getTime()}
                                onTouchStart={() => downTime = new Date().getTime()}
                                onClick={() => {
                                    // const time = new Date().getTime() - downTime;
                                    // if (time < maxTimeLimit)
                                        this.onView(item)
                                }}>
                                <div className="single-feature requestHolder">
                                    <div className="details p-4" style={inlineStyle}>
                                        <h7 className="title readeal-top"><Link>
                                            <label className='latest-tender-title'>
                                                {item.topic}
                                            </label>
                                        </Link></h7>
                                        <h6 className="category readeal-top"><Link>{
                                            item.category.map((item, index) => index === 0 ? item.name : ", " + item.name)
                                        }</Link></h6>

                                        <span className={'landing-latest-request-data-header'}>Quantity </span>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            className={'landing-latest-request-data-header'}>:</span> {item.qty}</span>
                                        <br />
                                        {
                                            item.estimatedValue !== null
                                                ? <><span className={'landing-latest-request-data-header'}>Estimated Value </span>
                                                    <span>&nbsp;<span
                                                        className={'landing-latest-request-data-header'}>:</span> {item.estimatedValue === 0 ? 'N/A' :
                                                            item.currency + " " + numberWithCommas(item.estimatedValue.toFixed(2))}</span></> : null
                                        }

                                        <li className="date mt-3">
                                            <b>Published
                                                On</b> : {moment.utc(item.publishedDate).local().format(constants.DATE_FORMAT_EXTEND)}
                                        </li>
                                        <li className="date mt-1">
                                            <b>Closing
                                                On</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {moment.utc(item.closingDate).local().format(constants.DATE_FORMAT_EXTEND)}
                                        </li>
                                        {/*<li className="remaining mt-2" style={{*/}
                                        {/*    background: dateDiff(item) <= 0 ? "var(--main-color-two)" :*/}
                                        {/*        dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)"*/}
                                        {/*}}>{*/}
                                        {/*    dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 1 ? '1 Day Remaining' : dateDiff(item) + " Days Remaining"*/}
                                        {/*}*/}
                                        {/*</li>*/}

                                    </div>
                                    <div className={'landing-latest-request-footer'} style={{
                                        background: dateDiff(item) <= 0 ? "var(--main-color-two)" :
                                            dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)"
                                    }}>
                                        {
                                            dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 1 ? 'LESS THAN A DAY' : dateDiff(item) + " DAYS MORE"
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(LatestRequests));
