import React from "react";
import './statistics.scss';
import strings from "../../locals/string.json";
import {getStatistics} from "../../service/auth";
import {ToastUtil} from "../../constants/toastUtil";
import {isBrowser} from "react-device-detect";

const landing = strings.landing;

class Statistics extends React.Component {

    state = {
        requests: 0,
        products: 0,
        suppliers: 0,
        buyers: 0
    }

    componentWillMount() {
        getStatistics()
            .then(response => {
                if (response.success){
                    const obj = document.getElementById("requestCount");
                    this.animateValue(obj, 0, response.body.requests ?? 0, 2500, 'requests');
                    this.animateValue(obj, 0, response.body.products ?? 0, 2500, 'products');
                    this.animateValue(obj, 0, response.body.suppliers ?? 0, 2500, 'suppliers');
                    this.animateValue(obj, 0, response.body.buyers ?? 0, 2500, 'buyers');
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    animateValue = (obj, start, end, duration, name) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            this.setState({[name]: Math.floor(progress * (end - start) + start)})
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    render() {
        return <div className="statistics">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="staticTile">
                            <p className="count" id="requestCount">{this.state.requests}</p>
                            <p className="title">Processed {!isBrowser && <br/>}Requests</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="staticTile">
                            <p className="count">{this.state.products}</p>
                            <p className="title">Products {!isBrowser && <br/>}Listed</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="staticTile">
                            <p className="count">{this.state.suppliers}</p>
                            <p className="title">Active {!isBrowser && <br/>}Sellers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="staticTile">
                            <p className="count">{this.state.buyers}</p>
                            <p className="title">Registered {!isBrowser && <br/>}Buyers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Statistics;
