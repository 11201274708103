import React from "react";
import strings from "../../locals/string.json";
import constants from "../../constants/constants";
import {Assets} from "../../assets";
import 'antd/dist/antd.css';
import ReactPaginate from 'react-paginate';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PurchaseRequestModal from "../purchaseRequest/purchaseRequestModal";
import Products from "./products";
import NewProductModal from "./newProductModal";
import './products.scss'
import {isBrowser} from "react-device-detect";
import Filter from "../filter/filter";
import {deleteProduct, getAllProducts} from "../../service/product";
import {ToastUtil} from "../../constants/toastUtil";
import swal from "sweetalert";

const pr = strings.purchaseRequest;
const modals = strings.modals;
const toast = strings.toast

class AllProducts extends React.Component {

    state = {
        advance: false,
        products: [],
        action: "All",
        purchaseRequestModal: false,
        addProductModal: false,
        index: 0,
        size: 12,
        totalPages: 0,
        totalElements: 0,
        search: this.props.state === undefined ? '' : this.props.state.data,
        selectedSubCategory: null,
        location: null,
        isNoData: false,
        preLoaderValue: false
    }

    componentDidMount  () {
        this.checkDataHandler()
    }

    checkDataHandler = async () => {
        const stateData = this.props?.location?.state
        if (stateData?.currentStatus) {
            await this.setState({...stateData.currentStatus})
        }
        this.getAllProducts()
    }

    getAllProducts = async (isSearch) => {
        if (isSearch) await this.setState({index:0})
        if (this.props.state !== undefined) {
            this.setState({preLoaderValue: true});
            getAllProducts(isSearch ? 0 : this.state.index, this.state.size, {
                action: this.props.own ? "MY" : "ALL",
                location: this.state.location,
                name: this.state.search === "" ? null : this.state.search,
                category: null,
                subCategory: this.state.selectedSubCategory,
            }).then(response => {
                this.setState({preLoaderValue: false});
                if (response.success) {
                    this.setState({
                        products: response.body.content,
                        totalPages: response.body.totalPages,
                        totalElements: response.body.totalElements,
                        isNoData: response.body.content.length <= 0
                    })

                    document.querySelector('body').scrollTo(0,0)
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
        } else {
            this.setState({preLoaderValue: true});
            getAllProducts(this.state.index, this.state.size, {
                action: this.props.own ? "MY" : "ALL",
                location: this.state.location,
                name: this.state.search === "" ? null : this.state.search,
                category: null,
                subCategory: this.state.selectedSubCategory,
            }).then(response => {
                this.setState({preLoaderValue: false});
                if (response.success) {
                    this.setState({
                        products: response.body.content,
                        totalPages: response.body.totalPages,
                        totalElements: response.body.totalElements,
                        isNoData: response.body.content.length <= 0
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
        }
    }

    onFilterItemChange = async (name, value) => {
        await this.setState({[name]: value});
        this.getAllProducts();
    }

    onPageSelect = async data => {
        await this.setState({index: data.selected});
        this.getAllProducts();
    }

    onClear = async () => {
        await this.setState({
            selectedSubCategory: null,
            location: null,
            search: ''
        });
        this.getAllProducts();
    }

    onDelete = (id) => {
        swal({
            title: modals.areUSure,
            text: modals.delete,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Delete", value: "action", className: "swalSuccess"},
                dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        deleteProduct(id)
                            .then(response => {
                                if (response.success) {
                                    this.getAllProducts();
                                } else {
                                    ToastUtil.showErrorToast(response.msg)
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    render() {

        return <div>
            <div className="main-search-area requests">
                <ul className="banner-button nav nav-tabs rld-banner-tab">
                    <li className="nav-item">
                        <a
                            onClick={() => this.setState({purchaseRequestModal: !this.state.purchaseRequestModal})}
                            className="free-quotes-btn nav-link active" data-toggle="tab">{pr.getFreeQuotes}</a>
                    </li>
                </ul>
                <div className="container">
                    <div className="banner-search-wrap">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tabs_1">
                                <div className="product-search-container">
                                    <div className={'row'}>
                                        <div className="titleHolder col-md-3 mb-2">
                                            <h8 className="searchTitle-new">{pr.findAnything}</h8>
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6">
                                            <div className="rld-single-input left-icon">
                                                <input
                                                    onKeyDown={e => e.key === 'Enter' && this.getAllProducts(true)}
                                                    onChange={event => this.setState({search: event.target.value})}
                                                    type="text" placeholder={pr.searchPH3} value={this.state.search}/>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12" align={'center'}>
                                            <button type={"button"}
                                                    onClick={() => this.getAllProducts(true)}
                                                    className="btn searchBtn">{pr.findNow}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center mg-top-40 ">
                        <div className="display-flex justify-content-end" style={{width: '95%'}}>
                            {
                                !isBrowser ? this.state.advance ?
                                    <h6 className="advanceFilter"
                                        onClick={() => this.setState({advance: !this.state.advance})}>{pr.hide}</h6> :
                                    <img className="advanceFilter" src={Assets.advFilter}
                                         alt="advance filter"
                                         onClick={() => this.setState({advance: !this.state.advance})}/> : null
                            }
                        </div>

                        <Filter
                            category={true}
                            isLocation={!this.props.own}
                            quoteStatus={false}
                            publishedDate={false}
                            closingDate={false}
                            advance={this.state.advance}
                            onClear={this.onClear}
                            onFilterItemChange={this.onFilterItemChange}
                        />

                        <div className={"col-xl-8 col-lg-8"}>
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <h6 className="filter-title mb-lg-0">{this.state.totalElements !== 0 && this.state.totalElements + " Products"}&nbsp;</h6>
                                    {
                                        this.props.loggedInStatus && this.props.userType === constants.USER_SELLER &&
                                        <button className="btn btn-yellow mt-3 addNewProduct"
                                                onClick={() => this.setState({addProductModal: !this.state.addProductModal})}>+
                                            Add Product</button>
                                    }
                                </div>
                                {
                                    !this.state.preLoaderValue?
                                    this.state.products.length === 0 && this.state.isNoData &&
                                    <div className="display-flex flex-column align-items-center"
                                         style={{width: '100%'}}>
                                        <img src={Assets.productEmpty} alt="empty"/>
                                    </div>
                                        :
                                        null
                                }
                            </div>
                            <div className="row popularSupplier mt-2">
                                {
                                    !this.state.preLoaderValue?
                                        this.state.products.map((item, i) =>
                                                <Products
                                                    key={i}
                                                    filter={true}
                                                    reload={this.getAllProducts}
                                                    currentStatus={this.state}
                                                    history={this.props.history}
                                                    onDelete={() => this.onDelete(item.id)}
                                                    item={{...item, own: this.props.own}}
                                                />
                                            )
                                        :
                                        <div className="spinner">
                                            <div className="dot1"></div>
                                            <div className="dot2"></div>
                                        </div>
                                }
                            </div>
                        </div>

                    </div>

                    <div className="display-flex justify-content-center" style={{width: '100%'}}>
                        {
                            this.state.products.length !== 0 &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                forcePage={this.state.index}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.onPageSelect}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        }

                    </div>
                </div>
            </div>

            <PurchaseRequestModal
                title={''}
                open={this.state.purchaseRequestModal}
                close={() => this.setState({purchaseRequestModal: !this.state.purchaseRequestModal})}
            />

            <NewProductModal
                open={this.state.addProductModal}
                reload={this.getAllProducts}
                close={() => this.setState({addProductModal: !this.state.addProductModal})}/>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(AllProducts));
