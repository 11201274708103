export const Assets = {
    logo: require('./img/logo.png'),
    logoWhite: require('./img/b2b_logo_white.svg'),
    statisticsBg: require('./img/statisticsBg.png'),
    landing: require('./img/main-banner.png'),
    subBanner: require('./img/subBanner.png'),
    purchaseBanner: require('./img/purchase_main_banner.jpg'),
    supplierBanner: require('./img/supplier_main_banner.jpg'),
    subBanner2: require('./img/subBanner2.png'),
    regBuyer: require('./img/regBuyer.png'),
    regSeller: require('./img/regSeller.png'),
    back: require('./img/back.png'),
    biCheck: require('./img/biCkeck.png'),
    cardBg1: require('./img/cardBg1.png'),
    cardBg2: require('./img/cardBg3.png'),
    cardBg3: require('./img/cardBg4.png'),
    cat: require('./img/cat.png'),
    clientTest: require('./img/clientTest.png'),
    advFilter: require('./img/advFilter.png'),
    companyLogo: require('./img/companyLogo.png'),
    about1: require('./img/about1.png'),
    about2: require('./img/about2.png'),
    about3: require('./img/6.png'),
    vision: require('./img/vision.png'),
    target: require('./img/target.png'),
    empty: require('./img/empty.png'),
    productEmpty: require('./img/empty.png'),
    supplierEmpty: require('./img/empty.png'),
    requestEmpty: require('./img/empty.png'),
    quotationEmpty: require('./img/empty.png'),
    reviewEmpty: require('./img/empty.png'),
    paymentEmpty: require('./img/empty.png'),
    descEmpty: require('./img/desEmpty.png'),
    categoryPlaceholder: require('./img/categoryPlaceholder.png'),
    placeholderCompany: require('./img/placeholderCompany.png'),
    avatarPlaceholder: require('./img/avatarPlaceholder.png'),
    productBack: require('./img/productBack.jpg'),
    comma: require('./img/comma.png'),
    comma2: require('./img/comma2.png'),
    test: require('./img/test.png'),
    badge: require('./img/badge.png'),
    sellerAbout: require('./img/sellerAbout.png'),
    flow: require('./img/flow.png'),
    aboutUsVideo: require('./img/aboutUsVideo.png'),
    aboutUsVideo2: require('./img/aboutUsVideo2.jpg'),
    ad: require('./img/ad.png'),
    newAdBanner: require('./img/mid_banner.jpg'),
    sell: require('./img/9.png'),
    rent: require('./img/10.png'),
    logoS: require('./img/logoS.png'),
}
