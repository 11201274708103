import React from "react";
import '../purchaseRequest/requests.scss'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import constants from "../../constants/constants";
import UpdateQuoteModal from "./updateQuoteModal";
import moment from "moment";
import swal from "sweetalert";
import {ToastUtil} from "../../constants/toastUtil";
import {deleteQuotation} from "../../service/quotation";
import strings from "../../locals/string.json";
import {numberWithCommas} from "../../constants/commonFunc";
import routes from "../../constants/routes";


const modals = strings.modals;

class RequestItem extends React.Component {
    state = {
        updateQuoteModal: false,
        quotation: undefined,
    }

    onDelete = (id) => {
        swal({
            title: modals.areUSure,
            text: modals.delete,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Delete", value: "action", className: "swalSuccess"},
                dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        deleteQuotation(id)
                            .then(response => {
                                if (response.success) {
                                    this.props.reload();
                                } else {
                                    ToastUtil.showErrorToast(response.msg)
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    render() {
        const item = this.props.item;
        let status = item.buyerFollowupStatus, bg, font;

        if (status === constants.AWARDED) {
            bg = "rgba(33, 170, 104, 0.15)";
            font = '#21AA68';
            status = 'Won'
        } else if (status === constants.INTERESTED) {
            bg = "rgba(255, 172, 51, 0.15)";
            font = '#FFAC33';
            status = 'Interested'
        } else {
            bg = "rgba(255,110,99, 0.15)";
            font = "var(--main-color-two)";
            status = status === constants.PENDING ? 'Pending' : 'Rejected'
        }

        return <div className="single-feature style-two">
            <div className="details pointer">
                <div className="details-wrap">

                    <div className="actionHolder2">
                        <p className="actionBtn2 deleteLabel" onClick={() => this.onDelete(item.id)}><i
                            className="fa fa-trash"/> Delete</p>
                        <p className="actionBtn2 btn-white customPadding"
                           onClick={() => this.setState({updateQuoteModal: !this.state.updateQuoteModal})}><i
                            className="fa fa-eye"/> View
                        </p>
                    </div>
                    <div>
                        <h6 className="request-title-new tender-title-quotation mb-2 pr-4">{item.requestDetails.topic}</h6>
                        <span className="request-category">
                            <span>{item.requestDetails.category.map((item, index) =>
                                index === 0 ? item.name : ", " + item.name)}</span>
                        </span>
                        <br/>
                        <span className={'request-ref-no'}>Ref. No: {item.requestDetails.refNo}</span>

                        <div className={'row mt-1 mb-2 mr-2'}>
                            <div className={'col-md-2'}>
                                <span><b>Quantity:</b></span>
                                <span className={'txt-card-data'}>{item.requestDetails.qty}</span>
                            </div>

                            <div className={'col-md-4'}>
                                <span><b>Published On: </b></span>
                                <span
                                    className={'txt-card-data'}>{moment(item.requestDetails.publishedDate).format(constants.DATE_FORMAT)}</span>
                            </div>

                            <div className={'col-md-3'}>
                                <span><b>Closing On: </b></span>
                                <span
                                    className={'txt-card-data'}>{moment(item.requestDetails.closingDate).format(constants.DATE_FORMAT)}</span>
                            </div>

                            <div className={'col-md-3'} align={'right'}>
                                {
                                    item.requestDetails.status !== 'DELETED' &&
                                    <div className="viewPR" onClick={() => {
                                        const data = {
                                            ...item,
                                            ...item.requestDetails,
                                            buyer: {
                                                ...item.requestDetails.buyer,
                                                ...item.requestDetails.buyer.account,
                                            },
                                            quotationsState : this.props.quotationsState,
                                            qType:this.props.qtype
                                        }
                                        this.props.history.push(routes.viewRequest + `/${data.userRequestUrl}`, data)
                                    }}>
                                        <i className="fa fa-eye"/> View Purchase Request</div>
                                }
                            </div>
                        </div>

                        {/*<ul className="info-list mb-2">*/}
                        {/*    <li>*/}
                        {/*        <b>Published On</b>: {moment(item.requestDetails.publishedDate).format(constants.DATE_FORMAT)}*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        <b>Closing On</b>: {moment(item.closingDate).format(constants.DATE_FORMAT)}*/}
                        {/*    </li>*/}
                        {/*</ul>*/}


                        <div className="separator mt-3"/>

                        <div className={'row'}>
                            <div className={'col-md-6 mt-3'}>
                                <span><b>Status</b>:&nbsp;&nbsp;
                                    <span className="quoteStatus" style={{
                                        background: bg,
                                        color: font,
                                        borderColor: font,
                                    }}>
                                    {status}
                                </span>
                                </span>
                            </div>

                            <div className={'col-md-6'} align={'right'}>
                                <div className={'quotation-price-card'}>
                                    <span className={'quotation-price-title'}>Quotation: </span>
                                    <span
                                        className={'quotation-price-data'}>{item.currency + " " + numberWithCommas(item.quotePrice.toFixed(2))}</span>
                                </div>
                            </div>
                        </div>
                        {/*<ul className="info-list mb-2"*/}
                        {/*    onClick={() => this.setState({updateQuoteModal: !this.state.updateQuoteModal})}>*/}
                        {/*    <h6 className="title display-flex justify-content-between readeal-top mb-4">Quotation*/}
                        {/*        {*/}
                        {/*            <li className="colorGreen">{item.currency + " " + numberWithCommas(item.quotePrice.toFixed(2))}</li>*/}
                        {/*        }*/}
                        {/*    </h6>*/}
                        {/*    /!*<li><b>Status</b>:&nbsp;&nbsp;*!/*/}
                        {/*    /!*    <span className="quoteStatus" style={{*!/*/}
                        {/*    /!*        background: bg,*!/*/}
                        {/*    /!*        color: font,*!/*/}
                        {/*    /!*        borderColor: font,*!/*/}
                        {/*    /!*    }}>*!/*/}
                        {/*    /!*        {status}*!/*/}
                        {/*    /!*    </span>*!/*/}
                        {/*    /!*</li>*!/*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>
            <UpdateQuoteModal
                item={item}
                open={this.state.updateQuoteModal}
                reload={this.props.reload}
                qtype={this.props.qtype}
                history={this.props.history}
                close={() => this.setState({updateQuoteModal: !this.state.updateQuoteModal})}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(RequestItem));
