import React from "react";
import strings from "../../locals/string.json";
import {Select} from "antd";
import PhoneInput from "react-phone-number-input";
import {isPossiblePhoneNumber} from 'react-phone-number-input';

const {Option} = Select;

const registration = strings.registration;
const toast = strings.toast;
const Name_REGEX = /^[A-z ]+$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const WEB_SITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export default (props) => {
    return <form className="contact-form-wrap contact-form-bg">
        <h6>{registration.personalInformation}</h6>
        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.firstName}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="firstName" required="required" onChange={props.onTextChange}
                        value={props.state.firstName} placeholder={registration.firstName}/>
                    {
                        (props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.lastName}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="lastName" required="required" onChange={props.onTextChange}
                        value={props.state.lastName} placeholder={registration.lastName}/>
                    {
                        (props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidLName}</span>
                            : null
                    }
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.email}<span>*</span></p>
                <div className="rld-single-input">
                    <input
                        className={(props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ? 'input-error' : ''}
                        type="text" name="email" required="required" onChange={props.onTextChange}
                        value={props.state.email} placeholder={registration.email}/>
                    {
                        (props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidEmail}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <p className="inputLabel mb-2">{registration.mobile}<span>*</span></p>
                {/*<div className="rld-single-input">*/}
                {/*    <input type="number" name="mobile" required="required" onChange={props.onTextChange}*/}
                {/*           value={props.state.mobile} placeholder={registration.mobile}/>*/}
                {/*</div>*/}
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Mobile number"
                    value={props.state.mobile}
                    className={(props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ? 'input-error' : ''}
                    onChange={value => props.onTextChange({target: {name: 'mobile', value: value ?? ''}})}
                />
                {
                    (props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
        </div>

        <h6 className="mt-5 ">{registration.companyInformation}</h6>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.companyName}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="companyName" placeholder={registration.companyName}
                           onChange={props.onTextChange}
                           value={props.state.companyName}
                           className={props.state.companyName.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        props.state.companyName.trim() === '' && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidCName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.designation}</p>
                <div className="rld-single-input">
                    <input type="text" name="designation" placeholder={registration.designation}
                           onChange={props.onTextChange}
                           value={props.state.designation}
                           className={props.state.designation.trim() !== '' && !Name_REGEX.test(props.state.designation) ? 'input-error' : ''}
                    />
                    {
                        props.state.designation.trim() !== '' && !Name_REGEX.test(props.state.designation) ?
                            <span
                                className={'fields-error-text'}>{toast.invalidDName}</span>
                            : null
                    }
                </div>
            </div>
            {/* <div className="col-md-6 col-sm-12">
                <p className="inputLabel mb-2">{registration.hotline}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Hot line"
                    value={props.state.hotline}
                    className={props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline) ? 'input-error' : ''}
                    onChange={value => props.onTextChange({ target: { name: 'hotline', value: value ?? '' } })}
                />
                {
                    props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline) ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel mb-2">{registration.land}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Land line"
                    value={props.state.land}
                    className={props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land) ? 'input-error' : ''}
                    onChange={value => props.onTextChange({ target: { name: 'land', value: value ?? '' } })}
                />
                {
                    props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land) ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>

            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.website}</p>
                <div className="rld-single-input">
                    <input type="text" name="website" placeholder={registration.website} onChange={props.onTextChange}
                        value={props.state.website}
                        className={props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website) ? 'input-error' : ''}
                    />
                    {
                        props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website) ?
                            <span
                                className={'fields-error-text'}>{toast.invalidWeb}</span>
                            : null
                    }
                </div>
            </div> */}
        </div>

    </form>
}
