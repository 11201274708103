import ApiService from './apiHandler';

const received = (index,size) =>  `quotation/buyer/submitted?index=${index}&size=${size}`
const submitted = (index,size) =>  `quotation/submitted?index=${index}&size=${size}`

export async function getMyQuotations(index,size,data,type) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = type === "R" ? received(index,size) : submitted(index,size);
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updateQuote(data) {
    const apiObject = {};
    apiObject.method = 'PATCH';
    apiObject.endpoint = `quotation`;
    apiObject.body = data
    apiObject.multipart = true
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function deleteQuotation(id) {
    const apiObject = {};
    apiObject.method = 'DELETE';
    apiObject.endpoint = `quotation/${id}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}
