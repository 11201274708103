import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import '../globalComponents/globalComponents.scss'
import strings from "../../locals/string.json";
import { checkLoginStatus, numberWithCommas } from "../../constants/commonFunc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { validateProductInqure } from "../../constants/validation";
import { productInqury } from "../../service/product";
import { ToastUtil } from "../../constants/toastUtil";
import constants from "../../constants/constants";
import Cookies from "js-cookie";
import swal from "sweetalert";


const pr = strings.purchaseRequest;
const modals = strings.modals;

class productViewModal extends React.Component {
    state = {
        inquire: this.props.inquire ?? false,
        description: '',
        email: '',
        subject: 'Inquiry on ' + this.props.item.productName,
        item: this.props.item
    }

    onInquire = () => {
        if (validateProductInqure(this.state)) {
            const item = this.props.item;
            productInqury({ ...this.state, productId: item.id })
                .then(response => {
                    if (response.success) {
                        this.onClose()
                        this.onShowConfirmtion(response.msg)
                        // ToastUtil.showSuccessToast(response.msg);
                    } else {
                        this.onShowConfirmtion(response.msg)
                        // ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    onShowConfirmtion = (message) => {
        swal({
            // title: modals.hi,
            text: message,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Ok", value: "action", className: "swalSuccess" },
            }
        })
    }

    onClose = () => {
        this.setState({ subject: 'Inquiry on ' + this.props.item.productName, email: '', description: '' })
        this.props.close();
    }

    render() {
        const item = this.props.item;

        return <Modal
            className="productViewModal"
            show={this.props.open}
            onHide={this.onClose}
            onShow={() => {
                const email = (this.props.user !== undefined && this.props.user !== null) ? (this.props.user.email !== null ? this.props.user.email : '') : ''
                this.setState({
                    inquire: this.props.inquire ?? false,
                    email: email
                })
            }}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{item.productName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="mt-4 title">{pr.productInformation}</h6>
                <div className="row">
                    <div className="col-md-3 col-sm-12 mt-3">
                        <img className="productImage" src={item.image} alt='image' />
                    </div>
                    <div className="col-md-9 col-sm-12 mt-3">
                        <div>
                            <p className="sub-title">{item.productName}</p>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <p className="headline">Unit Amount:
                                        <span
                                            className="c1"> {item.currency + " " + numberWithCommas(item.unitPrice.toFixed(2))}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <p className="headline">Availability:
                                        <span className="c1"> {item.availability ? 'In-Stock' : 'Sold Out'}</span></p>
                                </div>
                            </div>

                            <p className="headline">Category:
                                <span className="c3"> {item.category.name}</span></p>
                            <p className="headline">Sub Category:
                                <span className="c3"> {item.subCategory.name}</span></p>
                        </div>
                    </div>
                </div>

                <div className="separator mt-3 mb-3" />

                {
                    !this.state.inquire ?
                        <>
                            <h6 className="title">{pr.rDescription}</h6>
                            <p className="c2 mt-3">{item.description}</p>

                            <div className="separator mt-3 mb-3" />

                            <ul>
                                {
                                    item.productAttributes !== "" && item.productAttributes !== null && item.productAttributes.split(',').map((item, index) => {
                                        const att = item.split(':');
                                        return <div className="productAttribute">
                                            <li key={index} style={{ flex: 3 }}><b>{att[0]}</b></li>
                                            <p style={{ flex: 9 }}>: {att[1]}</p>
                                        </div>
                                    })
                                }
                            </ul>
                        </> :
                        <>
                            <form className="contact-form-bg">
                                <h6 className="headline">{pr.email}</h6>
                                <div className="form-group rld-single-input">
                                    <input type="text" name="lastName" required="required"
                                        disabled={Cookies.get(constants.ACCESS_TOKEN) !== undefined}
                                        onChange={event => this.setState({ email: event.target.value })}
                                        value={this.state.email} placeholder={pr.email} />
                                </div>

                                <h6 className="headline mt-2">{pr.subject}</h6>
                                <div className="form-group rld-single-input">
                                    <input type="text" name="lastName" required="required"
                                        onChange={event => this.setState({ subject: event.target.value })}
                                        value={this.state.subject}
                                        placeholder={pr.subject} />
                                </div>

                                <h6 className="headline mt-2">{pr.message}</h6>
                                <div className="form-group rld-single-input">
                                    <textarea rows={4} name="title" required="required"
                                        onChange={event => this.setState({ description: event.target.value })}
                                        value={this.state.description} placeholder={pr.description}
                                        maxLength={constants.inquiryWordLimit} />
                                    <span style={{
                                        position: "absolute",
                                        bottom: "-15px",
                                        right: "0px",
                                        color: this.state.description.length === constants.inquiryWordLimit ? 'red' : 'black'
                                    }}>{this.state.description.length}/100</span>
                                </div>
                            </form>
                        </>
                }


            </Modal.Body>

            <Modal.Footer>
                {
                    !this.state.inquire ?
                        <button className="btn btn-yellow" onClick={() => {
                            if (checkLoginStatus()) {
                                this.setState({ inquire: false })
                            }
                        }}>{pr.inquireNow}</button> :
                        <>
                            <button className="btn btn-white" onClick={() => {
                                if (this.props.inquire) {
                                    this.onClose();
                                } else {
                                    this.setState({ inquire: false })
                                }
                            }}>{pr.cancel}</button>
                            <button className="btn btn-yellow" onClick={this.onInquire}>{pr.sendInquire}</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(productViewModal));
