import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import { Assets } from "../../assets";
import strings from "../../locals/string.json";
import Step1 from './purchaseRequestStep01'
import Step3 from './purchaseRequestStep03'
import * as commonFunc from "../../constants/commonFunc";
import { createPurchaseRequestDetails } from "../../constants/commonFunc";
import constants, { __DEV__, categoryActions } from "../../constants/constants";
import {
    validatePurchaseRequestDetails,
    validatePurchaseRequestSubCategory,
    validatePurchaseRequestUserDetails
} from "../../constants/validation";
import { ToastUtil } from "../../constants/toastUtil";
import { getCategories } from "../../service/category";
import { addNewPurchaseRequest } from "../../service/request";
import MultiSelect from "../multiSelect/multiSelect";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import moment from "moment";
import swal from "sweetalert";

const purchaseRequest = strings.purchaseRequest;
const toast = strings.toast;
const modals = strings.modals;
const afterMonth = moment(moment().add(30, "days")).month();

class PurchaseRequestModal extends React.Component {

    state = {
        step: 1,
        title: __DEV__ ? 'looking for monitors' : '',
        estimatedValue: '',
        quantity: __DEV__ ? '10' : '',
        description: __DEV__ ? 'looking for monitors' : '',
        urgent: false,
        attachment: undefined,
        attachmentType: undefined,
        attachmentFile: undefined,
        categories: [],
        subCategory: [],
        firstName: __DEV__ ? 'Udara' : '',
        lastName: __DEV__ ? 'Prabath' : '',
        email: __DEV__ ? 'udarap@ceyentra.com' : '',
        mobile: '',
        hotline: '',
        land: '',
        district: '',
        companyName: __DEV__ ? 'Ceyentra' : '',
        designation: '',
        closingDate: afterMonth === 1 || afterMonth === 3 || afterMonth === 5 || afterMonth === 7 || afterMonth === 8 || afterMonth === 10 || afterMonth === 12 ? moment().add(31, "days") : moment().add(30, "days"),
        currency: 'LKR',
        website: '',
        preferredContactMethod: constants.TELEPHONE,
        selectedCategory: undefined,
        selectedSub: undefined,
        categoriesSorted: [],
        recaptchaToken: '',
        isValidation: false,
        previousSection: 0,
        filename: ''
    }

    onShow = () => {
        loadReCaptcha(constants.reCaptureSiteKey, () => {
        });
        this.setState({ title: this.props.title })
        // const data = [];
        // getCategories(categoryActions.GET_ALL)
        //     .then(response => {
        //         if (response.success) {
        //             response.body.map(item => {
        //                 item.category.map(main => {
        //                     main.subCategory.map(sub => {
        //                         data.push({
        //                             cat: main.name,
        //                             value: sub.id,
        //                             key: sub.name
        //                         })
        //                     })
        //                 })
        //             })
        //             this.setState({
        //                 categories: response.body,
        //                 categoriesSorted: data
        //             })
        //         }
        //     })
    }


    onShowConfirmtion = (msg) => {
        swal({
           // title: modals.hi,
            text: msg,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Ok", value: "action", className: "swalSuccess" },
            }
        })
    }


    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                attachmentType: file.type,
                attachmentFile: file,
                attachment: imageDataUrl,
                filename: file.name
            });
        }
    };

    onRemoveSubCategory = (item) => {
        let data = this.state.subCategory;
        data.splice(data.findIndex(e => e.id === item.id), 1);
        this.setState({ subCategory: data })
    }

    onChangeSubCategory = (item) => {
        let data = this.state.subCategory;
        if (data.length === 3) {
            ToastUtil.showErrorToast(toast.maximumCategory);
            return;
        }
        if (!(data.filter(e => e.id === item.id).length > 0)) {
            data.push(item);
        }
        this.setState({ subCategory: data, selectedSub: item.name })
    }

    onChangeCategory = (item) => {
        this.setState({
            selectedSub: null,
            selectedCategory: this.state.categories[item]
        })
    }

    onButtonClick = () => {
        if (this.props.loggedInStatus) {
            this.setState({ ...this.state, isValidation: true })
            if (this.state.step === 1 && validatePurchaseRequestDetails(this.state,"logged")) {
                // this.setState({ step: 2 })
                this.addPurchaseRequest(this.props.user);

            }
            // if (this.state.step === 2 && validatePurchaseRequestSubCategory(this.state)) {
            //     // save request after validations
            //     this.addPurchaseRequest(this.props.user);
            // }
        } else {
            this.setState({ ...this.state, isValidation: true })
            if (this.state.step === 1 && validatePurchaseRequestDetails(this.state)) {
                // this.setState({ step: 2 });
                this.setState({ ...this.state, step: 3, isValidation: false, previousSection: this.state.step });
                return;
            }
            // if (this.state.step === 2 && validatePurchaseRequestSubCategory(this.state)) {
            //     this.setState({ step: 3, isValidation: false });
            //     return;
            // }

            if (this.state.step === 3 && validatePurchaseRequestUserDetails(this.state)) {
                this.setState({ isValidation: true })
                this.addPurchaseRequest({
                    firstName: this.state.firstName === '' ? null : this.state.firstName,
                    lastName: this.state.lastName === '' ? null : this.state.lastName,
                    email: this.state.email === '' ? null : this.state.email,
                    mobileNumber: this.state.mobile === '' ? null : this.state.mobile,
                    landNumber: (this.state.land === '' || this.state.land === '+94') ? null : this.state.land,
                    hotline: (this.state.hotline === '' || this.state.hotline === '+94') ? null : this.state.hotline,
                    companyName: this.state.companyName === '' ? null : this.state.companyName,
                    designation: this.state.designation === '' ? null : this.state.designation,
                    website: this.state.website === '' ? null : this.state.website,
                    userType: constants.USER_GUEST
                });
            }
        }
    }

    addPurchaseRequest = async (user) => {
        await this.recaptcha.execute();
        this.state.recaptchaToken !== '' &&
            await addNewPurchaseRequest(createPurchaseRequestDetails({ ...this.state, user: user }))
                .then(response => {
                    if (response.success) {
                        this.onClear();
                        this.onShowConfirmtion(response.msg)
                        // ToastUtil.showSuccessToast(response.msg)
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
    }

    onClear = () => {
        this.setState({
            step: 1,
            title: '',
            estimatedValue: '',
            quantity: '',
            description: '',
            urgent: false,
            attachment: undefined,
            attachmentType: undefined,
            attachmentFile: undefined,
            categories: [],
            subCategory: [],
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            hotline: '',
            land: '',
            district: '',
            companyName: '',
            designation: '',
            closingDate: moment().add(30, "days"),
            website: '',
            preferredContactMethod: constants.TELEPHONE,
            selectedCategory: undefined,
            selectedSub: undefined,
            isValidation: false,
            filename: ''
        })
        this.props.close();
    }

    verifyCallback = async (recaptchaToken) => {
        await this.setState({ recaptchaToken })
    }

    render() {

        return <Modal
            className="register"
            show={this.props.open}
            onShow={this.onShow}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >

            <ReCaptcha
                ref={ref => this.recaptcha = ref}
                sitekey={constants.reCaptureSiteKey}
                action='submit'
                verifyCallback={this.verifyCallback}
            />

            <Modal.Header
                closeButton={true}
            >
                {
                    this.state.step !== 1 &&
                    <img className="pointer" src={Assets.back} alt="back"
                        onClick={async () => {
                            await this.setState({
                                ...this.state,
                                step: this.state.previousSection !== 0 ? this.state.previousSection : this.state.step - 1,
                            })
                        }} />
                }
                <Modal.Title>{this.state.step === 1 ? purchaseRequest.title1 : this.state.step === 2 ? purchaseRequest.title2 : purchaseRequest.title3}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.step === 1 &&
                    <Step1
                        state={{ ...this.state }}
                        onTextChange={this.onTextChange}
                        onCheck={() => this.setState({ urgent: !this.state.urgent })}
                        onViewFile={() => commonFunc.getB64ToBlob(this.state.attachment, this.state.attachmentType)}
                        onFileChange={this.onFilesChange} />
                }
                {/*{*/}
                {/*    this.state.step === 2 &&*/}
                {/*    <div className="row updateRequest">*/}
                {/*        <div className="col-md-12 col-sm-12 ">*/}
                {/*            <MultiSelect*/}
                {/*                onRemoveSubCategory={this.onRemoveSubCategory}*/}
                {/*                onChangeSubCategory={this.onChangeSubCategory}*/}
                {/*                state={{ ...this.state }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                {
                    this.state.step === 3 &&
                    <Step3
                        state={{ ...this.state }}
                        onTextChange={this.onTextChange} />
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClear}>{purchaseRequest.cancel}</button>
                <button className="btn btn-yellow" onClick={this.onButtonClick}>{
                    this.props.loggedInStatus ? (this.state.step !== 2 ? purchaseRequest.continue : purchaseRequest.submit) :
                        (this.state.step !== 3 ? purchaseRequest.continue : purchaseRequest.submit)
                }</button>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(PurchaseRequestModal));
