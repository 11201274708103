import React from "react";
import constants, { categories, categoryActions } from "../../constants/constants";
import strings from "../../locals/string.json";
import * as commonFunc from "../../constants/commonFunc";
import { createUpdateUserCategory, createUser } from "../../constants/commonFunc";
import { ToastUtil } from "../../constants/toastUtil";
import { getSupplierCategories, requestMoreCategory, updateUser } from "../../service/suppliers";
import { getCategories } from "../../service/category";
import { Assets } from "../../assets";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import swal from "sweetalert";
import StepTwo from "../oauth/registrationStep2";
import * as userActions from "../../store/domain/user/action";
import routes from "../../constants/routes";
import Container from "./newCategoryModel";
import CategorySelector from "../categorySelector";

const toast = strings.toast;
const modals = strings.modals;

class Categories extends React.Component {

    state = {
        id: '',
        categories: [],
        userCategories: [],
        category: [],
        subCategory: [],
        catIdArray: [],
        seeMore: false,
        selectedId: -1,
        maxCategoryCount: 0,
        currentCount: 0,

        selectedIndex: -1,
        selectedIndexID: -1,
        selectAll: false,
        index: 0,
        size: 10,
        disabled: false,
        selectedNodes: [],
        isNoData: false,
        nodeLimit: 0
    }

    componentWillMount() {
        this.getCategories();
        this.setState({
            id: this.props.user.id,
            maxCategoryCount: this.props.user.categoryCount
        })
    }

    getCategories = () => {
        getCategories(categoryActions.GET_ALL, true)
            .then(async response => {
                if (response.success) {
                    let mainCategoryArray = []
                    let subCategoryArray = []
                    let subCategoryArraywithboolean = []
                    let withoutDefault = []
                    response.body.map(e => {
                        // if (e.id !== 1) {
                        //     withoutDefault.push(e)
                        // }
                        withoutDefault.push(e)
                    })

                    withoutDefault.forEach(element => {
                        mainCategoryArray = mainCategoryArray.concat(element.category);
                    });

                    mainCategoryArray.forEach(ele => {
                        subCategoryArray = subCategoryArray.concat(ele);
                    });

                    subCategoryArray.map((val, index) => {
                        val.subCategory.map((ele, ind) => {
                            if (ele.checked) {
                                // subCategoryArraywithboolean.push({ value: `${ele.id}L` + `${val.id}M` })
                                subCategoryArraywithboolean.push({ value: val.id })
                            }
                        })
                    })

                    let cArray = [];

                    const cat = withoutDefault.map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                            disabled: true,
                            children: item.category.map(main => {
                                let subCatList = []
                                main.subCategory.map(c => {
                                    subCatList.push(c.checked)
                                })

                                let has_true = false;

                                let result =  {
                                    value: main.id,
                                    label: main.name,
                                    disabled: false,
                                    checked: subCatList.includes(false) === false ? true : false,
                                    subCategory: main.subCategory.map(setSubCategory => {
                                        if(setSubCategory.checked) {has_true=true}
                                        return {
                                            value: setSubCategory.id,
                                            label: setSubCategory.name,
                                            checked: setSubCategory.checked
                                        }
                                    }),
                                    children: main.subCategory.map(sub => {
                                        return {
                                            value: sub.id,
                                            label: sub.name,
                                            disabled: false,
                                            checked: sub.checked,
                                            subCategory: [
                                                {
                                                    value: sub.id,
                                                    label: sub.name,
                                                    checked: sub.checked
                                                }
                                            ]
                                        }
                                    })
                                }

                                if(has_true) {cArray.push({
                                    superId: item.id,
                                    mainId: main.id
                                })}

                                return result;

                            }),
                        }
                    });

                    let selectedCat = [];

                    cArray.map((val, index) => {
                        let selectedCatValue = cat[cat.findIndex(e => e.value === val.superId)].children[cat[cat.findIndex(e => e.value === val.superId)].children.findIndex(e => e.value === val.mainId)]
                        selectedCat.push(selectedCatValue);
                    });

                    let catIdArray = [];

                    selectedCat.map((val, index) => {
                        this.setState({ ['cat' + (index + 1)]: val })
                    });

                    let catCount = this.props.user.categoryCount;
                    let realCount = 0;
                    if(catCount!==undefined) {
                        for(let i = 0; i< catCount; i++) {
                            let catId = -1;
                            let result = cArray[i];
                            if(result) {
                                catId = result.mainId;
                                realCount++;
                            }
                            catIdArray.push(catId);
                        }
                    }

                    await this.setState({
                        categories: cat,
                        selectedNodes: subCategoryArraywithboolean,
                        userCategories: realCount,
                        catIdArray: catIdArray,
                        isNoData: false
                    })
                    // this.clickChild()
                } else {
                    this.setState({ isNoData: true })
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    getSupplierCategories = (categories) => {
        getSupplierCategories(this.props.user.id, this.state.index, this.state.size)
            .then(async response => {
                if (response.success) {

                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    handleSubCategoryChange = async (index, status, id) => {
        let data = { ...this.state['cat' + index] };
        const result = commonFunc.handleSubCategoryChange(index, status, id, data)
        this.setState({ ['cat' + index]: result })
    }

    setCategory = async (index, value) => {
        // const category = { ...this.state.categories[value] }
        await this.setState({
            ["cat" + index]: value
        })
    }

    onCategoryChange = async (index, value) => {
        // const category = { ...this.state.categories[value] }
        const category = value;
        const result = commonFunc.onCategoryChange(category, this.state.subCategory, this.state.category)
        const catIdArray = [...this.state.catIdArray];
        catIdArray[index] = category.value;
        await this.setState({
            subCategory: result.data, category: result.cat,
            ["cat" + (index + 1)]: category,
            catIdArray: catIdArray
        })
    }

    onSubCategoryChange = (category, item) => {
        const result = commonFunc.onSubCategoryChange(category, item, this.state.subCategory, this.state.category)
        this.setState({ subCategory: result.data, category: result.cat })
    }

    onSelectAllSubCategory = (category, status) => {
        const data = this.state.subCategory;
        const cat = this.state.category;
        category.subCategory.map((item, index) => {
            if ((data.filter(e => e.id === item.id).length > 0)) {
                data.splice(data.findIndex(e => e.id === item.id), 1);
            }
            if (status) {
                data.push(item);
            }
        })

        if (!(cat.filter(e => e.id === category.id).length > 0)) {
            cat.push(category);
        } else {
            if (!status) {
                cat.splice(cat.findIndex(e => e.id === category.id), 1);
            }
        }
        this.setState({ subCategory: data, category: cat })
    }

    onSave = async () => {
        if (this.state.selectedNodes.length === 0) {
            ToastUtil.showErrorToast(toast.emptyCategory);
            return false;
        } else {
            let limit = this.props.user.categoryCount

            if (limit < this.state.nodeLimit) {
                ToastUtil.showErrorToast('Category count exeeded')
                return false;
            }
        }

        updateUser(await createUpdateUserCategory(this.state))
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.body)));
                    this.props.setAuth(createUser(response.body));
                    // this.setState({disabled: !this.state.disabled});
                    this.getCategories();
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    requestMore = () => {
        swal({
            title: modals.areUSure,
            text: modals.moreCategories,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Request", value: "action", className: "swalSuccess" },
                dangerMood: { text: "Cancel", value: "cancel", className: "swalCancel" }
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        requestMoreCategory()
                            .then(response => {
                                if (response.success) {
                                    swal({
                                        title: response.msg,
                                        icon: null,
                                        closeOnClickOutside: false,
                                        buttons: {
                                            dangerMode: { text: "Ok", value: "action", className: "swalSuccess" }
                                        }
                                    })
                                        .then((value) => {
                                            switch (value) {
                                                case "action":
                                                default:
                                                    break;
                                            }
                                        });
                                } else {
                                    ToastUtil.showErrorToast(response.msg);
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    onChange = async (currentNode, selectedNodes) => {
        let catArray = []
        selectedNodes.map((cat, index) => {
            cat.subCategory.map((selectCat) => {
                let mainCatID = selectCat.value.split("L")[1].replace('M', '')
                catArray.push(mainCatID)
            })
        })
        this.clickChild()

        await this.setState({ nodeLimit: [...new Set(catArray)].length })
        let limit = this.props.user.categoryCount
        if ([...new Set(catArray)].length <= limit) {
            this.setState({ selectedNodes })
        } else {
            ToastUtil.showErrorToast('Category count exeeded')
        }
    }

    render() {
        const userCategories = this.state.isNoData
        let userType = this.props.user.userType;
        let categoryCount = this.props.user.categoryCount;

        let cc = this.state.userCategories;

        console.log("user>>>> ", this.props.user.categories);
        console.log("this.state.categories >>>> ", this.state.categories);

        return <div>
            <div className="display-flex justify-content-end">
                {
                    this.state.disabled ?
                        <button className="btn btn-white mt-3 mr-2"
                            onClick={() => this.setState({ disabled: !this.state.disabled })}>
                            <i className="fa fa-edit mr-2" />Edit</button> :
                        <button className="btn btn-yellow mt-3 mr-2"
                            onClick={this.onSave}>
                            <i className="fa fa-save mr-2" />Save</button>
                }
                {/*<button className="btn btn-yellow mt-3 mr-2" onClick={() => this.props.history.push(routes.myProducts)}>My*/}
                {/*    Products*/}
                {/*</button>*/}
                <div className="display-flex justify-content-end">
                    <button className="btn btn-yellow mt-3" onClick={this.requestMore}>Request
                        more categories
                    </button>
                </div>
            </div>
            <form className="contact-form-bg mt-5">
                {
                    userCategories ?
                        <div className="display-flex flex-column  align-items-center"
                            style={{ width: '100%' }}>
                            <img src={Assets.empty} alt="empty" />
                        </div> :
                        // <StepTwo
                        //     state={{ ...this.state, userType: constants.USER_SELLER }}
                        //     categoryCount={this.state.disabled ? userCategories : this.state.maxCategoryCount}
                        //     onCategoryChange={this.onCategoryChange}
                        //     onSelectAllSubCategory={this.onSelectAllSubCategory}
                        //     setCategory={this.setCategory}
                        //     active={userCategories}
                        //     clearSelection={this.clearSelection}
                        //     disabled={this.state.disabled}
                        //     handleSubCategoryChange={this.handleSubCategoryChange}
                        //     onSubCategoryChange={this.onSubCategoryChange} />

                        // <Container
                        //     state={this.state}
                        //     data={this.state.categories}
                        //     onChange={this.onChange}
                        //     setClick={click => this.clickChild = click}
                        // />
                        cc > 0 &&
                        <CategorySelector
                            data={this.state.categories}
                            state={{ ...this.state, userType }}
                            categoryCount={categoryCount}
                            // categoryCount={3} // only For Testing
                            onCategoryChange={this.onCategoryChange}
                            onSelectAllSubCategory={this.onSelectAllSubCategory}
                            setCategory={this.setCategory}
                            clearSelection={this.clearSelection}
                            active={cc}
                            handleSubCategoryChange={this.handleSubCategoryChange}
                            onSubCategoryChange={this.onSubCategoryChange}
                        />
                }
            </form>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Categories));
