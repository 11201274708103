import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import PopularSuppliers from '../components/suppliers/popularSuppliers';
import PopularProducts from '../components/products/popularProducts';
import LatestRequests from '../components/purchaseRequest/latestRequests';
import PopularCategory from '../components/globalComponents/popularCategory';
import Testimonials from '../components/globalComponents/testimonials';
import Pricing from '../components/globalComponents/pricing';
import Banner from '../components/banner/banner';
import Footer from "../components/globalComponents/footer";
import Ads from "../components/globalComponents/ads";
import Intro from "../components/globalComponents/intro";
import Statistics from "../components/statistics/statistics";

const Landing = (props) => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0)
    }, [])

    return <div>
        <Navbar/>
        <Banner history={props.history}/>
        <Statistics/>
        {/*<Testimonials/>*/}
        <PopularSuppliers history={props.history}/>
        <PopularProducts history={props.history}/>
        <Ads/>
        <LatestRequests history={props.history}/>
        <PopularCategory/>
        {/*<Intro/>*/}

        <Footer/>
    </div>
}

export default Landing

