import React, { Component } from 'react';
import './globalComponents.scss';
import { Link } from 'react-router-dom';
import { Assets } from "../../assets";
import strings from '../../locals/string.json';
import routes from "../../constants/routes";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import constants, { popularSuppliers } from "../../constants/constants";
import * as userActions from "../../store/domain/user/action";
import { createUser, removeCookiesValues } from "../../constants/commonFunc";
import ResetPasswordModal from "../oauth/resetPasswordModal";
import { isBrowser } from "react-device-detect";
import Cookies from "js-cookie";
import * as services from "../../service/auth";
import { ToastUtil } from "../../constants/toastUtil";
import moment from "moment";
import PurchaseRequestModal from "../purchaseRequest/purchaseRequestModal";

const string = strings.navBar;

class Navbar extends Component {

    state = {
        modelOpen: false,
        requestModelOpen: false
    }

    checkExpired = async () => {
        let date = await JSON.parse(localStorage.getItem(constants.USER_OBJ)).expiryDate
        // change days to hours
        if (moment(date).diff(moment(), 'hours') <= 0 && window.location.pathname.split('/')[1] !== 'my-profile' && (window.location.search !== ' ' && window.location.search.split('=')[1] !== 'registration')) {
            this.props.history.push(routes.myProfile)
            ToastUtil.showErrorToast(string.deactivateError);
        }
    }

    componentDidMount() {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            this.checkExpired()
        }
    }

    componentWillMount() {
        if (localStorage.getItem(constants.ACCESS_TOKEN) !== null) {
            this.props.setLoginStatus(true)
            this.props.setUser(JSON.parse(localStorage.getItem(constants.USER_OBJ)))
            this.props.setUserType(JSON.parse(localStorage.getItem(constants.USER_OBJ)).userType);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined && nextProps) {
            this.getUserDetails()
        }
    }

    getUserDetails = async () => {
        let user = JSON.parse(await localStorage.getItem(constants.USER_OBJ))

        if (user.userType === constants.B2B_EXPIRED_SELLER && window.location.pathname !== routes.myProfile) {
            this.props.history.push(routes.myProfile)
            ToastUtil.showErrorToast("Your account is expired, Please make payment");
        }
    }

    logOUt = async () => {
        await removeCookiesValues();
        localStorage.removeItem(constants.ACCESS_TOKEN);
        // this.props.history.push(routes.login)
        window.location.href = routes.home
        this.props.setAuth(undefined);
        this.props.setUser(null);
        this.props.setLoginStatus(false);
        localStorage.removeItem(constants.USER_OBJ);
    }

    updatedQuoteType =  (type) => {

        if(type === "r"){
            localStorage.setItem("QTYPE","R")
        }else {
            localStorage.setItem("QTYPE","S")
        }
    }

    render() {
        return (
            <div>
                <div className="navbar-area navBar">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse"
                                    data-target="#realdeal_main_menu" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="icon-left" />
                                    <span className="icon-right" />
                                </button>
                            </div>
                            <div className="logo readeal-top" style={{ width: isBrowser ? '120px' : '30px' }}>
                                <Link to={routes.home}><img src={isBrowser ? Assets.logo : Assets.logoS}
                                    alt="logo" /></Link>
                            </div>

                            <div className="nav-right-part nav-right-part-mobile oauthHolder-mobile">

                                {
                                    !this.props.loggedInStatus ?
                                        <>
                                            <Link className="btn btn-yellow"
                                                to={routes.register}>Register your Business</Link>
                                            <p className="or">or</p>
                                            <Link className="btn login" to={routes.login}>{string.login}</Link>
                                        </>
                                        : null
                                }

                            </div>

                            <div className="collapse navbar-collapse" id="realdeal_main_menu">
                                <ul className="navbar-nav menu-open readeal-top">
                                    {/*<li className="current-menu-item">*/}
                                    {/*    <a><Link to={routes.home}>{string.home}</Link></a>*/}
                                    {/*</li>*/}

                                    {/*{*/}
                                    {/*    this.props.loggedInStatus ?*/}
                                    {/*        <li className="menu-item-has-children">*/}
                                    {/*            <a>{string.requests}</a>*/}
                                    {/*            <ul className="sub-menu">*/}
                                    {/*                <li><Link to={routes.requests}>{string.all} {string.requests}</Link>*/}
                                    {/*                </li>*/}
                                    {/*                <li><Link*/}
                                    {/*                    to={routes.myRequests}>{string.my} {string.requests}</Link></li>*/}
                                    {/*            </ul>*/}
                                    {/*        </li> :*/}
                                    {/*        <li><Link to={routes.requests}>{string.requests}</Link></li>*/}
                                    {/*}*/}

                                    {/*{*/}
                                    {/*    (this.props.loggedInStatus && this.props.userType === constants.USER_SELLER) &&*/}
                                    {/*    <li><Link to={routes.myQuotes}>{string.quotations}</Link></li>*/}
                                    {/*}*/}

                                    {/*{*/}
                                    {/*    this.props.loggedInStatus ? this.props.userType === constants.USER_SELLER ?*/}
                                    {/*        <li className="menu-item-has-children">*/}
                                    {/*            <a>{string.products}</a>*/}
                                    {/*            <ul className="sub-menu">*/}
                                    {/*                <li><Link to={routes.products}>{string.all} {string.products}</Link>*/}
                                    {/*                </li>*/}
                                    {/*                <li><Link to={routes.myProducts}>{string.my} {string.products}</Link></li>*/}
                                    {/*            </ul>*/}
                                    {/*        </li>*/}
                                    {/*        : <li><Link to={routes.products}>{string.products}</Link></li>*/}
                                    {/*        : <li><Link to={routes.products}>{string.products}</Link></li>*/}
                                    {/*}*/}


                                    {/*<li><Link to={routes.suppliers}>{string.suppliers}</Link></li>*/}

                                    {/*<li className="menu-item-has-children">*/}
                                    {/*    <a>{string.company}</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li><Link to={routes.about}>{string.aboutUs}</Link></li>*/}
                                    {/*        <li><Link to={routes.contact}>{string.contact}</Link></li>*/}
                                    {/*        <li><Link to={routes.pricing}>Pricing</Link></li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}

                                    <li className="current-menu-item">
                                        <a><Link to={routes.home}>{string.home}</Link></a>
                                    </li>

                                    <li className="menu-item-has-children">
                                        <a>{string.forBuyers}</a>
                                        {
                                            this.props.loggedInStatus ?
                                                <ul className="sub-menu">
                                                    <li onClick={() => this.setState({ requestModelOpen: !this.state.requestModelOpen })}><Link>Submit a Purchase Request</Link></li>
                                                    <li><Link to={routes.myRequests}>{string.my} {string.requests}</Link></li>
                                                    <li><Link  to={routes.receivedQuotes}>{string.quotationsBuyer}</Link></li>
                                                    <li><Link to={routes.products}>Find Products</Link></li>
                                                    <li><Link to={routes.suppliers}>Find Suppliers</Link></li>
                                                    <li onClick={() => this.props.history.push(routes.myProfile, popularSuppliers[0])}><Link>{string.profile}</Link></li>
                                                </ul>
                                                :
                                                <ul className="sub-menu">
                                                    <li onClick={() => this.setState({ requestModelOpen: !this.state.requestModelOpen })}><Link>Submit a Purchase Request</Link></li>
                                                    <li><Link to={routes.products}>Find Products</Link></li>
                                                    <li><Link to={routes.suppliers}>Find Suppliers</Link></li>
                                                    <li><Link to={routes.register}>Register for FREE</Link></li>
                                                </ul>
                                        }

                                    </li>

                                    <li className="menu-item-has-children">
                                        <a>{string.forSuppliers}</a>
                                        {
                                            this.props.loggedInStatus ? this.props.userType === constants.USER_SELLER ?
                                                <ul className="sub-menu" style={{right: "-85px"}}>
                                                    <li><Link to={routes.requests}>Find Purchase Requests</Link></li>
                                                    <li><Link to={routes.myQuotes}>{string.quotationsSeller}</Link></li>
                                                    <li><Link to={routes.myProducts}>{string.my} {string.products}</Link></li>
                                                    <li onClick={() => this.props.history.push(routes.myProfile, popularSuppliers[0])}><Link>{string.profile}</Link></li>
                                                </ul>
                                                :
                                                <ul className="sub-menu" style={{right: "-85px"}}>
                                                    <li><Link to={routes.requests}>Find Purchase Requests</Link></li>
                                                    <li><Link to={routes.register}>Display Your Products</Link></li>
                                                    <li><Link to={routes.register}>Register Now</Link></li>
                                                    <li><Link to={routes.pricing}>Member Pricing</Link></li>
                                                </ul>
                                                :
                                                <ul className="sub-menu" style={{right: "-85px"}}>
                                                    <li><Link to={routes.requests}>Find Purchase Requests</Link></li>
                                                    <li><Link to={routes.register}>Display Your Products</Link></li>
                                                    <li><Link to={routes.register}>Register Now</Link></li>
                                                    <li><Link to={routes.pricing}>Member Pricing</Link></li>
                                                </ul>
                                        }
                                    </li>

                                    <li><Link to={routes.about}>{string.aboutUs}</Link></li>

                                    <li><Link to={routes.contact}>{string.contact}</Link></li>

                                    {
                                        this.props.loggedInStatus &&
                                        <li className="menu-item-has-children">
                                            <a><img className='loggedUser'
                                                src={this.props.user?.profilePicture ?? Assets.avatarPlaceholder}
                                                alt="user" width={45} />
                                            </a>
                                            <ul className="sub-menu">
                                                {/* <li style={{ color: 'var(--main-color-one)' }}
                                                ><Link style={{ color: 'var(--main-color-one)' }}>Logged As {this.props.userType === constants.USER_SELLER ? 'Seller' : 'Buyer'}</Link></li> */}
                                                <li style={{ color: 'var(--main-color-one)' }}
                                                ><Link
                                                    style={{ color: 'var(--main-color-one)' }}>{this.props.userType === constants.USER_SELLER ? 'Seller Account - ' + JSON.parse(localStorage.getItem(constants.USER_OBJ)).firstName : 'Buyer Account - ' + JSON.parse(localStorage.getItem(constants.USER_OBJ)).firstName}</Link>
                                                </li>
                                                <li onClick={() => this.props.history.push(routes.myProfile, popularSuppliers[0])}>
                                                    <Link>{string.profile}</Link></li>
                                                <li onClick={() => this.setState({ modelOpen: !this.state.modelOpen })}>
                                                    <Link>{string.reset}</Link></li>
                                                <li onClick={this.logOUt}><Link>{string.logOut}</Link></li>
                                            </ul>
                                        </li>
                                    }

                                </ul>
                            </div>
                            <div className="nav-right-part nav-right-part-desktop readeal-top oauthHolder">
                                {
                                    !this.props.loggedInStatus ?
                                        <>
                                            <Link className="btn btn-yellow"
                                                to={routes.register}>Register your Business</Link>
                                            <p className="or">or</p>
                                            <Link className="btn login" to={routes.login}>{string.login}</Link>
                                        </> : null

                                }
                            </div>

                        </div>
                    </nav>
                </div>

                <ResetPasswordModal
                    open={this.state.modelOpen}
                    close={() => this.setState({ modelOpen: !this.state.modelOpen })}
                />

                <PurchaseRequestModal
                    title={''}
                    open={this.state.requestModelOpen}
                    close={() => this.setState({requestModelOpen: !this.state.requestModelOpen})}
                />

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setLoginStatus: data => dispatch(userActions.setLoginStatus(data)),
    setUser: data => dispatch(userActions.setUser(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
