import React from "react";
import { Rate } from "antd";
import { Assets } from "../../assets";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import strings from "../../locals/string.json";
import { openWebSite } from "../../constants/commonFunc";

const pr = strings.purchaseRequest;

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorHtml: ''
        }
    }

    componentDidMount() {

        const item = this.props.item;

        if (item.description !== null && item.description.trim() !== '' && item.description.trim() !== 'null') {
            const xmlhttp = new XMLHttpRequest();
            const self = this;
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    self.setState({ editorHtml: xmlhttp.responseText })
                }
            }
            // xmlhttp.open("GET", item.description, true);
            xmlhttp.open("GET", item.description, true);
            xmlhttp.send();
        }
    }

    render() {
        const item = this.props.item;
        return <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div className="profileDetailsHolder">
                    <div className="companyDetails">
                        <img className="logo"
                            src={item.profilePicture !== null ? item.profilePicture : Assets.companyLogo}
                            alt="img" />
                        <p className="title">{item.companyName !== null ? item.companyName : "N/A"}</p>

                        <div className="display-flex align-items-center">
                            <Rate
                                disabled
                                value={1}
                                count={1}
                            />

                            <p className="mb-0 ratingValue display-flex align-items-center">&nbsp;{item.ratingValue}
                                <p className="mb-0 ratingCount">&nbsp;&nbsp;{item.ratingCount} Reviews</p>
                            </p>
                        </div>

                        {
                            this.props.loggedInStatus &&
                            <button className="btn btn-white review"
                                onClick={this.props.modalOpen}>Write
                                Review</button>
                        }

                    </div>

                    <div className="separator mt-3" />

                    <div className="p-3">
                        <p className="title">{pr.contactDetails}</p>

                        <p className="headline mt-3"><i
                            className="fa fa-map-marker" /> &nbsp;&nbsp;{pr.address}</p>
                        <p className="contactDetails">{item.address !== null ? item.address : "N/A"}</p>

                        <p className="headline mt-3"><i
                            className="fa fa-mobile" /> &nbsp;&nbsp;{pr.mobile}
                        </p>
                        <p className="contactDetails">{item.hotLine !== null ? item.hotLine : "N/A"}</p>

                        <p className="headline mt-3"><i
                            className="fa fa-fax" /> &nbsp;&nbsp;{pr.telephone}
                        </p>
                        <p className="contactDetails">{item.landNumber !== null ? item.landNumber : "N/A"}</p>

                        <p className="headline mt-3"><i
                            className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}
                        </p>
                        <p className="contactDetails">{item.email !== null ? item.email : "N/A"}</p>

                        {
                            item.secondaryEmails !== null && item.secondaryEmails.split(',').map((email, index) => {
                                return <>
                                    <p key={index} className="headline mt-3"><i
                                        className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}</p>
                                    <p className="contactDetails">{email}</p>
                                </>
                            })
                        }

                        <p className="headline mt-3"><i
                            className="fa fa-globe" /> &nbsp;&nbsp;{pr.website}
                        </p>
                        <p className="contactDetails pointer" onClick={() => openWebSite(item.website)}>{item.website !== null ? item.website : "N/A"}</p>

                    </div>

                </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                {
                    (item.description === null || item.description === '' || item.description === 'null') ?
                        <div className="display-flex flex-column  align-items-center"
                            style={{ width: '100%' }}>
                            <img src={Assets.descEmpty} alt="empty"/>
                            {/* <p>{pr.noDesc}</p> */}
                        </div> :
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: this.state.editorHtml }} />
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(About));
