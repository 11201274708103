import constants from "../constants/constants";
import Cookies from "js-cookie";
import {ToastUtil} from "./toastUtil";
import strings from "../locals/string.json";
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import apiConfig from "../service/apiConfig";

const toast = strings.toast;


export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX = /^.{7,}$/;
const OTP_REGEX = /^.{5,}$/;
const Name_REGEX = /^[A-z ]+$/;
const MOBILE_REGEX = /^(0)[0-9]{9}$|^(07)[0-9]{8}$/;
const CVV = /^[0-9]{3}$/;
const EXP_MONTH = /^(0[1-9]|1[0-2])$/;
const EXP_DATE = /^([0-9]{2})$/;
const CARD_NUMBER = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const WEB_SITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const SEARCH_VALUE_REGEXP = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const SEARCH_SPACE_REGEXP = /\s+/g;

export const validateContact = (state) => {
    if (state.name.trim() === '' || !Name_REGEX.test(state.name)) {
        ToastUtil.showErrorToast(toast.invalidFName);
        return false;
    }
    if (state.email.trim() === '' || !EMAIL_REGEX.test(state.email)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    if (state.mobile.trim() === '' || !MOBILE_REGEX.test(state.mobile)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.invalidMessage);
        return false;
    }
    return true
}
export const validateProductInqure = (state) => {
    if (state.email.trim() === '' || !EMAIL_REGEX.test(state.email)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    if (state.subject.trim() === '') {
        ToastUtil.showErrorToast(toast.invalidSubject);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.invalidMessage);
        return false;
    }
    return true
}
export const validateEmail = (state) => {
    if (state.email.trim() === '' || !EMAIL_REGEX.test(state.email)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    return true
}
export const validateOtp = (state) => {
    if (state.otp.trim() === '' || !OTP_REGEX.test(state.otp)) {
        ToastUtil.showErrorToast(toast.invalidOtp);
        return false;
    }
    return true
}
export const validatePassword = (state) => {
    if (state.password.trim() === '' || !PASSWORD_REGEX.test(state.password)) {
        ToastUtil.showErrorToast(toast.emptyPassword);
        return false;
    }
    if (state.password.trim() !== state.confirmPassword.trim()) {
        ToastUtil.showErrorToast(toast.confirmPasswordNotMatch);
        return false;
    }
    return true
}
export const validatePasswordReset = (state) => {
    if (state.oldPassword.trim() === '' || !PASSWORD_REGEX.test(state.oldPassword)) {
        ToastUtil.showErrorToast(toast.emptyOldPassword);
        return false;
    }
    if (state.password.trim() === '' || !PASSWORD_REGEX.test(state.password)) {
        ToastUtil.showErrorToast(toast.emptyPassword);
        return false;
    }
    if (state.password.trim() !== state.confirmPassword.trim()) {
        ToastUtil.showErrorToast(toast.confirmPasswordNotMatch);
        return false;
    }
    return true
}

export const validateLoginDetails = (state) => {
    if (state.username.trim() === '' || !EMAIL_REGEX.test(state.username)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    if (state.password.trim() === '' || !PASSWORD_REGEX.test(state.password)) {
        ToastUtil.showErrorToast(toast.emptyPassword);
        return false;
    }
    return true
}

export const validateBuyerRegDetails = (state, avoid, company, image) => {

    if (state.firstName.trim() === '' || !Name_REGEX.test(state.firstName)) {
        ToastUtil.showErrorToast(toast.invalidName);
        return false;
    }
    if (state.lastName.trim() === '' || !Name_REGEX.test(state.lastName)) {
        ToastUtil.showErrorToast(toast.invalidLName);
        return false;
    }
    if (state.email.trim() === '' || !EMAIL_REGEX.test(state.email)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    if (state.mobile.trim() === '' || !isPossiblePhoneNumber(state.mobile)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.hotline.trim() !== '' && !isPossiblePhoneNumber(state.hotline)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.land.trim() !== '' && !isPossiblePhoneNumber(state.land)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (avoid === undefined) {
        if (state.secondaryEmail.trim() !== '' && !EMAIL_REGEX.test(state.secondaryEmail)) {
            ToastUtil.showErrorToast(toast.invalidEmail);
            return false;
        }
        if (state.password.trim() === '' || !PASSWORD_REGEX.test(state.password)) {
            ToastUtil.showErrorToast(toast.emptyPassword);
            return false;
        }
        if (state.password.trim() !== state.confirmPassword.trim()) {
            ToastUtil.showErrorToast(toast.confirmPasswordNotMatch);
            return false;
        }
    }

    if (image) {
        if (state.image === null || state.image.trim() === '') {
            ToastUtil.showErrorToast(toast.profileImage);
            return false;
        }
        if (state.coverImage === null || state.coverImage.trim() === '') {
            ToastUtil.showErrorToast(toast.coverImage);
            return false;
        }
    }

    if (company) {
        if (state.companyName.trim() === '') {
            ToastUtil.showErrorToast(toast.invalidCName);
            return false;
        }
        if (state.companyUrl.trim() === apiConfig.frontEnd) {
            ToastUtil.showErrorToast(toast.invalidCUrl);
            return false;
        }
        if (state.companyType === null) {
            ToastUtil.showErrorToast(toast.companyType);
            return false;
        }
    } else {
        // if (state.companyName.trim() === '') {
        //     ToastUtil.showErrorToast(toast.invalidCName);
        //     return false;
        // }
    }
    if (state.designation.trim() !== '' && !Name_REGEX.test(state.designation)) {
        ToastUtil.showErrorToast(toast.invalidDName);
        return false;
    }
    if (company) {
        if (state.address.trim() === '') {
            ToastUtil.showErrorToast(toast.address);
            return false;
        }
        if (state.location === null || state.location.trim() === '') {
            ToastUtil.showErrorToast(toast.district);
            return false;
        }
    }
    if (state.website.trim() !== '' && !WEB_SITE_REGEX.test(state.website)) {
        ToastUtil.showErrorToast(toast.invalidWeb);
        return false;
    }
    return true
}

export const validateUpdateBuyerDetails = (state) => {
    if (state.firstName.trim() === '' || !Name_REGEX.test(state.firstName)) {
        ToastUtil.showErrorToast(toast.invalidName);
        return false;
    }
    if (state.lastName.trim() === '' || !Name_REGEX.test(state.lastName)) {
        ToastUtil.showErrorToast(toast.invalidLName);
        return false;
    }

    if (state.mobile.trim() === '' || !isPossiblePhoneNumber(state.mobile)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.hotLine.trim() !== '' && !isPossiblePhoneNumber(state.hotLine)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.landNumber.trim() !== '' && !isPossiblePhoneNumber(state.landNumber)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }

    if (state.companyName.trim() !== '' && !Name_REGEX.test(state.companyName)) {
        ToastUtil.showErrorToast(toast.invalidCName);
        return false;
    }
    if (state.designation.trim() !== '' && !Name_REGEX.test(state.designation)) {
        ToastUtil.showErrorToast(toast.invalidDName);
        return false;
    }
    if (state.website.trim() !== '' && !WEB_SITE_REGEX.test(state.website)) {
        ToastUtil.showErrorToast(toast.invalidWeb);
        return false;
    }
    return true;
}

export const validateUpdateSellerDetails = (state) => {
    if (state.firstName.trim() === '' || !Name_REGEX.test(state.firstName)) {
        ToastUtil.showErrorToast(toast.invalidName);
        return false;
    }
    if (state.lastName.trim() === '' || !Name_REGEX.test(state.lastName)) {
        ToastUtil.showErrorToast(toast.invalidLName);
        return false;
    }

    if (state.mobile.trim() === '' || !isPossiblePhoneNumber(state.mobile)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.hotLine.trim() !== '' && !isPossiblePhoneNumber(state.hotLine)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.landNumber.trim() !== '' && !isPossiblePhoneNumber(state.landNumber)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }

    if (state.companyName.trim() === '' && !Name_REGEX.test(state.companyName)) {
        ToastUtil.showErrorToast(toast.invalidCName);
        return false;
    }
    if (state.companyUrl.trim() === apiConfig.frontEnd) {
        ToastUtil.showErrorToast(toast.invalidCUrl);
        return false;
    }
    if (state.address.trim() === '') {
        ToastUtil.showErrorToast(toast.address);
        return false;
    }
    if (state.location === null || state.location.trim() === '') {
        ToastUtil.showErrorToast(toast.district);
        return false;
    }
    if (state.designation.trim() !== '' && !Name_REGEX.test(state.designation)) {
        ToastUtil.showErrorToast(toast.invalidDName);
        return false;
    }
    if (state.website.trim() !== '' && !WEB_SITE_REGEX.test(state.website)) {
        ToastUtil.showErrorToast(toast.invalidWeb);
        return false;
    }
    return true;
}

export const validateCategory = (state) => {
    // console.log(state.selectedNodes)
    // if (state.selectedNodes.length === 0) {
    //     ToastUtil.showErrorToast(toast.emptyCategory);
    //     return false;
    // }
    let cat_validation = false;
    state.catIdArray.map((cat, index) => {
        if(cat!==-1) {
            cat_validation = true;
        }
    });

    if (!cat_validation) {
        ToastUtil.showErrorToast(toast.emptyCategory);
        return false;
    }
    return true;
}

export const validatePurchaseRequestDetails = (state,type) => {
    if (state.title.trim() === '') {
        ToastUtil.showErrorToast(toast.purchaseRequestTitle);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.descriptionError);
        return false;
    }
    if (state.estimatedValue.trim() !== '' && Name_REGEX.test(state.estimatedValue)) {
        ToastUtil.showErrorToast(toast.estimatedValue);
        return false;
    }
    if (state.quantity.trim() === '') {
        ToastUtil.showErrorToast(toast.qtyError);
        return false;
    }
    if (state.closingDate === '') {
        ToastUtil.showErrorToast(toast.closingDateError);
        return false;
    }
    if (state.preferredContactMethod === constants.ALL) {
        if(type !== "logged"){
            if (state.email === '') {
                ToastUtil.showErrorToast(toast.invalidEmail);
                return false;
            }
            if(state.mobile === '') {
                ToastUtil.showErrorToast(toast.invalidMobile);
                return false;
            }
        }
    }

    if (Cookies.get(constants.ACCESS_TOKEN) === undefined) {
        if (state.preferredContactMethod === constants.EMAIL & state.email === '') {
            ToastUtil.showErrorToast(toast.invalidEmail);
            return false;
        }
        if ((state.preferredContactMethod === constants.TELEPHONE || state.preferredContactMethod === constants.WHATSAPP) & state.mobile === '') {
            ToastUtil.showErrorToast(toast.invalidMobile);
            return false;
        }
    }

    if (Cookies.get(constants.ACCESS_TOKEN) === undefined) {
        if (state.mobile.trim() !== '' && !isPossiblePhoneNumber(state.mobile)) {
            return false;
        }

        if (state.email.trim() !== '' && !EMAIL_REGEX.test(state.email)) {
            return false;
        }

    }
    return true
}

export const validatePurchaseRequestSubCategory = (state) => {
    if (state.subCategory.length === 0 || state.subCategory.length > 3) {
        ToastUtil.showErrorToast(toast.emptyCategory2);
        return false;
    }
    return true;
}

export const validateRating = (state) => {
    if (state.rate === 0) {
        ToastUtil.showErrorToast(toast.reviewCount);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.review);
        return false;
    }
    return true;
}

export const validatePurchaseRequestUserDetails = (state) => {
    if (state.firstName.trim() === '' || !Name_REGEX.test(state.firstName)) {
        ToastUtil.showErrorToast(toast.invalidName);
        return false;
    }
    if (state.lastName.trim() === '' || !Name_REGEX.test(state.lastName)) {
        ToastUtil.showErrorToast(toast.invalidLName);
        return false;
    }
    if (state.email.trim() === '' || !EMAIL_REGEX.test(state.email)) {
        ToastUtil.showErrorToast(toast.invalidEmail);
        return false;
    }
    if (state.mobile.trim() === '' || !isPossiblePhoneNumber(state.mobile)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.hotline.trim() !== '' && !isPossiblePhoneNumber(state.hotline)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.land.trim() !== '' && !isPossiblePhoneNumber(state.land)) {
        ToastUtil.showErrorToast(toast.invalidMobile);
        return false;
    }
    if (state.companyName.trim() === '') {
        ToastUtil.showErrorToast(toast.invalidCName);
        return false;
    }
    if (state.designation.trim() !== '' && !Name_REGEX.test(state.designation)) {
        ToastUtil.showErrorToast(toast.invalidDName);
        return false;
    }
    if (state.website.trim() !== '' && !WEB_SITE_REGEX.test(state.website)) {
        ToastUtil.showErrorToast(toast.invalidWeb);
        return false;
    }
    return true;
}

export const validateQuoteDetails = (state, update) => {
    if (state.quotePrice.trim() === '' || Name_REGEX.test(state.quotePrice)) {
        ToastUtil.showErrorToast(toast.quotePrice);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.descriptionError);
        return false;
    }
    if (!update && state.attachmentFile === undefined) {
        ToastUtil.showErrorToast(toast.attachmentError);
        return false;
    }
    return true
}

export const validateProductDetails = (state) => {
    if (state.image.trim() === '') {
        ToastUtil.showErrorToast(toast.pImage);
        return false;
    }
    if (state.productName.trim() === '') {
        ToastUtil.showErrorToast(toast.pName);
        return false;
    }
    if (state.description.trim() === '') {
        ToastUtil.showErrorToast(toast.pDescription);
        return false;
    }
    if (state.unitPrice.trim() === '' || Name_REGEX.test(state.unitPrice)) {
        ToastUtil.showErrorToast(toast.pPrice);
        return false;
    }

    // if (state.selectedNodes === undefined || state.selectedNodes.length === 0) {
    //     ToastUtil.showErrorToast(toast.pSubCategory);
    //     return false;
    // }

    if (state.category === undefined || state.category === null || state.category.length === 0) {
        ToastUtil.showErrorToast(toast.pSubCategory);
        return false;
    } else {
        let ok = false;
        state.category.map(result => {
            if(result!==-1) {
                ok = true;
            }
        })
        if(!ok) {
            ToastUtil.showErrorToast(toast.pSubCategory);
            return false;
        }
    }

    let valid = true;
    state.productAttributes.map(item => {
        if (item.key.trim() === '' || item.value.trim() === '') {
            valid = false
            ToastUtil.showErrorToast(toast.keyValue);
        }
    })
    return valid;
}


export const validateCardDetails = (state) => {
    if (state.nameOnTHeCard.trim() === '' || !Name_REGEX.test(state.nameOnTHeCard)) {
        ToastUtil.showErrorToast(toast.cardName);
        return false;
    }
    if (state.cardNumber.trim() === '' || !CARD_NUMBER.test(state.cardNumber)) {
        ToastUtil.showErrorToast(toast.cardNumber);
        return false;
    }
    if (state.expMonth.trim() === '' || !EXP_MONTH.test(state.expMonth)) {
        ToastUtil.showErrorToast(toast.cardExp);
        return false;
    }
    if (state.expYear.trim() === '' || !EXP_DATE.test(state.expYear)) {
        ToastUtil.showErrorToast(toast.cardExp);
        return false;
    }
    if (state.cvv.trim() === '' || !CVV.test(state.cvv)) {
        ToastUtil.showErrorToast(toast.cardCvv);
        return false;
    }
    return true;
}
export const validatePromoCode = (state) => {
    if (state.promo.trim() === '') {
        ToastUtil.showErrorToast(toast.promoCode);
        return false;
    }
    return true;
}
