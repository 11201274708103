import React, { Component } from 'react';
import './oauth.scss';
import strings from "../../locals/string.json";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import { validateLoginDetails } from "../../constants/validation";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as userActions from '../../store/domain/user/action';
import ForgotPWModal from "./forgotPWModal";
import constants, { __DEV__ } from "../../constants/constants";
import { loginUser, register } from "../../service/auth";
import qs from "qs";
import Cookies from "js-cookie";
import {createETUserRegDetails, createUser, removeCookiesValues} from "../../constants/commonFunc";
import { ToastUtil } from "../../constants/toastUtil";

const login = strings.login;


class Login extends Component {

    state = {
        modelOpen: false,
        username: __DEV__ ? 'udarap@ceyentra.com' : '',
        password: __DEV__ ? '12345678' : '',
        etUser: false
    }

    componentWillMount() {
        this.setState({
            etUser: this.props.location?.state?.error !== undefined &&
                this.props.location?.state?.error === 602
        })
         let user = JSON.parse(localStorage.getItem(constants.USER_OBJ))
        let uid = user !== null ? user.id : ""
        if(uid !== null && uid !== ""){
           this.logOUt();
        }else{
            if(this.props.location.state){
                ToastUtil.showErrorToast(login.loggedByAnotherAccount)
            }
        }
    }

    logOUt = async () => {
        await removeCookiesValues();
        localStorage.removeItem(constants.ACCESS_TOKEN);
        this.props.setAuth(undefined);
        this.props.setUser(null);
        this.props.setUserType(null)
        this.props.setLoginStatus(false);
        localStorage.removeItem(constants.USER_OBJ);
        window.location.href = routes.login
    }

    onLogin = () => {
        if (validateLoginDetails(this.state)) {
            const data = {
                username: this.state.username,
                password: this.state.password,
                grant_type: 'password'
            }
            loginUser(qs.stringify(data), this.state.etUser)
                .then(response => {
                    if (response.success) {
                        if (!this.state.etUser) {
                            Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                            localStorage.setItem(constants.ACCESS_TOKEN, response.access_token);
                            Cookies.set(constants.REFRESH_TOKEN, response.refresh_token);
                            localStorage.setItem(constants.REFRESH_TOKEN, response.refresh_token);
                            localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.user)));
                            this.props.setAuth(createUser(response.user));
                            this.props.setUserType(response.user.userType);
                            this.props.history.push(routes.home)
                            // this.props.location?.state?.fromReg ? this.props.history.push(routes.home) : this.props.history.goBack();
                            if (sessionStorage.getItem(constants.VIEW_TENDER) !== undefined) {
                                const tenderURL = sessionStorage.getItem(constants.VIEW_TENDER)
                                if (tenderURL === null) {
                                    this.props.history.push(routes.requests)
                                } else {
                                    this.props.history.push(tenderURL)
                                }
                            } else {
                                if (localStorage.getItem(constants.REDIRECT_TO_HOME) !== undefined && localStorage.getItem(constants.REDIRECT_TO_HOME) === "true") {
                                    this.props.history.push(routes.home)
                                } else {
                                    // this.props.history.goBack()
                                    this.props.history.push(routes.home)
                                }
                            }
                        } else {
                            this.register(response.user)
                        }
                    } else {
                        ToastUtil.showErrorToast(response.msg);
                    }
                })
        }
    }

    register = (user) => {
        register(createETUserRegDetails({ ...user, password: this.state.password, ...user.account }))
            .then(response => {
                if (response.success) {
                    this.setState({ etUser: false })
                    this.onLogin();
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    render() {

        return <div className="register-page-area pd-bottom-100 login">

            <div className="container">
                <div className="justify-content-center">
                    <h1 className="page-title">{login.welcome}</h1>
                    <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                        <div className="contact-form-wrap contact-form-bg form">
                            <h4>{login.login}</h4>
                            <div className="rld-single-input">
                                <input type="email" value={this.state.username}
                                    onChange={event => this.setState({ username: event.target.value })}
                                    placeholder={login.email}
                                    onKeyDown={e => e.keyCode === 13 && this.onLogin()}
                                />
                            </div>
                            <div className="rld-single-input">
                                <input type="password" value={this.state.password}
                                    onChange={event => this.setState({ password: event.target.value })}
                                    onKeyDown={e => e.keyCode === 13 && this.onLogin()}
                                    placeholder={login.password} />
                            </div>
                            <div className="btn-wrap">
                                <button className="btn btn-yellow loginBtn"
                                    onClick={this.onLogin}>{login.login}</button>
                                <p className="forgotPW"
                                    onClick={() => this.setState({ modelOpen: !this.state.modelOpen })}>{login.forgotPW}</p>
                            </div>
                            <p className="dont">{login.dontHave}
                                <p className="register">&nbsp;<Link to={routes.register}>{login.register}</Link></p>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <ForgotPWModal
                open={this.state.modelOpen}
                close={() => this.setState({ modelOpen: !this.state.modelOpen })}
            />
        </div>
    }
}

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
    setLoginStatus: data => dispatch(userActions.setLoginStatus(data)),
    setUser: data => dispatch(userActions.setUser(data)),
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
