import React, {Component} from 'react';
import {Assets} from "../../assets";
import VideoModal from '../globalComponents/videoModal';
import MyProfileUpdateModal from "../myProfile/myProfileUpdateMdal";

class IslandWild extends Component {

    state = {
        modalOpen: false,
    }

    render() {
        return <div className="Professisonal-area mt-5 ">
            <div className="container">
                <div className="row pd-bottom-70">
                    <div className="col-lg-6 offset-lg-1 order-lg-12 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            <img className="shadow-img" src={Assets.about1} alt="image"/>
                        </div>
                    </div>
                    <div className="col-lg-5 align-self-center order-lg-1">
                        <div className="section-title mb-0">

                            <h2 className="title inner-title">Island’s Only B to B <br/>Online Marketplace</h2>

                            <p>B2b.lk is Sri Lanka’s only ONLINE BUSINESS TO BUSINESS marketplace that connects buyers
                                and sellers to fulfil their business needs. Currently Sri Lanka has 125,000 businesses
                                and we are paving the way to bring all of them onto one online platform in order to
                                provide visibility to and create efficiency in the sourcing and procurement
                                function. </p>
                            <p>B2b.lk a subsidiary of eTenders.lk, Sri Lanka’s largest Online Tender Platform and the
                                winner of ICTA e-Swabhimani Award 2012 for Best Business and e-Commerce. We are in a
                                journey of transforming businesses in Sri Lanka by facilitating complicated business
                                operations through digitally enabled processes that are fast, convenient and cost
                                effective.
                            </p>
                        </div>
                    </div>
                </div>


                <div className="section-title mb-5">
                    <h2 className="title inner-title text-center">Watch Our Story</h2>
                </div>

                <div className="display-flex justify-content-center section-title pd-bottom-70 pointer"
                     onClick={() => this.setState({modalOpen: true})}
                     style={{width: '100%'}}>
                    <img className="shadow-img" width={'50%'} src={Assets.aboutUsVideo2} alt="image"/>
                </div>

                <VideoModal
                    open={this.state.modalOpen}
                    close={() => this.setState({modalOpen: false})}
                    videoId='RLZVONxJUFI'
                />
            </div>
        </div>
    }
}

export default IslandWild
