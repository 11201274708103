import React from "react";
import './requests.scss'
import { Assets } from "../../assets";
import strings from "../../locals/string.json";
import {dateDiff, getBase64, numberWithCommas, openWebSite,removeCookiesValues} from "../../constants/commonFunc";
import AddQuoteModal from "../quotation/addQuoteModal";
import constants, { QUOTATIONS_STATUS } from "../../constants/constants";
import ViewQuotationsModal from "./viewQuotationsModal";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    favRequest,
    getRequestDetails,
    getSubmittedQuotationsForRequest,
    updateRequestStatus
} from "../../service/request";
import { ToastUtil } from "../../constants/toastUtil";
import ReactPaginate from "react-paginate";
import { isBrowser } from "react-device-detect";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import swal from "sweetalert";
import routes from "../../constants/routes";
import * as userActions from "../../store/domain/user/action";

const pr = strings.purchaseRequest;
const modals = strings.modals;

class ViewRequest extends React.Component {

    state = {
        addQuoteModal: false,
        submittedQuotations: [],
        viewQuotationModal: false,
        quotation: undefined,
        fav: false,
        index: 0,
        size: 4,
        totalPages: 0,
        totalElements: 0,
        action: 'ALL',
        item: undefined,
        isNoData: false
    }

    componentWillMount() {
        const REQSTATE = this.props?.location?.state
        console.log("REQSTATE",REQSTATE)
        const user_obj = JSON.parse(localStorage.getItem(constants.USER_OBJ))
        const user_id = user_obj !== null ? user_obj.id : ""
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('uid')
        this.props.loggedInStatus ? this.getRequest() : ToastUtil.showErrorToast(modals.becomeASeller);
        if (!this.props.loggedInStatus) {
            sessionStorage.setItem(constants.VIEW_TENDER, this.props.history.location.pathname);
            this.props.history.push(routes.login)
        } else {
            sessionStorage.clear();
            if((id !== null && id !== "") && (user_id !== parseInt(id))){
                sessionStorage.setItem(constants.VIEW_TENDER, this.props.history.location.pathname);
                this.props.history.push({pathname:routes.login,state:true})
            }
        }
    }

    getRequest = () => {
        let url = this.props.match.params.url;
        getRequestDetails(url)
            .then(response => {
                if (response.success) {
                    let item = {
                        ...response.body,
                        owner: this.props?.location?.state?.owner ?? (this.props.match.path === routes.viewRequestQuotations + "/:url" ?? false) }
                    this.setState({
                        item: item,
                        fav: item.fav
                    })
                    item.attachment && getBase64(item.attachment);
                    this.getSubmittedQuotationsForRequest()
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onFilterBtn = async action => {
        await this.setState({ action });
        this.getSubmittedQuotationsForRequest();
    }

    getSubmittedQuotationsForRequest = () => {
        const item = this.state.item;
        if (item?.owner) {
            getSubmittedQuotationsForRequest(this.state.index, this.state.size, {
                requestId: item.id,
                status: this.state.action
            })
                .then(response => {
                    if (response.success) {
                        this.setState({
                            submittedQuotations: response.body.content,
                            totalPages: response.body.totalPages,
                            totalElements: response.body.totalElements,
                        })
                    }
                })
        }
    }

    onPageSelect = async data => {
        await this.setState({ index: data.selected });
        this.getSubmittedQuotationsForRequest();
    }

    onFav = (id) => {
        favRequest(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        fav: !this.state.fav
                    })
                } else {
                    ToastUtil.showSuccessToast(response.msg)
                }
            })
    }

    contact = () => {
        const item = this.props.location.state;
        const cc = item.buyer?.secondaryEmails !== null ? 'cc=' + item.buyer.secondaryEmails + '&' : '';
        window.open(`mailto:${item.buyer.email}?${cc}subject=${item.topic}`, '_blank')
    }

    onDelete = (id) => {
        swal({
            title: modals.areUSure,
            text: modals.delete,
            closeOnClickOutside: false,
            buttons: {
                success: { text: "Delete", value: "action", className: "swalSuccess" },
                dangerMood: { text: "Cancel", value: "cancel", className: "swalCancel" }
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        updateRequestStatus(id, 'DELETED')
                            .then(response => {
                                if (response.success) {
                                    this.props.history.goBack()
                                } else {
                                    ToastUtil.showErrorToast(response.msg)
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }
    onError(e) {
        console.log(e, 'error in file-viewer');
    }

    render() {
        const item = this.state.item;
        console.log("attachment",item)
        return item ? <div>
            <div className="register-page-area pd-bottom-100 view-request">
                <div className="container">
                    <img className="pointer mt-3" src={Assets.back} alt="back"
                        onClick={() => {
                            if (this.props?.location?.state.myReqState) {
                                this.props.history.push(routes.myRequests, { myReqState: this.props?.location?.state.myReqState })
                            } else if (this.props?.location?.state.allReqState) {
                                this.props.history.push(routes.requests, { allReqState: this.props?.location?.state.allReqState })
                            } else if (this.props?.location?.state.quotationsState) {
                                if (this.props?.location?.state.qType === "R") {
                                    this.props.history.push(routes.receivedQuotes, { quotationsState: this.props?.location?.state.quotationsState })
                                } else {
                                    this.props.history.push(routes.myQuotes, { quotationsState: this.props?.location?.state.quotationsState })
                                }
                            } else {
                                this.props.history.goBack()
                            }
                        }}
                    />

                    <div className="row">
                        <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                            <div className="refHolder">
                                <p className="ref"><b>Ref: {item.refNo}</b></p>

                                <div className="display-flex flex-row align-items-center">
                                    {
                                        // (!item.owner && this.props.userType === constants.USER_SELLER) || !item.myRequest &&
                                        !item.
                                            owner && this.props.userType === constants.USER_SELLER &&
                                        <p className="customSubmitBtn mr-3 pointer"
                                            onClick={() => this.setState({ addQuoteModal: !this.state.addQuoteModal })}>
                                            {isBrowser ? (item.submittedQuotations !== 0 ? 'Submit new Quotation' : 'Submit a Quotation') : 'Submit a Quote'}
                                        </p>
                                    }

                                    {/*{*/}
                                    {/*    item.owner &&*/}
                                    {/*    <>*/}
                                    {/*        <p className="editBtns deleteLabel" onClick={() => this.onDelete(item.id)}><i*/}
                                    {/*            className="fa fa-trash"/> Delete</p>*/}
                                    {/*        <p className="editBtns"*/}
                                    {/*           onClick={() => this.setState({updateRequestModal: !this.state.updateRequestModal})}>*/}
                                    {/*            <i*/}
                                    {/*                className="fa fa-edit"/> Edit*/}
                                    {/*        </p>*/}
                                    {/*    </>*/}
                                    {/*}*/}

                                    <i className={this.state.fav ? "fa fa-heart pointer" : "fa fa-heart-o pointer"}
                                        onClick={() => this.onFav(item.id)} />
                                </div>

                            </div>

                            {
                                item.owner && item.quotationCount !== 0 &&
                                <>
                                    <p className="title mt-4">{pr.submittedQuotations}</p>

                                    <div className="row custom-gutter mt-3">
                                        <div className="col-lg-12">
                                            <div className="property-filter-menu-wrap">
                                                <div className="property-filter-menu mb-0 portfolio-filter ">
                                                    <button
                                                        onClick={() => this.onFilterBtn('ALL')}
                                                        className={this.state.action === "ALL" ? "active" : ''}>All
                                                    </button>
                                                    {
                                                        QUOTATIONS_STATUS.map((item, index) => (
                                                            <button
                                                                key={index}
                                                                className={this.state.action === item.value ? "active" : ''}
                                                                onClick={() => this.onFilterBtn(item.value)}>{item.key}
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quotationHolder mt-3">
                                        {
                                            this.state.submittedQuotations !== null && this.state.submittedQuotations.map((quote, index) => (
                                                <div key={index} className="holder mb-3 pointer"
                                                    onClick={() => this.setState({
                                                        viewQuotationModal: !this.state.viewQuotationModal,
                                                        quotation: quote
                                                    })}>
                                                    <div className="detailsHolder">
                                                        <p className="title mt-0">{quote.seller.companyName}</p>
                                                        <p className="view">{
                                                            quote.buyerFollowupStatus === constants.AWARDED ?
                                                                <p className="awarded mr-2"><i
                                                                    className="fa fa-trophy mr-2" /> Awarded</p> :
                                                                quote.buyerFollowupStatus === constants.INTERESTED ?
                                                                    <i className="fa fa-star mr-2" /> :
                                                                    quote.buyerFollowupStatus === constants.NOT_INTERESTED ?
                                                                        <i className="fa fa-ban mr-2" /> : null
                                                        }View</p>
                                                    </div>
                                                    <div className="detailsHolder">
                                                        <p className="sub-title">Submitted: <span>{moment(item.publishedDate).format(constants.DATE_FORMAT_EXTEND)}</span>
                                                        </p>
                                                        <p className="price">{quote.quotePrice === 0 ? 'N/A' :
                                                            quote.currency + " " + numberWithCommas(quote.quotePrice.toFixed(2))}</p>
                                                    </div>

                                                </div>
                                            ))
                                        }

                                        <div className="display-flex justify-content-center" style={{ width: '100%' }}>
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.totalPages}
                                                forcePage={this.state.index}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={1}
                                                onPageChange={this.onPageSelect}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                    <div className="separator mt-5 mb-4" />
                                </>
                            }

                            {
                                // (!item.owner && item.submittedQuotations !== 0) || !item.myRequest &&
                                !item.owner && item.submittedQuotations !== 0 &&
                                <>
                                    <p className="title mt-4">Submitted Quotations</p>
                                    <div className="quotationHolder mt-3">
                                        {
                                            item.submittedQuotations !== null && item.submittedQuotations.map((quote, index) => (
                                                <div key={index} className="holder mb-3 pointer"
                                                    onClick={() => this.setState({
                                                        viewQuotationModal: !this.state.viewQuotationModal,
                                                        quotation: quote,
                                                        owner: item.owner
                                                    })}>
                                                    <div className="detailsHolder">
                                                        <p className="title mt-0">{quote.seller.companyName}</p>
                                                        <p className="view">{
                                                            quote.buyerFollowupStatus === constants.AWARDED ?
                                                                <p className="awarded mr-2"><i
                                                                    className="fa fa-trophy mr-2" /> Awarded</p> :
                                                                quote.buyerFollowupStatus === constants.INTERESTED ?
                                                                    <i className="fa fa-star mr-2" /> :
                                                                    quote.buyerFollowupStatus === constants.NOT_INTERESTED ?
                                                                        <i className="fa fa-ban mr-2" /> : null
                                                        }View</p>
                                                    </div>
                                                    <div className="detailsHolder">
                                                        <p className="sub-title">Submitted: <span>{moment.utc(item.submittedDate).local().format(constants.DATE_FORMAT_EXTEND)}</span>
                                                        </p>
                                                        <p className="price">{quote.quotePrice === 0 ? 'N/A' :
                                                            quote.currency + " " + numberWithCommas(quote.quotePrice.toFixed(2))}</p>
                                                    </div>

                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className="separator mt-5 mb-4" />
                                </>
                            }

                            <p className="title ">Request Summary</p>
                            <div className="p-3">
                                <p className="sub-title">{item.topic}</p>
                                <p className="headline mt-3">Estimated Purchase Value: <span
                                    className="c1">{item.estimatedValue === 0 ? 'N/A' :
                                        item.currency + " " + numberWithCommas(item.estimatedValue.toFixed(2))}</span></p>
                                <p className="headline">Quantity: <span className="c2">{item.qty}</span></p>
                                <p className="headline">Urgent Requirement: <span
                                    className="c2">{item.urgent ? 'Yes' : 'No'}</span></p>
                                <p className="headline">Category: <span className="c3">{
                                    item.category.map((item, index) => index === 0 ? item.name : ", " + item.name)
                                }</span></p>
                                <p className="headline">Sub Categories: <span className="c3">{
                                    item.subCategory.map((item, index) => index === 0 ? item.name : ", " + item.name)
                                }</span></p>

                                <div className="separator mt-3 mb-3" />

                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mt-2">
                                        <p className="headline">Published On: <span
                                            className="c2">{moment.utc(item.publishedDate).local().format(constants.DATE_FORMAT_EXTEND)}</span>
                                        </p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mt-2">
                                        <p className="headline">Closing On: <span
                                            className="c2">{moment.utc(item.closingDate).local().format(constants.DATE_FORMAT_EXTEND)}</span>
                                        </p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2">
                                        <p className="headline pl-2 pr-2" style={{
                                            background: dateDiff(item) <= 0 ? "rgba(255, 110, 99, 0.15)" :
                                                dateDiff(item) > 0 && dateDiff(item) <= 5 ? "rgba(243, 141, 16, 0.15)" : "rgba(33, 170, 104, 0.15)",
                                            color: dateDiff(item) <= 0 ? "var(--main-color-two)" :
                                                dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)",
                                            borderColor: dateDiff(item) <= 0 ? "var(--main-color-two)" :
                                                dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)",
                                            textAlign: 'center',
                                            // width: 'fit-content',
                                            borderRadius: 5,
                                            width: '100%',
                                            border: '1px solid'
                                        }}>{
                                                dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 0 ? '1 Day Remaining' : dateDiff(item) + " Days Remaining"
                                            }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="separator mb-3" />

                            <p className="title ">{pr.rDescription}</p>
                            <div className="p-3">
                                <p className="headline lineHeight"><span
                                    className="c2 lineHeight whiteSpacePre">{item.description}</span></p>
                            </div>

                            <div className="separator mt-3 mb-3" />

                            {
                                item.attachment !== null &&
                                <>
                                    <p className="title">{pr.attachment}</p>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 mt-3">
                                            {/*<button*/}
                                            {/*    onClick={() => window.open(item.attachment, '_blank')}*/}
                                            {/*    className="btn btn-white mr-2">{pr.view}</button>*/}

                                            <a className="btn btn-white"
                                                type="button"
                                                href=""
                                                id="downloadbtn"
                                                download={item.attachment.split(/(\\|\/)/g).pop()}>{pr.download}</a>
                                        </div>
                                    </div>

                                    {
                                        item.attachment.split(/\.(?=[^\.]+$)/)[1] !== "docx" ?
                                            <div className="col-12 mt-3">
                                                <DocViewer
                                                    pluginRenderers={DocViewerRenderers}
                                                    documents={[{ uri: item.attachment }]} />
                                            </div>
                                            : null
                                    }

                                    {/*{(item.attachment.split(/\.(?=[^\.]+$)/)[1] !== "docx" && (item.attachment.split(/\.(?=[^\.]+$)/)[1] === "png" || item.attachment.split(/\.(?=[^\.]+$)/)[1] === "jpg" || item.attachment.split(/\.(?=[^\.]+$)/)[1] === "jpeg"))? <div className="col-12 mt-5">*/}
                                    {/*    <img src={item.attachment} className={'img-fluid'}/>*/}
                                    {/*</div> : null}*/}

                                </>
                            }
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <div className="profileDetailsHolder">
                                <div className="companyDetails">
                                    <img className="logo"
                                        src={item.buyer.profilePicture !== null ? item.buyer.profilePicture : Assets.companyLogo}
                                        alt="img" />

                                    <p className="title">{item.buyer.companyName !== null ? item.buyer.companyName : "N/A"}
                                        {
                                            item.buyer?.verifiedSeller &&
                                            <img className="verified verifiedWithViewSupplier ml-2" src={Assets.badge}
                                                alt='img' />
                                        }
                                    </p>
                                </div>
                                <div className="separator mt-3" />

                                <div className="p-3">
                                    <p className="title">{pr.contactDetails}</p>

                                    <p className="headline mt-3"><i className="fa fa-user" /> &nbsp;&nbsp;{pr.buyerName}
                                    </p>
                                    <p className="contactDetails">{item.buyer.firstName !== null && item.buyer.lastName !== null ?
                                        item.buyer.firstName + " " + item.buyer.lastName : "N/A"}</p>

                                    <p className="headline mt-3"><i
                                        className="fa fa-map-marker" /> &nbsp;&nbsp;{pr.address}</p>
                                    <p className="contactDetails">{item.buyer?.address !== null ? item.buyer.address : "N/A"}</p>

                                    {
                                        (item.prefferedContactMethod === constants.TELEPHONE ||
                                            item.prefferedContactMethod === constants.ALL) &&
                                        <>
                                            <p className="headline mt-3"><i
                                                className="fa fa-mobile" /> &nbsp;&nbsp;{pr.mobile}
                                            </p>
                                            <p className="contactDetails">{item.buyer?.hotLine !== null ? item.buyer.hotLine : "N/A"}</p>

                                            <p className="headline mt-3"><i
                                                className="fa fa-fax" /> &nbsp;&nbsp;{pr.telephone}
                                            </p>
                                            <p className="contactDetails">{item.buyer?.landNumber !== null ? item.buyer.landNumber : "N/A"}</p>
                                        </>
                                    }
                                    {
                                        (item.prefferedContactMethod === constants.EMAIL ||
                                            item.prefferedContactMethod === constants.ALL) &&
                                        <>
                                            <p className="headline mt-3"><i
                                                className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}
                                            </p>
                                            <p className="contactDetails hideOverflow">{item.buyer?.email !== null ? item.buyer.email : "N/A"}</p>

                                            {
                                                item.buyer?.secondaryEmails !== null && item.buyer.secondaryEmails.split(',').map((email, index) => {
                                                    return <>
                                                        <p key={index} className="headline mt-3"><i
                                                            className="fa fa-envelope" /> &nbsp;&nbsp;{pr.email}</p>
                                                        <p className="contactDetails hideOverflow">{email}</p>
                                                    </>
                                                })
                                            }
                                        </>
                                    }


                                    <p className="headline mt-3"><i className="fa fa-globe" /> &nbsp;&nbsp;{pr.website}
                                    </p>
                                    <p className="contactDetails pointer"
                                        onClick={() => openWebSite(item.buyer?.website)}
                                    >{item.buyer?.website == null || item.buyer?.website == "null" ? "N/A" : item.buyer.website}</p>

                                    {
                                        !item.owner && (item.prefferedContactMethod === constants.EMAIL ||
                                            item.prefferedContactMethod === constants.ALL) &&
                                        <button className="btn btn-yellow mt-4"
                                            onClick={this.contact}>{pr.contact}</button>
                                    }

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddQuoteModal
                item={item}
                open={this.state.addQuoteModal}
                reload={this.getRequest}
                close={() => this.setState({ addQuoteModal: !this.state.addQuoteModal })}
            />

            <ViewQuotationsModal
                item={{ item, quotation: this.state.quotation }}
                open={this.state.viewQuotationModal}
                reload={this.getRequest}
                close={() => this.setState({ viewQuotationModal: !this.state.viewQuotationModal })}
            />

        </div> :
            <div className="display-flex flex-column  align-items-center"
                style={{ width: '100%' }}>
                {/*<img src={Assets.requestEmpty} alt="empty"/>*/}
            </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setLoginStatus: data => dispatch(userActions.setLoginStatus(data)),
    setUser: data => dispatch(userActions.setUser(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewRequest));
