import React, { Component } from 'react';
import { Assets } from "../../assets";

class Mission extends Component {


    render() {

        return <div className="mission-vission-area pd-top-100 pd-bottom-70">
            <div className="container">
                <div className="row justify-content-center">
                    <div className={"col-xl-6 col-lg-6 col-sm-6 mb-6 "}>
                        <div className="single-intro text-lg-left text-sm-center">
                            <div className="thumb">
                                <img src={Assets.target} alt={"image"} />
                            </div>
                            <div className="details">
                                <h4 className="title">Our Mission</h4>
                                <p>What if we told you that you could sit at your office and receive sales leads to your
                                    Laptop, Desktop or Mobile. Yes its that easy. B2b.lk will do this for you. Whenever
                                    there is a request for the product or service you sell on B2b.lk we will inform you
                                    immediately via SMS alert and email. This will help you get more business and more
                                    sales leads to your business. We will also be providing a micro webpage ie:
                                    www.b2b.lk/c/your-company-name where you can display Your Company Logo, About Us,
                                    Products, Contact Information, Year of Establishment, Number of Employees, Turnover
                                    of Company, Ownership Type, Major Markets, Quality Policy & Certification, Factory &
                                    Infrastructure, Trade/Chamber Memberships, Special Achievements and even Customer
                                    References. Best part is you get this for a very reasonable annual fee. Kindly
                                    contact us for package information.</p>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-6 col-lg-6 col-sm-6 mb-6 "}>
                        <div className="single-intro text-lg-left text-sm-center">
                            <div className="thumb">
                                <img src={Assets.vision} alt={"image"} />
                            </div>
                            <div className="details">
                                <h4 className="title">Our Vision</h4>
                                <p>Every office has to purchase goods and services for their day to day operation.
                                    Sometimes getting the right supplier or finding one on time is a hassle. Now, all
                                    you need to do is log on to B2b.lk and enter your requirement and click send. You
                                    will not only get immediate responses, but you will also be able to compare the
                                    pricing and benefits of each supplier and select the one, most suitable for you.
                                    Best Part is that it is ABSOLUTLY FREE.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-title mg-top-100 mb-5">
                <h2 className="title inner-title text-center">How It Works</h2>
            </div>

            <div className="display-flex justify-content-center section-title mb-5" style={{ width: '100%' }}>
                <img className="shadow-img" src={Assets.flow} alt="image" />
            </div>
        </div>


    }
}

export default Mission
