import React from "react";
import "./oauth.scss";

class SecondaryEmail extends React.Component {
    state = {
        items: [],
        value: "",
        error: null,
        emailCount: 2
    };

    componentWillMount() {
        this.setState({
            emailCount: this.props.emailCount,
            items: this.props.items ?? []
        })
    }

    handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            const value = this.state.value.trim();

            if (this.props.user.email.trim() !== value) {
                if (value && this.isValid(value) && this.props.emailCount >= (this.state.items.length + 1)) {
                    this.setState({
                        items: [...this.state.items, this.state.value],
                        value: ""
                    });
                    this.props.onChangeSecondaryEmail([...this.state.items, this.state.value]);
                } else {
                    this.setState({error: `Your can only add ${this.props.emailCount} email address${this.props.emailCount > 1 ? 'es' : ''}.`})
                }
            } else {
                this.setState({
                    ...this.state,
                    error: `${value} is your primary email address.`
                })
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleDelete = item => {
        this.setState({
            items: this.state.items.filter(i => i !== item)
        });
        this.props.onChangeSecondaryEmail(this.state.items.filter(i => i !== item));
    };

    handlePaste = evt => {
        evt.preventDefault();

        if (this.state.items.length >= this.state.emailCount) {
            return;
        }

        const paste = evt.clipboardData.getData("text");
        const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            const toBeAdded = emails.filter(email => !this.isInList(email));

            this.setState({
                items: [...this.state.items, ...toBeAdded]
            });
            this.props.onChangeSecondaryEmail([...this.state.items, ...toBeAdded]);
        }
    };

    isValid(email) {
        let error = null;

        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            this.setState({error});

            return false;
        }

        return true;
    }

    isInList(email) {
        return this.state.items.includes(email);
    }

    isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    render() {
        return (
            <div className="secondary">
                <div className={this.props.items ? "wrapper2" : 'wrapper'}>
                    {this.state.items.map(item => (
                        <div className="tag-item" key={item}>
                            {item}
                            <button
                                type="button"
                                className="button"
                                onClick={() => this.handleDelete(item)}
                            >
                                &times;
                            </button>
                        </div>
                    ))}

                    <input
                        className={"input " + (this.state.error && " has-error")}
                        value={this.state.value}
                        placeholder="Type or paste email addresses and press `Enter`"
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        onPaste={this.handlePaste}
                        onBlur={event => this.setState({value: ''})}
                    />

                    {this.state.error && <p className="error-label">{this.state.error}</p>}
                </div>

            </div>
        );
    }
}

export default SecondaryEmail;
