import React from "react";
import strings from "../../locals/string.json";
import { Assets } from "../../assets";


const registration = strings.registration;

export default (props) => {
    return <form className="contact-form-wrap contact-form-bg package">
        <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 padding15">
                {
                    props.state.packages.map((item, index) => {
                        if (index === 0) {
                            return (
                                <div
                                    onClick={() => props.onSelectSubscription(index, item)}>
                                    <div className="packageHolder">
                                        <img className="cardBg" src={Assets.cardBg1} alt={'check'} />

                                        <h6 className="title">{item.packageName}</h6>

                                        <h6 className="priceTag">{item.price !== 0 && "Rs"}
                                            <h6 className="price">{item.price === 0 ? 'Free' : item.price}</h6>
                                        </h6>

                                        <h6 className="priceTag">{item.validityDuration}</h6>
                                        <h6 className="userTag mt-4">{item.userCount} Email{item.userCount === 1 ? '' : 's'}</h6>
                                        <h6 className="userTag">{item.categoryCount} {item.categoryCount === 1 ? 'Category' : 'Categories'}</h6>
                                        {
                                            props.subscription_plan === index ?
                                                <button className="btn btn-yellow mb-4"
                                                    onClick={event => event.preventDefault()}>{registration.selected}
                                                    <img className="checkedImg" src={Assets.biCheck} alt={'check'} />
                                                </button> :
                                                <button className="btn btn-white mb-4"
                                                    onClick={event => event.preventDefault()}>{registration.selectPlan}
                                                </button>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div style={{ height: '55vh', overflow: 'scroll', overflowX: 'hidden' }}
                className="col-lg-9 col-md-12 col-sm-12">
                <div className="row">
                    {
                        props.state.packages.map((item, index) => {
                            if (index > 0) {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-12 padding15"
                                        onClick={() => props.onSelectSubscription(index, item)}>
                                        <div className="packageHolder">
                                            <img className="cardBg" src={Assets.cardBg1} alt={'check'} />

                                            <h6 className="titleMini">{item.packageName}</h6>

                                            <h6 className="priceTagMini">{item.price !== 0 && "Rs"}
                                                <h6 className="priceMini">{item.price === 0 ? 'Free' : item.price}</h6>
                                            </h6>

                                            <h6 className="priceTagMini">{item.validityDuration}</h6>
                                            <h6 className="userTagMini mt-2">{item.userCount} Email{item.userCount === 1 ? '' : 's'}</h6>
                                            <h6 className="userTagMini">{item.categoryCount === 69 ? "Unlimited" : item.categoryCount} {item.categoryCount === 1 ? 'Category' : 'Categories'}</h6>
                                            {
                                                props.subscription_plan === index ?
                                                    <button className="btn btn-yellow mb-4 btnMini"
                                                        onClick={event => event.preventDefault()}>{registration.selected}
                                                        <img className="checkedImg" src={Assets.biCheck} alt={'check'} />
                                                    </button> :
                                                    <button className="btn btn-white mb-4 btnMini"
                                                        onClick={event => event.preventDefault()}>{registration.selectPlan}
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    </form>
}



