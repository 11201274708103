import React, { Component } from 'react';
import './oauth.scss';
import strings from "../../locals/string.json";
import { Assets } from "../../assets";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import RegistrationModal from "./registrationModal";
import constants from "../../constants/constants";

const login = strings.login;

class Register extends Component {

    state = {
        modelOpen: false,
        userType: undefined,
        subscription_plan: 0,
        becomeSeller: undefined
    }

    componentWillMount() {
        const becomeSeller = this.props.location.state?.becomeSeller;
        const subscription_plan = this.props.location.state?.subscription_plan;
        if (becomeSeller) this.setState({ modelOpen: true, userType: constants.USER_SELLER, subscription_plan, becomeSeller })
    }

    onClickRegister = (type) => {
        this.setState({
            modelOpen: !this.state.modelOpen,
            userType: type
        })
    }

    render() {
        return <div className="register-page-area pd-bottom-100 register">
            <div className="container">
                <div className="justify-content-center">
                    <h1 className="page-title">{login.createAcc}</h1>
                    <h1 className="page-subTitle">{login.fewSteps}</h1>
                    <div className="col-12 form">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 regTile">
                                <img className="regImg" src={Assets.regSeller} alt="seller" />
                                <p className="regTitle">{login.purchaseAndSell}</p>
                                <p className="regSubTitle">{login.goodsWith}&nbsp;<p>{login.fullAccess}</p></p>
                                <button className="btn btn-yellow loginBtn" onClick={() => this.onClickRegister(constants.USER_SELLER)}>{login.register}</button>
                            </div>
                            <div className="col-md-6 col-sm-12 regTile">
                                <img className="regImg" src={Assets.regBuyer} alt="buyer" />
                                <p className="regTitle">{login.purchase}</p>
                                <p className="regSubTitle">{login.goodsAs}&nbsp;<p>{login.buyer}</p></p>
                                <button className="btn btn-yellow loginBtn" onClick={() => this.onClickRegister(constants.USER_BUYER)}>{login.register}</button>
                            </div>
                            <div className="lineSeparator" />
                            <p className="or">or</p>
                        </div>
                    </div>

                    <p className="dont">{login.alreadyReg}
                        <p className="register" onClick={() => this.props.history.push(routes.login, { fromReg: true })}>&nbsp;{login.login}</p>
                    </p>
                </div>
            </div>

            <RegistrationModal
                userType={this.state.userType}
                subscription_plan={this.state.subscription_plan}
                becomeSeller={this.state.becomeSeller}
                open={this.state.modelOpen}
                history={this.props.history}
                close={() => this.setState({ modelOpen: !this.state.modelOpen })}
            />

        </div>
    }
}

export default Register
