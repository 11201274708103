import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import SignIn from "../components/oauth/login";
const Login = (props) => {
    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    return <div>
        <Navbar />
        <Banner headertitle="Sign In"/>
        <SignIn history={props.history}/>
        <Footer />
    </div>
}

export default Login

