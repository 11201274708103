import React from "react";
import {isBrowser} from "react-device-detect";
import {Assets} from "../../assets";
import Filter from "../filter/filter";
import Products from "../products/products";
import strings from "../../locals/string.json";
import {ToastUtil} from "../../constants/toastUtil";
import ReactPaginate from "react-paginate";
import {getSupplierProducts} from "../../service/suppliers";

const pr = strings.purchaseRequest;

class SupplierProduct extends React.Component {

    state = {
        products: [],
        index: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        search: '',
        selectedSubCategory: null,
        isNoData: false
    }

    componentWillMount() {
        this.getSupplierProducts();
    }

    getSupplierProducts = () => {
        getSupplierProducts(this.state.index, this.state.size, {
            sellerId: this.props.item.id,
            name: this.state.search,
            category: null,
            subCategory: this.state.selectedSubCategory,
        }).then(response => {
            if (response.success) {
                this.setState({
                    products: response.body.content,
                    totalPages: response.body.totalPages,
                    totalElements: response.body.totalElements,
                    isNoData: response.body.content.length === 0
                })
            } else {
                this.setState({isNoData: true})
                ToastUtil.showErrorToast(response.msg);
            }
        })
    }

    onFilterItemChange = async (name, value) => {
        await this.setState({[name]: value});
        this.getSupplierProducts();
    }

    onPageSelect = async data => {
        await this.setState({index: data.selected});
        this.getSupplierProducts();
    }

    onClear = async () => {
        await this.setState({
            selectedSubCategory: null,
            location: null,
            search: ''
        });
        this.getSupplierProducts();
    }

    render() {
        return <div>
            <>
                <div className="row mt-3">
                    <div className="col-xl-10 col-lg-10 col-md-9">
                        <div className="rld-single-input left-icon">
                            <input
                                onKeyDown={e => e.key === 'Enter' && this.getSupplierProducts()}
                                onChange={event => this.setState({search: event.target.value})}
                                type="text" placeholder={pr.searchPH3} value={this.state.search}/>
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3">
                        <button type={"button"}
                                onClick={this.getSupplierProducts}
                                className="btn btn-yellow searchBtn">{pr.findNow}</button>
                    </div>
                </div>

                <div className="row justify-content-center mg-top-40 ">
                    <div className="display-flex justify-content-end" style={{width: '95%'}}>
                        {
                            !isBrowser ? this.state.advance ?
                                <h6 className="advanceFilter"
                                    onClick={() => this.setState({advance: !this.state.advance})}>{pr.hide}</h6> :
                                <img className="advanceFilter" src={Assets.advFilter}
                                     alt="advance filter"
                                     onClick={() => this.setState({advance: !this.state.advance})}/> : null
                        }
                    </div>

                    <Filter
                        category={true}
                        supplier={this.props.supplier}
                        location={false}
                        quoteStatus={false}
                        publishedDate={false}
                        closingDate={false}
                        advance={this.state.advance}
                        onClear={this.onClear}
                        onFilterItemChange={this.onFilterItemChange}
                    />

                    <div className={"col-xl-9 col-lg-8"}>
                        <div className="row ">
                            <div className="col-12 mb-3">
                                <h6 className="filter-title mb-lg-0">{this.state.totalElements !== 0 && this.state.totalElements + " Products"}&nbsp;</h6>
                            </div>
                        </div>
                        <div className="row popularSupplier mt-2">

                            {this.state.products.map((item, i) =>
                                <Products
                                    key={i}
                                    filter={true}
                                    history={this.props.history}
                                    loggedInStatus={this.props.loggedInStatus}
                                    item={item}
                                />
                            )}
                            {
                                this.state.isNoData && this.state.products.length === 0 &&
                                <div className="display-flex flex-column  align-items-center" style={{width: '100%'}}>
                                    <img src={Assets.productEmpty} alt="empty"/>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="display-flex justify-content-center" style={{width: '100%'}}>
                    {
                        this.state.products.length !== 0 &&
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.totalPages}
                            forcePage={this.state.index}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.onPageSelect}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    }

                </div>

            </>
        </div>
    }
}

export default SupplierProduct;
