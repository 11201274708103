import React from "react";
import './supplier.scss'
import { Assets } from "../../assets";
import Products from "../products/products";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import RateModal from "./rateModal";
import SupplierProduct from "./supplierProduct";
import SupplierReview from './supplierReview'
import About from './about'
import routes from "../../constants/routes";

class ViewRequest extends React.Component {

    state = {
        action: 'Products',
        modalOpen: false,
    }

    render() {
        const item = this.props.supplier;
        return <div>
            <div className="register-page-area pd-bottom-100 view-supplier">
                <div className="container">
                    <img className="pointer mt-3" src={Assets.back} alt="back"
                        onClick={() =>  {
                            if (this.props.location.state !== undefined) {
                                this.props.history.push(routes.suppliers, { currentState: this.props.location.state.currentState })
                            } else {
                                this.props.history.push(routes.suppliers)
                            }
                        }
                        } />

                    {
                        item === undefined ?
                            <div className="display-flex flex-column align-items-center"
                                style={{ width: '100%' }}>
                                <img src={Assets.supplierEmpty} alt="empty" />
                            </div> :
                            <div className="row">
                                <div className="col-12">
                                    <div className="property-filter-menu-wrap mt-3">
                                        <div className="property-filter-menu mb-0 portfolio-filter ">
                                            <button
                                                onClick={() => this.setState({ action: 'Products' })}
                                                className={this.state.action === "Products" ? "active" : ''}>Products
                                            </button>
                                            <button
                                                className={this.state.action === "Review" ? "active" : ''}
                                                onClick={() => this.setState({ action: 'Review' })}>Reviews
                                            </button>
                                            <button
                                                className={this.state.action === "About" ? "active" : ''}
                                                onClick={() => this.setState({ action: 'About' })}>About
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        this.state.action === "Products" &&
                                        <SupplierProduct item={item} supplier={this.props.supplier} history={this.props.history} />
                                    }

                                    {
                                        this.state.action === 'About' &&
                                        <About
                                            modalOpen={() => this.setState({ modalOpen: !this.state.modalOpen })}
                                            item={item} />
                                    }

                                    {
                                        this.state.action === 'Review' &&
                                        <SupplierReview
                                            modalOpen={() => this.setState({ modalOpen: !this.state.modalOpen })}
                                            item={item} />
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>

            <RateModal
                open={this.state.modalOpen}
                reload={() => {
                    this.setState({ action: 'Products' })
                    this.props.reload();
                }}
                item={item}
                close={() => this.setState({ modalOpen: !this.state.modalOpen })} />
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(ViewRequest));
