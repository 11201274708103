import React from "react";
import constants from "../../constants/constants";
import {getMySubscriptionData} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";
import moment from "moment";
import {Assets} from "../../assets";
import StepThree from '../oauth/registrationStep03';
import {
    get_company_types,
    get_packages_from_company_type
} from "../../service/auth";
import ChangeSubscriptionModal from './changeSubscriptionModal';
import RenewSubscriptionModal from './renewSubscriptionModal';

class MySubscription extends React.Component {

    state = {
        subscriptionData: undefined,
        updatePcakage: false,
        userType: constants.USER_SELLER,
        modelOpen: true,
        package: [],
        renewModal: false
    }

    componentWillMount() {
        getMySubscriptionData()
            .then(response => {
                if (response.success) {
                    this.setState({
                        subscriptionData: response.body,
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    updatePackageHandler = async (val) => {
        await this.setState({updatePcakage: val})
    }

    renewPackageHandler = async (val) => {
        await this.setState({renewModal: val})
    }

    loadHandler = (value) => {
        if (value) {
            getMySubscriptionData()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            subscriptionData: response.body,
                        })
                    } else {
                        ToastUtil.showErrorToast(response.msg);
                    }
                })
        }
    }

    render() {
        const item = this.state.subscriptionData;
        const userType = "SELLER"
        return <div className="mySubscription mt-4 p-4">
            <p className="subDetailTopic">Subscription Details</p>
            {
                this.state.subscriptionData ?
                    <div className="row">

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12  mt-3">
                            <div className="subscriptionHolder">
                                <h6 className="title">{item.subscriptionPlan.packageName}</h6>

                                <h6 className="priceTag">{item.subscriptionPlan.price !== 0 && "Rs"}
                                    <h6 className="price">{item.subscriptionPlan.price === 0 ? 'Free' : item.subscriptionPlan.price}</h6>
                                </h6>
                                <h6 className="priceTag">{item.subscriptionPlan.validityDuration}</h6>
                                <h6 className="userTag mt-4">{this.props.user.emailCount} Email{this.props.user.emailCount > 1 ? 's' : ''}</h6>
                                <h6 className="userTag">{this.props.user.categoryCount} {this.props.user.categoryCount > 1 ? 'Categories' : 'Category'}</h6>
                                {
                                    item.subscriptionPlan.price === 0 ?
                                        <>
                                            <button onClick={() => this.updatePackageHandler(true)}
                                                    className="btn btn-yellow">Upgrade Package
                                            </button>
                                            <h6 className="userTag renewPayment m-3"></h6>
                                        </>
                                        :
                                        <>
                                            <button onClick={() => this.renewPackageHandler(true)}
                                                    className="btn btn-yellow">Renew Package
                                            </button>
                                            <h6 onClick={() => this.updatePackageHandler(true)}
                                                className="userTag renewPayment m-3">Change Package</h6>
                                        </>
                                }


                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12  mt-3">
                            <p className="topic">Valid Till
                                <p className="validity">: {moment(item.expiryDate).format(constants.DATE_FORMAT_EXTEND)}</p>
                            </p>

                            {
                                item.paymentHistory.length !== 0 &&
                                <>
                                    <p className="topic">Payment History</p>
                                    {
                                        item.paymentHistory.map((item, index) => (
                                            <div className="row" key={index}>
                                                <div
                                                    className="col-xl-3 col-lg-4 col-md-12 col-sm-12">{moment(item.paymentDate).format(constants.DATE_FORMAT_EXTEND)}</div>
                                                <div
                                                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12">{item.paymentMethod + " " + item.paymentType} PAYMENT
                                                </div>
                                                <div
                                                    className="col-xl-3 col-lg-4 col-md-12 col-sm-12 text-right">Rs. {item.amount.toFixed(2)}</div>
                                                <div className="separator m-2"/>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div> :
                    <div className="display-flex flex-column  align-items-center"
                         style={{width: '100%'}}>
                        <img src={Assets.paymentEmpty} alt="empty"/>
                    </div>
            }

            {
                this.state.updatePcakage &&
                <ChangeSubscriptionModal
                    userType={this.state.userType}
                    subscription_plan={this.state.subscriptionData}
                    // becomeSeller={this.state.becomeSeller}
                    open={this.state.updatePcakage}
                    loadHandler={this.loadHandler}
                    // history={this.props.history}
                    close={() => this.setState({updatePcakage: false})}
                />
            }

            {
                this.state.renewModal &&
                <RenewSubscriptionModal
                    state={this.state}
                    userType={this.state.userType}
                    subscription_plan={this.state.subscriptionData}
                    // becomeSeller={this.state.becomeSeller}
                    open={this.state.renewModal}
                    loadHandler={this.loadHandler}
                    // history={this.props.history}
                    close={() => this.setState({renewModal: false})}
                    props={this.props}
                />
            }

        </div>
    }
}

export default MySubscription;
