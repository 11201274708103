import React, {Component} from 'react';
import strings from '../../locals/string.json';
import {isBrowser} from "react-device-detect";
import {Assets} from "../../assets";
import PurchaseRequestModal from "../purchaseRequest/purchaseRequestModal";
import Statistics from "../statistics/statistics";
import '../globalComponents/globalComponents.scss'
import routes from "../../constants/routes";
import {ToastUtil} from "../../constants/toastUtil";

const landing = strings.landing;

class Banner extends Component {

    state = {
        modelOpen: false,
        title: '',
        searchText: ''
    }

    searchHandler = () => {
        let text = this.state.searchText.trim()
        if (text !== '') {
            this.props.history.push({
                pathname: routes.products,
                state: {
                    isEnable: true,
                    data: text
                }
            })
        } else {
            ToastUtil.showErrorToast('Type something');
        }
    }

    onEnterHandler = (e) => {
        if (e.keyCode === 13) {
            this.searchHandler()
        }
    }

    render() {
        const inlineStyle = {
            backgroundImage: `url(${Assets.landing})`,
            minHeight: 600
        }

        return <div className="banner-area jarallax " style={inlineStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-inner-wrap">
                            <div className="landing-banner banner-inner">
                                <span className={'first-title'}>Tell us your purchase requirements.</span>
                                <span className={'second-title'}>We will find you the right seller.</span>
                                <span className={'title-paragraph'}>"If you have any specific products or services that you are looking to buy, you can submit your purchase requirement with all the details to us. The sellers will directly contact you with their quotes"</span>
                                <div className="rld-banner-search search-field-mobile">
                                    <div className="rld-single-input">
                                        <input type="text"
                                               value={this.state.title}
                                               onChange={event => this.setState({title: event.target.value})}
                                               placeholder={isBrowser ? landing.webInputPlaceholder : landing.mobileInputPlaceholder}/>
                                        <button className="custom-quote-btn btn-home3 realdealtop"
                                                onClick={() => this.setState({modelOpen: true})}>{isBrowser ? landing.webGetQuotes : landing.mobileGetQuotes}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="banner-inner-wrap-new">
                            <div className="landing-banner banner-inner">
                                <span className={'first-title'}>Browse through the listed Products</span>
                                <span className={'second-title'}>Find what you want then and there.</span>
                                <span className={'title-paragraph'}>“Find the product or service you are interested in, among our listed products and services, and send your product inquiries to the sellers. They will tap at your door in no time with their quotes”</span>
                                <div className="rld-banner-search search-field-mobile">
                                    <div className="rld-single-input">
                                        <input type="text"
                                               value={this.state.searchText}
                                               onChange={event => this.setState({searchText: event.target.value})}
                                               placeholder={isBrowser ? landing.webSearchInputPlaceholder : landing.mobileSearchInputPlaceholder}
                                               onKeyDown={this.onEnterHandler}
                                        />
                                        <button className="custom-quote-btn btn-home3 realdealtop"
                                                onClick={this.searchHandler}>{landing.search}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'row banner-search-field'}>
                    <div className="col-lg-6 col-md-12">

                        <div className="rld-banner-search">
                            <div className="rld-single-input">
                                <input type="text"
                                       value={this.state.title}
                                       onChange={event => this.setState({title: event.target.value})}
                                       placeholder={isBrowser ? landing.webInputPlaceholder : landing.mobileInputPlaceholder}/>
                                <button className="custom-quote-btn btn-home3 realdealtop"
                                        onClick={() => this.setState({modelOpen: true})}>{isBrowser ? landing.webGetQuotes : landing.mobileGetQuotes}</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-12">

                        <div className="rld-banner-search">
                            <div className="rld-single-input">
                                <input type="text"
                                       value={this.state.searchText}
                                       onChange={event => this.setState({searchText: event.target.value})}
                                       placeholder={isBrowser ? landing.webSearchInputPlaceholder : landing.mobileSearchInputPlaceholder}
                                       onKeyDown={this.onEnterHandler}
                                />
                                <button className="custom-quote-btn btn-home3 realdealtop"
                                        onClick={this.searchHandler}>{landing.search}</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {/*<Statistics/>*/}
            {/*<div className="shading"/>*/}
            {/*<div className="shading2"/>*/}
            {/*<div className="shading3"/>*/}

            <PurchaseRequestModal
                title={this.state.title}
                open={this.state.modelOpen}
                close={() => this.setState({
                    title: '',
                    modelOpen: !this.state.modelOpen
                })}
            />

        </div>
    }
}

export default Banner
