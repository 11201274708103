import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../globalComponents/globalComponents.scss';
import ProductViewModal from './productViewModal';
import ProductUpdateModal from './productUpdateModal';
import { checkLoginStatus, numberWithCommas } from "../../constants/commonFunc";
import routes from "../../constants/routes";

export default (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [inquire, setInquire] = useState(false);
    const item = props.item;
    const maxTimeLimit = 150;
    let downTime = 0;



    return item.own ?
        <div className={"col-12 myProduct"}>
            <div key={props.key}
                className={"single-feature style-two supplierHolder"}>
                <div className="thumb" onClick={() => setModalOpen(!modalOpen)} style={{height: '100% !important'}}>
                    <img src={item.image} alt="img" />
                </div>
                <div className="details pb-5">
                    <h6 className="title readeal-top"><Link>{item.productName}</Link></h6>
                    <span className={'product-category-title'}>{item.subCategory.name}</span>
                    <ul className="info-list" onClick={() => setModalOpen(!modalOpen)}>
                        <li className="mt-1"><b style={{ fontWeight: 600 }}>Unit Price </b>
                            {item.unitPrice === 0 ? 'N/A' : item.currency + " " + numberWithCommas(item.unitPrice.toFixed(2))}

                        </li>
                        <li className="description mt-2">{item.description}</li>

                    </ul>
                    <div className="separator mt-2 mb-2" />

                    <div className="actionHolder-products">
                        <p className="actionBtn deleteLabel" onClick={props.onDelete}><i
                            className="fa fa-trash " /> Delete</p>
                        <p className="actionBtn" onClick={() => setModalOpen(!modalOpen)}><i
                            className="fa fa-edit" /> View & Edit
                        </p>
                    </div>
                </div>
            </div>
            <ProductUpdateModal
                item={item}
                open={modalOpen}
                reload={props.reload}
                close={() => setModalOpen(!modalOpen)}
            />
        </div> :

        <div key={props.key} className={props.filter ? "col-xl-4 col-lg-4 col-sm-6 comXS" : ""}>
            <div className="single-feature supplierHolder"
                onMouseDown={() => downTime = new Date().getTime()}
                onTouchStart={() => downTime = new Date().getTime()}
                onClick={() => {
                    // const time = new Date().getTime() - downTime;
                    // if (time < maxTimeLimit)
                    //     props.history.push(routes.products + `/${item.productUrl}`)
                    props.history.push(routes.products + `/${item.productUrl}`,{ item: item, currentStatus: props.currentStatus })
                }}>
                <div className="thumb thumb-c1">
                    <img className="product-c1 objectFit" src={item.image} alt="img" />
                    {/*<img className="product-c1" src={item.image} alt="img" />*/}
                </div>
                <div className="details pl-3 pr-3 pt-3 pb-3">
                    {/* // change category name to sub category // */}
                    <span className="product-category-title">{item.subCategory.name}</span>
                    <h6 className="title readeal-top latest-tender-title"><Link>{item.productName}</Link></h6>
                    <ul className="info-list">
                        <li className="mt-1"><b style={{ fontWeight: 600 }}>Unit Price </b>
                            {item.unitPrice === 0 ? 'N/A' : item.currency + " " + numberWithCommas(item.unitPrice.toFixed(2))}
                        </li>
                    </ul>
                    <div className="separator mt-2 mb-2" />
                    <h6 className="company mt-2 readeal-top"><Link>{item.seller.companyName}</Link></h6>
                    <h6 className="company readeal-top"><i className="fa fa-map-marker" style={{ color: 'var(--blue)' }} /> <Link>{item.seller.location !== null ? item.seller.location : "N/A"}</Link></h6>

                </div>
            </div>

            <ProductViewModal
                item={item}
                loggedInStatus={props.loggedInStatus}
                open={modalOpen}
                inquire={inquire}
                close={() => setModalOpen(!modalOpen)}
            />

        </div>
}
