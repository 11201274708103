import React from "react";
import '../purchaseRequest/requests.scss'
import { Assets } from "../../assets";
import { QUOTATIONS_STATUS_SELLER } from "../../constants/constants";
import strings from "../../locals/string.json";
import QuotationItem from "./quotationItem";
import ReactPaginate from "react-paginate";
import PurchaseRequestModal from "../purchaseRequest/purchaseRequestModal";
import { isBrowser } from "react-device-detect";
import Filter from "../filter/filter";
import { getMyQuotations } from "../../service/quotation";
import { ToastUtil } from "../../constants/toastUtil";
import * as common from "../../constants/commonFunc";
import moment from "moment";

const pr = strings.purchaseRequest;

class ViewAllQuotations extends React.Component {

    state = {
        advance: false,
        modelOpen: false,
        quotations: [],
        purchaseRequestModal: false,
        action: "ALL",
        search: '',
        publishedDate: null,
        closingDate: null,
        index: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        isNoData: false,
        type: "S"
    }

    componentDidMount(){
        this.checkDataHandler();
    }

    checkDataHandler = async () => {
        const stateData = this.props?.stateData
        if (stateData) {
            await this.setState({...stateData})
        }
        let qType = this.props.qtype ? this.props.qtype : "S"
        await this.setState({type:this.props.qtype ?? "S"})
        await this.getQuotations(qType);
    }


    getQuotations = async (qType) => {
        let publishedDate = null
        let closingDate = null
        if (this.state.publishedDate !== null && this.state.closingDate !== null) {
            let dates = await common.getConvertedUTCDateTIme(this.state.publishedDate, this.state.closingDate)
            publishedDate = Number(moment(dates.dateStart))
            closingDate = Number(moment(dates.dateEnd))
        }

        getMyQuotations(this.state.index, this.state.size, {
            status: this.state.action,
            requestName: this.state.search === "" ? null : this.state.search,
            publishedDate: publishedDate,
            endDate: closingDate
        },qType ?? this.state.type).then(response => {
            if (response.success) {
                this.setState({
                    quotations: response.body.content,
                    totalPages: response.body.totalPages,
                    totalElements: response.body.totalElements,
                    isNoData: response.body.content.length === 0 ? true : false
                })

                document.querySelector('body').scrollTo(0,0)
            } else {
                this.setState({
                    isNoData: true
                })
                ToastUtil.showErrorToast(response.msg);
            }
        })
    }

    onPageSelect = async data => {
        await this.setState({ index: data.selected });
        this.getQuotations();
    }

    onFilterBtn = async action => {
        await this.setState({ action });
        this.getQuotations();
    }

    onFilterItemChange = async (name, value1, value2) => {
        this.setState({
            ...this.state,
            publishedDate: value1,
            closingDate: value2,
        })
        this.getQuotations();
    }

    onClear = async () => {
        await this.setState({
            publishedDate: null,
            closingDate: null,
            search: ''
        });
        this.getQuotations();
    }

    render() {

        return <div className="main-search-area requests">
            <div className="container">

                <div className="banner-search-wrap">
                    <ul className="banner-button nav nav-tabs rld-banner-tab">
                        <li className="nav-item">
                            <a
                                onClick={() => this.setState({ purchaseRequestModal: !this.state.purchaseRequestModal })}
                                className="free-quotes-btn active" data-toggle="tab">{pr.getFreeQuotes}</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="tabs_1">
                            <div className="product-search-container">
                                <div className="row">
                                    <div className="titleHolder col-md-3 mb-2">
                                        <h8 className="searchTitle-new">{this.props.qtype === "R" ? pr.findReceivedQuotes : pr.findQuotations}</h8>
                                    </div>

                                    <div className="col-xl-7 col-lg-7 col-md-6">
                                        <div className="rld-single-input left-icon">
                                            <input
                                                value={this.state.search}
                                                onKeyDown={e => e.key === 'Enter' && this.getQuotations()}
                                                onChange={event => this.setState({ search: event.target.value })}
                                                type="text" placeholder={pr.searchPH2} />
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12" align={'center'}>
                                        <button type={"button"}
                                            onClick={this.getQuotations}
                                            className="btn btn-yellow searchBtn">{pr.findNow}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mg-top-40">
                        <div className="display-flex justify-content-end" style={{ width: '95%' }}>
                            {
                                !isBrowser ? this.state.advance ?
                                    <h6 className="advanceFilter"
                                        onClick={() => this.setState({ advance: !this.state.advance })}>{pr.hide}</h6> :
                                    <img className="advanceFilter" src={Assets.advFilter}
                                        alt="advance filter"
                                        onClick={() => this.setState({ advance: !this.state.advance })} /> : null
                            }
                        </div>

                        <Filter
                            category={false}
                            isLocation={false}
                            quoteStatus={false}
                            publishedDate={true}
                            closingDate={false}
                            advance={this.state.advance}
                            onClear={this.onClear}
                            onFilterItemChange={this.onFilterItemChange}
                        />
                        <div className="col-xl-8 col-lg-8">
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <div className="row custom-gutter mt-3">
                                        <div className="col-xl-9 col-lg-10 col-md-9 col-sm-9 col-xs-12">
                                            <div className="property-filter-menu-wrap">
                                                <div className="property-filter-menu mb-0 portfolio-filter ">
                                                    <button
                                                        onClick={() => this.onFilterBtn('ALL')}
                                                        className={this.state.action === "ALL" ? "active" : ''}>ALL
                                                    </button>
                                                    {
                                                        QUOTATIONS_STATUS_SELLER.map((item, index) => (
                                                            <button
                                                                key={index}
                                                                className={this.state.action === item.value ? "active" : ''}
                                                                onClick={() => this.onFilterBtn(item.value)}>{item.key}
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={'col-xl-3 col-lg-2 col-md-3 col-sm-3 col-xs-12 text-right mt-3'}>
                                            <h6 className="filter-title mb-lg-0">{this.state.totalElements !== 0 && this.state.totalElements + " QUOTATIONS"}&nbsp;</h6>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.quotations.map((item, index) => (
                                        <div className="col-md-12 col-sm-12">
                                            <QuotationItem
                                                key={index}
                                                qtype={this.props.qtype}
                                                quotationsState={this.state}
                                                history={this.props.history}
                                                reload={this.getQuotations}
                                                item={item} />
                                        </div>
                                    ))
                                }
                                {
                                    this.state.quotations.length === 0 && this.state.isNoData &&
                                    <div className="display-flex flex-column  align-items-center"
                                        style={{ width: '100%' }}>
                                        <img src={Assets.quotationEmpty} alt="empty" />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <div className="display-flex justify-content-center" style={{ width: '100%' }}>
                    {
                        this.state.quotations.length !== 0 &&
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.totalPages}
                            forcePage={this.state.index}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.onPageSelect}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    }
                </div>
            </div>

            <PurchaseRequestModal
                title={''}
                open={this.state.purchaseRequestModal}
                close={() => this.setState({ purchaseRequestModal: !this.state.purchaseRequestModal })}
            />

        </div>
    }
}

export default ViewAllQuotations;
