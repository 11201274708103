import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import AllRequests from "../components/purchaseRequest/allRequests";
import routes from "../constants/routes";

const Requests = (props) => {

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    const own = props.location.pathname === routes.myRequests
    return <div>
        <Navbar/>
        <Banner headertitle={own ? "My Requests" : "Requests"} type={'purchase'}/>
        <AllRequests history={props.history} myRequest={own}/>
        <Footer/>
    </div>
}

export default Requests
