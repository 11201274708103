import React, {useEffect, useState} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner2';
import ViewSupplierDetails from '../components/suppliers/viewSupplier';
import {getSelectedSupplier} from "../service/suppliers";
import {ToastUtil} from "../constants/toastUtil";

const ViewSupplier = (props) => {

    const [supplier, setSupplier] = useState(undefined);

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0);
        getSupplierDetails();
    },[])

    const getSupplierDetails = () => {
        let company_name = props.match.params.company_name;
        getSelectedSupplier(company_name)
            .then(response => {
                if (response.success) {
                    setSupplier(response.body);
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    return <div>
        <Navbar/>
        <Banner supplier={supplier}/>
        <ViewSupplierDetails history={props.history} supplier={supplier} reload={getSupplierDetails}/>
        <Footer/>
    </div>
}

export default ViewSupplier
