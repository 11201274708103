import React from 'react';
import routes from "../../constants/routes";
import constants from "../../constants/constants";
import moment from "moment";
import warningImg from '../../assets/img/warning-red.png'
import closeBlack from '../../assets/img/close-black.png'
import './globalComponents.scss'
import Cookies from "js-cookie";
import * as services from "../../service/auth";
import {createUser} from "../../constants/commonFunc";
import {connect} from "react-redux";
import * as userActions from '../../store/domain/user/action'
import {ToastUtil} from "../../constants/toastUtil";

class Error extends React.Component {

    state = {
        view: false,
        expiryDate: '',
        diff: 1,
        packageType: ''
    }

    componentDidMount() {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            this.getAllData()
        }
    }

    getAllData = async () => {
        await services.getExpireData().then(async (res) => {
            if (res.success) {
                await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)));
                this.props.setAuth(createUser(res.body));

                const user = createUser(res.body);
                if (user === undefined || user === null) {
                    this.setState({view: false})
                } else {
                    if (user.userType === constants.USER_BUYER) {
                        this.setState({view: false})
                    } else {
                        const diff = moment(user.expiryDate).diff(moment(), 'days');
                        if (diff < 7) {
                            this.setState({
                                diff,
                                view: 'ERROR',
                                expiryDate: moment(user.expiryDate).format(constants.DATE_FORMAT_EXTEND),
                                packageType: res.body.account.sellerType
                            })
                        } else {
                            if (user.billingProof === null || user.profilePicture === null || user.coverImage === null) {
                                this.setState({view: 'VERIFY'})
                            }
                        }
                    }
                }
            }
        })
    }

    render() {
        return (Cookies.get(constants.ACCESS_TOKEN) !== undefined && this.state.view && this.state.view === 'ERROR') ?
            <div className="error">
                <div className={'error-inner'}>
                    <img src={warningImg} height={25} width={25} className={'img-warning'}/>
                    <span className="text">Your account will expire in {this.state.expiryDate}. For a better experience {this.state.packageType === 'Trial' ? 'upgrade' : 'renew or upgrade'} your account.</span>
                    <div className="display-flex flex-row justify-content-end">
                        <button className="btn-upgrade"
                                onClick={() => window.location = routes.myProfile}>{this.state.packageType === 'Trial' ? 'Upgrade' : 'Renew or Upgrade'}
                        </button>
                        <img onClick={() => this.setState({view: false})} className={'btn-close ml-3 mt-1'}
                             src={closeBlack}
                             height={20} width={20}/>
                    </div>
                </div>
            </div> : (Cookies.get(constants.ACCESS_TOKEN) !== undefined && this.state.view && this.state.view === 'VERIFY') ?
                <div className="error bg-white">
                    <div className={'error-inner'}>
                        <img src={warningImg} height={25} width={25} className={'img-warning'}/>
                        <span className="text">Upload your company logo, cover image and business
                        registration in order to get the
                        'B2B verified Supplier' badge.</span>
                        <div className="display-flex flex-row justify-content-end">
                            <button className="btn-upgrade" onClick={() => window.location = routes.myProfile}>Upload
                            </button>

                            <img onClick={() => this.setState({view: false})} className={'btn-close ml-3 mt-1'}
                                 src={closeBlack}
                                 height={20} width={20}/>
                        </div>
                    </div>
                </div> : null

    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Error);
