import React from "react";
import './filter.scss'
import { DatePicker, Select } from "antd";
import constants, { categoryActions, DISTRICTS, QUOTATIONS_STATUS } from "../../constants/constants";
import moment from "moment";
import { isBrowser } from "react-device-detect";
import { getCategories } from "../../service/category";
import { Switch } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import strings from "../../locals/string.json";
import Cookies from "js-cookie";
import { getOnlySupplierCategories } from "../../service/suppliers";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges, defaultStaticRangesClosing } from "./DefaultRange";
import { pl, enGB } from "date-fns/locale";
import { Modal } from "react-bootstrap";

const { Option } = Select;
const { RangePicker } = DatePicker;
const pr = strings.filter;
const staticRangesLabels = {
    Today: "Today",
    Tomorrow: "Tomorrow",
    Yesterday: "Yesterday",
    "This Week": "This Week",
    "Last Week": "Last Week",
    "Next Week": "Next Week",
    "This Month": "This Month"
};

const staticRangesLabelsClosing = {
    Today: "Today",
    Tomorrow: "Tomorrow",
}

class Filter extends React.Component {

    state = {
        categories: [],
        categoriesCopy: [],
        selectedSuperCategory: -1,
        selectedCategory: -1,
        selectedSubCategory: -1,
        viewCat: false,
        viewSub: false,
        location: null,
        status: null,
        publishedDate: {
            startDate: "",
            endDate: "",
            key: "selection"
        },
        closingDate: {
            startDate: "",
            endDate: "",
            key: "selection"
        },
        searchValue: '',
        myCategory: window.location.pathname.split('/')[1] === 'products' ? false : Cookies.get(constants.ACCESS_TOKEN) === undefined ? false : this.props.userType === constants.USER_SELLER ? true : false,
        isDateModalPublish: false,
        isDateModalClosing: false,
        publishedDateStartString: '',
        publishedDateEndString: '',
        closingDateStartString: '',
        closingDateEndString: '',
        isExpand:false
    }

    componentWillMount() {
        this.getCategories();
    }

    getCategories = () => {
        if (this.props.supplier !== null && this.props.supplier !== undefined) {
            this.props.category && getOnlySupplierCategories(this.props.supplier.id)
                .then(response => {
                    let dummyCat = []
                    if (response.success) {
                        // response.body.map((cat) => {
                        //     if (cat.name !== 'DEFAULT') {
                        //         dummyCat.push(cat)
                        //     }
                        // })

                        this.setState({
                            categories: response.body,
                            categoriesCopy: response.body
                        })
                        this.props.onFilterItemChange('categoryList', response.body);
                    }
                })
        } else {
            this.props.category && getCategories(this.state.myCategory ? categoryActions.AVAILABLE : categoryActions.GET_ALL, this.props.userType === constants.USER_SELLER)
                .then(response => {
                    let dummyCat = []
                    if (response.success) {
                        // response.body.map((cat) => {
                        //     if (cat.name !== 'DEFAULT') {
                        //         dummyCat.push(cat)
                        //     }
                        // })

                        this.setState({
                            categories: response.body,
                            categoriesCopy: response.body
                        })
                        this.props.onFilterItemChange('categoryList', response.body);
                    }
                })
        }

    }

    onSubCategoryChange = (id) => {
        this.setState({ selectedSubCategory: id });
        this.props.onFilterItemChange('selectedSubCategory', id, null, true);
    }

    onLocationSelect = (location) => {
        this.setState({ location });
        this.props.onFilterItemChange('location', location, null, true);
    }

    onClear = () => {
        this.setState({
            selectedCategory: -1,
            selectedSubCategory: -1,
            selectedSuperCategory: -1,
            viewSub: false,
            location: null,
            status: null,
            publishedDate: {
                startDate: "",
                endDate: "",
                key: "selection"
            },
            closingDate: {
                startDate: "",
                endDate: "",
                key: "selection"
            },
            searchValue: '',
            publishedDateStartString: '',
            publishedDateEndString: '',
            closingDateStartString: '',
            closingDateEndString: '',
            isDateModalPublish: false,
            isDateModalClosing: false
            // categoriesCopy: []
        })
        this.props.onClear()
        this.getCategories();
    }

    onFilterSearch = async (e) => {
        const currValue = e.target.value;
        await this.setState({
            searchValue: currValue
        })
        let dummySuperArr = []
        let finalArray = []

        if(currValue === ""){
            this.onClear();
           await this.setState({isExpand:false})
        }else {
           await this.setState({isExpand:true})
        }

        this.state.categories.filter((superCat, iSuper) => {
            if (superCat.name.toLowerCase().includes(currValue.toLowerCase().trim())) {
                dummySuperArr.push(superCat)
            } else {
                let tempMainCat = []
                superCat.category.filter((mainCat, iMain) => {
                    if (mainCat.name.toLowerCase().includes(currValue.toLowerCase().trim())) {
                        tempMainCat.push(mainCat)
                    } else {
                        let tempSubCat = []
                        mainCat.subCategory.map((subCat, iSub) => {
                            if (subCat.name.toLowerCase().includes(currValue.toLowerCase().trim())) {
                                tempSubCat.push(subCat)
                            }
                        })
                        if (tempSubCat.length > 0) tempMainCat.push({...mainCat, subCategory: tempSubCat})
                    }
                })
                if (tempMainCat.length > 0) dummySuperArr.push({...superCat, category: tempMainCat})
            }
        });

        if (currValue.trim() !== '') {
            this.setState({
                ...this.state,
                categoriesCopy: dummySuperArr,
                viewCat: true,
                selectedSuperCategory: 0,
                viewSub: true,
                selectedCategory: 0
            })
        } else {
            this.setState({
                ...this.state,
                categoriesCopy: finalArray,
                viewCat: false,
                selectedSuperCategory: -1,
                viewSub: false,
                selectedCategory: -1
            })
        }
    }

    dateModalHandler = (value, type) => {
        if (type === "PUBLISHED") {
            this.setState({
                isDateModalPublish: true
            })
        } else {
            this.setState({
                isDateModalClosing: true
            })
        }
    }

    handleOnChange = async (ranges) => {
        const { selection } = ranges;
        if (selection.endDate === null) {
            this.setState({
                publishedDate: {
                    startDate: selection.startDate,
                    endDate: selection.startDate,
                    key: "selection"
                },
                publishedDateStartString: moment(selection.startDate).format(constants.DATE_FORMAT),
                publishedDateEndString: moment(selection.startDate).format(constants.DATE_FORMAT),
            })
        } else {
            await this.setState({
                publishedDate: selection,
                publishedDateStartString: moment(selection.startDate).format(constants.DATE_FORMAT),
                publishedDateEndString: moment(selection.endDate).format(constants.DATE_FORMAT),
            });
        }

        this.props.onFilterItemChange('publishedDate', moment(selection.startDate), moment(selection.endDate === null ? selection.startDate : selection.endDate), true)
        if (
            moment(selection.startDate).format("MM-DD-YYYY") !==
            moment(selection.endDate).format("MM-DD-YYYY")
        ) {
            this.setState({ isDateModalPublish: false });
        } else if (selection.startDate === "" && selection.endDate === "") {
            this.setState({ isDateModalPublish: false });
        }

    }

    handleOnChangeClosing = async (ranges) => {
        const { selection } = ranges;
        if (selection.endDate === null) {
            this.setState({
                closingDate: {
                    startDate: selection.startDate,
                    endDate: selection.startDate,
                    key: "selection"
                },
                closingDateStartString: moment(selection.startDate).format(constants.DATE_FORMAT),
                closingDateEndString: moment(selection.startDate).format(constants.DATE_FORMAT),
            })
        } else {
            await this.setState({
                closingDate: selection,
                closingDateStartString: moment(selection.startDate).format(constants.DATE_FORMAT),
                closingDateEndString: moment(selection.endDate).format(constants.DATE_FORMAT),
            });
        }
        this.props.onFilterItemChange('closingDate', moment(selection.startDate), moment(selection.endDate === null ? selection.startDate : selection.endDate), true)
        if (
            moment(selection.startDate).format("MM-DD-YYYY") !==
            moment(selection.endDate).format("MM-DD-YYYY")
        ) {
            this.setState({ isDateModalClosing: false });
        } else if (selection.startDate === "" && selection.endDate === "") {
            this.setState({ isDateModalClosing: false });
        }

    }

    translateRange(dictionary) {
        return (item) =>
            dictionary[item.label]
                ? { ...item, label: dictionary[item.label] }
                : item;
    }

    ruStaticRanges = defaultStaticRanges.map(
        this.translateRange(staticRangesLabels)
    );

    ruStaticRangesClosing = defaultStaticRangesClosing.map(
        this.translateRange(staticRangesLabels)
    );

    closeHandler = async () => {
        await this.setState({
            isDateModalPublish: false,
            publishedDate: {
                startDate: "",
                endDate: "",
                key: "selection"
            },
        })
    }

    onClose = (type) => {
        if (type === "PUBLISHED") {
            this.setState({
                isDateModalPublish: false
            })
        } else {
            this.setState({
                isDateModalClosing: false
            })
        }
    }

    render() {

        const { publishedDate, category, closingDate, location, isLocation, advance, quoteStatus } = this.props

        function disabledDate(current) {
            return current && current > moment().endOf('day');
        }

        const selectionRange = {
            startDate: "",
            endDate: "",
            key: 'selection',
        }

        const content = <div className="col-xl-3 col-lg-4 sitebar filter">
            {/*<h6 className="filter-title mb-4">Filter</h6>*/}
            <form className="widget widget-sidebar-search-wrap">
                <div className="widget-sidebar-search">

                    {
                        category &&
                        <div className="widget-sidebar-item-wrap rld-single-select">
                            <div className="inputTitle mb-3">FILTER CATEGORIES</div>
                            <hr />
                            {
                                (this.props.categoryFilter && (this.props.loggedInStatus && this.props.userType === constants.USER_SELLER)) &&
                                <>
                                    <div className="display-flex flex-row justify-content-between">
                                        <p className="myCat">My Categories</p>
                                        <Switch checked={this.state.myCategory}
                                            onChange={async value => {
                                                await this.setState({ myCategory: value });
                                                this.props.onMyCategory('myCategory', value);
                                                this.getCategories();
                                            }} />
                                    </div>
                                    <div className="separator mb-3" />
                                </>
                            }
                            {this.state.categories.length > 0 && <div className="rld-single-input left-icon mb-2">
                                <input
                                    type="text" placeholder={pr.searchPlaceholder}
                                    onChange={this.onFilterSearch}
                                    value={this.state.searchValue}
                                />
                            </div>
                            }
                            {
                                this.state.categoriesCopy.map((itemSuper, iSuper) => {
                                    return <>
                                        <div className={'superCategory ml-1 mr-1'}
                                            onClick={() => {
                                                this.setState({
                                                    viewCat: iSuper !== this.state.selectedSuperCategory,
                                                    selectedSuperCategory: iSuper !== this.state.selectedSuperCategory ? iSuper : -1,
                                                    viewSub: false,
                                                    selectedCategory: -1,
                                                    isExpand:false
                                                })
                                            }}
                                        >
                                            <p className={"superCatName" + (this.state.selectedSuperCategory === iSuper || this.state.isExpand ? " highlight" : "")}>{itemSuper.name}</p>
                                            <i className="fa fa-chevron-down superArrowIcon" />
                                        </div>
                                        {
                                            itemSuper.category.map((itemMain, i) => {
                                                if ((this.state.viewCat && this.state.selectedSuperCategory === iSuper) || this.state.isExpand) {
                                                    return <>
                                                        <div key={i} className="catHolder"
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewSub: i !== this.state.selectedCategory,
                                                                    selectedCategory: i !== this.state.selectedCategory ? i : -1,
                                                                    isExpand:false
                                                                })
                                                            }}>
                                                            <p className={"catName" + (this.state.selectedCategory === i ? " highlight" : "")}>{itemMain.name}</p>
                                                            <i className="fa fa-chevron-down arrowIcon" />
                                                        </div>
                                                        {
                                                            itemMain.subCategory.map((sub, index) => {
                                                                if ((this.state.viewSub && this.state.selectedCategory === i) || this.state.isExpand)
                                                                    return <p
                                                                        key={index}
                                                                        onClick={() => this.onSubCategoryChange(sub.id)}
                                                                        className={"subCat" + (this.state.selectedSubCategory === sub.id ? " highlight-black" : "")}
                                                                    >{sub.name}</p>
                                                            })
                                                        }
                                                    </>
                                                }
                                            })
                                        }
                                    </>
                                }
                                )
                            }
                        </div>
                    }

                    {
                        quoteStatus &&
                        <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                            <div className="inputTitle">Status</div>
                            <Select
                                value={this.state.status}
                                showSearch
                                className="select"
                                placeholder="Status"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={status => {
                                    this.setState({ status });
                                    this.props.onFilterItemChange('status', status, null, true);
                                }}
                            >
                                {
                                    QUOTATIONS_STATUS.map((item, i) => (
                                        <Option key={i} value={item}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {
                        isLocation &&
                        <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                            {/*<div className="inputTitle">Location</div>*/}
                            <Select
                                value={this.state.location}
                                showSearch
                                className="select"
                                placeholder="Location"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={this.onLocationSelect}
                            >
                                {
                                    DISTRICTS.map((item, i) => (
                                        <Option key={i} value={item}>{item}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }

                    {
                        publishedDate &&
                        <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                            <div className="inputTitle">Published Date</div>
                            {/* <RangePicker
                                value={this.state.publishedDate}
                                placeholder={['From', 'To']}
                                // disabledDate={disabledDate}
                                onChange={async data => {
                                    await this.setState({ publishedDate: data });
                                    this.props.onFilterItemChange('publishedDate', data[0], data[1], true)
                                }}
                                format={constants.DATE_FORMAT}
                                allowClear={false}
                            /> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="ant-picker ant-picker-range ant-picker-focused" onClick={() => this.dateModalHandler(true, "PUBLISHED")}>
                                    <div class="ant-picker-input ant-picker-input-active"><input placeholder="From" size="12" autocomplete="off" value={this.state.publishedDate.startDate !== "" ? this.state.publishedDateStartString : ''} /></div>
                                    <div class="ant-picker-range-separator"><span aria-label="to" class="ant-picker-separator"><span role="img" aria-label="swap-right" class="anticon anticon-swap-right"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="swap-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z"></path></svg></span></span></div>
                                    <div class="ant-picker-input"><input readonly="" placeholder="To" size="12" autocomplete="off" value={this.state.publishedDate.endDate !== "" ? this.state.publishedDateEndString : ''} /></div>
                                    {/* {
                                        this.state.isDateModalPublish ?
                                            <i
                                                onClick={() => this.closeHandler()} aria-label="icon: close-circle" class="anticon anticon-close-circle pl-2"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></i>
                                            :
                                            <span class="ant-picker-suffix"><span role="img" aria-label="calendar" class="anticon anticon-calendar"><svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg></span></span>
                                    } */}
                                    <span class="ant-picker-suffix"><span role="img" aria-label="calendar" class="anticon anticon-calendar"><svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg></span></span>


                                </div>
                            </div>
                            {
                                this.state.isDateModalPublish ?
                                    <Modal
                                        className="modal-content-rewamp"
                                        show={true}
                                        onHide={() => this.onClose("PUBLISHED")}
                                        // onShow={() => {
                                        //     const email = (this.props.user !== undefined && this.props.user !== null) ? (this.props.user.email !== null ? this.props.user.email : '') : ''
                                        //     this.setState({
                                        //         inquire: this.props.inquire ?? false,
                                        //         email: email
                                        //     })
                                        // }}
                                        backdrop="static"
                                        keyboard={false}
                                        size="lg"
                                    >
                                        <Modal.Header closeButton={true}>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <DateRangePicker
                                                ranges={[this.state.publishedDate]}
                                                rangeColors={["#007bff"]}
                                                onChange={this.handleOnChange}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                // months={2}
                                                editableDateInputs={true}
                                                dateDisplayFormat="MM-dd-yyyy"
                                                maxDate={new Date()}
                                                direction="horizontal"
                                                locale={enGB}
                                                staticRanges={this.ruStaticRanges}
                                            />

                                        </Modal.Body>
                                    </Modal>

                                    : null
                            }
                        </div>
                    }

                    {
                        closingDate &&
                        <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                            <div className="inputTitle">Closing Date</div>
                            {/* <RangePicker
                                value={this.state.closingDate}
                                placeholder={['From', 'To']}
                                onChange={async data => {
                                    await this.setState({ closingDate: data });
                                    this.props.onFilterItemChange('closingDate', data[0], data[1], true)
                                }}
                                format={constants.DATE_FORMAT}
                                allowClear={false}
                            /> */}
                            <div class="ant-picker ant-picker-range ant-picker-focused" onClick={() => this.dateModalHandler(true, "CLOSING")}>
                                <div class="ant-picker-input ant-picker-input-active"><input placeholder="From" size="12" autocomplete="off" value={this.state.closingDate.startDate !== "" ? this.state.closingDateStartString : ''} /></div>
                                <div class="ant-picker-range-separator"><span aria-label="to" class="ant-picker-separator"><span role="img" aria-label="swap-right" class="anticon anticon-swap-right"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="swap-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z"></path></svg></span></span></div>
                                <div class="ant-picker-input"><input readonly="" placeholder="To" size="12" autocomplete="off" value={this.state.closingDate.endDate !== "" ? this.state.closingDateEndString : ''} /></div>
                                <span class="ant-picker-suffix"><span role="img" aria-label="calendar" class="anticon anticon-calendar"><svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg></span></span>

                            </div>
                            {
                                this.state.isDateModalClosing ?
                                    <Modal
                                        className="modal-content-rewamp"
                                        show={true}
                                        onHide={() => this.onClose("CLOSING")}
                                        // onShow={() => {
                                        //     const email = (this.props.user !== undefined && this.props.user !== null) ? (this.props.user.email !== null ? this.props.user.email : '') : ''
                                        //     this.setState({
                                        //         inquire: this.props.inquire ?? false,
                                        //         email: email
                                        //     })
                                        // }}
                                        backdrop="static"
                                        keyboard={false}
                                        size="lg"
                                    >
                                        <Modal.Header closeButton={true}>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <DateRangePicker
                                                ranges={[this.state.closingDate]}
                                                rangeColors={["#007bff"]}
                                                onChange={this.handleOnChangeClosing}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                // months={2}
                                                editableDateInputs={true}
                                                dateDisplayFormat="MM-dd-yyyy"
                                                // maxDate={new Date()}
                                                direction="horizontal"
                                                locale={enGB}
                                                staticRanges={this.ruStaticRangesClosing}
                                            />

                                        </Modal.Body>
                                    </Modal>

                                    : null
                            }
                        </div>
                    }
                </div>
                <div className="btn-wrap text-center">
                    <button type="button" className="btn btn-yellow" style={{ width: '100%' }}
                        onClick={this.onClear}>CLEAR FILTER
                    </button>
                    {/*<button type="button" className="btn btn-yellow">Apply</button>*/}
                </div>
            </form>
        </div>

        return isBrowser ? content : advance ? content : null
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(Filter));
