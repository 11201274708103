import React from "react";
import {Modal} from "react-bootstrap";
import '../oauth/oauth.scss'
import 'antd/dist/antd.css';
import {Steps} from "antd";
import strings from "../../locals/string.json";
import constants, {__DEV__, categoryActions} from "../../constants/constants";
import {Assets} from "../../assets";
import StepOne from '../oauth/registrationStep01';
import StepTwo from '../oauth/registrationStep2';
import StepThree from '../oauth/registrationStep03';
import Payment from '../oauth/payment';
import {isBrowser} from "react-device-detect";
import * as commonFunc from "../../constants/commonFunc";
import {createUser, createUserRegDetails} from "../../constants/commonFunc";
import {
    SEARCH_SPACE_REGEXP,
    SEARCH_VALUE_REGEXP,
    validateBuyerRegDetails,
    validateCategory, validatePromoCode
} from "../../constants/validation";
import {
    checkCompanyUrlAvailability,
    checkEmailAvailability, checkETUserDiscount,
    checkPromoCode,
    getSubscriptions, makePayment,
    register,
    trackUser,
    get_company_types,
    get_packages_from_company_type,
    renewPackage
} from "../../service/auth";
import {ToastUtil} from "../../constants/toastUtil";
import routes from "../../constants/routes";
import {getCategories} from "../../service/category";
import {handleErrors, WebXPayTokenizeInit} from "../../constants/payment";
import getCroppedImg, {dataURLtoFile} from "../../constants/cropImage";
import Cropper from "react-easy-crop";
import $ from 'jquery'
import apiConfig from "../../service/apiConfig";
import Cookies from "js-cookie";
import * as userActions from "../../store/domain/user/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import swal from "sweetalert";
import * as Constants from "../../constants/constants";

const registration = strings.registration;
const toast = strings.toast;
const modals = strings.modals;
const {Step} = Steps;

class changeSubscriptionModal extends React.Component {

    state = {
        step: 4,
        firstName: '',
        lastName: '',
        email: '',
        password: __DEV__ ? '12345678' : '',
        confirmPassword: __DEV__ ? '12345678' : '',
        companyName: __DEV__ ? 'Ceyentra' : '',
        companyUrl: __DEV__ ? apiConfig.frontEnd + 'ceyentra122' : '',
        designation: __DEV__ ? 'Software Engineer' : '',
        website: __DEV__ ? 'ceyentra.com' : '',
        mobile: '',
        hotline: '',
        land: '',
        address: __DEV__ ? 'Panadura' : '',
        billingProof: undefined,
        billingProofType: undefined,
        billingProofFile: undefined,
        category: [],
        subscription_plan: 0,
        packages: [],
        cat1: undefined,
        cat2: undefined,
        cat3: undefined,
        location: null,
        nameOnTHeCard: '',
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cvv: '',
        promo: '',
        applyPromo: false,
        viewBreakDown: true,
        secondaryEmail: '',
        secondaryEmails: [],

        catIdArray: [],
        categories: [],
        subCategory: [],

        imageType: undefined,
        imageFile: undefined,
        image: null,
        coverImageFile: undefined,
        coverImageType: undefined,
        coverImage: null,
        crop: {x: 100, y: 100},
        zoom: 1,
        open: false,
        isProfile: true,
        percentage: 0,
        maximumAmount: 0,
        etUserDiscount: 0,
        isValidation: false,
        companyType: null,
        companyTypeData: [],
        promoCodeId: ''
    }

    componentWillMount() {
        this.setState({
            subscription_plan: this.props.subscription_plan.subscriptionPlan.id - 1
        })
    }

    onTextChange = async (event) => {
        if (event.target.name === 'promo') {
            if (event.target.value === '') {
                await this.setState({
                    [event.target.name]: event.target.value,
                    percentage: 0,
                    maximumAmount: 0,
                    promoCodeId: 0
                })
            } else {
                await this.setState({
                    [event.target.name]: event.target.value,
                })
            }
        } else {
            await this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onCardDetailsChange = async (name, value) => {
        await this.setState({
            [name]: value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                billingProofType: file.type,
                billingProofFile: file,
                billingProof: imageDataUrl
            });
        }
    };

    onFilesChange1 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                imageType: file.type,
                imageFile: file,
                image: imageDataUrl,
                open: true,
                isProfile: true
            });
        }
    };

    onModalHandler = () => {
        this.paymentHandler(this.state);
        this.getSubscriptionPackages();
        this.checkETUserDiscount();
    }

    onFilesChange2 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                coverImageType: file.type,
                coverImageFile: file,
                coverImage: imageDataUrl,
                isProfile: false,
                open: true
            });
        }
    };

    // this.props.subscription_plan.subscriptionPlan.price

    getSubscriptionPackages = (id) => {
        const companyTypeId = this.props.user.companyType.id
        this.props.userType === constants.USER_SELLER ?
            get_packages_from_company_type(companyTypeId)
                .then(response => {
                    if (response.success) {
                        let obj = response.body
                        obj[this.props.subscription_plan.subscriptionPlan.id - 1].price = this.props.props.state.renewalFee
                        obj[this.props.subscription_plan.subscriptionPlan.id - 1].categoryCount = this.props.props.user.categoryCount
                        obj[this.props.subscription_plan.subscriptionPlan.id - 1].emailCount = this.props.props.user.emailCount
                        this.setState({
                            packages: obj,
                        });
                        this.paymentHandler(this.state);
                        if (this.props.becomeSeller) {
                            this.setState({
                                subscription_plan: this.props.subscription_plan.subscriptionPlan.id - 1,
                                step: 1,
                            });
                            if (this.props.loggedInStatus) {
                                this.setState({
                                    firstName: this.props.user.firstName,
                                    lastName: this.props.user.lastName,
                                    email: this.props.user.email,
                                })
                            }

                        }

                        this.checkETUserDiscount()
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                }) : this.setState({step: 2})
    }

    onButtonClick = () => {
        if (this.state.open) {
            this.setState({
                open: false,
                [this.state.isProfile ? 'image' : 'coverImage']: this.state.imageCrop
            });
            return;
        }

        if (this.props.userType === constants.USER_BUYER) {
            this.setState({isValidation: true})
            if (validateBuyerRegDetails(this.state, undefined, false)) {
                this.checkEmailAvailability();
            }
        } else {
            if (this.state.step === 2) {
                this.setState({step: 3})
                return;
            }
            if (this.state.step === 1) {
                this.setState({isValidation: true})
                if (validateBuyerRegDetails(this.state, undefined, true, false)) {
                    this.checkEmailAvailability();
                    return;
                }
                return;
            }
            if (this.state.step === 3 && validateCategory(this.state)) {
                this.saveUser();
            }
        }
    }

    checkEmailAvailability = () => {
        this.setState({isValidation: false})
        const userType = this.props.userType;
        checkEmailAvailability(this.state.email, this.props.becomeSeller ?? false)
            .then(response => {
                if (response.success) {
                    if (userType === constants.USER_SELLER) {
                        this.checkCompanyUrlAvailability(true);
                    } else {
                        this.saveUser();
                    }
                } else {
                    if (response.status && response.status === constants.EXISTING_E_TENDER_SELLER) {
                        if (userType === constants.USER_SELLER) {
                            this.checkCompanyUrlAvailability(true);
                        } else {
                            this.saveUser();
                        }
                    } else {
                        ToastUtil.showErrorToast(response.msg);
                        if (response.status) {
                            this.props.history.push(routes.login, {error: response.status});
                        }
                    }
                }
            })
    }

    checkCompanyUrlAvailability = (btnClick) => {
        const companyUrl = this.state.companyUrl;
        companyUrl !== apiConfig.frontEnd ?
            checkCompanyUrlAvailability(companyUrl.split(apiConfig.frontEnd)[1])
                .then(response => {
                    if (response.success) {
                        btnClick && this.getCategories();
                    } else {
                        btnClick && ToastUtil.showErrorToast(response.msg);
                        this.setState({companyUrl: companyUrl + Math.floor(Math.random() * 100)})
                    }
                }) : btnClick && this.getCategories();
    }

    trackUserRegistration = () => {
        const obj = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobileNumber: this.state.mobile,
            address: this.state.address,
            companyName: this.state.companyName === '' ? null : this.state.companyName
        }
        trackUser(obj)
            .then(response => {
            })
    }

    onShowConfirmtion = (name, email) => {
        swal({
            title: modals.hi + name + ",",
            text: toast.trialAccountCreateSeller,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Ok", value: "action", className: "swalSuccess"},
            }
        })
    }

    saveUser = () => {
        this.setState({isValidation: false})
        const userType = this.props.userType;
        register(createUserRegDetails({...this.state, userType}))
            .then(response => {
                if (response.success) {
                    Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(constants.ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.user)));
                    this.props.setAuth(createUser(response.user));
                    this.props.setUserType(response.user.userType);

                    if (userType === constants.USER_SELLER) {
                        if (this.state.subscription_plan !== 0) {
                            this.paymentHandler(this.state);
                            this.setState({step: 4});
                            this.checkETUserDiscount();
                        } else {
                            this.onShowConfirmtion(response.user.firstName, response.user.email)
                            this.props.history.push(routes.home);
                        }
                    } else {
                        this.props.history.push(routes.home);
                    }
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    onClear = () => {
        this.setState({
            step: 1,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            companyName: '',
            companyUrl: '',
            designation: '',
            website: '',
            address: '',
            mobile: '',
            hotline: '',
            land: '',
            billingProof: undefined,
            billingProofType: undefined,
            billingProofFile: undefined,
            imageType: undefined,
            imageFile: undefined,
            image: null,
            coverImageFile: undefined,
            coverImageType: undefined,
            coverImage: null,
            category: [],
            subCategory: [],
            subscription_plan: 1,
            categories: [],
            packages: [],
            cat1: undefined,
            cat2: undefined,
            cat3: undefined,
            nameOnTHeCard: '',
            cardNumber: '',
            expDate: '',
            cvv: '',
            promo: '',
            applyPromo: false,
            viewBreakDown: true,
            secondaryEmail: '',
            catIdArray: [],
            percentage: 0,
            maximumAmount: 0,
            etUserDiscount: 0,
            isValidation: false
        });
        this.props.close();
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const result = await getCroppedImg(this.state.isProfile ? this.state.image : this.state.coverImage, croppedAreaPixels, 0);
        const file = dataURLtoFile(result, 'product-image.jpg')
        file.preview = {
            type: "image",
            url: result
        }
        file.extension = "jpg"
        file.id = "files-1"
        await this.setState({
            imageCrop: result,
            [this.state.isProfile ? 'imageFile' : 'coverImageFile']: file
        })
    }

    onClose = () => {
        this.setState({isValidation: false})
        if (this.state.open) {
            this.setState({
                [this.state.isProfile ? 'image' : 'coverImage']: null,
                open: false
            })
        } else {
            this.onClear()
        }
    }

    removeImage = type => {
        if (type === 'PROFILE') {
            this.setState({
                imageType: undefined,
                imageFile: undefined,
                image: null,
            })
        } else {
            this.setState({
                coverImageFile: undefined,
                coverImageType: undefined,
                coverImage: null,
            })
        }
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    checkPromoCode = () => {
        if (validatePromoCode(this.state)) {
            checkPromoCode(this.state.promo)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            percentage: response.body.discountPercentage,
                            maximumAmount: response.body.maxDiscount,
                            promoCodeId: response.body.id
                        })
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    checkETUserDiscount = () => {
        checkETUserDiscount(this.props.user.email)
            .then(res => {
                if (res.success) {
                    let price = this.state.packages[this.state.subscription_plan].price
                    let discount = (price * res.body.discount) / 100

                    this.setState({
                        ...this.state,
                        etUserDiscount: res.body.suitable ? discount : 0,
                        suitableForDiscount: res.body.suitable
                    })
                }
            })
    }

    paymentHandler = () => {
        let script = document.createElement('script');
        script.setAttribute('src', constants.gatewayUrl);
        document.head.appendChild(script);
        const local = this;
        script.onload = function () {
            WebXPayTokenizeInit(
                {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                    nameOnCard: "#cardholder-name",
                },
                local.afterInit,
            );
        };
        script.onerror = function (e) {
            console.log(e)
        }
    }

    afterInit = (GenerateSession) => {
        const local = this
        $('#save-card-button').click(function () {
            GenerateSession(
                function (session) {
                    local.makePayment(session)
                },
                function (error) {
                    handleErrors(error);
                }
            );
        });
    }

    makePayment = (session) => {
        const plan = this.state.packages[this.state.subscription_plan]
        let discount = Math.round((plan.price * this.state.percentage) / 100).toFixed(2)
        if (discount > this.state.maximumAmount)
            discount = this.state.maximumAmount;
        let totalDiscount = (Number(discount) + this.state.etUserDiscount).toFixed(2)

        const data = {
            email: this.props.user.email,
            amount: plan.price - totalDiscount,
            sessionId: session,
            secure3dResponseURL: Constants.paymentResponse,
            promoCodeId: this.state.promoCodeId === '' ? 0 : this.state.promoCodeId
        }
        renewPackage(data)
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    this.onClear();
                    this.props.loadHandler(true);
                    this.props.history.push(routes.myProfile)
                } else {
                    if(response.data.type === '3ds') {
                        this.props.history.push({
                            pathname: '/secure-3ds-redirection',
                            state: {html3ds: response.data.html3ds},
                        });
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                }
            })
    }

    render() {
        const userType = this.props.userType;
        return <Modal
            className="register"
            show={this.props.open}
            onShow={this.getSubscriptionPackages}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                {
                    userType === constants.USER_SELLER && (this.state.step !== 1 && this.state.step !== 4) &&
                    <img className="pointer" src={Assets.back} alt="back"
                         onClick={() => this.setState({step: this.state.step - 1})}/>
                }
                <Modal.Title>{this.state.open ? 'Crop Image' : this.state.step === 4 ? registration.payment : registration.subscriptionUpdate}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.open ?
                        <Cropper
                            image={this.state.isProfile ? this.state.image : this.state.coverImage}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.isProfile ? 4 / 4 : 16 / 5}
                            onCropChange={value => this.setState({crop: value})}
                            onZoomChange={value => this.setState({zoom: value})}
                            onCropComplete={this.onCropComplete}
                        /> :
                        <>
                            {
                                (userType === constants.USER_SELLER && this.state.step !== 4) &&
                                <Steps current={this.state.step - 1}>
                                    <Step title={isBrowser ? "Renew Subscription Plan" : ""}/>
                                    {/* <Step title={isBrowser ? "Basic Information" : ""} />
                                    <Step title={isBrowser ? "Categories" : ""} /> */}
                                </Steps>
                            }
                            {
                                this.state.step === 2 && this.state.packages.length !== 0 &&
                                <StepThree
                                    state={{...this.state, userType}}
                                    subscription_plan={this.state.subscription_plan}
                                    onSelectSubscription={(id) => this.setState({subscription_plan: id})}/>
                            }
                            {
                                this.state.step === 4 && this.state.packages.length !== 0 &&
                                <Payment
                                    state={{...this.state}}
                                    onTextChange={this.onTextChange}
                                    applyPromo={this.checkPromoCode}
                                    viewBreakDown={() => this.setState({viewBreakDown: !this.state.viewBreakDown})}
                                    onSelectSubscription={(id) => this.setState({subscription_plan: id})}/>
                            }
                        </>
                }

            </Modal.Body>
            {
                this.state.step === 3 &&
                <p className="warningBeforReg"><span>*</span>Please note - If you are unable to
                    complete the payment process after the registration, your account will be
                    created as a trial user. You may need to complete payment after the trial period.
                </p>
            }

            <Modal.Footer>
                <button className="btn btn-white" onClick={this.onClose}>{registration.cancel}</button>
                {
                    this.state.step !== 4 &&
                    <button className="btn btn-yellow"
                            onClick={this.onButtonClick}>{this.state.open ? "Crop" : userType === constants.USER_BUYER ? registration.register :
                        this.state.step !== 3 ? registration.next : "Register"}</button>
                }
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(changeSubscriptionModal));
