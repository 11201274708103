import React, { Component } from 'react';
import { Assets } from "../../assets";

class OurStory extends Component {


    render() {

        return <>
            <div className="section-title mb-5">
                <h2 className="title inner-title text-center">Why Use B2B.lk</h2>
            </div>
            <div className="sell-home-area rld-1-3-bg pd-top-70 pd-bottom-70"
                style={{ backgroundImage: `url(${Assets.about3})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="shape-image-list-wrap">
                                <img className="shadow-img" src={Assets.about2} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-title pd-left mb-0">
                                <h2 className="title inner-title">Benefits to the Buyer</h2>
                                <ul>
                                    <li style={{ marginBottom: 17 }}>Prepare and send out your purchase request in less than 30 seconds</li>
                                    <li style={{ marginBottom: 17 }}>No need to go in search for suppliers, they will come to you</li>
                                    <li style={{ marginBottom: 17 }}>Your purchase request gets sent to a large number of potential sellers
                                        instantly
                                    </li>
                                    <li style={{ marginBottom: 17 }}>You receive SMS & EMAIL notifications each time a seller responds with a quote
                                    </li>
                                    <li style={{ marginBottom: 17 }}>Dashboard to easily manage, compare and shortlist the incoming quotations from
                                        the sellers.
                                    </li>
                                    <li style={{ marginBottom: 17 }}>Best part is that all these benefits come to you at no cost, it is absolutely
                                        FREE
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="process-area pd-top-100 pd-bottom-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1 order-lg-12 mb-4 mb-lg-0">
                            <div className="shape-image-list-wrap">
                                <div className="shape-image-list right-top">
                                    <img className="shadow-img" src={Assets.sellerAbout} alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 align-self-center order-lg-1">
                            <div className="section-title mb-0">
                                <h2 className="title inner-title">Benefits to the Seller</h2>
                                <ul>
                                    <li style={{ marginBottom: 17 }}>Get immediate Access to corporate / industrial sales leads.</li>
                                    <li style={{ marginBottom: 17 }}>Mini Business page for your company  to display company profile and products</li>
                                    <li style={{ marginBottom: 17 }}>Receive direct product inquiries from interested buyers for the displayed products.</li>
                                    <li style={{ marginBottom: 17 }}>Receive Email and SMS notifications for new requests relevant to your category and for product inquiries.</li>
                                    <li style={{ marginBottom: 17 }}>Dashboard to manage inquiries and send quotations to received requests.</li>
                                    <li style={{ marginBottom: 17 }}>Maintain Trusted supplier status</li>
                                    <li style={{ marginBottom: 17 }}>Receive foreign sales inquiries ( Maldives, Malta, Bangladesh and More )</li>
                                    <li style={{ marginBottom: 17 }}>Free Setup of Facebook, LinkedIn, Twitter Accounts and YouTube Channel ( If required )</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default OurStory
