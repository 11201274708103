import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual'
import "./index.css";
import 'react-dropdown-tree-select/dist/styles.css'

export default class Container extends Component {
    constructor(props) {
        super(props)
        this.state = { data: props.data, disabled: false }
        this.setClick = this.setClick.bind(this);
    }

    componentDidMount() {
        this.props.setClick(this.setClick);
    }

    async setClick() {

    }

    componentWillReceiveProps = (nextProps) => {
        if (!isEqual(nextProps.data, this.state.data)) {
            this.setState({ data: nextProps.data })
        }
    }

    shouldComponentUpdate = (nextProps) => {
        return !isEqual(nextProps.data, this.state.data)
    }

    render() {
        const { data, ...rest } = this.props
        return (
            <>
                <h6>
                    Select Categories
                </h6>
                <div>
                    (Please select the categories you are preferred.)
                </div>
                <br/>
                <DropdownTreeSelect data={this.state.data} {...rest} className="bootstrap-demo" />

            </>
        )
    }
}
